import { useState, useEffect, useCallback } from "react";

import {
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  getDaysInMonth,
  parse,
  setDate,
  startOfDay,
  startOfMonth,
} from "date-fns";
import {
  CalendarData,
  dayFormat,
  monthFormat,
  MonthData,
} from "@welldigital/components";

//import { getLocationId } from "../../../../services/auth";
import {
  getInstoreServiceAvailability,
  getInstoreServiceAvailabilityVariables,
  getInstoreServiceAvailability_getInstoreServiceAvailability_daySlots,
} from "graphql/__generated__/getInstoreServiceAvailability";
import { GET_INSTORE_SERVICE_AVAILABILITY } from "graphql/getInstoreServiceAvailability";
import useImperativeQuery from "hooks/useImperativeQuery";

export type UseFindAvailabilityResult = {
  errorMessage?: string;
  fetchMonthCalendarData: (formattedMonth: string) => Promise<void>;
  fetchSlotsInDay: (selectedDate: Date) => void;
  calendarData: CalendarData;
  slotsData?: TimeSlot[];
  isLoadingSlots: boolean;
  //locationId: string;
};

export type TimeSlot = {
  resourceId: string;
  startTime: Date;
  endTime: Date;
};

export function useFindAvailability(
  serviceId: string,
  minDate: Date,
  maxDate?: Date,
  locationId?: string,
): UseFindAvailabilityResult {
  const defaultErrorMessage = "There was an error fetching availability data.";
  const queryStringDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  const yearMonthDateFormat = "yyyy-MM-dd";
  const fullDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  const locationID = locationId;
  //const [locationID, setLocationID] = useState<string>("");
  const [calendarData, setCalendarData] = useState<CalendarData>({});
  const [slotsData, setSlotsData] = useState<TimeSlot[]>([]);
  const [fetchingCalendarData, setFetchingCalendarData] = useState<{
    [key: string]: { [key: string]: boolean };
  }>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     setLocationID(await getLocationId());
  //   })();
  // }, [setLocationID]);

  const getAvailabilityData = useImperativeQuery<getInstoreServiceAvailability>(
    GET_INSTORE_SERVICE_AVAILABILITY,
  );

  const setMonthData = useCallback(
    (
      formattedMonth: string,
      daySlots: (getInstoreServiceAvailability_getInstoreServiceAvailability_daySlots | null)[],
    ) => {
      const month = parse(formattedMonth, monthFormat, new Date());
      const monthData: MonthData = {};
      const availableDays = daySlots.map((slot) => slot?.date);
      const daysInMonth = getDaysInMonth(month);

      for (let i = 1; i <= daysInMonth; i++) {
        const day = setDate(month, i);
        const dayString = format(day, yearMonthDateFormat);
        if (!availableDays.includes(dayString)) {
          const formattedDay = format(day, dayFormat);
          monthData[formattedDay] = { isUnavailable: true };
        }
      }

      setCalendarData((oldState) => ({
        ...oldState,
        [serviceId]: {
          ...oldState[serviceId],
          [formattedMonth]: monthData,
        },
      }));
    },
    [serviceId],
  );

  const setFetchingMonth = useCallback(
    (formattedMonth: string, fetching: boolean) => {
      setFetchingCalendarData((oldState) => ({
        ...oldState,
        [serviceId]: {
          ...oldState[serviceId],
          [formattedMonth]: fetching,
        },
      }));
    },
    [serviceId],
  );

  const fetchNextTwoMonthsData = useCallback(
    async (minDate: Date, maxDate?: Date) => {
      if(locationID){
        const variables: getInstoreServiceAvailabilityVariables = {
          serviceId,
          startDate: format(minDate, fullDateFormat),
          endDate: format(
            maxDate || endOfMonth(addMonths(minDate, 2)),
            fullDateFormat,
          ),
          isDays: true,
          locationID,
          numberOfBooks: 1,
        };
        try {
          const { data, error } = await getAvailabilityData(variables);
  
          if (error) {
            setErrorMessage(error.message);
            return;
          }
  
          setMonthData(
            format(minDate, monthFormat),
            data?.getInstoreServiceAvailability?.daySlots || [],
          );
          setMonthData(
            format(maxDate || addMonths(minDate, 1), monthFormat),
            data?.getInstoreServiceAvailability?.daySlots || [],
          );
        } catch (err) {
          setErrorMessage(defaultErrorMessage);
        }
      }
      
    },
    [locationID, serviceId, setMonthData, getAvailabilityData],
  );

  useEffect(() => {
    fetchNextTwoMonthsData(minDate, maxDate);
  }, [serviceId, minDate, maxDate, fetchNextTwoMonthsData]);

  const fetchMonthCalendarData: UseFindAvailabilityResult["fetchMonthCalendarData"] = useCallback(
    async (formattedMonth) => {
      if (
        (calendarData[serviceId] && calendarData[serviceId][formattedMonth]) ||
        (fetchingCalendarData[serviceId] &&
          fetchingCalendarData[serviceId][formattedMonth])
      )
        return;

      setFetchingMonth(formattedMonth, true);
      const month = parse(formattedMonth, monthFormat, new Date());
      const startDate = format(startOfMonth(month), queryStringDateFormat);
      const endDate = format(endOfMonth(month), queryStringDateFormat);
      if(locationID){
        const variables: getInstoreServiceAvailabilityVariables = {
          serviceId: serviceId,
          startDate,
          endDate,
          isDays: true,
          locationID,
          numberOfBooks: 1,
        };
  
        try {
          const { data, error } = await getAvailabilityData(variables);
  
          if (error) {
            setErrorMessage(error.message);
            return;
          }
  
          setMonthData(
            formattedMonth,
            data?.getInstoreServiceAvailability?.daySlots || [],
          );
          setFetchingMonth(formattedMonth, false);
        } catch (err) {
          setErrorMessage(defaultErrorMessage);
        }
      }
      
    },
    [
      setFetchingMonth,
      getAvailabilityData,
      serviceId,
      locationID,
      setMonthData,
      fetchingCalendarData,
      calendarData,
    ],
  );

  const fetchSlotsInDay: UseFindAvailabilityResult["fetchSlotsInDay"] = useCallback(
    async (selectedDate) => {
      setSlotsData([]);
      setIsLoadingSlots(true);
      if(locationID){
        const variables: getInstoreServiceAvailabilityVariables = {
          serviceId: serviceId,
          startDate: format(startOfDay(selectedDate), fullDateFormat),
          endDate: format(endOfDay(selectedDate), fullDateFormat),
          isDays: false,
          locationID,
          numberOfBooks: 1,
        };
  
        try {
          const { data, error } = await getAvailabilityData(variables);
  
          if (error) {
            setErrorMessage(error.message);
            return;
          }
  
          const processedSlots =
            data?.getInstoreServiceAvailability?.slots?.map((slot: any) => ({
              resourceId: slot.resourceId,
              startTime: new Date(slot.startTime),
              endTime: new Date(slot.endTime),
            })) || [];
  
          setSlotsData(processedSlots);
          setIsLoadingSlots(false);
        } catch (err) {
          setErrorMessage(defaultErrorMessage);
        }
      }
      
    },
    [serviceId, locationID, getAvailabilityData, setSlotsData],
  );

  return {
    calendarData,
    slotsData,
    fetchMonthCalendarData,
    fetchSlotsInDay,
    isLoadingSlots,
    errorMessage,
  };
}

export default useFindAvailability;
