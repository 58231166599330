import { AlertProps } from "@welldigital/components";
import React, { FC, useCallback, useState } from "react";
import { createContext } from "react";

export type AlertOptions = Pick<AlertProps, "message" | "type"> & {
  isAlertShowing: boolean;
};

export type AlertContextProps = {
  enqueueAlert: (options: Omit<AlertOptions, "isAlertShowing">) => void;
  closeAlert: Function;
  alertOptions: AlertOptions;
};

const alertDefault: AlertContextProps = {
  closeAlert: () => {},
  enqueueAlert: (options: Omit<AlertOptions, "isAlertShowing">) => {},
  alertOptions: {
    isAlertShowing: false,
    message: "",
  },
};
export const AlertContext = createContext(alertDefault);
AlertContext.displayName = "AlertContext";

export const AlertContextProvider: FC = ({ children }) => {
  const [alertOptions, setAlertOptions] = useState<AlertOptions>(
    alertDefault.alertOptions,
  );

  const enqueueAlert = useCallback(
    (options: Omit<AlertOptions, "isAlertShowing">) => {
      setAlertOptions({
        isAlertShowing: true,
        ...options,
      });
    },
    [setAlertOptions],
  );

  const closeAlert = useCallback(() => {
    setAlertOptions({
      ...alertOptions,
      isAlertShowing: false,
    });
  }, [setAlertOptions, alertOptions]);

  return (
    <AlertContext.Provider
      value={{
        enqueueAlert,
        closeAlert,
        alertOptions,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
