import React, { useCallback, useState, useEffect } from "react";
import {
  Theme,
  makeStyles,
  TextField,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Typography, Spacing } from "@welldigital/components";
import { Appointment } from "graphql/types";
import { GuardianInput } from "../../__generated__/globalTypes";
import { getAppointment_getAppointment_guardian } from "graphql/__generated__/getAppointment";
import { colors } from "@welldigital/components";

const useStyles = makeStyles((theme: Theme) => ({
  titleInput: {
    marginBottom: theme.spacing(1),
    minWidth: "100px",
  },
  confirmTextField: {
    marginBottom: theme.spacing(1),
    backgroundColor: colors.updatedInputBackground,
  },
  confirmTitleInput: {
    backgroundColor: colors.updatedInputBackground,
  },
}));

export interface EditGuardianProps {
  allowHighlight?: boolean;
  guardian?: GuardianInput | null;
  appointment: Appointment;
  handleUpdateGuardian: (
    guardian: getAppointment_getAppointment_guardian | null,
  ) => void;
}

const EditGuardian: React.FC<EditGuardianProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { appointment, handleUpdateGuardian, allowHighlight, guardian } = props;
  const classes = useStyles();

  const [title, setTitle] = useState(
    guardian ? guardian?.title : appointment?.guardian?.title || "",
  );
  const [firstName, setFirstName] = useState(
    guardian ? guardian.firstName : appointment?.guardian?.firstName || "",
  );

  const [lastName, setLastName] = useState(
    guardian ? guardian.lastName : appointment?.guardian?.lastName || "",
  );

  const handleTitleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
    ) => {
      setTitle(event.target.value as string);
    },
    [setTitle],
  );

  const handleFirstNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName(event.target.value);
    },
    [setFirstName],
  );

  const handleLastNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLastName(event.target.value);
    },
    [setLastName],
  );

  useEffect(() => {
    handleUpdateGuardian({
      __typename: "Guardian",
      title,
      firstName,
      lastName,
    });
  }, [title, firstName, lastName, handleUpdateGuardian]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const highlightTitle =
    allowHighlight && guardian?.title !== appointment.guardian?.title;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const highlightFirstName =
    allowHighlight && guardian?.firstName !== appointment.guardian?.firstName;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const highlightLastName =
    allowHighlight && guardian?.lastName !== appointment.guardian?.lastName;

  return (
    <>
      <Typography variant={"h4"} spacingAfter={2}>
        Guardian information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant={"outlined"} fullWidth>
            <InputLabel htmlFor={"outlined-title-native-simple"}>
              Title
            </InputLabel>
            <Select
              label="Title"
              data-testid={"guardian-title"}
              value={title}
              onChange={handleTitleChange}
              className={
                highlightTitle ? classes.confirmTitleInput : classes.titleInput
              }
            >
              {["Mr", "Mrs", "Miss", "Ms", "Mx", "Sir", "Dr"].map(
                (title, index) => {
                  return (
                    <MenuItem key={`${title}-${index}`} value={title}>
                      {title}
                    </MenuItem>
                  );
                },
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={6}>
          <Spacing spacing={2} />
          <TextField
            label={"Guardian first name"}
            value={firstName}
            className={
              highlightFirstName ? classes.confirmTextField : undefined
            }
            inputProps={{ "data-testid": "guardian-firstName" }}
            onChange={handleFirstNameChange}
            size={"medium"}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Spacing spacing={2} />
          <TextField
            inputProps={{ "data-testid": "guardian-lastName" }}
            label={"Guardian last name"}
            value={lastName}
            className={highlightLastName ? classes.confirmTextField : undefined}
            onChange={handleLastNameChange}
            size={"medium"}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditGuardian;
