import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
  } from "@material-ui/core";
  import axios from "axios";
  import React, { FC, useState, useEffect } from "react";
  import { EditWegovyQuestion } from "../editWegovyQuestion/editWegovyQuestion";
  export const API_HOST = process.env.REACT_APP_INTEG_API;
  
  export interface EditQuestionsTableProps {
    appointmentId: string;
  }
  
  export interface dataProps {
    traversal_id: string,
    identifier: string,
    label: string,
    formType: string,
    gender: string,
    age: string,
    selfOrder: string,
    sortkey: string,
    index_sl: string,
    multi_select: [],
    traversal_response: {
      id: string,
      options: {
        checked: boolean,
        label: string,
        correct: boolean
      }[]
    }
  }
  
  const EditWegovyQuestionsTable: FC<EditQuestionsTableProps> = (props) => {
    const [data, setData] = useState<dataProps[]>();
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      async function traversalDetailsWegovyApi(
      ): Promise<any> {
        try{
        setLoading(true);
        let payload = {"appointment_id":props.appointmentId,"genderValue":"nogender"}
        const response  = await axios.post<any>(
          `${API_HOST}traversals/detailedQuestionsFormBuilder`, payload
        );
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        }else {
          setLoading(false);
        }
      }catch(error){
        setLoading(false);
      }
      }
      traversalDetailsWegovyApi();
    }, [props.appointmentId])
    
    if (loading) return <p>Loading...</p>;
    return (
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"h4"}>Pre-screen questions</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
  
            <TableBody>
              {data &&
                data.map((questionAudit: dataProps) => {
                  return (
                    <EditWegovyQuestion
                      question={questionAudit}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  
  export default EditWegovyQuestionsTable;
  