import React, { useState, useEffect } from "react";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { ArrowDropUp } from "@material-ui/icons";
import { Typography } from "@welldigital/components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getOrderHistoryDetailsAsync } from "../../reduxSlice/actionsAsync";
import { selectOrderHistoryList } from "../../reduxSlice/selector";

import { OrderHistory as OrderHistoryTypes } from "../../Types";
import Pagination from "../../components/Pagination";
import "./index.scss";
export interface PropsTypes {
  orderHistory: OrderHistoryTypes;
}

interface WrapperProps {
  customerId: number;
  orderId: number;
}

export default (WrappedComponent: React.FC<PropsTypes>) =>
  ({ customerId, orderId }: WrapperProps) => {
    const dispatch = useAppDispatch();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
      // TODO: check if its getting triggered every time
      customerId &&
        dispatch(
          getOrderHistoryDetailsAsync({ customerId, currentPage, orderId }),
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, currentPage, orderId]);

    const orderHistory = useAppSelector(selectOrderHistoryList);
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
      <Grid className="crp_order_history_wrapper">
        <Accordion
          className="crp_order_history"
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
        >
          <AccordionSummary
            className="crp_order_history_summary"
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ArrowDropUp />}
          >
            <Typography
              variant="h4"
              className="crp_order_history_summary_inner"
            >
              Customer order history
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="crp_order_history_inner">
            <Grid container className="crp_order_history_box">
              {orderHistory &&
                orderHistory?.orders?.map((order: OrderHistoryTypes) => (
                  <WrappedComponent orderHistory={order} />
                ))}

              <hr className="horizonal_rule" />
              {/* <!-- pagination--> */}
              {orderHistory && (
                <Pagination
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  totalItems={orderHistory.total}
                  pageSize={5}
                />
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
