import React, { FC, useMemo, useCallback, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import WalkInCustomerDetails from "./WalkInCustomerDetails";
import { FormValues } from "persister/types";
import { ROUTES } from "app/routers/LoggedInRoutes";
import { useAppointmentPersister } from "persister/useAppointmentPersister";
import { clearScreeningQuestion } from "../../components/Screening/hooks/useScreeningQuestions";
import useServices from "../../hooks/useServices";

const WalkInCustomerDetailsContainer: FC = () => {
  const { appointment, setFormData } = useAppointmentPersister();
  const history = useHistory();
  const { services } = useServices();

  console.log(`services`, services);
  const { serviceId = "" } = appointment || {};

  const [selectedService] = useMemo(
    () => services.filter((service) => service.id === parseInt(serviceId)),
    [serviceId, services],
  );

  useEffect(() => {
    if (!selectedService?.id) {
      console.error(
        `Selected service was not found, serviceId: ${appointment?.serviceId} `,
      );
      history.push(ROUTES.walkIn.bookAppointment);
    }
  }, [history, appointment, selectedService]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      clearScreeningQuestion();
      setFormData(values);
      history.push(ROUTES.walkIn.screening);
    },
    [setFormData, history],
  );

  const onCancel = useCallback(() => history.push("/"), [history]);

  console.log(`selectedService`, selectedService);
  return selectedService ? (
    <WalkInCustomerDetails
      service={selectedService}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  ) : (
    <Redirect to={ROUTES.walkIn.bookAppointment} />
  );
};

export default WalkInCustomerDetailsContainer;
