import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import "./index.scss";
import { confirmBMI } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import { useCurrentUserEmail } from "services/auth-hooks";
import { PropsTypes } from "../UploadImageContainer/UploadImageContainer";


const ConfirmBMIDetails = ({
  orderId,
  onClose,
  productId,
  onUpdateImages
}: PropsTypes) => {
  const formStatusRef = useRef<HTMLFormElement>(null);
  const dialogRefStatus = useRef<CustomDialogRef>(null);

  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(false);
  const email = useCurrentUserEmail();
  useEffect(() => {
    dialogRefStatus.current?.show();
  }, []);

  const confirmBMIHandler =  () => {
    setLoading(true);
     confirmBMI({ orderId, productId, createdBy: email || '' }).then(()=>{
      onUpdateImages(true);
      dialogRefStatus.current?.hide();
      setLoading(false);
    });
  }


  return (
    <CustomDialog
      onClose={() => onClose?.(false)}
      ref={dialogRefStatus}
      title={
        <>
          Confirm BMI
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
        </>
      }
      className="update-bmi-dialog"
    >
      <form ref={formStatusRef} onSubmit={(e) => {e.preventDefault();confirmBMIHandler()}}>
        <Grid container className="update-bmi-container">
          <Grid item md={12}>
            <Typography className="popup-para-color mb-10">
              <p>
                By selecting this option you are confirming:
              </p>

              <li>
                <div>
                  you have completed a video or face-to-face consultation with the patient</div>
              </li>
              <li><div>
                you are satisfied that the height and weight information they provided in their consultation form is accurate, and</div>
              </li>
              <li><div>
                you believe their BMI falls within the prescribing criteria for the weight loss medication they have requested.</div>
              </li>


            </Typography>

          </Grid>
          <Grid item md={6}>
            {/* This is to fix spacing */}
          </Grid>
        </Grid>
        {/* {error && (
          <Typography variant="subtitle2" className="color-error">
            Error in updating BMI status
          </Typography>
        )} */}
        <div className="buttons-container">
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => onClose?.(false)}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Confirm BMI
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};

export default ConfirmBMIDetails;