import { gql } from "@apollo/client";

export const CUSTOMER_SEARCH = gql`
  query customerSearch($search: String!) {
    customerSearch(search: $search) {
      id
      firstName
      lastName
      dob
      address {
        line1
        line2
        city
        postcode
      }
    }
  }
`;
