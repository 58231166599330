import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectUserGroup } from "pages/ClinicalReviewPage/reduxSlice/selector";
import { ROUTES } from "../../ClinicalReviewRoutes";
import { getGroups, isAuthenticated, getUserInfo } from "services/auth";
import { clinicalReviewActions } from "pages/ClinicalReviewPage/reduxSlice";
import { getClinicalReviewUserGroup } from "utils/user";
import { CLINICAL_REVIEW_USER_GROUP } from "../../../../constants";
import Loader from "pages/ClinicalReviewPage/components/Loader/Loader";

export const ClinicalReviewProtectedRoute: React.FC<RouteProps> = (props) => {
  const userGroup = useAppSelector(selectUserGroup);
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    isAuthenticated().then(async (isAuth) => {
      if (isAuth) {
        const groups = await getGroups();
        const userGroup = getClinicalReviewUserGroup(
          groups?.split(",") as CLINICAL_REVIEW_USER_GROUP[],
        );
        const userInfo = await getUserInfo();

        appDispatch(clinicalReviewActions.setUserGroup(userGroup));
        appDispatch(clinicalReviewActions.setUserDetails(userInfo));
      }
      setIsAuth(isAuth);
    });
  }, [setIsAuth, appDispatch]);

  if (isAuth === null) {
    return <Loader loading className="min-h-full" />;
  }

  if (isAuth && userGroup) {
    const { children, ...rest } = props;
    return <Route {...rest}>{children}</Route>;
  }

  return <Redirect to={{ pathname: ROUTES.clinical.login }} />;
};

export default ClinicalReviewProtectedRoute;
