import { useQuery } from "@apollo/client/react";
import React, { useCallback, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import EditServiceDetailsPage from "./EditServiceDetailsPage";
import {
  QuestionAudit,
  getQuestionAudits,
  getUpdatedQuestionAudits,
} from "../../utils/question";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import {
  getAppointment,
  getAppointment_getAppointment_guardian,
} from "graphql/__generated__/getAppointment";
import { Question } from "graphql/types";
import { PharmacistInput } from "../../__generated__/globalTypes";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const EditServiceDetailsContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, error, data } = useQuery<getAppointment>(
    GET_APPOINTMENT,
    {
      variables: { appointmentId: id, locationId: locationId },
    },
  );

  const [updatedAppointmentNotes, setUpdatedAppointmentNotes] = React.useState<
    string | null
  >(null);

  const existingNotes = data?.getAppointment?.notes || "";
  const displayNotes =
    updatedAppointmentNotes === null ? existingNotes : updatedAppointmentNotes;

  const [questionAudits, setQuestionAudits] = React.useState<QuestionAudit[]>(
    [],
  );

  const [
    updatedAppointmentPharmacist,
    setUpdatedAppointmentPharmacist,
  ] = useState(
    data?.getAppointment?.pharmacist || { title: "", name: "", gphcNumber: "" },
  );

  useMemo(() => {
    if (data?.getAppointment?.course?.questions) {
      setQuestionAudits(getQuestionAudits(data?.getAppointment));
    }
  }, [data]);

  const handleQuestionUpdate = useCallback(
    (question: Question) => {
      const newQuestionAudits = getUpdatedQuestionAudits(
        questionAudits,
        question,
      );

      setQuestionAudits(newQuestionAudits);
      return;
    },
    [questionAudits],
  );

  const history = useHistory();

  const handleUpdatePharmacist = useCallback(
    (updatedPharmacist: PharmacistInput) => {
      setUpdatedAppointmentPharmacist(updatedPharmacist);
    },
    [setUpdatedAppointmentPharmacist],
  );

  const [
    updatedAppointmentGuardian,
    setUpdatedAppointmentGuardian,
  ] = useState<getAppointment_getAppointment_guardian | null>(
    data?.getAppointment?.guardian || null,
  );

  const handleUpdateGuardian = useCallback(
    (updatedGuardian: getAppointment_getAppointment_guardian | null) => {
      setUpdatedAppointmentGuardian(updatedGuardian);
    },
    [setUpdatedAppointmentGuardian],
  );

  const handleNext = useCallback(() => {
    const updatedQuestions = questionAudits.filter((qa: QuestionAudit) => {
      return !!qa.updatedQuestion;
    });
    const isNotesChanged = displayNotes !== existingNotes;
    const isPharmacistUpdated =
      data?.getAppointment?.pharmacist?.title !==
        updatedAppointmentPharmacist.title ||
      data?.getAppointment?.pharmacist.name !==
        updatedAppointmentPharmacist.name ||
      data?.getAppointment?.pharmacist.gphcNumber !==
        updatedAppointmentPharmacist.gphcNumber;

    const isGuardianUpdated =
      data?.getAppointment?.guardian?.title !==
        updatedAppointmentGuardian?.title ||
      data?.getAppointment?.guardian?.firstName !==
        updatedAppointmentGuardian?.firstName ||
      data?.getAppointment?.guardian?.lastName !==
        updatedAppointmentGuardian?.lastName;

    if (
      !updatedQuestions.length &&
      !isNotesChanged &&
      !isPharmacistUpdated &&
      !isGuardianUpdated
    ) {
      history.push(`/service-details/${id}/complete`);
      return;
    }

    history.push(`/service-details/${id}/confirm`, {
      questionAudits,
      appointmentNotes: updatedAppointmentNotes,
      appointmentPharmacist: updatedAppointmentPharmacist,
      appointmentGuardian: updatedAppointmentGuardian,
    });
  }, [
    questionAudits,
    displayNotes,
    existingNotes,
    history,
    id,
    updatedAppointmentNotes,
    updatedAppointmentPharmacist,
    updatedAppointmentGuardian,
    data,
  ]);

  return (
    <EditServiceDetailsPage
      appointmentId={id}
      onNotesChange={setUpdatedAppointmentNotes}
      notes={displayNotes}
      data={data}
      error={error}
      isLoading={isLoading}
      onSubmit={handleNext}
      questionAudits={questionAudits}
      onQuestionUpdate={handleQuestionUpdate}
      updatedPharmacist={updatedAppointmentPharmacist}
      handleUpdatePharmacist={handleUpdatePharmacist}
      handleUpdateGuardian={handleUpdateGuardian}
    />
  );
};

export default EditServiceDetailsContainer;
