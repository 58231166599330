import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import { useParams } from "react-router";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getCustomerDetailsAsync,
  getPreApprovedProductsAsync,
  getPreApprovedProductIdVerificationAsync
} from "../reduxSlice/actionsAsync";
import {
  selectCustomerDetails,
  selectCustomerDetailsLoaded,
  selectIdChecksPreApprovedProduct,
  selectPreApprovedProducts,
} from "../reduxSlice/selector";
import { useHistory } from "react-router-dom";
import "./index.scss";

export interface PreApprovedProductOption{
  id: number;
  name: string;
  treatmentName: string;
  doseNumber: number;
}
export interface PropsTypes {
  customerId: number;
  preApprovedProduct: PreApprovedProductOption[];
  idChecksPreApprovedProduct: IdCheckPreApprovedProduct;
  treatmentName: string;
  previousTreatmentName: string;
  treatmentUpdatedBy: string;
  treatmentUpdatedAt: string;
  treatmentWarning: boolean | false;
}
//const ORDER_LIMIT=10;
export interface IdCheckPreApprovedProduct {
  idCheckPreviousStatus: string;
  idCheckStatus: string;
  lnSearchRef: string;
  lnStatusUpdateAt: string;
  lnStatusUpdatedBy: string;
}
export default (WrappedComponent: React.FC<PropsTypes>) => () => {
  const dispatch = useAppDispatch();
  const customerDetails = useAppSelector(selectCustomerDetails);
  const customerDetailsLoader = useAppSelector(selectCustomerDetailsLoaded);
  const preApprovedProduct = useAppSelector(selectPreApprovedProducts);
  const history = useHistory();
  const { customerId } = useParams<{ customerId?: string | '' }>();
  const params = new URLSearchParams(document.location.search);
  const treatment = params.get("treatment") || '';
  const idChecksPreApprovedProduct = useAppSelector(selectIdChecksPreApprovedProduct);
  useEffect(()=>{
    dispatch(getPreApprovedProductsAsync());
  },[dispatch]);

  useEffect(() => {
    customerId && dispatch(getCustomerDetailsAsync({customerId, treatment}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,customerId]);
  
  useEffect( ()=>{
    customerId && dispatch(getPreApprovedProductIdVerificationAsync(parseInt(customerId)));
  },[dispatch,customerId])
  
  return (
    <>
      <button className="back-button mt-16 mb-16" onClick={history.goBack}>
        Back
      </button>
      <Grid container className="crp_container">
        <Grid item xs={12} sm={4} lg={3} className="crp_customer_details">
          <CustomerDetails
            customerId={customerId ? parseInt(customerId) : -1}
            customerDetails={customerDetails}
            loading={customerDetailsLoader}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={9}>
          {(
            <WrappedComponent
              preApprovedProduct={preApprovedProduct}
              customerId={customerId ? parseInt(customerId) : -1}
              idChecksPreApprovedProduct={idChecksPreApprovedProduct}
              treatmentName={customerDetails?.treatmentName || ''}
              previousTreatmentName={customerDetails?.previousTreatmentName || ''}
              treatmentUpdatedBy={customerDetails?.treatmentUpdatedBy || ''}
              treatmentUpdatedAt={customerDetails?.treatmentUpdatedAt || ''}
              treatmentWarning={customerDetails?.treatmentWarning || false}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};