import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Button, Typography } from "@welldigital/components";
import WalkInActions from "components/WalkInActions/WalkinActions";

export interface ConfirmationPageProps {
  onFinished: () => void;
  appointmentData: AppointmentData;
}

type AppointmentData = {
  bookingType: string;
  bookingDate: string;
  customerName: string;
  customerPhone?: string;
  customerEmail?: string;
  paymentAmount?: string;
  isFlu: boolean;
};

export const ConfirmationPage: FC<ConfirmationPageProps> = ({
  onFinished,
  appointmentData,
}) => (
  <>
    <Typography variant={"h2"} spacingAfter={6}>
      {"Booking confirmed"}
    </Typography>
    <Box mb={6}>
      <Typography variant={"h4"} spacingAfter={0}>
        {appointmentData.bookingType}
      </Typography>
      <Typography variant={"h4"} spacingAfter={0}>
        {appointmentData.bookingDate}
      </Typography>
    </Box>
    <Typography variant={"h5"} spacingAfter={2}>
      {"Customer details"}
    </Typography>
    <Box mb={6}>
      <Typography variant={"body1"} color={"secondary"}>
        {appointmentData.customerName}
        <br />
        {appointmentData.customerPhone}
        <br />
        {appointmentData.customerEmail}
      </Typography>
    </Box>
    <Typography variant={"h3"} spacingAfter={0}>
      {appointmentData.isFlu
        ? "Please take payment at the time of appointment."
        : "Please take payment at the till."}
    </Typography>

    <WalkInActions>
      <Button
        data-testid={"confirmationPage/finished-button"}
        onClick={onFinished}
        variant={"contained"}
        size={"medium"}
        minWidth={"320px"}
      >
        {"Finished"}
      </Button>
    </WalkInActions>
  </>
);

export default ConfirmationPage;
