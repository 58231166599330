import React, { FC, useCallback, useMemo, useState } from "react";
import DashboardCalendar from "./DashboardCalendar";
import { useQuery } from "@apollo/client/react";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  Appointments,
  Appointments_getAppointments,
} from "./__generated__/Appointments";
import { GET_MONTH_APPOINTMENTS } from "./gql";
import { AppointmentStatus } from "__generated__/globalTypes";
//import { getLocationId } from "services/auth";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";

export interface DashboardCalendarProps {
  pickerProps: {
    date: MaterialUiPickersDate;
    onChange(newDate: MaterialUiPickersDate, isFinish?: boolean): void;
  };
  locationId: string;
}

const DashboardCalendarContainer: FC<DashboardCalendarProps> = (props) => {
  const { pickerProps, locationId } = props;
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const startMonth = format(startOfMonth(currentMonth), "yyyy-MM-dd");
  const endMonth = format(endOfMonth(currentMonth), "yyyy-MM-dd");
  
  // useEffect(() => {
  //   if (!locationId) {
  //     const getLocId = async () => {
  //       const location = await getLocationId();
  //       setLocationId(location);
  //     };

  //     getLocId();
  //   }
  // }, [locationId, setLocationId]);
  const { data: dataMonth } = useQuery<Appointments>(GET_MONTH_APPOINTMENTS, {
    variables: {
      startDate: startMonth,
      endDate: endMonth,
      locationId: locationId,
    },
  });

  const daysWithAppointments = useMemo(() => {
    if (dataMonth?.getAppointments && dataMonth.getAppointments.length > 0) {
      return dataMonth.getAppointments.reduce(
        (acc: Date[], curr: Appointments_getAppointments) => {
          const formattedStartTime = new Date(curr.startTime);
          if (curr.status !== AppointmentStatus.cancelled) {
            acc.push(formattedStartTime);
          }
          return acc;
        },
        [],
      );
    }
    return [];
  }, [dataMonth]);

  const onMonthChange = useCallback(
    (month: Date) => {
      setCurrentMonth(month);
    },
    [setCurrentMonth],
  );

  return (
    <DashboardCalendar
      pickerProps={pickerProps}
      onMonthChange={onMonthChange}
      daysWithAppointments={daysWithAppointments}
      locationId={locationId}
    />
  );
};

export default DashboardCalendarContainer;
