import React, { FC, useCallback, useMemo, useState } from "react";
import { Box, Link, makeStyles, Theme } from "@material-ui/core";
import { Alert, Calendar, Spacing, Typography } from "@welldigital/components";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import AvailabilityModal from "../../../../components/AvailabilityModal/AvailabilityModal";
import { CalendarData, dayFormat, monthFormat } from "@welldigital/components";
import { format, parse } from "date-fns";
import { selectBookwellGroup } from "redux/reduxSlice/selector";
import { useAppSelector } from "redux/hooks";
import { BOOKWELL_USER_GROUP } from "../../../../constants";

const useStyles = makeStyles((theme: Theme) => ({
  calendarWrapper: {
    padding: theme.spacing(3),
  },
  dayWithDotContainer: {
    position: "relative",
  },
  dayWithDot: {
    position: "absolute",
    height: 0,
    width: 0,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "15%",
  },
  linkModal: {
    position: "relative",
    bottom: "26px",
    margin: theme.spacing(0, 0, 0, 3),
    color: theme.palette.primary.light,
    textDecoration: "none",
    cursor: "pointer",
  },
  link: {
    color: theme.palette.primary.light,
  },
}));

export interface DashboardCalendarProps {
  pickerProps: {
    date: MaterialUiPickersDate;
    onChange(newDate: MaterialUiPickersDate, isFinish?: boolean): void;
  };
  onMonthChange(month: MaterialUiPickersDate): void;
  daysWithAppointments: Date[];
  locationId: string;
}

const DashboardCalendar: FC<DashboardCalendarProps> = ({
  pickerProps,
  onMonthChange,
  daysWithAppointments,
  locationId
}) => {
  const bookwellGroup =  useAppSelector(selectBookwellGroup);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openAvailabilityModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeAvailabilityModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const calendarData = useMemo(() => {
    const data: CalendarData = {};
    daysWithAppointments.forEach((date) => {
      const monthString = format(date, monthFormat);
      const dayString = format(date, dayFormat);
      data[monthString] = data[monthString] || {};
      data[monthString][dayString] = { hasEvents: true };
    });
    return data;
  }, [daysWithAppointments]);

  const fetchMonthData = useCallback(
    async (date: string) => {
      onMonthChange(parse(date, "yyyyMM", new Date()));
    },
    [onMonthChange],
  );

  const isKnownErrorsShown = false;

  return (
    <div className={classes.calendarWrapper}>
      <Box px={2}>
        <Calendar
          {...pickerProps}
          value={pickerProps.date}
          data={calendarData}
          fetchMonthData={fetchMonthData}
        />
        <Spacing spacing={2} />
        {!bookwellGroup?.includes(BOOKWELL_USER_GROUP.BW_CUSTOMER_SERIVCE) && <Typography
          className={classes.linkModal}
          data-testid={"dashboard/link-to-availability-modal"}
          variant={"h6"}
          onClick={openAvailabilityModal}
          spacingAfter={0}
        >
          Edit service availability
        </Typography>}
        {locationId && <AvailabilityModal
          isModalOpen={isModalOpen}
          closeModal={closeAvailabilityModal}
          locationId={locationId}
        />}
      </Box>
      {isKnownErrorsShown && (
        <Box mt={4}>
          <Alert
            size={"small"}
            message={
              <Typography variant={"subtitle2"}>
                We are aware of problems affecting some users. Before reporting,
                please{" "}
                <Link
                  className={classes.link}
                  href={"https://www.well.co.uk/known-errors"}
                  target={"_blank"}
                >
                  see the latest information on known issues
                </Link>
                .
              </Typography>
            }
          />
        </Box>
      )}
    </div>
  );
};

export default DashboardCalendar;
