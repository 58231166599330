// previously known as ServOs
export const WEBSITE_NAME = "BookWell";
export const WEBSITE_NAME_CLINICAL = "Online Doctor";
export const WEBSITE_NAME_CLINICAL_REVIEW = "Clinical Review";

// login path will be saved in this name in session storage
// so when Azure login redirects to home page, we can
// redirect to origin application
export const ORIGIN_URL = "ORIGIN_LOGIN_URL";

export enum CLINICAL_REVIEW_USER_GROUP {
  CR_CUSTOMER_SERIVCE_AGENT = "clinicalreview-cs",
  CR_PHARMACIST = "clinicalreview-dp",
  CR_INDEPENDENT_PRESCRIBER = "clinicalreview-ip",
}

export enum BOOKWELL_USER_GROUP {
  BW_CUSTOMER_SERIVCE = "bookwell-customer-service",
  BW_SERIVCE_TEAM = "bookwell-service-team",
}

export const CLINICAL_REVIEW_USER_GROUPS = [
  CLINICAL_REVIEW_USER_GROUP.CR_CUSTOMER_SERIVCE_AGENT,
  CLINICAL_REVIEW_USER_GROUP.CR_PHARMACIST,
  CLINICAL_REVIEW_USER_GROUP.CR_INDEPENDENT_PRESCRIBER,
];

export const BOOKWELL_USER_GROUPS = [
  BOOKWELL_USER_GROUP.BW_CUSTOMER_SERIVCE,
  BOOKWELL_USER_GROUP.BW_SERIVCE_TEAM,
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CONSULTATION_FORM_TITLE_MAP: Record<string, string> = {
  ED: "ED consultation form",
  AC: "Acne consultation form",
  PO: "Contraception consultation form",
  EZ: "Eczema consultation form",
  HL: "Hair loss consultation form",
  MPR: "Migraine consultation form",
  WL: "Weight loss consultation form",
  PMED: "Pharmacy medicine form",
};

export const CONSULTATION_FORM_TITLE_MAP_TO_SECONDARY_TAG: Record<string, string> = {
  onlinedoctoracne: "Acne consultation form",
  onlinedoctorcontraception: "Contraception consultation form",
  onlinedoctoreczema: "Eczema consultation form",
  onlinedoctorhairloss: "Hair loss consultation form",
  onlinedoctormigraine: "Migraine consultation form",
  onlinedoctorweightloss: "Weight loss consultation form",
};