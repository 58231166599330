import React, { FC, MouseEventHandler } from "react";
import "./index.scss";
interface ButtonProps {
  label: string;
  isDisabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const Button: FC<ButtonProps> = ({ label, isDisabled, onClick }) => {
  return (
    <button
      type="button"
      disabled={isDisabled}
      className={`button ${isDisabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
