//Constants
export const KEY_FORM_ID = 'form_id';
export const ONLINE_DOCTOR = 'onlinedoctor';
export const exception = [
    "None of the above",
    "No, I am not taking other weight loss medicines",
    "None of these",
    "I haven't had any of these",
    "I don't know",
    "Yes - Something else"
];

export const ageQuestion = [
    "How old is the person who will be using this medication?",
    "Are you 18 or over, and male?",
    "How old are you?",
];

export const SELF = [
    "me",
    "myself"
];

export const OTHERS = [
    "others",
    "other",
    "someone else",
];


export const genderQuestion = [
    "Are you 18 or over, and male?",
    "Gender:",
];

export const selfOrderQuestion = [
    "Are you ordering for yourself?",
    "Who will be using this medication?",
];

export const errorMessages = {
    radioError: 'Select one of the options',
    textError: 'Response required',
    checkboxError: 'You must select at least 1 of the options',
    singleCheckboxError: 'Please select the checkbox'
};

