import { CognitoUserInfo, getUserInfo } from "./auth";
import { useState, useEffect } from "react";

export function useCurrentUserInfo(): CognitoUserInfo | null {
  const [info, setUserInfo] = useState<CognitoUserInfo | null>(null);
  useEffect(() => {
    getUserInfo().then(setUserInfo);
  }, [setUserInfo]);
  return info;
}

export function useCurrentUserEmail(): string | null {
  const info = useCurrentUserInfo();
  return info?.attributes?.email || null;
}
