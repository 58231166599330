import React, { FC, useState, useCallback, ChangeEvent } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useQuery } from "@apollo/client/react";

import { CUSTOMER_SEARCH } from "./gql";
import { customerSearch } from "./__generated__/customerSearch";
import Search from "./Search";
import { ukDateToIso } from "utils/time";

const SearchContainer: FC = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedSetSearchQuery] = useDebouncedCallback((text: string) => {
    const words = text.replace(",", "").split(" ");
    // If we find a "word" which is a UK date, convert it to ISO date
    words.forEach((word, i) => {
      const formattedDate = ukDateToIso(word);

      if (word !== formattedDate) {
        words[i] = formattedDate;
      }
    });
    setIsSearching(false);
    setSearchQuery(words.join(" "));
  }, 750);

  const onQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
      setIsSearching(true);
      debouncedSetSearchQuery(event.target.value);
    },
    [setSearchInput, setIsSearching, debouncedSetSearchQuery],
  );

  const clearSearchInput = useCallback(() => {
    setSearchInput("");
  }, [setSearchInput]);

  const { data, loading: isLoading } = useQuery<customerSearch>(
    CUSTOMER_SEARCH,
    {
      variables: {
        search: searchQuery,
      },
    },
  );

  return (
    <Search
      searchInput={searchInput}
      searchQuery={searchQuery}
      onQueryChange={onQueryChange}
      clearSearchInput={clearSearchInput}
      isSearching={isSearching}
      isLoading={isLoading}
      results={data?.customerSearch}
    />
  );
};

export default SearchContainer;
