import React, { ChangeEvent, ChangeEventHandler } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  TextFieldProps,
  FormHelperText,
} from "@material-ui/core";
import { MONTHS } from "../../../../constants";
import { ReactComponent as IcArrowDown } from "assets/icons/arrow-down.svg";

interface DateOfBirthProps
  extends Pick<TextFieldProps, "error" | "helperText"> {
  dob: string;
  onDOBChange: (dob: string) => void;
}

const DateOfBirth: React.FC<DateOfBirthProps> = ({
  dob,
  error,
  helperText,
  onDOBChange,
}) => {
  const [year, month, day] = dob.split("-");
  const onDayChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => onDOBChange(`${year}-${month}-${value}`);

  const onMonthChange = ({
    target: { value },
  }: ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => onDOBChange(`${year}-${value}-${day}`);

  const onYearChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => onDOBChange(`${value}-${month}-${day}`);

  return (
    <Grid container className="mb-20">
      <Grid item md={12} className="row">
        <Grid item md={3}>
          <TextField
            className="input mb-0"
            id="day"
            value={day}
            error={error}
            onChange={onDayChange}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6}>
          <Select
            IconComponent={() => (
              <IcArrowDown className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
            )}
            fullWidth
            className="input mb-0"
            id="month"
            variant="outlined"
            value={month.padStart(2, "0")}
            error={error}
            onChange={onMonthChange}
          >
            {MONTHS.map((month, index) => (
              <MenuItem
                className="color-neutrals-grey-dark"
                key={month}
                value={`${index + 1}`.padStart(2, "0")}
              >
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={3}>
          <TextField
            className="input mb-0"
            id="year"
            variant="outlined"
            value={year}
            error={error}
            onChange={onYearChange}
          />
        </Grid>
      </Grid>
      <Grid item md={12}>
        <FormHelperText
          className="MuiFormHelperText-contained Mui-error"
          error={error}
        >
          {helperText}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default DateOfBirth;
