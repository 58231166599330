import { gql } from "@apollo/client";

export const GET_MONTH_APPOINTMENTS = gql`
  query Appointments($startDate: AWSDate!, $endDate: AWSDate!, $locationId:String!) {
    getAppointments(startDate: $startDate, endDate: $endDate, locationId: $locationId) {
      id
      startTime
      status
    }
  }
`;
