import React, { FC } from "react";
import CompletedAppointmentCard from "../../../../components/CompletedAppointmentCard/CompletedAppointmentCard";
import { getPreviousAppointments_getPreviousAppointments } from "graphql/__generated__/getPreviousAppointments";
import { Appointment } from "graphql/types";

export interface PreviousAppointmentMedicineProps {
  previousAppointment: getPreviousAppointments_getPreviousAppointments;
}

const PreviousAppointmentMedicine: FC<PreviousAppointmentMedicineProps> = ({
  previousAppointment,
}) => {
  return (
    <CompletedAppointmentCard
      appointment={previousAppointment as Appointment}
      medicineView
    />
  );
};

export default PreviousAppointmentMedicine;
