import { gql } from "@apollo/client";
import { ScreeningFragment } from "./fragments/screening";

export const NEXT_SCREENING = gql`
  mutation nextScreening($input: NextScreeningInput!) {
    nextScreening(input: $input) {
      ...Screening
    }
  }
  ${ScreeningFragment}
`;
