import React, { FC } from "react";
import { useQuery } from "@apollo/client/react";
import { Chip } from "@material-ui/core";

import { getAppointment } from "graphql/__generated__/getAppointment";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export interface AppointmentCountChipProps {
  appointmentId: string;
}

const AppointmentCountChip: FC<AppointmentCountChipProps> = ({
  appointmentId,
}) => {
  const locationId = useAppSelector(selectLocationId);
  const { data } = useQuery<getAppointment>(GET_APPOINTMENT, {
    variables: { appointmentId, locationId},
  });

  if (data?.getAppointment) {
    const {
      getAppointment: { number, totalNumber },
    } = data;

    if (totalNumber > 1) {
      return (
        <Chip
          data-testid={"appointment-count"}
          label={`Appointment ${number} of ${totalNumber}`}
        />
      );
    }
  }

  return null;
};

export default AppointmentCountChip;
