import React from "react";
import { Grid } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { ImageType } from "./ShowImage";
import { formatBytes } from "utils/test-utils/Helper";

interface PropsTypes {
    image: ImageType;
    onImageDelete: () => void;
}

const Image = ({ image, onImageDelete }: PropsTypes) => {
    return (image?.src ? <div className="imageItemContainer">
        <Grid container spacing={1} className="img-with-details-container">
            <span>
                <Grid item xs={2} sm={2} md={2} >
                    <img src={image.src.toString()} alt={image.title} className="imageListImage" />
                </Grid>
            </span>
            <span>
                <Grid item xs={8} sm={8} md={8} className="img-details-imagelist">
                    <p className="imageTitle">
                        {image.title}
                    </p>
                    <p className="imageSize">
                        {formatBytes(image.size)}
                    </p>
                </Grid>
            </span>
        </Grid>
        <CloseOutlined style={{ width: "15px", height: "15px", cursor: "pointer" }} className="close-btn-imagelist" onClick={onImageDelete} />

    </div> : null)
};

export default Image;
