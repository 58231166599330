import React from "react";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { Control, Controller } from "react-hook-form";

interface HookFormSelectProps extends FormControlProps {
  name: string;
  control: Control;
  defaultValue: any;
  children: JSX.Element;
}

const HookFormSelect: React.FC<HookFormSelectProps> = ({
  name,
  control,
  defaultValue,
  children,
  ...props
}) => {
  return (
    <FormControl {...props}>
      <Controller
        as={children}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

export default HookFormSelect;
