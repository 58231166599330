import React, { useRef, useCallback, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import {
  Typography,
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  Theme,
  MenuItem,
  Menu,
  Avatar,
  Button,
} from "@material-ui/core";

import Search from "./components/Search";
import AvailabilityModal from "../AvailabilityModal/AvailabilityModal";
import { BOOKWELL_USER_GROUP, WEBSITE_NAME } from "../../constants";
import { prescriptionsUrlVar, homedeliveryUrlVar } from "reactive-vars";
import { getBranchId } from "@welldigital/ui-common/bookwell";
import { getGroups } from "services/auth";
import { useAppSelector } from "redux/hooks";
import { selectBookwellGroup, selectLocationId } from "redux/reduxSlice/selector";
import SubHeader from "./components/SubHeader";

export const headerHeight: number = 80;

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  hidden: {
    visibility: "hidden",
  },
  inline: {
    display: "inline-block",
  },
  accountCircle: {
    width: "100%",
    backgroundColor: "cadetblue",
    height: "24px"
  },
  paddingLeft: {
    paddingLeft: theme.spacing(4),
  },
  marginLeft: {
    marginLeft: theme.spacing(4),
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
  userName: {
    paddingLeft: theme.spacing(1),
  },
  servicesMenuOpenAnchor: {
    transform: "translateY(1em)",
  },
  avatar: {
    background: theme.palette.primary.dark,
  },
  menuItemContainer: {
    maxWidth: "20em",
    height: "auto",
    whiteSpace: "normal",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  menu: {
    overflow: "visible",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      margin: "auto",
      marginTop: "-9px",
      display: "block",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: `10px solid ${theme.palette.background.paper}`,
    },
  },
  toolbar: {
    minHeight: "56px !important",
  },
  paddingEmail: {
    padding: '0 0 0 0 !important',
  }
}));

export interface HeaderProps {
  email: string;
  signOut: () => Promise<void>;
}
const getPaidHDSPilot = (pharmacyID: string): boolean => {
  const pharmacyIdsString = process.env.REACT_APP_PAID_HDS_PILOT_PHARMACY || "";
  if (!pharmacyIdsString) return false;
  const pharmacyIds = pharmacyIdsString.split(",");
  console.log(pharmacyIds);
  return pharmacyIds.includes(pharmacyID);
};

const Header: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { signOut } = props;
  const signOutRedirect = useCallback(
    () => signOut().then(() => history.push("/login")),
    [signOut, history],
  );
  const prescriptionsUrl = useReactiveVar(prescriptionsUrlVar);
  const homedeliveryUrl = useReactiveVar(homedeliveryUrlVar);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHDS, setShowHDS] = useState(false);
  const handleUserMenuOpen = useCallback(() => setIsUserMenuOpen(true), []);
  const handleUserMenuClose = useCallback(() => setIsUserMenuOpen(false), []);
  const userMenuAnchorRef = useRef(null);
  const [showClinicalReview, setShowClinicalReview] = useState<boolean>(false)
  const bookwellGroup = useAppSelector(selectBookwellGroup);
  const locationId = useAppSelector(selectLocationId);
  const location = useLocation();
  const openAvailabilityModal = useCallback(() => {
    setIsModalOpen(true);
    setIsUserMenuOpen(false);
  }, [setIsModalOpen]);

  const closeAvailabilityModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  useEffect(() => {
    async function getPharmacyIdSync() {
      const pharmacyId = await getBranchId();

      setShowHDS(getPaidHDSPilot(pharmacyId));
    }
    getPharmacyIdSync();
  }, [setShowHDS]);

  useEffect(() => {
    getGroups().then((res: string) => {
      let groups: string[] = res?.split(",")
      setShowClinicalReview(groups?.filter((group) => (group === "clinicalreview-cs" || group === "clinicalreview-dp" || group === "clinicalreview-ip")).length > 0)
    }
    )

  }, [])

  // The services menu is temporarily hidden until we have content to show.
  // const [isServicesMenuOpen, setIsServicesMenuOpen] = React.useState(false);
  // const handleServicesMenuOpen = useCallback(
  //   () => setIsServicesMenuOpen(true),
  //   [],
  // );
  // const handleServicesMenuClose = useCallback(
  //   () => setIsServicesMenuOpen(false),
  //   [],
  // );
  // const servicesMenuAnchorRef = useRef(null);

  return <>
    {<AppBar position={"static"} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box>
          <Button variant={"text"} color={"inherit"} component={Link} to={"/"}>
            <Typography
              variant={"h3"}
              color={"inherit"}
              data-testid={"header/title-typography"}
            >
              {WEBSITE_NAME}
            </Typography>
          </Button>
        </Box>

        {location.pathname !== '/central-visibility' && <Search />}
        <div className={classes.grow} />

        <Box className={classes.paddingLeft}>
          <Button variant={"text"} color={"inherit"} component={Link} to={!bookwellGroup ? "/" : "/central-visibility"}>
            Dashboard
          </Button>
        </Box>
        {location.pathname !== '/central-visibility' && <Box className={classes.paddingLeft}>
          <Button
            variant={"text"}
            color={"inherit"}
            component={Link}
            to={"/walk-in/book-an-appointment"}
          >
            Book an appointment
          </Button>
        </Box>}

        {/* Temporily hidden until we have content to show.  */}
        {/* <Box mt={2} mb={2} className={classes.paddingLeft}>
          <Button
            variant={"text"}
            aria-label={"account of current user"}
            aria-controls={"menu-services"}
            aria-haspopup={"true"}
            onClick={handleServicesMenuOpen}
            color={"inherit"}
          >
            Services
          </Button>
          <Menu
            className={classes.paddingTop}
            id={"menu-services"}
            anchorEl={servicesMenuAnchorRef.current}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={isServicesMenuOpen}
            onClose={handleServicesMenuClose}
          >
            <MenuItem
              data-testid={"info-link"}
              component={Link}
              to={"/info"}
              className={classes.menuItemContainer}
            >
              Information on our services and how to prepare for an appointment
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/pneumococcal"}
              className={classes.menuItemContainer}
            >
              Pneumococcal
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/hpv"}
              className={classes.menuItemContainer}
            >
              HPV
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/chicken-pox"}
              className={classes.menuItemContainer}
            >
              Chicken Pox
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/meningitis-b"}
              className={classes.menuItemContainer}
            >
              Meningitis B
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/measles"}
              className={classes.menuItemContainer}
            >
              Measles
            </MenuItem>
          </Menu>
          <div
            ref={servicesMenuAnchorRef}
            className={classes.servicesMenuOpenAnchor}
          />
        </Box> */}

        {!!prescriptionsUrl && (
          <Box mt={2} mb={2} className={classes.paddingLeft}>
            <Button
              variant={"text"}
              color={"inherit"}
              component={"a"}
              href={prescriptionsUrl}
            >
              Prescriptions
            </Button>
          </Box>
        )}
        {showHDS && (
          <Box mt={2} mb={2} className={classes.paddingLeft}>
            <Button
              variant={"text"}
              color={"inherit"}
              component={"a"}
              href={homedeliveryUrl}
            >
              Home Delivery
            </Button>
          </Box>
        )}

        {(showClinicalReview && !bookwellGroup) && (
          <Box mt={2} mb={2} className={classes.paddingLeft}>
            <Button
              variant={"text"}
              color={"inherit"}
              component={Link}
              to={"/clinical-review"}
            >
              Clinical Review
            </Button>
          </Box>
        )}

        <Box className={classes.paddingLeft}>
          <Button
            variant={"text"}
            color={"inherit"}
            component={"a"}
            href={"https://eexpert.well.co.uk/enrol/index.php?id=586"}
          >
            Help
          </Button>
        </Box>

        <Button
          ref={userMenuAnchorRef}
          variant={"text"}
          aria-label={"account of current user"}
          aria-controls={"menu-user"}
          aria-haspopup={"true"}
          onClick={handleUserMenuOpen}
          color={"inherit"}
          className={`${classes.marginLeft}  ${classes.paddingEmail}`}
          startIcon={
            <Avatar className={classes.avatar}>
              {(props.email && props.email[0]?.toUpperCase()) || ""}
            </Avatar>
          }
        >
          {props.email}
        </Button>
        <Menu
          classes={{ paper: classes.menu }}
          className={classes.paddingTop}
          id={"menu-user"}
          anchorEl={userMenuAnchorRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={isUserMenuOpen}
          onClose={handleUserMenuClose}
        >
          {!bookwellGroup?.includes(BOOKWELL_USER_GROUP.BW_CUSTOMER_SERIVCE) &&<MenuItem onClick={openAvailabilityModal}>
            Service availability
          </MenuItem>}
          <MenuItem onClick={signOutRedirect}>Sign out</MenuItem>
        </Menu>
        {locationId && <AvailabilityModal
          isModalOpen={isModalOpen}
          closeModal={closeAvailabilityModal}
          locationId={locationId}
        />}
      </Toolbar>
      <SubHeader hideLocation={location.pathname === '/central-visibility'} isBookwell={bookwellGroup ? bookwellGroup : ''}></SubHeader>
    </AppBar>
    }
  </>;
};

export default Header;
