import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  ItemManagerField,
  ItemManagerFieldProps,
  Button,
  Form,
  ModalActions,
  PeriodTypes,
  SwitchField,
  Typography,
  WatchFields,
  ModalTitle,
  FormConfirmationModalProps,
  Alert,
  InputField,
} from "@welldigital/components";
import AvailabilityItem from "./AvailabilityItem/AvailabilityItem";
import useIndividualService from "./IndividualService.hooks";
import RescheduleConfirmation from "./RescheduleConfirmation/RescheduleConfirmation";
import { ServiceData } from "./IndividualService.types";
import { useLocation } from "../ServiceAvailability/hooks/useLocation";
import { getDateFromTime } from "./IndividualService.utils";
import { useServices } from "../IndividualServices/IndividualServices.hooks";

type IndividualServiceParams = {
  serviceId: string;
};

const IndividualService: React.FC = () => {
  const history = useHistory();
  const { serviceId } = useParams<IndividualServiceParams>();
  const { minTimeNumber, maxTimeNumber } = useLocation();
  const { services, locationId } = useServices(false);
  const service = services?.find((service) => service.serviceId === serviceId);
  const {
    data: blockData,
    updateData: updateBlockData,
    errorMessage,
  } = useIndividualService({ id: serviceId, locationId });

  const minTime = useMemo(() => getDateFromTime(minTimeNumber), [
    minTimeNumber,
  ]);
  const maxTime = useMemo(() => getDateFromTime(maxTimeNumber), [
    maxTimeNumber,
  ]);

  const handleSubmit = useCallback(
    async (values: ServiceData) => {
      values.blocks = values.blocks || [];
      await updateBlockData(values);
    },
    [updateBlockData],
  );

  const renderItem: ItemManagerFieldProps["renderItem"] = useCallback(
    (item) => (
      <AvailabilityItem {...item} minTime={minTime} maxTime={maxTime} />
    ),
    [maxTime, minTime],
  );

  const renderConfirmationModal = useCallback(
    (props: FormConfirmationModalProps) => (
      <RescheduleConfirmation {...props} />
    ),
    [],
  );

  if (!service || !blockData) return <div>Loading...</div>;
  return (
    <Form
      initialValues={blockData}
      onSubmit={handleSubmit}
      renderConfirmationModal={renderConfirmationModal}
    >
      <InputField type={"hidden"} name={"pausedBlockId"} />
      <ModalTitle
        label={service.serviceName}
        variant={"subtitle"}
        onBack={history.goBack}
      />
      {errorMessage && (
        <Alert type={"error"} message={errorMessage} spacingAfter={4} />
      )}
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        Any changes to service availability must be made{" "}
        <strong>at least 24 hours in advance</strong>.
      </Typography>
      <SwitchField
        name={"isPaused"}
        label={"Pause this service"}
        variant={"contained"}
        fullWidth
        reversed
        spacingAfter={3}
      />
      <Typography variant={"subtitle1"} spacingAfter={1}>
        Block this service
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        Block {service.serviceName} service from being booked by customers
        during selected times.
      </Typography>
      <WatchFields fieldNames={["isPaused"]}>
        {({ isPaused }: { isPaused: boolean }) => (
          <ItemManagerField
            name={"blocks"}
            disabled={isPaused}
            renderItem={renderItem}
            newItemValue={{
              periodType: PeriodTypes.weekdays,
            }}
            noItems={false}
          />
        )}
      </WatchFields>
      <ModalActions>
        <Button
          type={"submit"}
          variant={"contained"}
          color={"secondary"}
          children={"Save"}
          size={"medium"}
        />
      </ModalActions>
    </Form>
  );
};

export default IndividualService;
