import React from "react";
import { Theme, makeStyles, TextField, Link, Box } from "@material-ui/core";
import { Typography, Button } from "@welldigital/components";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useLocation } from "react-router-dom";
import * as yup from "yup";

const Schema = yup.object().shape({
  secret: yup
    .string()
    .trim()
    .required("Secret code is required")
    .matches(/^([0-9]{6}$)/, "Secret code is invalid"),
});

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: "#0061F2",
  },
}));

export interface SecretCodeForm {
  secret: string;
}

export interface SecretCodePageProps {
  onSubmit(data: SecretCodeForm): Promise<void>;
}

const SecretCodePage: React.FC<SecretCodePageProps> = (props) => {
  const location = useLocation<{ email: string }>();
  const { register, handleSubmit, errors } = useForm<SecretCodeForm>({
    validationSchema: Schema,
  });

  const classes = useStyles();
  const email = location.state?.email;

  return (
    <>
      <Typography variant={"h1"} spacingAfter={3}>
        We’ve sent you a code
      </Typography>
      <Typography>
        We have sent a 6-digit code to{" "}
        {email ? <strong>{email}</strong> : "your email"}
      </Typography>
      <form
        data-testid={"secret-code/form"}
        noValidate
        onSubmit={handleSubmit(props.onSubmit)}
      >
        <Box mt={2} mb={3}>
          <TextField
            fullWidth
            label={"Enter the 6-digit code"}
            autoComplete={"off"}
            name={"secret"}
            error={!!errors.secret}
            helperText={errors.secret && errors.secret.message}
            FormHelperTextProps={
              {
                "data-testid": "secret-code/helper-text",
              } as any // data-testid is omitted from the type
            }
            inputRef={register}
            inputProps={{
              "data-testid": "secret-code/code-input",
            }}
          />
        </Box>
        <Box mt={2} mb={3}>
          <Button type={"submit"} data-testid={"secret-code/signin-button"}>
            Sign in
          </Button>
        </Box>
      </form>
      <Link
        variant={"body1"}
        className={classes.link}
        component={RouterLink}
        to={"/login"}
      >
        I entered the incorrect email address
      </Link>
      <br />
      <br />
      <Typography variant={"h5"} spacingAfter={0}>
        Didn't get an email?
      </Typography>
      <ul>
        <Typography component={"li"}>
          Check you've entered the correct email address
        </Typography>
        <Typography component={"li"}>
          Allow up to 5 minutes for the email to arrive
        </Typography>
        <Typography component={"li"}>Check your Junk Email folder</Typography>
      </ul>
      <Typography>
        If you're still having problems, please contact the support team on{" "}
        <Link href={"tel:0161 2599135"} target={"_blank"}>
          0161 2599135
        </Link>
        {" or "}
        <Link
          className={classes.link}
          href={"tel:0161 2599136"}
          target={"_blank"}
        >
          0161 2599136
        </Link>
        {", "}
        or at{" "}
        <Link href={"mailto:Wellpharmacyservices@well.co.uk"} target={"_blank"}>
          Wellpharmacyservices@well.co.uk
        </Link>
      </Typography>
    </>
  );
};

export default SecretCodePage;
