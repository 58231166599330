import React, { FC, useMemo } from "react";
import {
  IRadioOption,
  RadioSelectField,
  Typography,
} from "@welldigital/components";
import { makeStyles } from "@material-ui/core";

import { Service } from "../../../../constants/service.constants";

const useStyles = makeStyles(() => ({
  vaccineSelection: {
    whiteSpace: "pre-line",
  },
}));

export type FluVaccineSelectionProps = {
  service: Service;
};

const FluVaccineSelection: FC<FluVaccineSelectionProps> = ({ service }) => {
  const servicesOptions: IRadioOption[] = useMemo(() => {
    const options = [
      {
        label: service.label,
        description: service.description,
        id: service.id,
      },
    ];

    return options;
  }, [service]);

  const styles = useStyles();

  return (
    <>
      <Typography variant={"h4"} spacingAfter={3}>
        Their pneumonia vaccination
      </Typography>

      <RadioSelectField
        name={"customerDetails.serviceID"}
        options={servicesOptions}
        defaultValue={service.id}
        variant={"contained"}
        className={styles.vaccineSelection}
        required
        fullWidth
      />
    </>
  );
};

export default FluVaccineSelection;
