import React, { FC, memo } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { Questions_getAppointment_questions } from "../../__generated__/Questions";

export interface QuestionType extends Questions_getAppointment_questions {
  updatedAnswer?: string;
}

interface QuestionProps {
  question: QuestionType;
}

function answer(a: string) {
  switch (a) {
    case "Y":
      return "Yes";
    case "N":
      return "No";
    default:
      return a;
  }
}

export const Question: FC<QuestionProps> = memo(({ question }) => (
  <TableRow key={question.number}>
    <TableCell data-testid={"question"}>{question.question}</TableCell>
    <TableCell data-testid={"answer"}>{answer(question.answer)}</TableCell>
    {!!question.updatedAnswer && (
      <TableCell data-testid={"updated-answer"}>
        {answer(question.updatedAnswer)}
      </TableCell>
    )}
  </TableRow>
));
