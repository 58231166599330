import React, { FC, useCallback, useContext } from "react";
import {
  makeStyles,
  Theme,
  Container,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import DashboardCalendar from "./components/DashboardCalendar";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { dateIsToday, formatDate } from "utils/time";
import TabTitle from "../../components/TabTitle/TabTitle";
import Splitter from "../../components/Splitter/Splitter";
import FixedPane from "../../components/FixedPane/FixedPane";
import FlexPane from "../../components/FlexPane/FlexPane";
import AppointmentsTable from "./components/AppointmentsTable";
import ServiceBlockAvailability from "./components/ServiceBlockAvailability";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import ToBeRescheduledTableContainer from "./components/ToBeRescheduledTable";
import { Alert } from "@welldigital/components";
import { AlertContext } from "./AlertContext.context";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  splitter: {
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  section: {
    position: "relative",
    padding: theme.spacing(2, 0),
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

export interface DashboardUIProps {
  pickerProps: {
    date: MaterialUiPickersDate;
    onChange(newDate: MaterialUiPickersDate, isFinish?: boolean): void;
  };
  selectedDate: string;
  changeByOneDay(back?: boolean): () => void;
  children: React.ReactNode;
}

const DashboardUI: FC<DashboardUIProps> = (props: DashboardUIProps) => {
  const {
    alertOptions: { isAlertShowing, message, type },
    closeAlert,
  } = useContext(AlertContext);
  const { selectedDate, pickerProps, changeByOneDay, children } = props;
  const isToday = dateIsToday(selectedDate);
  const classes = useStyles();

  const onAlertClose = useCallback(() => closeAlert(), [closeAlert]);
  const locationId = useAppSelector(selectLocationId);

  return (
    <>
      <TabTitle title={"Dashboard"} />
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
        ]}
      />
      <Splitter className={classes.splitter}>
        <FixedPane variant={"medium"}>
          {locationId && <DashboardCalendar pickerProps={pickerProps} locationId={locationId} />}
        </FixedPane>
        <FlexPane>
          <Container className={classes.container} maxWidth={"md"}>
            {isAlertShowing && (
              <section className={classes.section}>
                <Alert type={type} message={message} onClose={onAlertClose} />
              </section>
            )}
            <section className={classes.section}>
              {locationId && <ToBeRescheduledTableContainer locationId={locationId} />}
              <div className={classes.titleWrapper}>
                <Typography data-testid={"appointments-title"} variant={"h3"}>
                  {`Appointments ${
                    isToday ? "today" : `on ${formatDate(selectedDate)}`
                  }`}
                </Typography>
                <Tooltip title={"Previous day"}>
                  <IconButton
                    data-testid={"previous-day"}
                    className={classes.marginLeft}
                    onClick={changeByOneDay(true)}
                  >
                    <ChevronLeft />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Next day"}>
                  <IconButton
                    data-testid={"next-day"}
                    className={classes.marginLeft}
                    onClick={changeByOneDay()}
                  >
                    <ChevronRight />
                  </IconButton>
                </Tooltip>
              </div>
              {locationId && <AppointmentsTable selectedDate={selectedDate} locationId={locationId} />}
            </section>
            <section>
              <div className={classes.titleWrapper}>
                <Typography variant={"h3"}>
                  Service availability for next 90 days
                </Typography>
              </div>
              {locationId && <ServiceBlockAvailability locationId={locationId} />}
            </section>
          </Container>
        </FlexPane>
      </Splitter>
      {children}
    </>
  );
};

export default DashboardUI;
