import React, { FC, useCallback, useEffect, useMemo } from "react";
import { analytics } from "@welldigital/ui-common";

import ConfirmationPage from "./ConfirmationPage";

import { formatConfirmationDateTime } from "../../utils/time";
import EVENTS, { FLOW } from "../../utils/events";
import { useAppointmentPersister } from "persister/useAppointmentPersister";
import { useHistory, Redirect } from "react-router-dom";
import { Service, ServiceIds } from "../../constants/service.constants";
import useServices from "../../hooks/useServices";
import { selectBookwellGroup, selectLocationId } from "redux/reduxSlice/selector";
import { useAppSelector } from "redux/hooks";

const ConfirmationPageContainer: FC = () => {
  const { appointment } = useAppointmentPersister();
  const { serviceId, bookedSlot, formData } = appointment || {};
  const history = useHistory();
  const { services } = useServices();
  const locationId = useAppSelector(selectLocationId);
  const bookwellGroup = useAppSelector(selectBookwellGroup);

  const appointmentData = useMemo(() => {
    if (
      !bookedSlot ||
      !serviceId ||
      !formData ||
      !bookedSlot?.startTime ||
      !formData?.customerDetails?.firstName ||
      !formData?.customerDetails?.lastName
    )
      return null;

    const [selectedService] = services.filter(
      (service: Service) => service.id === parseInt(serviceId),
    );

    if (!selectedService?.name) return null;

    return {
      bookingType: selectedService.name,
      bookingDate: formatConfirmationDateTime(bookedSlot!.startTime),
      customerName: `${formData.customerDetails.firstName} ${formData.customerDetails.lastName}`,
      customerPhone:
        formData.customerDetails.phone || formData?.guardianDetails?.phone,
      customerEmail:
        formData.customerDetails.email || formData?.guardianDetails?.email,
      isFlu: [
        ServiceIds.Flu,
        ServiceIds.FluPPV,
        ServiceIds.FluPrevenar13,
      ].includes(selectedService.id),
    };
  }, [bookedSlot, serviceId, formData, services]);

  const onFinished = useCallback(() => {
    if(bookwellGroup && locationId)
      history.push(`/dashboard/${locationId}`);
    else
      history.push("/");
      history.go(0);
  }, [history, bookwellGroup, locationId]);

  useEffect(() => {
    analytics.trackEvent({
      flow: FLOW.walkIn,
      event: EVENTS.walkIn.booked,
      metadata: {
        serviceId,
      },
    });
  }, [serviceId]);

  return appointmentData ? (
    <ConfirmationPage
      appointmentData={appointmentData}
      onFinished={onFinished}
    />
  ) : (
    <Redirect to={"/"} />
  );
};

export default ConfirmationPageContainer;
