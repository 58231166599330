export const ServiceIds = {
  Flu: parseInt(process.env.REACT_APP_FLU!),
  FluPrevenar13: parseInt(process.env.REACT_APP_FLU_PREVNAR!),
  FluPPV: parseInt(process.env.REACT_APP_FLU_PPV!),
  PneumoniaWalkIn: parseInt(process.env.REACT_APP_PNEUMONIA_WALK_IN!),
  PneumoniaPPV: parseInt(process.env.REACT_APP_PNEUMONIA_PPV!),
  PneumoniaPrevenar13: parseInt(process.env.REACT_APP_PNEUMONIA_PREVNAR_13!),
  HPV: parseInt(process.env.REACT_APP_HPV!),
  Chickenpox: parseInt(process.env.REACT_APP_CHICKENPOX!),
  MenBBexsero: parseInt(process.env.REACT_APP_MENB_BEXSERO!),
  MenBTrumenba: parseInt(process.env.REACT_APP_MENB_TRUMENBA!),
  CovidAntigen: parseInt(process.env.REACT_APP_COVID_PCR_TEST!),
  CovidGreenPack: parseInt(process.env.REACT_APP_COVID_GREEN_PACK!),
  CovidAmberPack: parseInt(process.env.REACT_APP_COVID_AMBER_PACK!),
  Saxenda: parseInt(process.env.REACT_APP_SAXENDA!),
  HealthCheck: parseInt(process.env.REACT_APP_HEALTH_CHECK!),
  Wegovy: parseInt(process.env.REACT_APP_WEGOVY_SERVICES!),
  HPVSingleDose: parseInt(process.env.REACT_APP_HPV_SINGLE_DOSE!),
  HPVThreeDoses: parseInt(process.env.REACT_APP_HPV_THREE_DOSES!),
};
export type Service = {
  id: number;
  name: string;
  label?: string;
  description?: string;
  analyticsName: string;
  fee: number;
  includeGPDetails: boolean;
  includePaymentSelection?: boolean;
  includeVaccineSelection?: boolean;
};
