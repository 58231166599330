import React from 'react';
import Image from './Image';

export interface ImageType {
  id: number;
  src: string | ArrayBuffer | null;
  title: string;
  size: number;
}

interface PropsTypes {
  images: ImageType[];
  onImageDelete: (id: number) => void;
}
const ShowImage = ({ images, onImageDelete }: PropsTypes) => {

  return <div className="container">{images.map((image) => {
    return <Image image={image} onImageDelete={() => onImageDelete(image.id)} />;
  })}</div>;
};
export default ShowImage;