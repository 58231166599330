import React, { FC, useCallback } from "react";
import { useQuery } from "@apollo/client/react";
import { GetAppointments } from "./__generated__";
import AppointmentsTable from "./AppointmentsTable";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { GET_APPOINTMENTS } from "./gql";
//import { getLocationId } from "services/auth";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";


export interface AppointmentsProps {
  selectedDate: string;
  locationId: string;
}

const AppointmentsTableContainer: FC<AppointmentsProps> = (props) => {
  const history = useHistory();
  
  const goToServiceDetails = useCallback(
    (id: string) => () => history.push(`/service-details/${id}`),
    [history],
  );
  //const locationId = useAppSelector(selectLocationId);

  // const [locationId, setLocationId] = useState<any>();

  // useEffect(() => {
  //   if (!locationId) {
  //     const getLocId = async () => {
  //       const location = await getLocationId();
  //       setLocationId(location);
  //     };

  //     getLocId();
  //   }
  // }, [locationId, setLocationId]);
  const { selectedDate, locationId } = props;
  const startDate = format(new Date(selectedDate), "yyyy-MM-dd");
  const endDate = format(new Date(selectedDate), "yyyy-MM-dd");
  const { loading: isLoading, error, data } = useQuery<GetAppointments>(
    GET_APPOINTMENTS,
    {
      variables: {
        startDate,
        endDate,
        locationId: locationId,
      },
    },
  );

  if ((error?.networkError as any)?.statusCode === 401) {
    history.push("/login");
  }

  return (
    <AppointmentsTable
      isLoading={isLoading}
      error={error}
      data={data}
      selectedDate={selectedDate}
      goToServiceDetails={goToServiceDetails}
    />
  );
};

export default AppointmentsTableContainer;
