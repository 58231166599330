import React, { useContext, useEffect } from "react";
import {
  FormContextValue,
  FormContext,
  PeriodTypes,
  DaysMultiSelectField,
  Days,
} from "@welldigital/components";

export type CustomPeriodFieldProps = {
  name: string;
  periodTypeName: string;
  disabled?: boolean;
};

const WeekdaysField: React.FC<CustomPeriodFieldProps> = ({
  name,
  periodTypeName,
  disabled,
}) => {
  const {
    form: { watch, setValue },
  } = useContext<FormContextValue>(FormContext);
  const periodType = watch(periodTypeName);

  useEffect(() => {
    if (periodType === PeriodTypes.weekdays) {
      setValue(name, [Days.mon, Days.tue, Days.wed, Days.thu, Days.fri]);
    } else if (periodType === PeriodTypes.weekends) {
      setValue(name, [Days.sat, Days.sun]);
    }
  }, [name, periodType, setValue]);

  return (
    <DaysMultiSelectField
      periodType={periodType}
      name={name}
      disabled={disabled || periodType !== PeriodTypes.allWeek}
      spacingAfter={0}
      required
    />
  );
};

export default WeekdaysField;
