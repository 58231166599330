import React from "react";
import { Theme, makeStyles, Container } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { useQuery } from "@apollo/client/react";
import { useParams } from "react-router-dom";
import { Divider } from "@welldigital/components";
import GpDetails, { GpDetailsType } from "../../components/GpDetails/GpDetails";
import { GET_CUSTOMER } from "./gql";
import CustomerDetails, {
  CustomerDetailsType,
} from "../../components/CustomerDetails/CustomerDetails";
import CustomerBookings from "./CustomerBookings/CustomerBookings";
import Splitter from "../../components/Splitter/Splitter";
import FixedPane from "../../components/FixedPane/FixedPane";
import FlexPane from "../../components/FlexPane/FlexPane";
import TabTitle from "../../components/TabTitle/TabTitle";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import { Customer } from "./__generated__/Customer";
import { AppointmentStatus } from "__generated__/globalTypes";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  section: {
    position: "relative",
    padding: `${theme.spacing(3)}px 0`,
  },
}));

const CustomerDetailsPage: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, error, data } = useQuery<Customer>(GET_CUSTOMER, {
    variables: { customerId: id, locationId: locationId},
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data) return <p>no data</p>;

  const customer = data.getCustomer;

  const customerName = `${customer.firstName} ${customer.lastName}`;
  const upcomingAppointments =
    data.getCustomer.appointments?.filter(
      (appt) =>
        appt.status !== AppointmentStatus.completed &&
        appt.status !== AppointmentStatus.cancelled,
    ) ?? [];
  const previousAppointments = data.getCustomer.appointments?.filter(
    (appt) =>
      appt.status === AppointmentStatus.completed ||
      appt.status === AppointmentStatus.cancelled,
  );
  return (
    <>
      <TabTitle title={customerName} />
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `${window.location.pathname}`,
            name: customerName,
          },
        ]}
      />
      <Splitter data-testid={"customer-details"} className={classes.container}>
        <FixedPane variant={"small"}>
          <CustomerDetails
            customer={
              {
                id: customer.id,
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
                sex: customer.sex,
                phone: customer.phone,
                nhsNumber: customer.nhsNumber,
                address: customer.address,
                dob: customer.dob,
              } as CustomerDetailsType
            }
            id = {data.getCustomer.appointments && data.getCustomer.appointments?.length> 0 ? data.getCustomer.appointments[0].id : ''}
            serviceId = {data.getCustomer.appointments && data.getCustomer.appointments?.length> 0 ? data?.getCustomer?.appointments[0].course?.onSchedServiceId : ''}
          />
          <Divider />
          <GpDetails
            gp={
              {
                surgeryName: customer.surgeryName,
                surgeryAddress: customer.surgeryAddress,
              } as GpDetailsType
            }
          />
        </FixedPane>
        <FlexPane>
          <Container maxWidth={"md"}>
            <section className={classes.section}>
              <Typography variant={"h3"} spacingAfter={3}>
                Upcoming appointments
              </Typography>
              <CustomerBookings appointments={upcomingAppointments} />
            </section>
            {!!(previousAppointments && previousAppointments.length) && (
              <section className={classes.section}>
                <Typography variant={"h3"} spacingAfter={3}>
                  Previous appointments
                </Typography>
                <CustomerBookings appointments={previousAppointments} />
              </section>
            )}
          </Container>
        </FlexPane>
      </Splitter>
    </>
  );
};

export default CustomerDetailsPage;
