import React, { FC, memo, useRef, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Question } from "graphql/types";
import { QuestionAuditItem } from "../QuestionAuditItem/QuestionAuditItem";
import { Indent } from "components/Indent/Indent";

export interface EditQuestionProps {
  questionNumber: number;
  previousQuestions: Question[];
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    minWidth: "80px",
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const QuestionAudit: FC<EditQuestionProps> = memo((props) => {
  const { previousQuestions, questionNumber } = props;

  const classes = useStyles();
  const ref = useRef(null);
  const [height, setHeight] = useState("0");

  useEffect(() => {
    setHeight((ref?.current as any).clientHeight);
  }, [setHeight, ref]);

  return (
    <div className={classes.margin} ref={ref}>
      <Indent height={height}>
        {previousQuestions.map((pq: Question, index: number) => {
          let fromAnswer = "No answer";
          if (index > 0) {
            fromAnswer = previousQuestions[index - 1].answer;
          }
          return (
            <QuestionAuditItem
              key={`previousQuestion-${props.questionNumber}-${new Date(
                pq.updatedDate,
              ).toISOString()}`}
              questionNumber={questionNumber}
              fromAnswer={fromAnswer}
              toAnswer={pq.answer}
              updatedDate={pq.updatedDate}
            />
          );
        })}
      </Indent>
    </div>
  );
});
