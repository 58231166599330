import React, { FC } from "react";
import { makeStyles, Theme, CircularProgress } from "@material-ui/core";

import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((_theme: Theme) => ({
  loadingContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iframe: {
    width: "100%",
    height: 650,
    border: 0,
  },
}));

export interface WalkInProps {
  serviceId: string;
  locationId?: string;
}

const WalkInPage: FC<WalkInProps> = ({ serviceId, locationId }) => {
  const history = useHistory();
  const classes = useStyles();
  const src = `${process.env.REACT_APP_SERVICES_WEB}/?serviceId=${serviceId}&locationId=${locationId}&utm_source=serv-os&utm_medium=iframe&utm_campaign=walk-in&preventRedirect=true`;
  let walkInServiceName = "";

  if (!locationId) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color={"secondary"} />
      </div>
    );
  }

  switch (serviceId) {
    case process.env.REACT_APP_FLU:
      walkInServiceName = "Flu";
      break;
    case process.env.REACT_APP_PNEUMONIA_WALK_IN:
      walkInServiceName = "Pneumococcal";
      break;
    default:
      // Unsupported, redirect to root
      history.replace("/");
  }

  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `${window.location.pathname}`,
            name: `${walkInServiceName} walk in`,
          },
        ]}
      />
      <iframe
        src={src}
        title={"Services"}
        className={classes.iframe}
        sandbox={"allow-scripts allow-same-origin allow-forms"}
      />
    </>
  );
};

export default WalkInPage;
