import React from "react";
import { MemoryRouter, Route, Switch } from "react-router-dom";
import { Modal } from "@welldigital/components";
import ServiceAvailability from "./ServiceAvailability/ServiceAvailability";
import IndividualServices from "./IndividualServices/IndividualServices";
import IndividualService from "./IndividualService/IndividualService";
import { makeStyles } from "@material-ui/core";
import LunchHours from "./ServiceAvailability/LunchHours/LunchHours";
import WorkingHours from "./ServiceAvailability/WorkingHours/WorkingHours";
import EmergencyPause from "./ServiceAvailability/ManageServices/EmergencyPause";
import ScheduledLeaveContainer from "./components/ScheduledLeave/ScheduledLeaveContainer";
import WalkInFluClinicContainer from "./components/WalkInFluClinic/WalkInFluClinicContainer";
export interface AvailabilityModalContainerProps {
  isModalOpen: boolean;
  closeModal(): void;
  locationId: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    width: 598,
  },
}));

const AvailabilityModalContainer = (props: AvailabilityModalContainerProps) => {
  const classes = useStyles();
  const { closeModal, isModalOpen, locationId } = props;
  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      PaperProps={{ className: classes.paper }}
    >
      <MemoryRouter>
        <Switch>
          <Route path={"/"} exact>
            <ServiceAvailability onClose={closeModal} />
          </Route>
          <Route path={"/lunchhours"} exact>
            <LunchHours onClose={closeModal} />
          </Route>
          <Route path={"/appointmenthours"} exact>
            <WorkingHours onClose={closeModal} />
          </Route>
          <Route path={"/walkinfluclinic"} exact>
            <WalkInFluClinicContainer onClose={closeModal} locationId={locationId} />
          </Route>
          <Route path={"/scheduledleave"} exact>
            <ScheduledLeaveContainer onClose={closeModal} locationId={locationId} />
          </Route>
          <Route path={"/emergencypause"} exact>
            <EmergencyPause onClose={closeModal} />
          </Route>
          <Route path={"/services"} exact>
            <IndividualServices />
          </Route>
          <Route path={"/services/:serviceId"}>
            <IndividualService />
          </Route>
        </Switch>
      </MemoryRouter>
    </Modal>
  );
};

export default AvailabilityModalContainer;
