import { Typography } from "@welldigital/components";
import React from "react";
import { Products as ProductType } from "../../Types";
import "./subscription.scss";

const SubscriptionDetails = ({ products }: { products: ProductType[] }) => {
  return (
    <>
      {products
        ?.filter((item) => item.subscriptionId || item.subscriptionIssueNumber)
        .map((item) => {
          return (
            <div
              key={`subs_details_${item.subscriptionId}`}
              className="w-subscription-details-container"
            >
              <div className="w-subscription-details-inline">
                <Typography className="w-subscription-details-label">
                  Subscription Id: {item?.subscriptionId}
                </Typography>
                <Typography className="w-subscription-details-label">
                  Issue {item?.subscriptionIssueNumber} of {item?.maxIssueLimit || 12}
                </Typography>
              </div>
              <Typography className="w-subscription-details-productName">
                {item.productTitle}
              </Typography>
            </div>
          );
        })}
    </>
  );
};

export default SubscriptionDetails;
