import { ApolloError, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { Screening } from "../../../graphql/fragments/__generated__/Screening";
import { NEXT_SCREENING } from "../../../graphql/nextScreening";
import { START_SCREENING } from "../../../graphql/startScreening";
import {
  nextScreening,
  nextScreeningVariables,
} from "../../../graphql/__generated__/nextScreening";
import {
  startScreening,
  startScreeningVariables,
} from "../../../graphql/__generated__/startScreening";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export const LOCAL_STORAGE_SCREENING_QUESTION = "screeningQuestion";
export const LOCAL_STORAGE_SCREENING_COUNT = "screeningCount";

export function clearScreeningQuestion() {
  window.localStorage.removeItem(LOCAL_STORAGE_SCREENING_QUESTION);
  window.localStorage.removeItem(LOCAL_STORAGE_SCREENING_COUNT);
}

export function useScreeningQuestions() {
  const onError = (error: ApolloError) => {
    setError(error);
  };

  const [
    doStartScreening,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { data: startScreeningData, loading: startScreeningLoading },
  ] = useMutation<startScreening, startScreeningVariables>(START_SCREENING, {
    onError,
  });
  const [
    doNextScreening,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { data: nextScreeningData, loading: nextScreeningLoading },
  ] = useMutation<nextScreening, nextScreeningVariables>(NEXT_SCREENING, {
    onError,
  });

  const [error, setError] = useState<ApolloError>();
  const [screeningQuestion, setScreeningQuestion] = useLocalStorage<
    Screening | undefined
  >(LOCAL_STORAGE_SCREENING_QUESTION, undefined);
  const [count, setCount] = useLocalStorage<number>(
    LOCAL_STORAGE_SCREENING_COUNT,
    1,
  );

  const onStartScreening = useCallback(
    (variables: startScreeningVariables) => {
      if (!startScreeningData) {
        doStartScreening({
          variables,
        });
      }
    },
    [doStartScreening, startScreeningData],
  );

  const onNextScreening = useCallback(
    (variables: nextScreeningVariables) => {
      doNextScreening({
        variables,
      });
      setCount((oldCount) => oldCount + 1);
    },
    [doNextScreening, setCount],
  );

  const startScreeningQuestion = startScreeningData?.startScreening;
  const nextScreeningQuestion = nextScreeningData?.nextScreening;

  useEffect(() => {
    if (startScreeningQuestion) {
      setScreeningQuestion(startScreeningQuestion);
    } else if (nextScreeningQuestion) {
      setScreeningQuestion(nextScreeningQuestion);
    }
  }, [nextScreeningQuestion, setScreeningQuestion, startScreeningQuestion]);

  return {
    count,
    startScreening: onStartScreening,
    nextScreening: onNextScreening,
    clearScreeningQuestion,
    error,
    screeningQuestion,
    loading: startScreeningLoading || nextScreeningLoading,
  };
}
