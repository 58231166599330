import React, { useRef } from "react";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { Products as ProductsTypes } from "../../../Types";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import "./index.scss";
interface PropsTypes {
  products: ProductsTypes[];
}

const OtherOrderItemsModal = ({ products }: PropsTypes) => {
  const dialogRef = useRef<CustomDialogRef>(null);

  if (!products.length) {
    return null;
  }

  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        align="right"
        spacingAfter={4}
        className="view-items"
        onClick={() => dialogRef.current?.show()}
      >
        View other items in this order ({products.length})
      </Typography>

      <CustomDialog ref={dialogRef} title="GSL items in order">
        <Table className="gsl-items">
          <TableBody>
            {products?.map(
              ({
                productTitle,
                productPrice,
                productQuantity,
                productVariation,
                ageRestricted
              }) => (
                <TableRow key={`gsl-item-${productTitle}`}>
                  <TableCell>
                    <div className="flex-column">
                      <span>{productTitle}{ageRestricted === true && <span>*</span>}</span>
                      <span>{productVariation}</span>
                    </div>
                  </TableCell>
                  <TableCell align="right" width={80}>
                    Qty {productQuantity}
                  </TableCell>
                  <TableCell align="right" width={80}>
                    £{productPrice}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </CustomDialog>
    </>
  );
};

export default OtherOrderItemsModal;
