import React from "react";
import { Screening } from "../../graphql/fragments/__generated__/Screening";
import { Service } from "./types";
import CovidQuestions from "../CovidQuestions/CovidQuestions";
import ScreeningPage from "./ScreeningPage";
import BookAppointment from "./components/BookAppointment";
import WGForm from "../WGForm/components/WGForm";

function isCovidService(serviceId: string): boolean {
  return (
    serviceId === process.env.REACT_APP_COVID_PCR_TEST ||
    serviceId === process.env.REACT_APP_COVID_GREEN_PACK ||
    serviceId === process.env.REACT_APP_COVID_AMBER_PACK
  );
}

/**
 * Parses the result of the screening question and decides whether
 *
 * 1. The screening questions should begin (person maybe ineligible but handled downstream)
 * 2. The questions can be skipped and a booking can be made immediately
 * 3. Covid questions need to be done
 */

export interface ScreeningStepContainerProps {
  screening: Screening | undefined;
  service: Service;
}

function ScreeningStepContainer({
  screening,
  service,
}: ScreeningStepContainerProps) {
  const shouldSkipQuestions =
    screening?.isAllowedService &&
    screening.hasResult &&
    !screening.patientAdvice;

  if (shouldSkipQuestions) {
    if (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) {
      return <WGForm
        formname="WG-FORM"
        service={service}
      />;
    } 
    return <BookAppointment service={service} />;
    
  }
  if (isCovidService(service.id.toString())) {
    return <CovidQuestions service={service} />;
  }
  return <ScreeningPage service={service} />
}

export default ScreeningStepContainer;
