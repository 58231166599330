import React, { useEffect, useRef, useState } from "react";
import { useForm, OnSubmit } from "react-hook-form";
import {
  Grid,
  MenuItem,
  TextField,
  Select,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import { ReactComponent as IcArrowDown } from "assets/icons/arrow-down.svg";
import "./index.scss";
import HookFormSelect from "../CustomerDetails/HookFormSelect";
import { updateIdCheckStatus } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import {updateStatusSchema } from "utils/validations";
import { useCurrentUserEmail } from "services/auth-hooks";


interface PropsTypes {
  onClose?: (visible: boolean) => void;
  onUpdateSuccess: (showUpdateOrder?: boolean) => void;
  idCheckStatus: string;
  customerId: number;
}

const UpdateStatusDetails = ({
  onUpdateSuccess,
  onClose,
  idCheckStatus,
  customerId,
}: PropsTypes) => {
  const formStatusRef = useRef<HTMLFormElement>(null);
  const dialogRefStatus = useRef<CustomDialogRef>(null);
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const email = useCurrentUserEmail();
  useEffect(() => {
    dialogRefStatus.current?.show();
  }, []);

  const {
    register,
    handleSubmit,
    //clearError,
    control,
  //  getValues,
   // triggerValidation,
    errors,
    formState: { isValid },
  } = useForm<UpdateStatusForm>({defaultValues:toOrderStatusForm({status: idCheckStatus===""?"select" : idCheckStatus,lnSearchRef: ""}),
      validationSchema: updateStatusSchema,
      mode: "onChange"
  });


  const onSubmit: OnSubmit<UpdateStatusForm> = async (updateStatusForm) => {
    setLoading(true);
    setError(false);
    try{
      const response=await updateIdCheckStatus(customerId,{updatedBy: email || "test",...updateStatusForm});
      if(response?.status===204){
        dialogRefStatus.current?.hide();
        onUpdateSuccess?.(updateStatusForm?.status !=='pass' ? true : false);
      }else{
        setError(true);
      }
    }catch(error){
      setError(true);
      console.log("Error in updating id check status");
    }finally{
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      onClose={() => onClose?.(false)}
      ref={dialogRefStatus}
      title={
        <>
          ID check status
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
        </>
      }
      className="update-status-dialog"
    >
      <form ref={formStatusRef} onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="update-status-container">
             <Grid item md={12}>
              <Typography className="popup-para-color mb-10">
                <p>
                    By amending the customer’s ID check status, you are confirming that you have verified the customer’s ID via Lexis Nexis. 
                </p>
                <p>
                    If the ID check was referred or failed because the patient details we hold were incorrect, you should edit the patient details before submitting this update.
                 </p>   
                
              </Typography>
              
            </Grid>
            <Grid item md={12}>
              <Typography className="font-weight-700 color-primary-hale mb-10">
                  ID check status
              </Typography>
            </Grid>
            <Grid md={12} className="row">
              <Grid item md={4}>
                <HookFormSelect
                  control={control}
                  defaultValue={""}
                  name="status"
                  fullWidth
                >
                  <Select
                    IconComponent={() => (
                      <IcArrowDown className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
                    )}
                    className="input"
                    variant="outlined"
                    value={control.getValues().status}
                    error={!!errors.status}
                  >
                    <MenuItem value="select">Select an option</MenuItem>
                    <MenuItem value="pass">Pass</MenuItem>
                    <MenuItem value="refer">Refer</MenuItem>
                    <MenuItem value="fail">Fail</MenuItem>
                  </Select>
                </HookFormSelect>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid item md={12}>
                <Typography className="font-weight-700 color-primary-hale mb-10">
                    Notes
                </Typography>
                <Typography className="font-weight-500 color-primary-hale mb-10">
                (*Please add the LN reference number if available)
                </Typography>
              </Grid>
              <Grid item md={6}>
                  <TextField
                    className="input"
                    multiline
                    variant="outlined"
                    name="lnSearchRef"
                    error={!!errors.lnSearchRef}
                    helperText={errors.lnSearchRef?.message}
                    inputRef={register}
                    minRows={2}
                    maxRows={2}
                  />
              </Grid>
            </Grid>
            <Grid item md={6}>
              {/* This is to fix spacing */}
            </Grid>
          </Grid>
        {error && (
          <Typography variant="subtitle2" className="color-error">
            Error in updating ID check status
          </Typography>
        )}
        <div className="buttons-container">
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => dialogRefStatus.current?.hide()}
          >
            Cancel
          </Button>
          <Button type="submit"  disabled={!isValid || loading}>
            Update status
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};
export interface UpdateStatusForm {
    status: string;
    lnSearchRef: string;
}
const toOrderStatusForm = ({
    status,
    lnSearchRef
  }: UpdateStatusForm): UpdateStatusForm => ({
    status,
    lnSearchRef
  });
export default UpdateStatusDetails;


