import React, { FC } from "react";

import Header from "../../../components/Header";
import LoggedInRoutes from "../../routers/LoggedInRoutes";
import Footer, { footerHeight } from "components/Footer/Footer";
import { headerHeight } from "components/Header/Header";
import { makeStyles, Theme } from "@material-ui/core";
import { BreadCrumbsContainer } from "components/BreadCrumbs/Breadcrumbs";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
  },
  container: {
    padding: "24px 24px 24px 47px",
  },
  page: {
    marginTop: theme.spacing(3),
  },
}));

const LoggedInLayout: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Header />

      <div className={classes.root}>
        <div className={classes.container}>
          <BreadCrumbsContainer />
          <div className={classes.page}>
            <LoggedInRoutes />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoggedInLayout;
