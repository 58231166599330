import React, { FC } from "react";
import client from "services/graphql";
import { ApolloProvider } from "@apollo/client/react";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@welldigital/components";

import Routes from "./routers/Router";

import { SnackbarProvider } from "notistack";
import useServices from "../hooks/useServices";

const App: FC = () => {
  const { isLoading } = useServices();

  if (isLoading) return null;
  return (
    <ApolloProvider client={client}>
      <ThemeProvider themeName={"servOs"}>
        <CssBaseline />
        <Router
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          basename={(window as any).__POWERED_BY_QIANKUN__ ? "/appts" : "/"}
        >
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
          >
            <Routes />
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
