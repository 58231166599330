import React, { FC, memo, useCallback } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  FormControl,
  Typography,
} from "@material-ui/core";
import { Question } from "graphql/types";
import { QuestionAudit } from "../QuestionAudit/QuestionAudit";

export interface EditQuestionProps {
  question: Question;
  updatedQuestion: Question | null;
  previousQuestions: Question[];
  canHighlight?: boolean;
  showAudits?: boolean;
  handleQuestionUpdate(q: Question): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    minWidth: "80px",
  },
  highlight: {
    background: "#EAF2FE;",
  },
}));

export const EditQuestion: FC<EditQuestionProps> = memo((props) => {
  const { question, handleQuestionUpdate, updatedQuestion } = props;

  const classes = useStyles();

  const setUpdatedQuestionType1 = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
    ) => {
      const newQuestion: Question = {
        __typename: "Question",
        answer: event.target.value as string,
        number: question.number,
        question: question.question,
        questionType: question.questionType,
        updatedDate: new Date(),
      };
      handleQuestionUpdate(newQuestion);
    },
    [question, handleQuestionUpdate],
  );

  const setUpdatedQuestionTypeOther = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newQuestion: Question = {
        __typename: "Question",
        answer: event.target.value,
        number: question.number,
        question: question.question,
        questionType: question.questionType,
        updatedDate: new Date(),
      };
      handleQuestionUpdate(newQuestion);
    },
    [question, handleQuestionUpdate],
  );
  const value = props.updatedQuestion
    ? props.updatedQuestion.answer
    : props.question.answer;

  const isEdited = !!updatedQuestion && props.canHighlight;

  return (
    <TableRow
      key={props.question.number}
      className={`${isEdited ? classes.highlight : ""}`}
    >
      <TableCell data-testid={"question"}>
        <Typography variant={"body1"}>{props.question.question}</Typography>
        {props.showAudits && props.previousQuestions.length > 1 && (
          <QuestionAudit
            previousQuestions={props.previousQuestions}
            questionNumber={props.question.number}
          />
        )}
      </TableCell>
      <TableCell data-testid={"updated-answer"} size={"medium"}>
        {question.questionType === 1 && (
          <FormControl variant={"outlined"}>
            <Select
              inputProps={{
                "data-testid": `editQuestion/answer-${props.question.number}-select`,
              }}
              className={classes.input}
              value={value}
              onChange={setUpdatedQuestionType1}
              autoWidth
            >
              <MenuItem value={"Y"}>Yes</MenuItem>
              <MenuItem value={"N"}>No</MenuItem>
            </Select>
          </FormControl>
        )}
        {question.questionType !== 1 && (
          <TextField
            className={classes.input}
            inputProps={{
              "data-testid": `editQuestion/answer-${props.question.number}-text`,
            }}
            value={value}
            onChange={setUpdatedQuestionTypeOther}
            size={"medium"}
          />
        )}
      </TableCell>
    </TableRow>
  );
});
