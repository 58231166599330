import { gql } from "@apollo/client";

export const LocationFragment = gql`
  fragment LocationService on LocationService {
    id
    serviceId
    serviceName
  }
`;

export interface LocationQueryVars {
  locationId: string;
  ignoreCovid: Boolean;
}

export type LocationService = {
  id: string;
  serviceId: string;
  serviceName: string;
};

export interface LocationQueryData {
  getLocationServices: LocationService[];
}

export const GET_LOCATIONS_QUERY = gql`
  query getLocationServices($locationId: ID!, $ignoreCovid: Boolean) {
    getLocationServices(locationId: $locationId, ignoreCovid: $ignoreCovid) {
      ...LocationService
    }
  }
  ${LocationFragment}
`;

export const GET_LOCATION = gql`
  query getLocation($locationId: ID!) {
    getLocation(locationId: $locationId) {
      name
      email
      phone
      businessHours {
        mon {
          startTime
          endTime
          isOpen
          is24Hours
        }
        tue {
          startTime
          endTime
          isOpen
          is24Hours
        }
        wed {
          startTime
          endTime
          isOpen
          is24Hours
        }
        thu {
          startTime
          endTime
          isOpen
          is24Hours
        }
        fri {
          startTime
          endTime
          isOpen
          is24Hours
        }
        sat {
          startTime
          endTime
          isOpen
          is24Hours
        }
        sun {
          startTime
          endTime
          isOpen
          is24Hours
        }
      }
    }
  }
`;
