import React, { FC } from "react";
import {Alert, Button, Typography } from "@welldigital/components";

import SelectAService from "./components/SelectAService";
import SelectADate from "./components/SelectADate";
import WalkInActions from "components/WalkInActions/WalkinActions";
import { useAppointmentPersister } from "persister/useAppointmentPersister";
import { ErrorAlertProps } from "components/ErrorAlert/ErrorAlert";
export interface BookAnAppointmentProps {
  onCancel: () => void;
  onNext: () => void;
  nextDisabled: boolean;
  initError: ErrorAlertProps;
}

const BookAnAppointment: FC<BookAnAppointmentProps> = ({
  onCancel,
  onNext,
  nextDisabled,
  initError
}) => {
  const { appointment } = useAppointmentPersister();
  return (
    <>
      <Typography variant={"h2"} color={"textPrimary"}>
        Book an appointment
      </Typography>
      <SelectAService ignoreCovid />
      {appointment?.serviceId && <SelectADate />}
      {initError && (
         <Alert   type={"error"} message={initError.message} />
      )}
      <WalkInActions display={"flex"} justifyContent={"flex-end"}>
        <Button
          data-testid={"bookAppointmentActions/cancel"}
          variant={"outlined"}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid={"bookAppointmentActions/next"}
          onClick={onNext}
          minWidth={320}
          disabled={nextDisabled}
        >
          Next
        </Button>
      </WalkInActions>
    </>
  );
};

export default BookAnAppointment;
