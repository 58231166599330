import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";

// import Pagination from "@material-ui/Pagination";
import OrderHistoryDetailsWrapper, {
  PropsTypes,
} from "./OrderHistoryDetailsWrapper";
import { OrderItemsForReview } from "../OrderItemsForReview/OrderItemsForReview";
import { formatDate } from "utils/time";
import { formatOrderStatus } from "utils/user";
// import { Products as ProductType } from "../../Types";

const OrderHistoryDetails = ({ orderHistory }: PropsTypes) => {
  const gslProducts = orderHistory?.products.filter(
    ({ primaryTag }) => primaryTag === "GSL",
  );
  const restProducts = orderHistory?.products.filter(
    ({ primaryTag }) => primaryTag !== "GSL",
  );

  // console.log("orderHistory1", orderHistory, gslProducts, restProducts);
  /*const getTableHeaders = (product: ProductType) => [
    product.primaryTag,
    product.reviewStatus,
    product.reviewDate,
    product.reviewedBy
  ];*/

  const orderList = {
    orderId: "Order no.",
    orderDate: "Order placed",
    orderAmount: "Total",
    orderStatus: "Status",
  };

  const formatHeaderData = (key: keyof typeof orderList) => {
    switch (key) {
      case "orderDate":
        return formatDate(orderHistory[key] as string, "dd/MM/yy");
      case "orderStatus":
        return formatOrderStatus(orderHistory[key]);
      default:
        return orderHistory[key];
    }
  };
  const orderNotesForConfirmBMI = orderHistory?.orderNotes?.length > 0 ? orderHistory?.orderNotes?.filter(notes => notes.productId !== 0) : [];

  return (
    <>
      <Grid container className="crp_order_history_item">
        {/**Order details */}
        <Grid container className="crp_order_history_item_header color">
          {(Object.keys(orderList) as (keyof typeof orderList)[]).map((key) => (
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2">{orderList[key]}</Typography>
              <Typography variant="caption">
                {orderList[key] === "Total" && "£"}
                {formatHeaderData(key)}
              </Typography>
            </Grid>
          ))}
        </Grid>

        {/**Order GSL items list */}
        {gslProducts.length > 0 && (
          <Grid container>
            {" "}
            {gslProducts.map(
              ({
                productTitle,
                productQuantity,
                productPrice,
                productVariation,
              }) => (
                <Grid xs={12} className="crp_order_history_item_details">
                  <div className="flex-column product_label">
                    <Typography variant="caption" color="primary">
                      {productTitle}
                    </Typography>
                    {!!productVariation && (
                      <Typography variant="caption" color="primary">
                        {productVariation}
                      </Typography>
                    )}
                  </div>
                  <Typography
                    className="product_label"
                    variant="caption"
                    color="primary"
                    spacingAfter={1}
                  >
                    &#163;{productPrice}
                  </Typography>
                  <Typography
                    className="product_label"
                    variant="caption"
                    color="primary"
                    spacingAfter={1}
                  >
                    Qty {productQuantity}
                  </Typography>
                </Grid>
              ),
            )}
          </Grid>
        )}

        {/**Order rest items list */}
        {/* <Grid container> */}
        <Grid item xs={12}>
          {restProducts &&
            restProducts.map((product) => (
              <div className="crp_order_item crp_order_history_item_history">
                <div className="crp_order_item_header">
                  {/* {getTableHeaders(product).map((header) => (
                    <>
                   { header !== null && header!= "" && <Typography
                      variant="subtitle1"
                      color="primary"
                      className="crp_order_item_header_item"
                    >
                      {header}
                    </Typography>}
                    </>
                  ))} */}
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className="crp_order_item_header_item"
                  >
                    {product.primaryTag}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className="crp_order_item_header_item header_item2"
                  >
                    {formatOrderStatus(product.reviewStatus)}
                    <br />
                    {product.reviewedBy && (
                      <span className="history_text text-lower">
                        {formatDate(product.reviewDate, "dd/MM/yy")}{" "}
                        {product.reviewedBy}
                      </span>
                    )}
                  </Typography>
                </div>
                <OrderItemsForReview
                  orderId={product.subscriptionBaseOrderId ?? orderHistory.orderId}
                  orderDate={orderHistory.orderDate}
                  product={product}
                  showHistory={true} idCheckStatus={""} idCheckApiStatus={""} isApprovedAllGSL={true} onUpdateImages={() => { }} orderNotesForConfirmBMI={orderNotesForConfirmBMI} />
              </div>
            ))}
        </Grid>
        {/* </Grid> */}
      </Grid>
      {/* {orderHistory?.products &&
        orderHistory.products.map((product) => {
          <OrderItemsForReview product={product} />;
        })} */}
    </>
  );
};

export default OrderHistoryDetailsWrapper(OrderHistoryDetails);
