import React, { FC } from "react";
import { Box, makeStyles, Theme, BoxProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    padding: theme.spacing(6, 6, 0, 6),

    "& button:first-child": {
      marginRight: theme.spacing(2),
    },
  },
}));

export interface WalkInActionsProps extends BoxProps {}

const WalkInActions: FC<WalkInActionsProps> = (props) => {
  const styles = useStyles();
  const { children } = props;
  return (
    <Box mx={-6} mt={6} className={styles.buttonContainer} {...props}>
      {children as Element}
    </Box>
  );
};

export default WalkInActions;
