import React, { useCallback } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { formatDate, formatTime } from "utils/time";
import { statusToString } from "utils/status";
import AppointmentActions from "../../../components/AppointmentActions/AppointmentActions";
import { Customer_getCustomer_appointments } from "../__generated__/Customer";

export interface CustomerBookingProps {
  appointments: Customer_getCustomer_appointments[];
}

const CustomerBooking: React.FC<CustomerBookingProps> = (props) => {
  const history = useHistory();

  const goToServiceDetails = useCallback(
    (id: string) => () => history.push(`/service-details/${id}`),
    [history],
  );

  const items = props.appointments?.map((appt) => (
    <TableRow key={appt.id} hover>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {formatDate(appt.startTime)}
      </TableCell>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {formatTime(appt.startTime)}
      </TableCell>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {formatTime(appt.endTime)}
      </TableCell>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {appt.course.name}
      </TableCell>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {statusToString(appt.status)}
      </TableCell>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {appt.modifiedBy}
      </TableCell>
      <TableCell onClick={goToServiceDetails(appt.id)}>
        {appt.modifiedAt? formatDate(appt.modifiedAt) : ''} {appt.modifiedAt? formatTime(appt.modifiedAt) : ''}
      </TableCell>
      <TableCell>
        <AppointmentActions
          appointmentId={appt.id}
          paymentType={appt.course.paymentType!}
          appointmentStatus={appt.status}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      <TableContainer>
        <Table aria-label={"simple table"}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Modified By</TableCell>
              <TableCell>Modified At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{items}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomerBooking;
