import { gql } from "@apollo/client";

export const BOOK_NEXT_APPOINTMENT = gql`
  mutation bookNextAppointment(
    $courseId: ID!
    $resourceId: ID!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
    $locationId: String!
  ) {
    bookNextAppointment(
      courseId: $courseId
      resourceId: $resourceId
      startTime: $startTime
      endTime: $endTime
      locationId: $locationId
    ) {
      id
      startTime
      endTime
    }
  }
`;
