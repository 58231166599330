import React from "react";

import { getPreviousAppointments_getPreviousAppointments } from "graphql/__generated__/getPreviousAppointments";
import { Appointment } from "graphql/types";
import CompletedAppointmentCard from "components/CompletedAppointmentCard/CompletedAppointmentCard";

export interface PreviousAppointmentsProps {
  appointments: getPreviousAppointments_getPreviousAppointments[];
}

const PreviousAppointments: React.FC<PreviousAppointmentsProps> = ({
  appointments,
}) => {
  return (
    <div>
      {appointments.map((appointment) => (
        <CompletedAppointmentCard
          key={`appointment-${appointment.id}`}
          appointment={appointment as Appointment}
        />
      ))}
    </div>
  );
};

export default PreviousAppointments;
