import React, { FC, useRef, useState } from "react";
import { LinearProgress, Button } from "@material-ui/core";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import "./index.scss";
import WarningBox from "../../WarningBox";
import QA from "./QA";
import { fetchConsultationForm } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import { ConsultationForm } from "pages/ClinicalReviewPage/Types";
import { parseConsultationForm } from "./consultation-utils";
import { useAppSelector } from "redux/hooks";
import { selectOrderWarnings } from "pages/ClinicalReviewPage/reduxSlice/selector";
import { format } from "date-fns";
import { formatDate } from "utils/time";
import { pluralForm } from "utils/commonUtils";

interface ViewConsultationProps {
  traversalId: string;
  orderDate: string;
  secondaryTag: string;
}

const ViewConsultation: FC<ViewConsultationProps> = ({
  traversalId,
  orderDate,
  secondaryTag
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef<CustomDialogRef>(null);
  const [consultationForm, setConsultationForm] = useState<ConsultationForm>();
  const warnings = useAppSelector(selectOrderWarnings);

  const showAgeWarning = warnings.find(
    (warning) =>
      warning.type === "age" &&
      warning.header === consultationForm?.productName,
  )?.descriptions;

  const viewForm = async () => {
    modalRef.current?.show();
    setIsLoading(true);
    try {
      const data = await fetchConsultationForm(traversalId);
      if(data["form-type"] === "ed" || data["form-type"] === "lg"){
        const sortedData= data?.traversal_summary.sort((a: {index_sl : string}, b : {index_sl : string}) => {
          if (parseInt(a?.index_sl) < parseInt(b?.index_sl)) {
            return -1;
          }
        });
        const orphonQuestion=sortedData.filter((question: any)=>parseInt(question.index_sl)===0 && question.traversal_response.element !== "TerminalText");
        const answeredQuestion=sortedData.filter((question: any)=>parseInt(question.index_sl)!==0 && question.traversal_response.element !== "TerminalText");
        data.traversal_summary = [...answeredQuestion,...orphonQuestion];
      }


      setConsultationForm(parseConsultationForm(data,secondaryTag));
    } catch (error) {
      console.log("Error in fetching consulation form", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => setIsLoading(false);

  return (
    <>
      <Button
        variant="text"
        onClick={viewForm}
        color={"primary"}
        size={"medium"}
        className="button_view"
      >
        View consultation form
      </Button>
      <CustomDialog
        title={
          <div className="row justify-between mr-36">
            <div>{consultationForm?.title}</div>
            <div className="flex-column">
              <span className="font-16 mb-10">
                Order placed:{" "}
                {orderDate
                  ? format(new Date(orderDate), "dd/MM/yy hh:mm aa")
                  : null}
              </span>
              <span className="font-16">
                Consultation form submitted:{" "}
                {consultationForm?.createdAt
                  ? formatDate(consultationForm?.createdAt, "dd/MM/yy hh:mm aa")
                  : null}
              </span>
            </div>
          </div>
        }
        onClose={onClose}
        ref={modalRef}
        className="crp-consultation-form"
      >
        <div className="crp-consultation-form-content">
          {isLoading && (
            <div className="mt-20">
              <LinearProgress />
            </div>
          )}
          {!!consultationForm?.exCount && (
            <WarningBox
              className="consultation-exclusion"
              title={`${consultationForm?.exCount} exclusion ${pluralForm(
                "flag",
                consultationForm?.exCount > 1,
              )}`}
              warnings={[
                {
                  id: "exclusion",
                  descriptions: [
                    `${consultationForm?.exCount} exclusion ${pluralForm(
                      "flag",
                      consultationForm?.exCount > 1,
                    )} on form. You should reject this item.`,
                  ],
                },
              ]}
            />
          )}
          {!!consultationForm?.wCount && (
            <WarningBox
              className="consultation-warning"
              title={`${consultationForm?.wCount} warning ${pluralForm(
                "flag",
                consultationForm?.wCount > 1,
              )}`}
              warnings={
                !consultationForm.exCount
                  ? [
                      {
                        id: "warning",
                        descriptions: [
                          `${consultationForm?.wCount} warning ${pluralForm(
                            "flag",
                            consultationForm?.wCount > 1,
                          )} on form. Please speak to customer before approving item.`,
                        ],
                      },
                    ]
                  : []
              }
            />
          )}
          {!!showAgeWarning && (
            <WarningBox
              className="consultation-warning"
              title={`Check patient age`}
              warnings={[
                {
                  id: "warning",
                  descriptions: showAgeWarning,
                },
              ]}
            />
          )}
          {consultationForm?.summary.map((summary) => (
            <QA
              key={summary.id}
              question={summary.question}
              response={summary.responses}
              warning={summary.warning}
              height = {summary.height}
              weight = {summary.weight}
              bmiValue = {summary.bmiValue}
              exclusion={summary.exclusion}
              message={summary.message}
            />
          ))}
          {/* <QA
            question="Do you have any of the following conditions?"
            response={[
              "Condition name selected",
              "Condition 2 name selected",
              "Condition 8 name selected",
            ]}
          />
          <QA
            question="Do you have any of the following conditions?"
            response={[
              "Condition name selected",
              "Condition 2 name selected",
              "Condition 8 name selected",
            ]}
            warning
            message="Condition 2 name is associated with increased risk of an adverse reaction to sumatriptan."
          />
          <QA
            question="Do you have any of the following conditions?"
            response={["Response with exclusion flag"]}
            exclusion
            message="Warning message returned from HH"
          />
          <QA
            question="Do you have any of the following conditions?"
            response={["Response with exclusion flag"]}
          /> */}
        </div>
      </CustomDialog>
    </>
  );
};

export default ViewConsultation;
