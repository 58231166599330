import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  makeStyles,
  Theme,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

export interface Question1TypeProps {
  setAnswer(value: string): void;
  keyId: string;
  error: boolean;
}

const Question1: React.FC<Question1TypeProps> = (props) => {
  const { setAnswer } = props;
  const useStyles = makeStyles((theme: Theme) => ({
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
  }));

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer((event.target as HTMLInputElement).value);
  };
  const classes = useStyles();

  return (
    <FormControl error={props.error}>
      <RadioGroup
        row
        aria-label={"yes/no"}
        name={"yes/no"}
        onChange={onChange}
        key={props.keyId}
      >
        <FormControlLabel
          data-testid={"questionsPage/type1-y"}
          value={"Y"}
          control={<Radio color={"primary"} />}
          label={"Yes"}
          labelPlacement={"start"}
        />
        <FormControlLabel
          data-testid={"questionsPage/type1-n"}
          value={"N"}
          control={<Radio color={"primary"} />}
          label={"No"}
          labelPlacement={"start"}
        />
      </RadioGroup>
      {props.error && (
        <FormHelperText
          data-testid={"questions1/error"}
          className={classes.marginLeft}
        >
          Select one of the options
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Question1;
