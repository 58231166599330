import { Service } from "../constants/service.constants";

export interface BookingSlot {
  startTime: Date;
  endTime: Date;
  resourceId: string;
}

export interface Appointment {
  serviceId: string;
  bookedSlot: BookingSlot | null;
  formData: FormValues;
  service?: Service;
}

export enum PersisterLocalStorageKeys {
  appointment = "appointment",
}

export enum Genders {
  male = "male",
  female = "female",
}

export type Address = {
  postcode: string;
  city: string;
  line1: string;
  line2?: string;
};

export type CustomerDetails = {
  address: Address;
  dob: Date;
  firstName: string;
  gender?: string | null;
  lastName: string;
  email: string;
  phone: string;
  nhsNumber: string;
  surgeryAddress: string;
  surgeryName: string;
};

export type Title = "Mr" | "Mrs" | "Miss" | "Ms" | "Mx" | "Sir" | "Dr";

export type GuardianDetails = {
  title: Title;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type FormValues = {
  paymentType?: string;
  customerDetails: CustomerDetails;
  guardianDetails?: GuardianDetails;
};
