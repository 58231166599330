import { useQuery, useMutation } from "@apollo/client/react";
import React, { useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";

import { UPDATE_APPOINTMENT_STATUS } from "./gql";
import ServiceDetailsPage from "./ServiceDetailsPage";
import { GET_APPOINTMENT } from "../../graphql/getAppointment";
import { getAppointment } from "../../graphql/__generated__/getAppointment";
import { CANCEL_APPOINTMENT } from "graphql/cancelAppointment";
import { AppointmentStatus } from "__generated__/globalTypes";
import { trackServiceComplete } from "../../utils/trackServiceComplete";
import { quickAppointments } from "utils/quickAppointments";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";
import { useCurrentUserEmail } from "services/auth-hooks";

const ServiceDetailsContainer: React.FC = () => {
  const email = useCurrentUserEmail();
  const { id } = useParams<{ id: string }>();
  const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, error, data } = useQuery<getAppointment>(
    GET_APPOINTMENT,
    {
      variables: { appointmentId: id, locationId: locationId },
      fetchPolicy: "network-only",
    },
  );

  const [updateAppointmentStatus] = useMutation(UPDATE_APPOINTMENT_STATUS);

  const history = useHistory();
  const isQuickAppointment = quickAppointments.includes(
    data?.getAppointment?.course?.onSchedServiceId!,
  );
  const serviceId = data?.getAppointment?.course.id!;

  const onSubmit = useCallback(() => {
    if (isQuickAppointment) {
      trackServiceComplete(serviceId, locationId);

      updateAppointmentStatus({
        variables: {
          appointmentId: id,
          status: AppointmentStatus.completed,
          locationId: locationId
        },
      });
    } else {
      history.push(`/service-details/${id}/edit`);
      updateAppointmentStatus({
        variables: {
          appointmentId: id,
          status: AppointmentStatus.in_progress,
          locationId: locationId
        },
      });
    }
  }, [history, id, isQuickAppointment, serviceId, updateAppointmentStatus, locationId]);

  const [cancelAppointment] = useMutation(CANCEL_APPOINTMENT);
  const onCancelAppointment = useCallback(
    (shouldRefund: boolean, reason: string) => {
      return cancelAppointment({
        variables: {
          appointmentId: id,
          shouldRefund,
          reason,
          locationId,
          cancelledBy: email
        },
      });
    },
    [cancelAppointment, id, locationId,email],
  );

  return (
    <ServiceDetailsPage
      data={data}
      error={error}
      isLoading={isLoading}
      isQuickAppointment={isQuickAppointment}
      onSubmit={onSubmit}
      onCancelAppointment={onCancelAppointment}
    />
  );
};

export default ServiceDetailsContainer;