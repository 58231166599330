import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { WEBSITE_NAME } from "../../constants";

interface TabTitleProps {
  title: string;
}

/**
 * Sets the title of the users browser tab
 */
const TabTitle: FC<TabTitleProps> = (props) => (
  <Helmet>
    <title>
      {props.title} | {WEBSITE_NAME}
    </title>
  </Helmet>
);

export default TabTitle;
