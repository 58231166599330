import React, { FC } from "react";
import { Typography } from "@welldigital/components";
import { ReactComponent as IcWarning } from "assets/icons/warning-outlined.svg";
import "./index.scss";
import { WarningsType } from "pages/ClinicalReviewPage/Types";
import Loader from "pages/ClinicalReviewPage/components/Loader/Loader";

interface WarningBoxProps {
  title?: string;
  loading?: boolean;
  className?: string;
  hideIcon?: boolean;
  warnings?: WarningsType[];
  showLNWarning?:boolean;
  idCheckStatus?: string;
}

const WarningBox: FC<WarningBoxProps> = ({
  title = "Warnings",
  loading = false,
  className = "",
  hideIcon,
  warnings = [],
  showLNWarning=false,
  idCheckStatus,
}) => {
  let status="";
  let descriptions="";
  let lNWarnings:WarningsType={id: "ln-warnings"};
  if(showLNWarning)
  {
    if(idCheckStatus === ""){
      status= "API failure";
      descriptions="Please verify customer’s identity before approving item."
    }else{
      status= "ID status "+idCheckStatus;
      descriptions="Please verify customer’s identity before progressing this order."
    }
     lNWarnings={
      header: `LexisNexis ${status}`,
      descriptions: [`${descriptions}`],
      ...lNWarnings
    };
  }
  
  return (
    <div className={`warning-container ${className}`}>
      <div className="row align-items-center">
        {!hideIcon && <IcWarning className="mr-10" />}
        <Typography variant="h4">{title}</Typography>
      </div>
      <Loader loading={loading} />
      {showLNWarning && !loading && <div>
            <div key={`warning_${lNWarnings?.id}`} className="warning">
               <p>{lNWarnings?.header}</p>
                <span key={`${lNWarnings?.id}_desc_${1}`}>{lNWarnings?.descriptions?.[0]}</span>
            </div>
          
        </div>}
      {!!warnings.length && (
        <div>
          {warnings.map((warning) => (
            <div key={`warning_${warning.id}`} className="warning">
              {warning.header && <p>{warning.header}</p>}
              {warning.descriptions?.map((desc, index) => (
                <span key={`${warning.id}_desc_${index}`}>{desc}</span>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WarningBox;
