import React, { FC, useState, useCallback } from "react";
import { useStaticState } from "@material-ui/pickers";
import { addDays } from "date-fns";

import DashboardPage from "./DashboardPage";
import { AlertContextProvider, AlertContext } from "./AlertContext.context";

const Dashboard: FC = ({ children }) => {
  const [selectedDate, handleDateChange] = useState(new Date().toISOString());
  const { pickerProps } = useStaticState({
    value: selectedDate,
    onChange: handleDateChange as any, // misaligned type
  });

  const changeByOneDay = useCallback(
    (back?: boolean) => () => {
      const amount = back ? -1 : 1;
      handleDateChange(addDays(new Date(selectedDate), amount).toISOString());
    },
    [selectedDate],
  );

  return (
    <AlertContextProvider>
      <AlertContext.Consumer>
        {() => (
          <DashboardPage
            pickerProps={pickerProps}
            selectedDate={selectedDate}
            changeByOneDay={changeByOneDay}
          >
            {children}
          </DashboardPage>
        )}
      </AlertContext.Consumer>
    </AlertContextProvider>
  );
};

export default Dashboard;
