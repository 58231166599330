import { ROUTES } from "app/routers/ClinicalReviewRoutes";
import axios, { AxiosError } from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const IMAGES_API_BASE_URL = process.env.REACT_APP_IMAGES_API_BASE_URL;
export const WARNINGS_API_BASE_URL = process.env.REACT_APP_WARNINGS_API;
export const SUBSCRIPTION_API_BASE_URL =
  process.env.REACT_APP_SUBSCRIPTION_API_BASE_URL;

export const apiInstance = axios?.create?.();

apiInstance?.interceptors?.response?.use(
  (resp) => {
    return resp;
  },
  (err) => {
    const e = err as unknown as AxiosError;
    if (e.response?.status === 401) {
      apiInstance.defaults.headers.common.Authorization = undefined;
      window.location.assign(ROUTES.clinical.login);
    }
    return err;
  },
);
