import { useMutation, useQuery } from "@apollo/client/react";
import React, { useCallback, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  UPDATE_APPOINTMENT_NOTES,
  PUT_COURSE_QUESTIONS,
  UPDATE_APPOINTMENT_PHARMACIST,
  UPDATE_APPOINTMENT_GUARDIAN,
} from "./gql";
import ConfirmServiceDetailsPage from "./ConfirmServiceDetailsPage";
import { QuestionAudit, getUpdatedQuestionAudits } from "utils/question";
import { GuardianInput, QuestionInput } from "../../__generated__/globalTypes";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import { Question } from "graphql/types";
import {
  getAppointment,
  getAppointment_getAppointment_guardian,
} from "graphql/__generated__/getAppointment";
import { PharmacistInput } from "../../__generated__/globalTypes";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export interface ConfirmServiceState {
  questionAudits: QuestionAudit[];
  appointmentNotes: string;
  appointmentPharmacist: PharmacistInput;
  appointmentGuardian: getAppointment_getAppointment_guardian | null;
}

const ConfirmServiceDetailsPageContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const confirmServiceState = location.state as ConfirmServiceState;
  const locationId = useAppSelector(selectLocationId);

  const { loading: isLoading, error, data } = useQuery<getAppointment>(
    GET_APPOINTMENT,
    {
      variables: { appointmentId: id, locationId: locationId },
    },
  );

  const [appointmentNotes, setAppointmentNotes] = useState<string>(
    confirmServiceState.appointmentNotes,
  );

  const [questionAudits, setQuestionAudits] = useState<Array<QuestionAudit>>(
    confirmServiceState.questionAudits,
  );

  const [appointmentPharmacist, setAppointmentPharmacist] = useState<
    PharmacistInput
  >(confirmServiceState.appointmentPharmacist);

  const handleQuestionUpdate = useCallback(
    (question: Question) => {
      const newQuestionAudits = getUpdatedQuestionAudits(
        questionAudits,
        question,
      );

      setQuestionAudits(newQuestionAudits);
      return;
    },
    [questionAudits],
  );

  const [updateAppointmentNotes] = useMutation(UPDATE_APPOINTMENT_NOTES);
  const [updateAppointmentQuestions] = useMutation(PUT_COURSE_QUESTIONS);

  const [updateAppointmentPharmacist] = useMutation(
    UPDATE_APPOINTMENT_PHARMACIST,
  );

  const history = useHistory();

  const handleUpdatePharmacist = useCallback(
    (updatedPharmacist: PharmacistInput) => {
      setAppointmentPharmacist(updatedPharmacist);
    },
    [setAppointmentPharmacist],
  );

  const [updateAppointmentGuardian] = useMutation(UPDATE_APPOINTMENT_GUARDIAN);

  const [
    updatedAppointmentGuardian,
    setUpdatedAppointmentGuardian,
  ] = useState<getAppointment_getAppointment_guardian | null>(
    confirmServiceState.appointmentGuardian,
  );

  const handleUpdateGuardian = useCallback(
    (updatedGuardian: getAppointment_getAppointment_guardian | null) => {
      setUpdatedAppointmentGuardian(updatedGuardian);
    },
    [setUpdatedAppointmentGuardian],
  );

  const handleNext = useCallback(() => {
    if (appointmentPharmacist) {
      updateAppointmentPharmacist({
        variables: {
          appointmentId: id,
          pharmacist: appointmentPharmacist,
          locationId,
        },
      });
    }
    if (appointmentNotes) {
      updateAppointmentNotes({
        variables: {
          appointmentId: id,
          notes: appointmentNotes,
          locationId: locationId,
        },
      });
    }

    if (updatedAppointmentGuardian) {
      const updatedAppointmentGuardianInput: GuardianInput = {
        title: updatedAppointmentGuardian.title,
        firstName: updatedAppointmentGuardian.firstName,
        lastName: updatedAppointmentGuardian.lastName,
      };
      updateAppointmentGuardian({
        variables: {
          appointmentId: id,
          guardian: updatedAppointmentGuardianInput,
          locationId: locationId
        },
      });
    }
    const updatedQuestions = questionAudits.filter((qa: QuestionAudit) => {
      return !!qa.updatedQuestion;
    });
    if (updatedQuestions.length) {
      const questionInput: QuestionInput[] = updatedQuestions.map(
        (qa: QuestionAudit) => {
          if (!qa.updatedQuestion) {
            throw new Error("array filtered for nulls has nulls");
          }
          return {
            answer: qa.updatedQuestion.answer,
            number: qa.updatedQuestion.number,
            question: qa.updatedQuestion.question,
            questionType: qa.updatedQuestion.questionType,
          };
        },
      );
      const courseId = data?.getAppointment?.course.id;
      if (!courseId) {
        throw new Error("courseId was null or undefined");
      }
      updateAppointmentQuestions({
        variables: {
          courseId,
          questions: questionInput,
        },
      });
    }
    history.push(`/service-details/${id}/complete`);
  }, [
    updateAppointmentNotes,
    id,
    appointmentNotes,
    data,
    questionAudits,
    history,
    updateAppointmentQuestions,
    appointmentPharmacist,
    updateAppointmentPharmacist,
    updatedAppointmentGuardian,
    updateAppointmentGuardian,
    locationId
  ]);

  return (
    <ConfirmServiceDetailsPage
      appointmentId={id}
      onNotesChange={setAppointmentNotes}
      notes={appointmentNotes}
      pharmacist={appointmentPharmacist}
      data={data}
      error={error}
      isLoading={isLoading}
      questionAudits={questionAudits}
      onSubmit={handleNext}
      onQuestionUpdate={handleQuestionUpdate}
      handleUpdatePharmacist={handleUpdatePharmacist}
      guardian={updatedAppointmentGuardian}
      handleUpdateGuardian={handleUpdateGuardian}
    />
  );
};

export default ConfirmServiceDetailsPageContainer;
