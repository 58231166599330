import { gql } from "@apollo/client";

export const GET_INSTORE_SERVICE_AVAILABILITY = gql`
  query getInstoreServiceAvailability(
    $serviceId: ID!
    $startDate: AWSDateTime!
    $endDate: AWSDateTime!
    $locationID: String!
    $isDays: Boolean
    $numberOfBooks: Int
  ) {
    getInstoreServiceAvailability(
      serviceId: $serviceId
      startDate: $startDate
      endDate: $endDate
      locationID: $locationID
      isDays: $isDays
      numberOfBooks: $numberOfBooks
    ) {
      slots {
        startTime
        endTime
        resourceId
      }
      daySlots {
        date
      }
    }
  }
`;
