import React, { FC } from "react";
import { InputField, Typography } from "@welldigital/components";
import { Box } from "@material-ui/core";

const SurgeryFields: FC = () => {
  return (
    <Box mt={2}>
      <Typography variant={"h5"} spacingAfter={2}>
        Their GP
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        Please provide the name and address of their GP surgery.
      </Typography>
      <InputField
        name={"customerDetails.surgeryName"}
        label={"Surgery name"}
        required
      />
      <InputField
        name={"customerDetails.surgeryAddress"}
        label={"Surgery address"}
        required
      />
    </Box>
  );
};

export default SurgeryFields;
