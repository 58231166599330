import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { ExecutionResult } from "graphql";
import {
  Alert,
  Button,
  ConditionalFields,
  Form,
  IRadioOption,
  ISelectOption,
  Modal,
  ModalActions,
  ModalTitle,
  RadioSelectField,
  SelectField,
  Spacing,
  SubmitButton,
  Typography,
} from "@welldigital/components";
import { PaymentType } from "../../__generated__/globalTypes";

export type CancelAppointmentModalProps = {
  paymentType: PaymentType;
  isModalOpen: boolean;
  closeModal(): void;
  cancelAppointment(
    shouldRefund: boolean,
    reason: string,
  ): Promise<ExecutionResult<any>>;
};

const CancelAppointmentModal = (props: CancelAppointmentModalProps) => {
  const { isModalOpen, closeModal, cancelAppointment } = props;
  const [errorMessage, setErrorMessageMessage] = useState<string>();
  const isPaidAppointment = props.paymentType === PaymentType.payment;

  const shouldRefundOptions: IRadioOption[] = useMemo(
    () => [
      { label: "Refund", id: "yes" },
      { label: "Do not refund", id: "no" },
    ],
    [],
  );

  const reasonOptions: ISelectOption[] = useMemo(
    () =>
      [
        "Customer cancelled appointment",
        "Pharmacist availability",
        "Stock availability",
      ].map((reason) => ({ label: reason, value: reason })),
    [],
  );

  const initialValues = useMemo(() => {
    if (isPaidAppointment) return {};
    return { shouldRefund: "no" };
  }, [isPaidAppointment]);

  const onSubmit = useCallback(
    async ({ shouldRefund, reason }) => {
      try {
        await cancelAppointment(shouldRefund === "yes", reason);
        closeModal();
      } catch (e:any) {
        if (e.message.includes("errorType: BadRequest")) {
          setErrorMessageMessage(
            "This appointment may have already been cancelled or completed. Please check the status of the appointment or use the contact details at the bottom of the page for support if you're having problems.",
          );
        } else if (e.message.includes("Not Acceptable (406) with charge id")) {
          setErrorMessageMessage(
            "It looks like you’re trying to cancel an appointment that is part of a group booking. To make sure the customer’s refund is processed, please cancel all appointments with this group ID.",
          );
        } else {
          setErrorMessageMessage(e.message);
        }
        throw e;
      }
    },
    [cancelAppointment, closeModal],
  );

  return (
    <Modal maxWidth={"xs"} open={isModalOpen} onClose={closeModal}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        <ModalTitle
          label={"Are you sure you want to cancel this appointment?"}
          variant={"subtitle"}
        />

        {isPaidAppointment && (
          <>
            <Typography variant={"body1"} spacingAfter={2}>
              Please indicate whether the customer should be refunded for this
              appointment.
            </Typography>
            <Typography variant={"body1"} spacingAfter={2}>
              If a partial refund is required, select ‘Do not refund’ and
              contact your support team to arrange a manual refund.
            </Typography>
            <RadioSelectField
              name={"shouldRefund"}
              options={shouldRefundOptions}
              required
              spacingAfter={0}
            />
          </>
        )}

        <ConditionalFields fieldName={"shouldRefund"}>
          {isPaidAppointment && <Spacing spacing={2} />}
          <Typography variant={"body1"} spacingAfter={2}>
            {!isPaidAppointment && (
              <>
                As the customer hasn't paid for this service, there is no refund
                required.{" "}
              </>
            )}
            Select the reason for the appointment cancellation from the options
            below.
          </Typography>
          <SelectField
            name={"reason"}
            label={"Select reason"}
            options={reasonOptions}
            required
            fullWidth
            spacingAfter={0}
          />
        </ConditionalFields>

        {errorMessage && (
          <Box mt={2}>
            <Alert type={"error"} message={errorMessage} />
          </Box>
        )}

        <ModalActions fullWidth>
          <SubmitButton>Cancel appointment</SubmitButton>
          <Button variant={"outlined"} color={"primary"} onClick={closeModal}>
            Go back
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
};

export default CancelAppointmentModal;
