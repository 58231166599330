import { analytics } from "@welldigital/ui-common"; 

//import { getLocationId } from "../services/auth";

export async function trackServiceComplete(serviceId: string, locationId: string) {
 // const locationId = await getLocationId();

  analytics.setUser(locationId);
  analytics.trackEvent({
    flow: "service",
    event: "service complete",
    metadata: {
      serviceId,
    },
  });
}
