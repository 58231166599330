import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableCellProps,
} from "@material-ui/core";
import { ReactComponent as IcArrowDown } from "../../../../assets/icons/arrow-down.svg";
import "./index.scss";
import Loader from "../Loader/Loader";

interface NonSortableHeaderProps<T> {
  header: string;
  keyExtractor: keyof T;
  isSortable?: false;
  transform?: (data: T, key: keyof T) => any;
  cellProps?: TableCellProps;
}

interface SortableHeaderProps<T> {
  header: string;
  keyExtractor: keyof T;
  isSortable: true;
  sortKeyName: string;
  transform?: (data: T, key: keyof T) => any;
  onSortChange: (sortKeyName: string) => void;
  cellProps?: TableCellProps;
}

export type CustomTableHeaderProps<T> =
  | SortableHeaderProps<T>
  | NonSortableHeaderProps<T>;

export interface CustomTableProps<T> {
  id?: string;
  data: T[];
  sortKey: string;
  isAscending: boolean;
  loading?: boolean;
  columns: CustomTableHeaderProps<T>[];
}

const CustomTable = <T,>({
  id = "custom-table",
  data,
  columns,
  sortKey,
  loading,
  isAscending,
}: CustomTableProps<T>) => {
  return (
    <Table className="cr-table" aria-label="customized table">
      <TableHead>
        <TableRow>
          {columns &&
            columns.map((col, index) => (
              <TableCell key={`${id}_header_${index}`} {...col.cellProps}>
                <div
                  onClick={
                    col.isSortable
                      ? () => col.onSortChange(col.sortKeyName)
                      : undefined
                  }
                  className={
                    col.isSortable ? "header cursor-pointer" : "header "
                  }
                >
                  {col.isSortable && (
                    <>
                      {col.sortKeyName !== sortKey && (
                        <div className="sort-icons">
                          <IcArrowDown
                            viewBox="0 5 20 20"
                            className="rotate-180"
                          />
                          <IcArrowDown viewBox="0 5 20 20" />
                        </div>
                      )}
                      {col.sortKeyName === sortKey && (
                        <IcArrowDown
                          className={isAscending ? `rotate-180` : ""}
                        />
                      )}
                    </>
                  )}
                  <span>{col.header}</span>
                </div>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <Loader loading={loading} className="loader" />
        {data &&
          data.map((d, rowIndex) => (
            <TableRow key={`${id}-row-${rowIndex}`}>
              {columns.map((col, colIndex) => (
                <TableCell
                  key={`${id}-row-${rowIndex}-${colIndex}`}
                  {...col.cellProps}
                >
                  {col.transform?.(d, col.keyExtractor) ?? d[col.keyExtractor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
