import { OrderStatus } from "pages/ClinicalReviewPage/Types";
import {
  BOOKWELL_USER_GROUP,
  BOOKWELL_USER_GROUPS,
  CLINICAL_REVIEW_USER_GROUP,
  CLINICAL_REVIEW_USER_GROUPS,
} from "../constants";

export const getClinicalReviewUserGroup = (
  groups: CLINICAL_REVIEW_USER_GROUP[],
): CLINICAL_REVIEW_USER_GROUP | undefined =>
  groups?.find((group) => CLINICAL_REVIEW_USER_GROUPS.includes(group));

export const getBookwellUserGroup = (
  groups: BOOKWELL_USER_GROUP[],
): BOOKWELL_USER_GROUP | undefined =>
  groups?.find((group) => BOOKWELL_USER_GROUPS.includes(group));

export const formatOrderStatus = (status: OrderStatus | string = "") => {
  if (status.toLowerCase() === "Manual Verification Required".toLowerCase()) {
    return "Awaiting review";
  }
  //   if (status.toLowerCase() === "Awaiting Fulfillment".toLowerCase()) {
  //     return "Complete";
  //   }
  if (status.toLowerCase() === "Pending".toLowerCase()) {
    return "Payment failed";
  }
  const subStr = status.substring(1);
  return `${status[0]}${subStr?.toLowerCase?.()}`;
};

export const formatPhoneNumber = (phone: string) => {
  return phone;
  //   TODO: Disabled formatting because UK phone number validation
  //   const input = phone.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  //   const zip = input.substring(0, 4);
  //   const middle = input.substring(4, 7);
  //   const last = input.substring(7, 10);

  //   if (input.length > 7) {
  //     return `${zip} ${middle} ${last}`;
  //   } else if (input.length > 4) {
  //     return `${zip} ${middle}`;
  //   } else if (input.length > 0) {
  //     return `${zip}`;
  //   }
  //   return phone;
};
