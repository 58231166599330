import React from "react";
import { Box } from "@material-ui/core";
import { Button, Typography } from "@welldigital/components";

export interface NextQuestionButtonProps {
  fetchNextQuestion(): void;
  disabled: boolean;
}

const NextQuestionButton: React.FC<NextQuestionButtonProps> = ({
  fetchNextQuestion,
  disabled,
}) => {
  return (
    <Box mt={4}>
      <Button
        variant="contained"
        data-testid={"questionsPage/nextQuestion-button"}
        color="primary"
        minWidth={"50%"}
        onClick={fetchNextQuestion}
        disabled={disabled}
      >
        <Typography variant="h6" align="left">
          Next
        </Typography>
      </Button>
    </Box>
  );
};

export default NextQuestionButton;
