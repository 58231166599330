import { Button, Typography } from "@welldigital/components";
import React from "react";
import { formatDate, formatWeekday } from "../../../../utils/time";
import { FrequencyCodes } from "../../types/frequency";
import { getResourceBlocks_getResourceBlocks } from "../../__generated__/getResourceBlocks";
import { WALK_IN_FLU_CLINIC_TYPO } from "./WalkInFluClinic";
import { styles } from "./Slot";

interface BlockProps {
  block: getResourceBlocks_getResourceBlocks;
  onDelete: (block: getResourceBlocks_getResourceBlocks) => void;
}

export function fromFrequencyCode(
  code: FrequencyCodes,
  date: string | Date,
): string {
  switch (code) {
    case FrequencyCodes.Daily:
      return "Day";
    case FrequencyCodes.Monthly:
      return "Month";
    case FrequencyCodes.Weekdays:
      return formatWeekday(date);
    case FrequencyCodes.Weekly:
      return "Week";
  }
}

function Block({ block, onDelete }: BlockProps) {
  const classes = styles();

  return (
    <div className={classes.inlineFlex}>
      {block.repeats ? (
        <Typography className={classes.centerSelf} variant="h6">
          {formatDate(block.startDate)} repeating every{" "}
          {fromFrequencyCode(
            block.repeat?.frequency as FrequencyCodes,
            block.startDate,
          )}
        </Typography>
      ) : (
        <Typography className={classes.centerSelf} variant="h6">
          {formatDate(block.startDate)} until {formatDate(block.endDate)}
        </Typography>
      )}
      <Typography className={classes.centerSelf} >
          {block.reason.substring(block.reason.indexOf(":")+1)}
      </Typography>
      <Button
        data-testid={`Block/${block.id}`}
        className={classes.inline}
        variant="text"
        color="primary"
        onClick={() => onDelete(block)}
      >
        {WALK_IN_FLU_CLINIC_TYPO.buttons.delete}
      </Button>
    </div>
  );
}

export default Block;
