import React, { useEffect, useRef, useState } from "react";
import { useForm, OnSubmit } from "react-hook-form";
import {
  Grid,
  MenuItem,
  Select,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import { ReactComponent as IcArrowDown } from "assets/icons/arrow-down.svg";
import "./index.scss";
import HookFormSelect from "../CustomerDetails/HookFormSelect";
import { updateTreatment } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import { ChangeTreatmentSchema } from "utils/validations";
import { useCurrentUserEmail } from "services/auth-hooks";
import { PreApprovedProductOption } from "../PreApprovedProductOrderDetailsWrapper";
import { useHistory } from "react-router-dom";


interface PropsTypes {
  onClose?: (visible: boolean) => void;
  treatment: string;
  customerId: number;
  preApprovedProduct: PreApprovedProductOption[];
}

const ChangeTreatmentDetails = ({
  onClose,
  customerId,
  treatment,
  preApprovedProduct
}: PropsTypes) => {
  const formStatusRef = useRef<HTMLFormElement>(null);
  const dialogRefStatus = useRef<CustomDialogRef>(null);
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const email = useCurrentUserEmail();
  const history = useHistory();
  useEffect(() => {
    dialogRefStatus.current?.show();
  }, []);

  const {
    handleSubmit,
    control,
    errors,
    formState: { isValid },
  } = useForm<ChangeTreatmentForm>({defaultValues:toChangeTreatmentForm({treatment:'select'}),
      validationSchema: ChangeTreatmentSchema,
      mode: "onChange"
  });

  
  const onSubmit: OnSubmit<ChangeTreatmentForm> = async (ChangeTreatmentForm) => {
    setLoading(true);
    setError(false);
    console.log(ChangeTreatmentForm.treatment);
    
    try{
      const response=await updateTreatment(customerId,{updatedBy: email || "test",treatmentName:ChangeTreatmentForm.treatment, currentTreatment: treatment});
      if(response?.status===204){
        dialogRefStatus.current?.hide();
        history.push(`${window.location.pathname}?treatment=${ChangeTreatmentForm.treatment}`);
        window.location.reload();
      }else{
        setError(true);
      }
    }catch(error){
      setError(true);
      console.log("Error in updating treatment");
    }finally{
      setLoading(false);
    }
  };
  
const treatmentList = Array.from(new Set(preApprovedProduct.map(product=> product.treatmentName!==treatment && product.treatmentName))).sort();
  return (
    <CustomDialog
      onClose={() => onClose?.(false)}
      ref={dialogRefStatus}
      title={
        <>
          Select new medicated weight loss treatment
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
        </>
      }
      className="change-treatment-dialog"
    >
      <form ref={formStatusRef} onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="change-treatment-container">
             <Grid item md={12}>
             <Typography className="font-weight-700 color-primary-hale mb-10" style={{fontSize:'20px'}}>
                  Current treatment: {treatment}
              </Typography>
              <Typography className="popup-para-color mb-10">
                <p>
                Please ensure the patient is aware of and comfortable with this change before confirming this change </p>   
                
              </Typography>
              
            </Grid>
            <Grid md={12} className="row">
              <Grid item md={4}>
                <HookFormSelect
                  control={control}
                  defaultValue={'select'}
                  name="treatment"
                  fullWidth
                >
                  <Select
                    IconComponent={() => (
                      <IcArrowDown className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
                    )}
                    className="input"
                    variant="outlined"
                    value={control.getValues().treatment}
                    error={!!errors.treatment}
                  >
                    <MenuItem value="select">Select an option</MenuItem>
                    {treatmentList.map(item =>{
                      return <MenuItem value={item.toString()}>{item}</MenuItem>
                    })}
                  </Select>
                </HookFormSelect>
              </Grid>
            </Grid>
            
            <Grid item md={6}>
              {/* This is to fix spacing */}
            </Grid>
          </Grid>
        {error && (
          <Typography variant="subtitle2" className="color-error">
            Error in updating treatment
          </Typography>
        )}
        <div className="buttons-container">
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => dialogRefStatus.current?.hide()}
          >
            Cancel
          </Button>
          <Button type="submit"  disabled={!isValid || loading }>
            Change treatment
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};
export interface ChangeTreatmentForm {
    treatment: string;
}
const toChangeTreatmentForm = ({
  treatment
  }: ChangeTreatmentForm): ChangeTreatmentForm => ({
    treatment
  });
export default ChangeTreatmentDetails;


