import { useMutation } from "@apollo/client/react";
import { CREATE_RESOURCE_BLOCK, DELETE_RESOURCE_BLOCK } from "../../gql";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  createResourceBlock,
  createResourceBlockVariables,
} from "components/AvailabilityModal/__generated__/createResourceBlock";
import { format } from "date-fns";
import { deleteResourceBlockVariables } from "components/AvailabilityModal/__generated__/deleteResourceBlock";
import { AlertContext } from "../../../../pages/DashboardPage/AlertContext.context";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export type UseServicePauseResult = {
  areAllPaused: boolean;
  areAllExceptFluPaused: boolean;
  isPausing: boolean;
  updateAllPaused: any;
};

export const INFINITE_RESOURCE_BLOCK_VARS: createResourceBlockVariables = {
  reason: "Emergency - Pause all services",
  allDay: true,
  startDate: format(new Date(), "yyyy-MM-dd"),
  endDate: "9999-04-20",
  startTime: 0,
  locationId: "",
  endTime: 0,
  repeats: true,
  repeat: {
    frequency: "D",
    interval: 1,
    monthDay: 0,
    monthType: "",
    weekdays: "",
  },
};

export const INFINITE_BLOCK_KEY = "infinite-emergency-block-id";

export default function useServicePause(): UseServicePauseResult {
const locationId =  useAppSelector(selectLocationId)

  const isPausingAllServices =
    localStorage.getItem(INFINITE_BLOCK_KEY) !== null &&
    localStorage.getItem(INFINITE_BLOCK_KEY) !== "0";
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [areAllPaused, setAllPaused] = useState<
    UseServicePauseResult["areAllPaused"]
  >(isPausingAllServices);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [areAllExceptFluPaused, setAllExceptFluPaused] = useState<
    UseServicePauseResult["areAllExceptFluPaused"]
  >(false);

  const [isPausing, setPausing] = useState<UseServicePauseResult["isPausing"]>(
    false,
  );

  const { enqueueAlert } = useContext(AlertContext);

  const [setAllServicesPaused, { loading: isLoading, data }] = useMutation<
    createResourceBlock,
    createResourceBlockVariables
  >(CREATE_RESOURCE_BLOCK, {
    onCompleted: () =>
      enqueueAlert({
        type: "success",
        message: "Successfully paused all services",
      }),
  });
  const [deleteResourceBlock] = useMutation<any, deleteResourceBlockVariables>(
    DELETE_RESOURCE_BLOCK,
  );
  const [blockId, setBlockId] = useState<number>(
    parseInt(localStorage.getItem(INFINITE_BLOCK_KEY) ?? "0", 10),
  );

  const storeBlockId = useCallback(
    (id: number) => {
      localStorage.setItem(INFINITE_BLOCK_KEY, id.toString());
      setBlockId(id);
    },
    [setBlockId],
  );

  useEffect(() => {
    if (!isLoading && data) {
      storeBlockId(data.createResourceBlock.id);
    }
  }, [isLoading, data, storeBlockId, setBlockId]);

  const updateAllPaused = useCallback(
    async (isPausingServices: UseServicePauseResult["areAllPaused"]) => {
      if (isPausing) return;
      setPausing(true);
      if (isPausingServices) {
        setAllExceptFluPaused(false);
      }
      setAllPaused(isPausingServices);
      if (!isPausingServices && blockId !== 0) {
        const errors  = await deleteResourceBlock({
          variables: { blockId: blockId.toString() },
        });
        if (!errors) {
          console.error(
            "failed to delete resource block with id: ",
            blockId,
            // errors.toString(),
          );
          enqueueAlert({
            type: "error",
            message: "Failed to un-pause all services",
          });
        } else {
          enqueueAlert({
            type: "success",
            message: "Successfully un-paused all services",
          });
          // reset the block id
          storeBlockId(0);
        }
      } else if (isPausingServices && blockId === 0) {
        // bug with backend - cant omit endDate so just set to high number
        await setAllServicesPaused({ variables : {...INFINITE_RESOURCE_BLOCK_VARS, locationId:locationId} });
      }
      setPausing(false);
    },
    [
      isPausing,
      blockId,
      deleteResourceBlock,
      enqueueAlert,
      storeBlockId,
      setAllServicesPaused,
      locationId
    ],
  );

  return {
    areAllPaused,
    areAllExceptFluPaused,
    isPausing,
    updateAllPaused,
  };
}
