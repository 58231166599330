import React, { FC } from "react";
import { Button, Form, Typography, WatchFields } from "@welldigital/components";
import BirthDateFields from "./components/BirthDateFields";
import GuardianFields from "./components/GuardianFields";
import CustomerFields from "./components/CustomerFields";
import WalkInActions from "components/WalkInActions/WalkinActions";
import SurgeryFields from "./components/SurgeryFields";
import AddressFields from "./components/AddressFields";
import FluPaymentFields from "./components/FluPaymentFields";
import FluVaccineSelection from "./components/FluVaccineSelection";
import { Service } from "../../constants/service.constants";
import { isUnder18, isAbove74 } from "./utils";

export type WalkInCustomerDetailsProps = {
  service: Service;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

export type WatchFieldsParams = {
  "customerDetails.dob": Date;
};

export const WalkInCustomerDetails: FC<WalkInCustomerDetailsProps> = ({
  service,
  onSubmit,
  onCancel,
}) => {
  const {
    includeGPDetails,
    includePaymentSelection,
    includeVaccineSelection,
  } = service;

  return (
    <>
      <Typography variant={"h2"}>Enter customer details</Typography>
      <Form onSubmit={onSubmit} fullWidth>
        <BirthDateFields />
        <WatchFields fieldNames={["customerDetails.dob"]}>
          {({ "customerDetails.dob": birthDate }: WatchFieldsParams) =>
            birthDate ? (
             isUnder18(birthDate) && (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) ?
               ( <Typography paragraph>
                  Because you are under 18, you are unable to book an appointment for this service.
                </Typography>) :
                 isAbove74(birthDate) && (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) ?
                 (<Typography paragraph>
                 Because you are above 74, you are unable to book an appointment for this service.
               </Typography> ):
                <>
                  <GuardianFields birthDate={birthDate} service={service} />
                  <CustomerFields birthDate={birthDate} service={service} />
                  <AddressFields />
                  {includeGPDetails && <SurgeryFields />}
                  {includePaymentSelection && (
                    <FluPaymentFields birthDate={birthDate} service={service} />
                  )}
                  {includeVaccineSelection && (
                    <FluVaccineSelection service={service} />
                  )}
                </>
            ) : null
          }
        </WatchFields>
        <WatchFields fieldNames={["customerDetails.dob"]}>
          {({ "customerDetails.dob": birthDate }: WatchFieldsParams) =>
            birthDate && (isUnder18(birthDate) || isAbove74(birthDate)) && (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) ? (
              <WalkInActions display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant={"outlined"}
                  onClick={onCancel}
                  data-testid={"walkInCustomerDetailsActions/cancel"}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  minWidth={320}
                  data-testid={"walkInCustomerDetailsActions/next"}
                  disabled={true}
                >
                  Finished
                </Button>
              </WalkInActions>
            ) :
              <WalkInActions display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant={"outlined"}
                  onClick={onCancel}
                  data-testid={"walkInCustomerDetailsActions/cancel"}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  minWidth={320}
                  data-testid={"walkInCustomerDetailsActions/next"}
                >
                  Finished
                </Button>
              </WalkInActions>
          }
        </WatchFields>
      </Form>
    </>
  );
};

export default WalkInCustomerDetails;
