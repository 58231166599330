import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UpdateOrderDetails from "./UpdateOrderDetails";
interface PropsTypes {
  loading?: boolean;
  onUpdateSuccess: () => void;
  idCheckStatus: string;
  orderId: number;
  isUpdateOrderModalOpen:boolean;
}

const UpdateOrder: React.FC<PropsTypes> = ({idCheckStatus,onUpdateSuccess,orderId,isUpdateOrderModalOpen}) => {
  const [visible, setVisible] = useState(false);

  useEffect(()=>{
    idCheckStatus === "pass" && setVisible(false);
    ((idCheckStatus === "fail" || idCheckStatus === "refer") && isUpdateOrderModalOpen) && setVisible(true);
  },[idCheckStatus,isUpdateOrderModalOpen]);
  
 return (
    <>
     {/* <Loader loading={loading} /> */}
     <Button
              type={"submit"}
              size={"medium"}
              //disabled={buttonDisable}
              onClick={() => setVisible(true)}
              variant={"outlined"}
            >
              Update order
            </Button>
    {visible && (
        <UpdateOrderDetails
          onClose={setVisible}
          onUpdateSuccess={onUpdateSuccess}
          idCheckStatus={idCheckStatus}
          orderId={orderId}
        />
      )}
    </>
  );
};

export default UpdateOrder;
