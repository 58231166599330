import React, { useEffect, useRef } from "react";
import {
  Grid,
  Button,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";


interface PropsTypes {
  onClose?: (visible: boolean) => void;
  onRemovePatient: () => void;
}
const RemovePreApprovedProductPatientModal = ({
    onRemovePatient,
    onClose,
}: PropsTypes) => {
  const dialogRefConfirmPatient = useRef<CustomDialogRef>(null);
 
  useEffect(() => {
    dialogRefConfirmPatient.current?.show();
  }, []);

  return (
    <CustomDialog
      onClose={() => onClose?.(false)}
      ref={dialogRefConfirmPatient}
      title={<>Are you sure you want to remove this customer from the weight management patient list?</>}
      className="update-order-dialog"
    >
        <Grid container className="update-order-container">
          <Grid item md={12}>
            <Typography className="popup-para-color mb-10">
                <p>Please ensure you have advised the customer of this decision and they are aware that they will need to place a new order for any future Wegovy treatment.</p> 
                <p>You should speak to the customer before rejecting items or orders.</p>
            </Typography>
          </Grid>
         </Grid>
        <div className="buttons-container">
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => dialogRefConfirmPatient.current?.hide()}
          >
            Cancel
          </Button>
          <Button type="submit" onClick={onRemovePatient}>
            Remove patient
          </Button>
        </div>
    </CustomDialog>
  );
};

export default RemovePreApprovedProductPatientModal;


