import React from "react";
import { Grid } from "@material-ui/core";
import { getAppointment_getAppointment_guardian } from "graphql/__generated__/getAppointment";
import { Typography } from "@welldigital/components";

export interface GuardianDetailsProps {
  guardian: getAppointment_getAppointment_guardian | null;
}

const GuardianDetails: React.FC<GuardianDetailsProps> = (props) => {
  const { guardian } = props;

  return (
    <>
      <Typography variant={"h4"} spacingAfter={2}>
        Guardian information
      </Typography>
      <Grid container data-testid={"guardianDetails/container"}>
        <Grid item xs={4}>
          <Typography variant={"h6"} spacingAfter={0}>
            Title
          </Typography>
          <Typography
            data-testid={"guardianDetails/guardian-title"}
            spacingAfter={1}
          >
            {guardian?.title || "-"}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant={"h6"} spacingAfter={0}>
            First name
          </Typography>
          <Typography
            data-testid={"guardianDetails/guardian-firstName"}
            spacingAfter={1}
          >
            {guardian?.firstName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h6"} spacingAfter={0}>
            Last name
          </Typography>
          <Typography
            data-testid={"guardianDetails/guardian-lastName"}
            spacingAfter={1}
          >
            {guardian?.lastName || "-"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default GuardianDetails;
