import { Button } from "@material-ui/core";
import React, { useState } from "react";
import ChangeTreatmentDetails from "./ChangeTreatmentDetails";
import { PreApprovedProductOption } from "../PreApprovedProductOrderDetailsWrapper";

interface PropsTypes {
  loading?: boolean;
  customerId: number;
  treatment: string;
  preApprovedProduct: PreApprovedProductOption[];
}

const ChangeTreatment: React.FC<PropsTypes> = ({customerId,treatment,preApprovedProduct}) => {
  const [visible, setVisible] = useState(false);
 return (
    <>
     {/* <Loader loading={loading} /> */}
     <Button
              type={"submit"}
              size={"medium"}
              onClick={() => setVisible(true)}
              variant={"outlined"}
              style={{width: '198px', border: '2px solid #747474', color:'#747474', margin: '10px'}}
            >
              Change treatment
            </Button>
      {visible && (
        <ChangeTreatmentDetails
          onClose={setVisible}
          customerId={customerId}
          treatment={treatment}
          preApprovedProduct={preApprovedProduct}
        />
      )}
    </>
  );
};

export default ChangeTreatment;
