import axios from 'axios';

export const API_HOST = process.env.REACT_APP_INTEG_API;
export const FORMTYPE_ED = 'ed';
export const FORMTYPE_WG = 'lg';


//Form Builder - ED and PMED
export const traversalLoad_FB = (payload : any) => {
    return axios.post(`${API_HOST}/traversals/fb`, payload);
};

export const traversalRespond_FB = (payload: any) => {
    return axios.post(`${API_HOST}/traversals/fb/respond`, payload);
};

