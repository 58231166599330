import React, { FC } from "react";
import { Appointment } from "graphql/types";
import { useQuery } from "@apollo/client/react";
import PreviousAppointmentMedicine from "./PreviousAppointmentMedicine";
import { getPreviousAppointments } from "graphql/__generated__/getPreviousAppointments";
import { GET_PREVIOUS_APPOINTMENTS } from "graphql/getPreviousAppointments";
import { selectLocationId } from "redux/reduxSlice/selector";
import { useAppSelector } from "redux/hooks";

export interface PreviousAppointmentMedicineContainerProps {
  appointment: Appointment;
}

const PreviousAppointmentMedicineContainer: FC<PreviousAppointmentMedicineContainerProps> = ({
  appointment,
}) => {
  const { id, number } = appointment;
  const locationId = useAppSelector(selectLocationId);
  const { data } = useQuery<getPreviousAppointments>(
    GET_PREVIOUS_APPOINTMENTS,
    {
      variables: { appointmentId: id, locationId: locationId },
    },
  );

  if (data?.getPreviousAppointments?.length) {
    const { getPreviousAppointments } = data;

    return (
      <PreviousAppointmentMedicine
        previousAppointment={
          getPreviousAppointments.find((appt) => appt.number === number - 1)!
        }
      />
    );
  } else return null;
};

export default PreviousAppointmentMedicineContainer;
