import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import {
  Alert,
  DateSelect,
  Spacing,
  TimeAvailability,
  Typography,
} from "@welldigital/components";
import { Collapse } from "@material-ui/core";

import { getSlotMaximumDate, getSlotMinimumDate } from "utils/getMinimumDate";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import useFindAvailability from "../../../pages/BookAnAppointment/components/SelectADate/useFindAvailability";
import AvailabilityLoader from "../../../pages/BookAnAppointment/components/AvailabilityLoader";
import { BookingSlot } from "../../../persister/types";
import { ServiceIds } from "../../../constants/service.constants";
import { useServices } from "../../AvailabilityModal/IndividualServices/IndividualServices.hooks";
import { getProvinceByLocationAPI } from "../../../pages/WalkInCustomerDetails/components/AddressFields/api.fetchers";

export type SelectADateProps = {
  serviceId: string;
  bookedSlot: BookingSlot | undefined;
  setBookedSlot: (slot: BookingSlot | undefined) => void;
};

const SelectADate: FC<SelectADateProps> = ({
  serviceId,
  bookedSlot,
  setBookedSlot,
}) => {
  const [dateSelected, setDateSelected] = useState<Date | undefined>();
  const { locationId } = useServices();
  const isFlu = useMemo(
    () =>
      [ServiceIds.Flu, ServiceIds.FluPPV, ServiceIds.FluPrevenar13].includes(
        parseInt(serviceId),
      ),
    [serviceId],
  );
  const minDate = useMemo(() => getSlotMinimumDate({ isFlu, locationId }), [
    isFlu,
    locationId,
  ]);
  const maxDate = useMemo(() => getSlotMaximumDate({ isFlu, locationId }), [
    isFlu,
    locationId,
  ]);

  const {
    slotsData,
    calendarData,
    fetchMonthCalendarData,
    fetchSlotsInDay,
    isLoadingSlots,
    errorMessage,
  } = useFindAvailability(serviceId, minDate, maxDate, locationId);

  useEffect(() => {
    setDateSelected(undefined);
  }, []);

  const dateOnChange = useCallback(
    async (date: Date | null) => {
      setBookedSlot(undefined);
      setDateSelected(date || undefined);
      if (date !== null) fetchSlotsInDay(date);
    },
    [setDateSelected, setBookedSlot, fetchSlotsInDay],
  );

  const excludeMonthYear = "202210";
  const excludeDate:any = [
    {
      "01": {
        "isUnavailable": true
      },
      "02": {
        "isUnavailable": true
      },
      "03": {
        "isUnavailable": true
      },
      "04": {
        "isUnavailable": true
      },
      "05": {
        "isUnavailable": true
      },
      "06": {
        "isUnavailable": true
      },
      "07": {
        "isUnavailable": true
      },
      "08": {
        "isUnavailable": true
      },
      "09": {
        "isUnavailable": true
      },
      "10": {
        "isUnavailable": true
      },
      "11": {
        "isUnavailable": true
      },
      "12": {
        "isUnavailable": true
      },
      "13": {
        "isUnavailable": true
      },
      "14": {
        "isUnavailable": true
      },
      "15": {
        "isUnavailable": true
      }
    }
  ];

  const [storeLocation, setStoreLocation] = useState("");
  if (locationId != null) {
    getProvinceByLocationAPI(locationId).then(province => {
      setStoreLocation(province);
    })
  }
  
  let availableSlotDates = {};
  const calendarAppointmentData = calendarData[serviceId];
  if(calendarData[serviceId] !== undefined) { 
    const datesExcluded = Object.assign({}, ...excludeDate.concat(calendarAppointmentData[excludeMonthYear]));
    if(storeLocation === 'England' || storeLocation === 'Wales') {
      availableSlotDates = Object.assign({}, ...[calendarAppointmentData].concat({"202210": datesExcluded}));
    } else {
      availableSlotDates = Object.assign({}, ...[calendarAppointmentData].concat({"202210": datesExcluded}));
    }
  }

  return !errorMessage ? (
    <>
      <Typography variant={"body2"}>Select a date</Typography>
      <Spacing spacing={1} responsive />
      <DateSelect
        value={dateSelected}
        onChange={dateOnChange}
        label={!dateSelected ? "Select a date" : undefined}
        fetchMonthData={fetchMonthCalendarData}
        data={availableSlotDates}
        disablePast
        fullWidth
        minDate={minDate}
        maxDate={maxDate}
      />
      <Collapse in={!!dateSelected}>
        <Spacing spacing={3} responsive />
        <Typography variant={"h5"}>Select a time</Typography>
        {isLoadingSlots ? (
          <AvailabilityLoader />
        ) : slotsData?.length ? (
          <TimeAvailability
            value={bookedSlot}
            onChange={setBookedSlot}
            slots={slotsData}
          />
        ) : (
          <Alert
            type={"error"}
            message={
              <>
                No slots are available for the selected day. Please choose
                another day.
              </>
            }
            spacingAfter={2}
          />
        )}
      </Collapse>
    </>
  ) : (
    <ErrorAlert message={errorMessage} />
  );
};

export default SelectADate;
