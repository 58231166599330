import { useQuery } from "@apollo/client/react";
import React, { useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { GET_AVAILABILITY } from "graphql/getAvailability";
import { getAvailability } from "graphql/__generated__/getAvailability";
import AvailabilitySelectorScreen from "components/AvailabilitySelectorScreen/AvailabilitySelectorScreen";
import { rescheduleAppointmentAPI } from "./api.fetchers";
//import { getLocationId } from "services/auth";
import {
  Availability,
  FetchAvailabilityInput,
} from "../../components/AppointmentSelector";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";
import { useCurrentUserEmail } from "services/auth-hooks";

const now = new Date();

const RescheduleServicePageContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  //const [locationId, setLocationId] = useState<string>();

  const email = useCurrentUserEmail();
  const locationId = useAppSelector(selectLocationId);

  // useEffect(() => {
  //   (async () => {
  //     setLocationId(await getLocationId());
  //   })();
  // }, [setLocationId]);
  const getAppointmentQuery = useQuery<getAppointment>(GET_APPOINTMENT, {
    variables: { appointmentId: id, locationId: locationId },
  });

  const [selectedAvailability, setSelectedAvailability] = useState<
    Availability | undefined
  >();

  const [availability, setAvailability] = useState<Availability[]>();
  const [fetchAvailabilityInput, setFetchAvailabilityInput] = useState<
    FetchAvailabilityInput
  >();

  const [isFetching, setIsFetching] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  const getAvailabilityQuery = useQuery<getAvailability>(GET_AVAILABILITY, {
    skip:
      !fetchAvailabilityInput?.serviceId ||
      !fetchAvailabilityInput.start ||
      !fetchAvailabilityInput.end ||
      !getAppointmentQuery?.data?.getAppointment?.course.onSchedServiceId ||
      !locationId,
    variables: {
      serviceId:
        getAppointmentQuery?.data?.getAppointment?.course.onSchedServiceId,
      startDate: now,
      endDate: now,
      locationId: locationId
    },
  });

  const fetchAvailability = useCallback(
    (input: FetchAvailabilityInput) => {
      setFetchAvailabilityInput(input);
      setIsFetching(true);
      getAvailabilityQuery.fetchMore({
        variables: {
          serviceId: input.serviceId,
          startDate: input.start.toISOString(),
          endDate: input.end.toISOString(),
          locationId: locationId,
        },
        updateQuery: (prev: any, { fetchMoreResult, ...rest }: any) => {
          const newAvailability = fetchMoreResult?.getAvailability;
          setIsFetching(false);
          if (!fetchMoreResult || !newAvailability?.length) {
            return prev;
          }
          setAvailability(newAvailability as Availability[]);
          return {
            ...fetchMoreResult,
            ...prev,
          };
        },
      });
    },
    [getAvailabilityQuery, locationId],
  );

  const error = getAppointmentQuery.error || getAvailabilityQuery.error;
  const isFetchingAvailability = getAvailabilityQuery.loading || isFetching;
  const isLoading = getAppointmentQuery.loading || getAvailabilityQuery.loading;

  const history = useHistory();
  const handleComplete = useCallback(() => {
    if (!selectedAvailability) {
      throw new Error("no availability selected");
    }
    let serviceId = getAppointmentQuery?.data?.getAppointment?.course.onSchedServiceId;


    let requestInitBooking = {
        locationId: locationId,
        serviceId: serviceId,
        startDateTime: selectedAvailability?.startTime,
        endDateTime: selectedAvailability?.endTime,
        resourceId: selectedAvailability?.resourceId,
        travelTimeMins: 0,
        travelAppointmentId: "",
        rescheduledBy: email
      };

    rescheduleAppointmentAPI(requestInitBooking, id).then(() => {
      history.push(`/service-details/${id}/booked`);
      history.go(0);
    }).catch(error => {
      console.log('Error is', error);
      setErrorMessage(error?.response?.data?.message);
    })

  }, [selectedAvailability, id, history, getAppointmentQuery, locationId, email]);

  return (
    <>
      <AvailabilitySelectorScreen
        appointmentId={id}
        appointmentData={getAppointmentQuery.data}
        availabilityData={
          availability
            ? ({ getAvailability: availability } as getAvailability)
            : getAvailabilityQuery.data
        }
        fetchAvailability={fetchAvailability}
        selectedAvailability={selectedAvailability}
        setSelectedAvailability={setSelectedAvailability}
        isFetchingAvailability={isFetchingAvailability}
        error={error}
        isLoading={isLoading}
        errorMessage={errorMessage}
        onSubmit={handleComplete}
      />
    </>
  );
};

export default RescheduleServicePageContainer;
