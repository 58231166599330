import capitalize from "lodash/capitalize";

export const formatAddressLine = (line: string | null) => {
  if (!line) return;
  const individualNames = line.split(" ");
  return individualNames
    .map((word) => {
      return capitalize(word);
    })
    .join(" ");
};
