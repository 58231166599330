import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalTitle,
  Typography,
  FormConfirmationModalProps,
} from "@welldigital/components";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 598,
  },
  actions: {
    display: "flex",
    marginTop: theme.spacing(3),
    "&>*": {
      width: "50%",
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const RescheduleConfirmation: React.FC<FormConfirmationModalProps> = ({
  open,
  onReject,
  onAccept,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onReject}
      PaperProps={{ className: classes.modal }}
    >
      <ModalTitle label={"Are you sure?"} />
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        You might already have appointments scheduled during this time.
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        <strong>
          Please check and call any impacted customers to cancel or reschedule
          their appointments as soon as possible.
        </strong>
      </Typography>
      <ModalActions fullWidth>
        <Button
          data-testid="RescheduleConfirmation/accept"
          children={"Yes"}
          color={"secondary"}
          variant={"contained"}
          onClick={onAccept}
        />
        <Button
          children={"Go back"}
          color={"primary"}
          variant={"outlined"}
          onClick={onReject}
        />
      </ModalActions>
    </Modal>
  );
};

export default RescheduleConfirmation;
