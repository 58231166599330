
import { Service, ServiceIds } from "./service.constants";

export const mockServices: Service[] = [
  {
    id: ServiceIds.PneumoniaPPV,
    name: "Pneumonia PPV",
    label: "Pneumococcal polysaccharide vaccine (PPV) - £30.00",
    description:
      "Suitable for people over the age of 65 who are at a higher risk of developing pneumonia. (Usually people who are eligible for a free flu vaccination).",
    fee: 30.0,
    analyticsName: "PPV",
    includeGPDetails: true,
    includeVaccineSelection: true,
  },
  {
    id: ServiceIds.PneumoniaPrevenar13,
    name: "Pneumonia Prevenar 13",
    label: `Pneumococcal conjugate vaccine (PCV) - £70.00 \nAlso known by the brand name Prevenar 13`,
    description:
      "Suitable for people aged 2 and over who are not at an increased risk of developing pneumonia.",
    fee: 70.0,
    analyticsName: "Prevenar 13",
    includeGPDetails: true,
    includeVaccineSelection: true,
  },
  {
    id: ServiceIds.HPV,
    name: "Gardasil 9 (two doses)",
    fee: 320.0,
    analyticsName: "Gardasil 9 (two doses)",
    includeGPDetails: true,
  },
  {
    id: ServiceIds.Chickenpox,
    name: "Chickenpox",
    fee: 130.0,
    analyticsName: "Chickenpox",
    includeGPDetails: true,
  },
  {
    id: ServiceIds.MenBBexsero,
    name: "Meningitis-B",
    fee: 220.0,
    analyticsName: "Bexsero",
    includeGPDetails: true,
  },
  {
    id: ServiceIds.Flu,
    name: "Flu",
    fee: 17.99,
    analyticsName: "Flu",
    includeGPDetails: false,
    includePaymentSelection: true,
  },
  {
    id: ServiceIds.FluPrevenar13,
    name: "Flu and Pneumonia Prevenar 13",
    label: `Pneumococcal conjugate vaccine (PCV) - £70.00 \nAlso known by the brand name Prevenar 13`,
    description:
      "Suitable for people aged 2 and over who are not at an increased risk of developing pneumonia.",
    fee: 87.99,
    analyticsName: "FluPrevnar",
    includeGPDetails: true,
    includePaymentSelection: true,
    includeVaccineSelection: true,
  },
  {
    id: ServiceIds.FluPPV,
    name: "Flu and Pneumonia PPV",
    label: "Pneumococcal polysaccharide vaccine (PPV) - £30.00",
    description:
      "Suitable for people over the age of 65 who are at a higher risk of developing pneumonia. (Usually people who are eligible for a free flu vaccination).",
    fee: 47.99,
    analyticsName: "FluPPV",
    includeGPDetails: true,
    includePaymentSelection: true,
    includeVaccineSelection: true,
  },
  {
    id: ServiceIds.CovidAntigen,
    name: "COVID-19 PCR",
    fee: 99,
    analyticsName: "PCR",
    includeGPDetails: false,
  },
  {
    id: ServiceIds.CovidGreenPack,
    name: "COVID-19 Green travel package",
    fee: 160,
    analyticsName: "COVID-19 Green travel package",
    includeGPDetails: false,
  },
  {
    id: ServiceIds.CovidAmberPack,
    name: "COVID-19 Amber travel package",
    fee: 225,
    analyticsName: "COVID-19 Amber travel package",
    includeGPDetails: false,
  },
  {
    id: ServiceIds.Saxenda,
    name: "Medicated Weight Management Service",
    fee: 0,
    analyticsName: "Saxenda",
    includeGPDetails: false,
  },
  {
    id: ServiceIds.HealthCheck,
    name: "Medicated Weight Management Service",
    fee: 20,
    analyticsName: "Saxenda",
    includeGPDetails: true,
  },
  {
    id: ServiceIds.Wegovy,
    name: "Medicated Weight Management – Wegovy",
    fee: 20,
    analyticsName: "Medicated Weight Management – Wegovy",
    includeGPDetails: true,
  },
  {
    id: ServiceIds.HPVSingleDose,
    name: "Gardasil 9 (single dose)",
    fee: 160.0,
    analyticsName: "Gardasil 9 (single dose)",
    includeGPDetails: true,
  },
  {
    id: ServiceIds.HPVThreeDoses,
    name: "Gardasil 9 (three doses)",
    fee: 480.0,
    analyticsName: "Gardasil 9 (three doses)",
    includeGPDetails: true,
  },
];
