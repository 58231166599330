import React, { useCallback } from "react";
import { Link } from "@material-ui/core";
import { ApolloQueryResult } from "@apollo/client";

export interface GpLetterPdfProps {
  appointmentId: string;
  locationId: string;
  refetch: ({
    appointmentId,
    locationId
  }: {
    appointmentId: string;
    locationId: string;
  }) => Promise<ApolloQueryResult<any>>;
}

const GpLetterPdf: React.FC<GpLetterPdfProps> = (props: GpLetterPdfProps) => {
  const { appointmentId, refetch, locationId } = props;
  const openPdf = useCallback(() => {
    refetch({ appointmentId, locationId }).then(({ data }) => {
      const pdf = data?.getAppointment?.gpLetterPdf;
      let pdfWindow = window.open && window.open("");
      pdfWindow?.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
          pdf +
          "'></iframe>",
      );
    });
  }, [refetch, appointmentId,locationId]);

  return (
    <Link data-testid={"GpLetterPdf/pdf-link"} onClick={openPdf}>
      GP Letter.pdf
    </Link>
  );
};

export default GpLetterPdf;
