import { gql } from "@apollo/client";

export const UPDATE_APPOINTMENT_STATUS = gql`
  mutation ServiceDetailsPage_updateAppointmentStatus(
    $appointmentId: String!
    $status: AppointmentStatus!
    $locationId: String!
  ) {
    updateAppointmentStatus(appointmentId: $appointmentId, status: $status, locationId: $locationId) {
      id
      status
    }
  }
`;