import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchOrderDetails,
  fetchCustomerDetails,
  fetchOrderList,
  fetchOrderHistoryDetails,
  fetchOrderListTypes,
  fetchOrderHistoryListTypes,
  postOrderNote,
  postOrderNoteTypes,
  getOrderWarnings,
  getSubscriptionDetails,
  fetchIdVerification,
  fetchIdCheckListTypes,
  fetchIdVerificationAwaitCount,
  fetchPreApprovedProductCustomerList,
  fetchPreApprovedProductCustomerListTypes,
  fetchPreApprovedProducts,
  fetchPreApprovedProductIdVerification,
} from "../ClinicalReviewApi";

export const getOrderDetailsAsync = createAsyncThunk(
  "clinicalReview/orderDetails",
  async (orderId: Number) => await fetchOrderDetails(orderId),
);

export const getOrderHistoryDetailsAsync = createAsyncThunk(
  "clinicalReview/orderHistoryDetails",
  async (payload: fetchOrderHistoryListTypes) =>
    await fetchOrderHistoryDetails(payload),
);

export const getOrderListAsync = createAsyncThunk(
  "clinicalReview/awaitingOrderList",
  async (payload: fetchOrderListTypes) => await fetchOrderList(payload),
);

export const getIdVerificationAsync = createAsyncThunk(
  "clinicalReview/awaitingIdVerification",
  async (payload: fetchIdCheckListTypes) => await fetchIdVerification(payload),
);

export const getPreApprovedProductIdVerificationAsync = createAsyncThunk(
  "clinicalReview/awaitingPreApprovedProductIdVerification",
  async (id: number) => await fetchPreApprovedProductIdVerification(id),
);

export const getPreApprovedProductCustomerListAsync = createAsyncThunk(
  "clinicalReview/preApprovedProductCustomerList",
  async (payload: fetchPreApprovedProductCustomerListTypes) => await fetchPreApprovedProductCustomerList(payload),
);

export const getPreApprovedProductsAsync = createAsyncThunk(
  "clinicalReview/preApprovedProducts",
  async () => await fetchPreApprovedProducts(),
);

export const getIdVerificationAwaitCountAsync = createAsyncThunk(
  "clinicalReview/idVerificationAwaitCount",
  async () => await fetchIdVerificationAwaitCount(),
);

export const getCustomerDetailsAsync = createAsyncThunk(
  "clinicalReview/customerDetails",
  async ({customerId,treatment} : {customerId: string | Number,treatment?: string}) => await fetchCustomerDetails(customerId,treatment),
);

export const setOrderNoteAsync = createAsyncThunk(
  "clinicalReview/setOrderNote",
  async ({ orderId, noteText, createdBy }: postOrderNoteTypes) =>
    await postOrderNote({ orderId, noteText, createdBy }),
);

export const getOrderWarningsAsync = createAsyncThunk(
  "clinicalReview/orderWarnings",
  async ({ orderId, customerId }: { orderId: number; customerId: number }) =>
    await getOrderWarnings(orderId, customerId),
);

export const getSubscriptionDetailsAsync = createAsyncThunk(
  "clinicalReview/getSubscriptionDetails",
  async (subscriptionId: string) =>
    await getSubscriptionDetails(subscriptionId),
);
