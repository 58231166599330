import { FrequencyCodes } from "../../types/frequency";
import { RepeatInput } from "__generated__/globalTypes";

export function getRepeatInput(
  selectedFrequency: string,
  weekdayCode: string,
  selectedMonthDay: number,
): RepeatInput | null {
  if (!selectedFrequency) {
    return null;
  }

  switch (selectedFrequency) {
    case FrequencyCodes.Weekly:
      return {
        frequency: selectedFrequency,
        interval: 1,
        weekdays: weekdayCode,
        monthDay: 0,
        monthType: "",
      };
    case FrequencyCodes.Weekdays:
      return {
        frequency: "W",
        interval: 1,
        weekdays: "12345",
        monthDay: 0,
        monthType: "",
      };
    case FrequencyCodes.Monthly:
      return {
        frequency: selectedFrequency,
        interval: 1,
        weekdays: "",
        monthDay: selectedMonthDay,
        monthType: "D",
      };
    default:
      return {
        frequency: selectedFrequency,
        interval: 1,
        weekdays: "",
        monthDay: 0,
        monthType: "",
      };
  }
}

export function parseInitialMonthDay(
  monthDayInput: string | number | null | undefined,
): number {
  if (!monthDayInput) {
    return 0;
  }
  if (typeof monthDayInput == "string") {
    return parseInt(monthDayInput);
  }
  return monthDayInput;
}
