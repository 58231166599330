import React, { FC, Children, cloneElement } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import classNames from "clsx";

import FixedPane from "../FixedPane/FixedPane";

const useStyles = makeStyles((_theme: Theme) => ({
  splitter: {
    display: "flex",
    flexDirection: "row",
  },
}));

interface SplitterProps {
  className?: string;
}

/**
 * Splitter component for composing split screen functionality,
 * used in conjunction with FixedPane and FlexPane
 */
const Splitter: FC<SplitterProps> = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();
  const splitterClasses = classNames(classes.splitter, props.className);
  let childCount = 1;

  if (Array.isArray(children)) {
    childCount = children.length;
  }

  const mappedChildren = Children.map(children, (child, index) => {
    if (index === 0 && index === childCount - 1) {
      return child;
    }

    // React types are incomplete and doesn't have `type` available
    if ((child as any)?.type === FixedPane) {
      return cloneElement(child as any, {
        includeBorderRight: index === 0,
      });
    }

    return child;
  });

  return (
    <div {...rest} className={splitterClasses}>
      {mappedChildren}
    </div>
  );
};

export default Splitter;
