import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Divider, Typography } from "@welldigital/components";
import React, { FC } from "react";
import { Question } from "./components/Question";

export interface ServiceQuestionsProps {
  traversalData: dataProps[],
  loading: boolean
    
}
export interface dataProps {
  traversal_id: string,
  identifier: string,
  label: string,
  traversal_response: {
    options: {
      checked: boolean,
      label: string
    }[]
  }
}

const WegovyServiceQuestions: FC<ServiceQuestionsProps> = ({traversalData, loading}) => {
  if (loading) return <p>Loading...</p>;
  if (traversalData?.length === 0) return <p>no questions</p>;
  const hasQuestions = traversalData?.length > 0;
  if (hasQuestions) {
    return (
      <>
        <Divider spacingBefore={1} spacingAfter={2} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"h4"} spacingAfter={0}>
                    Pre-screen questions
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {traversalData.map((q: dataProps) => {
                return <Question key={`question`} question={q} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return null;
};

export default WegovyServiceQuestions;
