import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Theme } from "@material-ui/core";

import Header from "../../../components/ClinicalHeader";
import ClinicalReviewRoutes from "../../routers/ClinicalReviewRoutes";
import Footer, { footerHeight } from "components/Footer/Footer";
import { headerHeight } from "components/Header/Header";
import { BreadCrumbsContainer } from "components/BreadCrumbs/Breadcrumbs";
import { WEBSITE_NAME_CLINICAL_REVIEW } from "../../../constants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
    overflowY: "hidden",
  },
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

const ClinicalReviewLayout: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{WEBSITE_NAME_CLINICAL_REVIEW}</title>
      </Helmet>
      <Header />

      <div className={classes.root}>
        <div className={classes.container}>
          <BreadCrumbsContainer />
          <ClinicalReviewRoutes />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClinicalReviewLayout;
