import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  LinearProgress,
} from "@material-ui/core";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import "./index.scss";
import { useCurrentUserEmail } from "services/auth-hooks";
import DropBox from "./DropBox";
import ShowImage from "./ShowImage";
import { uploadImages } from "pages/ClinicalReviewPage/ClinicalReviewApi";


export interface PropsTypes {
  onClose?: (visible: boolean) => void;
  productId: number;
  orderId: number;
  onUpdateImages: (update: boolean) => void;
  categoryType?: string;
}

type ImageType = {
  id: number;
  src: string | ArrayBuffer | null;
  title: string;
  size: number;
}
let totalfileSize = 0;
const UploadImageContainer = ({
  orderId,
  productId,
  onClose,
  onUpdateImages,
  categoryType
}: PropsTypes) => {

  const dialogRefStatus = useRef<CustomDialogRef>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const email = useCurrentUserEmail();
  useEffect(() => {
    dialogRefStatus.current?.show();
  }, []);
  const [images, setImages] = useState<ImageType[]>([]);


  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onloadend = function (e) {
        totalfileSize = totalfileSize + file.size;

        if (totalfileSize > 26214400) {
          totalfileSize = 0;
          setImages([]);
          setError("Total files size shouldn't exceed 25MB");
          return;
        } else {
          if (totalfileSize >= 24117248 && totalfileSize < 26214400) {
            setError('You have almost utilise maximum files size limit. Please save you files before uploading the next image.');
          } else {
            setError('');
          }
          setImages((prevState) => [
            ...prevState,
            { id: prevState.length === 0 ? 1 : Math.max(...prevState.map(o => o.id)) + 1, src: e?.target?.result ?? '', title: file?.name, size: file.size },
          ]);
        }

      };
      reader.readAsDataURL(file);
      return file;
    });
    rejectedFiles?.forEach((file: any) => {
      if (file?.errors[0]?.code === "file-invalid-type") {
        return setError(file?.errors[0]?.message)
      }
    });

  }, []);

  const onImageDelete = (current: number) => {
    const initialValue = 0;
    const updatedImages = images.filter(img => current !== img.id).map(image => image);
    totalfileSize = 0;
    totalfileSize = updatedImages.map(img => img.size).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      initialValue,
    );

    setImages(updatedImages);
  }

  const handleSubmit = () => {
    setLoading(true);
    onUpdateImages(false);
    let uploadapis = images?.map((image, index) => {
      index++;
      let body =
        [{
          "title": "Pharmacist uploaded image",
          "src": image?.src?.toString().split("base64,")[1] || "",
          "order_item_id": productId,
          "file_name": image.title,
          "order_id": orderId,
          "created_by": email,
          "secondary_tag" : categoryType
        }]

      return uploadImages(body)
    });
    Promise.all(uploadapis).then(res => {
      setLoading(false)
      onUpdateImages(true);
      onClose?.(false);
    }).catch(error => {
      console.log(error);
      setLoading(true);
      setError('Unable to uplaod the images.')
    });
  }



  return (
    <CustomDialog
      onClose={() => onClose?.(false)}
      ref={dialogRefStatus}
      title={
        <>
          Upload Images
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
        </>
      }

    >
      <p>
        Upload images

        If the patient has supplied images, please upload them here. Please ensure you are satisfied that you have all the images you need to make a clinical decision.
      </p>
      {error !== '' && <p className="error center">{error}</p>}
      <div className="container-common">
        <div className="photo_upload">
          <ShowImage images={images} onImageDelete={onImageDelete} />
          <DropBox onDrop={onDrop} />
        </div>
      </div>
      <div className="w-btn-container">
        <button
          onClick={() => { onClose?.(false) }}
          className={"btn-cancel-secondary"}
          color={"secondary"}>
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className={"btn-save-primary"}
          color={"primary"} disabled={images.length === 0 || loading}>
          Save
        </button>
      </div>
    </CustomDialog>
  );
};

export default UploadImageContainer;