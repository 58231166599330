import { clinicalReviewActions } from "pages/ClinicalReviewPage/reduxSlice";
import { selectUserGroup } from "pages/ClinicalReviewPage/reduxSlice/selector";
import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getBookwellUserGroup, getClinicalReviewUserGroup } from "utils/user";
import { getBookwellGroups, getGroups, getLocationId, isAuthenticated } from "../../../../services/auth";
import { CLINICAL_REVIEW_USER_GROUP, ORIGIN_URL } from "../../../../constants";
import { ROUTES } from "app/routers/ClinicalReviewRoutes";
import { selectBookwellGroup, selectLocationId } from "redux/reduxSlice/selector";
import { bookwellActions } from "redux/reduxSlice";

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  let [isAuth, setIsAuth] = useState<boolean | null>(null);
  const userGroup = useAppSelector(selectUserGroup);
  const bookwellGroup = useAppSelector(selectBookwellGroup);
  const locationIdStore = useAppSelector(selectLocationId);

  const appDispatch = useAppDispatch();
  const pathName = props?.location?.pathname as string;
  let locationArr = [];
  let locationIdPath = "";
  if (pathName.includes("/dashboard")) {
    locationArr = pathName.split("/");

    if (locationArr.length > 2) {
      locationIdPath = locationArr.length === 4 ? locationArr[locationArr.length - 2] : locationArr[locationArr.length - 1];

    }
  }
  useEffect(() => {
    async function isAuthAsync() {
      const isAuth = await isAuthenticated();
      //   Check if clinical review user
      if (isAuth) {
        const groups = await getGroups();
        const userGroup = getClinicalReviewUserGroup(
          groups?.split(",") as CLINICAL_REVIEW_USER_GROUP[],
        );
        const bookwellGroups = await getBookwellGroups();
        const bookwellGroup=getBookwellUserGroup(bookwellGroups);
        
        bookwellGroup && bookwellGroup?.length > 0 && appDispatch(bookwellActions.setBookwellGroup(bookwellGroup));
        appDispatch(clinicalReviewActions.setUserGroup(userGroup));

      }
      setIsAuth(isAuth);
    }
    isAuthAsync();
  }, [appDispatch]);

  useEffect(() => {
    async function isAuthAsync() {
      if (isAuth) {
        if (locationIdPath !== "" && (locationIdPath !== locationIdStore)) {
          appDispatch(bookwellActions.setLocationId(locationIdPath));
          sessionStorage.setItem("sessionLocation",locationIdPath)
        }else if(sessionStorage.getItem("sessionLocation") && sessionStorage.getItem("sessionLocation") !== null){
          const locationSession=sessionStorage.getItem("sessionLocation") || "";
          appDispatch(bookwellActions.setLocationId(locationSession))
        }else if (locationIdStore === "") {
            const locationIdAPI = await getLocationId();
            appDispatch(bookwellActions.setLocationId(locationIdAPI))
        }
      }
    }
    isAuthAsync();
  }, [isAuth,appDispatch,locationIdPath,locationIdStore])



  if (isAuth === null) {
    return <div>loading</div>;
  }

  // Redirect to clinical review
  // Since Clinical review has only Azure login & Azure login
  // redirects to base path, we have to redirect from here
  if (isAuth && sessionStorage.getItem(ORIGIN_URL)) {
    const path = userGroup ? "/clinical-review" : ROUTES.clinical.login;
    return <Redirect to={{ pathname: path }} />;
  }
  // //Navigate to new Page

  if (isAuth && bookwellGroup && bookwellGroup.length > 0) {
    const { children, ...rest } = props;
    if (pathName === "/") {
      const paths = "/central-visibility";
      return <Redirect to={{ pathname: paths }} />;
    } else {
      return <Route {...rest}>{children}</Route>;
    }
  }
  if (isAuth && (pathName.includes("/central-visibility") || pathName.includes(("/dashboard")))) {
    if (bookwellGroup && bookwellGroup.length > 0) {
      const { children, ...rest } = props;

      return <Route {...rest}>{children}</Route>;
    } else {
      return <Redirect to={{ pathname: "/login" }} />;
    }
  }

  if (isAuth) {
    const { children, ...rest } = props;
    return <Route {...rest}>{children}</Route>;
  }
  const pathname = sessionStorage.getItem(ORIGIN_URL) ?? "/login";
  return <Redirect to={{ pathname }} />;
};

export default ProtectedRoute;
