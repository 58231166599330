import React, { useCallback, useState } from "react";
import { TextField, Grid } from "@material-ui/core";
import { Typography, Divider } from "@welldigital/components";
import ReactMarkdown from "react-markdown";
import { ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import ChooseBookNextAppointmentModal from "../../components/ChooseBookNextAppointmentModal/ChooseBookNextAppointment";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import { formatMarkdown } from "utils/markdown";
import ServiceSplitScreen from "components/ServiceSplitScreen/ServiceSplitScreen";
import { MedicineInput } from "../../__generated__/globalTypes";
import { getAppointment } from "graphql/__generated__/getAppointment";
import AppointmentCountChip from "components/AppointmentCountChip/AppointmentCountChip";
import PreviousAppointmentMedicine from "./components/PreviousAppointmentMedicine";
import { trackServiceComplete } from "utils/trackServiceComplete";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export interface CompleteServiceDetailsPageProps {
  appointmentId: string;
  isLoading?: boolean;
  error?: ApolloError;
  data?: getAppointment;
  onSubmit: (medicine: MedicineInput) => Promise<any>;
  newDate?: () => Date;
}

const CompleteServiceDetailsPage: React.FC<CompleteServiceDetailsPageProps> = (
  props,
) => {
  const { onSubmit, data, appointmentId } = props;
  const history = useHistory();
  const [expiryDate, setExpiryDate] = useState(
    props.newDate ? props.newDate() : new Date(),
  );
  const [manufacturer, setManufacturer] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [method, setMethod] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const locationId = useAppSelector(selectLocationId);
  const handleManufacturerChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setManufacturer(event.target.value);
    },
    [setManufacturer],
  );

  const handleBatchNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBatchNumber(event.target.value);
    },
    [setBatchNumber],
  );

  const handleExpiryDateChange = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined) => {
      if (!date) {
        return;
      }
      setExpiryDate(date);
    },
    [setExpiryDate],
  );

  const handleMethodAdministeredChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMethod(event.target.value);
    },
    [setMethod],
  );

  const onComplete = useCallback(() => {
    onSubmit({ expiryDate, manufacturer, batchNumber, method }).then(() => {
      const appt = data!.getAppointment;
      if (appt!.number < appt!.totalNumber) {
        setIsModalOpen(true);
      } else {
        trackServiceComplete(appt?.course.id!,locationId);
        history.push(`/service-details/${appointmentId}`);
      }
    });
  }, [
    data,
    onSubmit,
    expiryDate,
    manufacturer,
    method,
    history,
    appointmentId,
    batchNumber,
    locationId,
  ]);

  if (props.isLoading) return <p>Loading...</p>;
  if (props.error) return <p>Error: {props.error.message}</p>;
  if (!props.data?.getAppointment) return <p>Appointment not found</p>;
  const { getAppointment: appointment } = props.data;
  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `/customer-details/${appointment.customer.id}`,
            name: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
          },
          {
            url: `/service-details/${appointment.id}`,
            name: `${appointment.course?.name}`,
          },
          {
            url: `/service-details/${appointment.id}/complete`,
            name: "Appointment in progress",
          },
        ]}
      />
      <ServiceSplitScreen
        actionButtonText={"Complete appointment"}
        appointment={appointment}
        onActionSubmit={onComplete}
        isActionDisabled={
          !manufacturer || !expiryDate || !method || !batchNumber
        }
      >
        <AppointmentCountChip appointmentId={appointment.id} />
        <Typography variant={"h3"} spacingAfter={0}>
          Medication administered
        </Typography>
        <PreviousAppointmentMedicine appointment={appointment} />
        <Divider spacingBefore={3} spacingAfter={3} />
        <Typography variant={"h5"}>Current appointment</Typography>

        <Grid container spacing={2}>
          <Grid item xs={7}>
            <KeyboardDatePicker
              data-testid={"completeServiceDetails/expiryDate-datePicker"}
              label={"ExpiryDate"}
              format={"dd/MM/yyyy"}
              value={expiryDate}
              onChange={handleExpiryDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              size={"small"}
              id={"completeServiceDetails/expiryDate-datePicker"}
              fullWidth
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              data-testid={"completeServiceDetails/manufacturer-input"}
              label={"Manufacturer"}
              value={manufacturer}
              onChange={handleManufacturerChange}
              size={"small"}
              fullWidth
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              data-testid={"completeServiceDetails/batchNumber-input"}
              label={"Batch number"}
              value={batchNumber}
              onChange={handleBatchNumberChange}
              size={"small"}
              fullWidth
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              data-testid={"completeServiceDetails/method-input"}
              label={"Administration site"}
              helperText={
                "Indicate method of administration if this vaccine wasn’t injected"
              }
              value={method}
              onChange={handleMethodAdministeredChange}
              size={"small"}
              fullWidth
            />
          </Grid>
        </Grid>
        {appointment.course?.patientAdvice && (
          <>
            <Divider spacingBefore={4} spacingAfter={3} />
            <Typography variant={"h5"} spacingAfter={0}>
              Information for the customer
            </Typography>
            <ReactMarkdown
              source={formatMarkdown(appointment.course?.patientAdvice)}
            />
          </>
        )}
        <ChooseBookNextAppointmentModal
          isModalOpen={isModalOpen}
          appointment={appointment}
          completeAppointment={onSubmit}
        />
      </ServiceSplitScreen>
    </>
  );
};

export default CompleteServiceDetailsPage;
