import React, { useEffect, useRef, useState } from "react";
import { useForm, OnSubmit } from "react-hook-form";
import {
  Grid,
  MenuItem,
  Select,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import { ReactComponent as IcArrowDown } from "assets/icons/arrow-down.svg";
import "./index.scss";
import HookFormSelect from "../CustomerDetails/HookFormSelect";
import { updateOrderStatus } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import { useCurrentUserEmail } from "services/auth-hooks";
import { updateOrderSchema } from "utils/validations";



interface PropsTypes {
  onClose?: (visible: boolean) => void;
  onUpdateSuccess: () => void;
  idCheckStatus: string;
  orderId: number;
}
const UpdateOrderDetails = ({
  onUpdateSuccess,
  onClose,
  idCheckStatus,
  orderId,
}: PropsTypes) => {
  const formRef = useRef<HTMLFormElement>(null);
  const dialogRefOrder = useRef<CustomDialogRef>(null);
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const email = useCurrentUserEmail();
  
  useEffect(() => {
    dialogRefOrder.current?.show();
  }, []);

 
  const {
    handleSubmit,
   //clearError,
    control,
   // getValues,
   // triggerValidation,
    errors,
    formState: { isValid },
  } = useForm<UpdateOrderForm>({defaultValues:toOrderForm({status:"select"}),
      validationSchema: updateOrderSchema,
      mode: "onChange"
  });



  const onSubmit: OnSubmit<UpdateOrderForm> = async (updateOrderForm) => {
    setLoading(true);
    setError(false);
    const payload : updateOrderStatus ={
      status: "",
      updatedBy: ""
    };
    if(updateOrderForm.status === "reject-ri" || updateOrderForm.status === "reject"){
      payload.status="reject";
      payload.rejectAll=updateOrderForm.status === "reject-ri"? false : true;
      payload.updatedBy=email || "test";
    }else{
      payload.status=updateOrderForm.status;
      payload.updatedBy=email || "test";
    }

    try{
      const response=await updateOrderStatus(orderId,payload);
      if(response?.status === 204){
        dialogRefOrder.current?.hide();
        onUpdateSuccess?.();
      }else{
        setError(true);
      }
    }catch(error){
      setError(true);
      console.log("Error in updating id check status");
    }finally{
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      onClose={() => onClose?.(false)}
      ref={dialogRefOrder}
      title={
        <>
         How should this order be handled?
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
        </>
      }
      className="update-order-dialog"
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="update-order-container">
          
          <Grid item md={12}>
            <Typography className="popup-para-color mb-10">
              
                  <h3 className="color-primary-hale"> ID check status: {idCheckStatus.toUpperCase()}</h3>
                { idCheckStatus === "refer" ? <>
                <p>You can choose to reject or approve this order, or reject only those items in the order that are restricted.</p> 
                <p>You should speak to the customer before rejecting items or orders.</p>
                <p>By approving restricted items, you are confirming that you have satisfied yourself of the customer’s identity. If this is the case, please also leave an order note to aid future order reviews.</p></> 
                :<>
                <p>You can choose to reject the whole order or only those items in the order that are restricted.</p> 
                <p>You should speak to the customer before rejecting items or orders.</p>
                </>
                }
                  
            </Typography>
              
          </Grid>
          <Grid item md={12}>
            <Grid item md={6}>
              <HookFormSelect
                control={control}
                defaultValue={""}
                name="status"
                fullWidth
              >
                <Select
                  IconComponent={() => (
                    <IcArrowDown className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  className="input"
                  variant="outlined"
                  value={control.getValues().status}
                  error={!!errors.status}
                >
                  <MenuItem value="select">Select an option</MenuItem>
                  {idCheckStatus === "refer" && <MenuItem value="approve">Approve</MenuItem>}
                  <MenuItem value="reject-ri">Reject  only restricted item selected</MenuItem>
                  <MenuItem value="reject">Reject</MenuItem>
                </Select>
              </HookFormSelect>
            </Grid>
          </Grid>
         
            <Grid item md={6}>
              {/* This is to fix spacing */}
            </Grid>
          </Grid>
         
        
        {error && (
          <Typography variant="subtitle2" className="color-error">
            Error in updating order details
          </Typography>
        )}
        <div className="buttons-container">
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => dialogRefOrder.current?.hide()}
          >
            Skip
          </Button>
          <Button type="submit" disabled={!isValid || loading}>
            Update order
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};
export interface UpdateOrderForm {
    status: string;
}
const toOrderForm = ({
    status
  }: UpdateOrderForm): UpdateOrderForm => ({
    status
  });
export default UpdateOrderDetails;


