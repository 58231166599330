import { format } from "date-fns";
import { useMutation, useQuery } from "@apollo/client/react";
import {
  getResourceBlocksVariables,
  getResourceBlocks_getResourceBlocks,
} from "../../__generated__/getResourceBlocks";
import {
  CREATE_RESOURCE_BLOCK,
  GET_RESOURCE_BLOCKS,
  UPDATE_RESOURCE_BLOCK,
} from "../../gql";
import { useCallback, useContext, useEffect, useState } from "react";
import { updateResourceBlockVariables } from "../../__generated__/updateResourceBlock";
import { createResourceBlockVariables } from "../../__generated__/createResourceBlock";
import { useSnackbar } from "notistack";
import { AlertContext } from "../../../../pages/DashboardPage/AlertContext.context";
import { getDateFromTime } from "../../IndividualService/IndividualService.utils";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export interface ILunchHours {
  id?: number;
  startTime: Date;
  endTime: Date;
}

export type UseLunchHoursResult = {
  lunchHours: ILunchHours | null;
  saveLunchHours: (block: ILunchHours) => Promise<void>;
  isLunchHoursLoading: boolean;
  isLunchHoursSaving: boolean;
};

export default function useLunchHours(): UseLunchHoursResult {
  const reason = "Lunch Break";
  const hourFormat = "kmm";
  const { enqueueAlert } = useContext(AlertContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLunchHoursSaving, setLunchHoursSaving] = useState(false);
  const [lunchHoursLimit, setLunchHoursLimit] = useState(0);
  const [offset,SetOffset] = useState(0)
  const locationId = useAppSelector(selectLocationId);
  
    const { data, loading: isLoading, refetch } = useQuery<
      any,
      getResourceBlocksVariables
    >(GET_RESOURCE_BLOCKS, {
      variables: {
        limit: lunchHoursLimit,
        offset: offset,
        startDate: "2021-05-21",
        endDate: "9999-01-01",
        locationId: locationId,
      },
    });
  const [lunchHours, setLunchHours] = useState<
    UseLunchHoursResult["lunchHours"]
  >(null);
  const getResourceBlocks = data?.getResourceBlocks || [];

  if(getResourceBlocks && getResourceBlocks?.length > 0){
    let isLunchHour= false;
    for(let blockData of getResourceBlocks){
      console.log("blockData from getResourceBlocks:", blockData)
      if(blockData?.reason === "Lunch Break") {
        isLunchHour= true;
        break;
      }
    }
    if(!isLunchHour){
      SetOffset(lunchHoursLimit)
      setLunchHoursLimit(lunchHoursLimit+25);
    }
  }

  const [addResourceBlock] = useMutation(CREATE_RESOURCE_BLOCK);
  const [updateResourceBlock] = useMutation(UPDATE_RESOURCE_BLOCK);

  
  const fromResourceToLunchHours: (
    data?: getResourceBlocks_getResourceBlocks,
  ) => ILunchHours = useCallback(
    (lunchHoursBlock) => ({
      id: lunchHoursBlock?.id,
      startTime: getDateFromTime(
        lunchHoursBlock?.startTime ? lunchHoursBlock?.startTime : 1200,
      ),
      endTime: getDateFromTime(
        lunchHoursBlock?.endTime ? lunchHoursBlock?.endTime : 1300,
      ),
    }),
    [],
  );

  const fromLunchHoursToResourceCreate: (
    data: ILunchHours
  ) => createResourceBlockVariables = useCallback(
    (lunchHours) => ({
      startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: "9999-01-01",
      startTime: parseInt(format(lunchHours.startTime, hourFormat)),
      endTime: parseInt(format(lunchHours.endTime, hourFormat)),
      locationId: locationId,
      reason,
      allDay: false,
      repeats: true,
      repeat: {
        frequency: "D",
        interval: 1,
        monthDay: 0,
        monthType: "",
        weekdays: "",
      },
    }),
    [locationId],
  );

  const fromLunchHoursToResourceUpdate: (
    data: ILunchHours,
  ) => updateResourceBlockVariables = useCallback(
    (lunchHours) => ({
      blockId: (lunchHours.id as number).toString(),
      ...fromLunchHoursToResourceCreate(lunchHours),
    }),
    [fromLunchHoursToResourceCreate],
  );

  const saveLunchHours = useCallback(
    async (lunchHours: ILunchHours) => {
      setLunchHoursSaving(true);
      try {
        if (lunchHours.id) {
          console.log("to updateResourceBlock, lunchHours:", lunchHours)
          await updateResourceBlock({
            variables: fromLunchHoursToResourceUpdate(lunchHours),
          });
        } else {
          console.log("to createResourceBlock, lunchHours:", lunchHours)
          await addResourceBlock({
            variables: fromLunchHoursToResourceCreate(lunchHours),
          });
        }
        await refetch();
        enqueueAlert({
          type: "success",
          message: "You have successfully added lunch hours",
        });
      } catch (e) {
        enqueueSnackbar("Lunch hours not saved", { variant: "error" });
      }
      setLunchHoursSaving(false);
    },
    [
      refetch,
      enqueueAlert,
      enqueueSnackbar,
      updateResourceBlock,
      fromLunchHoursToResourceUpdate,
      addResourceBlock,
      fromLunchHoursToResourceCreate,
    ],
  );

  useEffect(() => {
    if (isLoading || isLunchHoursSaving) return;
    const lunchHoursBlock = getResourceBlocks.find(
      (block: { reason: string }) => block?.reason === reason,
    );
    setLunchHours(fromResourceToLunchHours(lunchHoursBlock || undefined));
  }, [
    fromResourceToLunchHours,
    getResourceBlocks,
    isLunchHoursSaving,
    isLoading,
  ]);
  return {
    lunchHours,
    saveLunchHours,
    isLunchHoursLoading: isLoading,
    isLunchHoursSaving,
  };
}
