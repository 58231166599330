import { addHours, addYears, parse, subHours } from "date-fns";

export interface SlotMinimumDateInput {
  isFlu: boolean;
  locationId?: string;
}

// TODO: this is NOT OK, must be moved to database
// Change to weekend so flu wont be allowed to be booked
const minFluDate = parse("09-09", "MM-dd", new Date());
const maxFluDate = parse("12-13", "MM-dd", new Date());

export function getNow() {
  return new Date();
}

export function getSlotMinimumDate({
  isFlu,
  locationId,
}: SlotMinimumDateInput): Date {
  const today = getNow();
  const isMobileVan = locationId ? checkIfIsMobileVan(locationId) : true;

  // Flu vaccines are available differently
  if (isFlu && !isMobileVan) {
    const isDuringSeason = today >= minFluDate && today < maxFluDate;
    if (!isDuringSeason) {
      const shouldBeNextYear = today > maxFluDate;
      if (shouldBeNextYear) {
        return addYears(minFluDate, 1);
      }
      const isUnder48Hours = today > subHours(minFluDate, 48);
      if (!isUnder48Hours) {
        return minFluDate;
      }
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const endsUnder48Hours = today > subHours(maxFluDate, 48);
    if (endsUnder48Hours) {
      return addYears(minFluDate, 1);
    }
  }

  return addHours(today, 48);
}

export function getSlotMaximumDate({
  isFlu,
  locationId,
}: SlotMinimumDateInput): Date | undefined {
  const today = getNow();
  const isMobileVan = locationId ? checkIfIsMobileVan(locationId) : true;

  if (isFlu && !isMobileVan) {
    const shouldBeNextYear = today > subHours(maxFluDate, 48);
    if (shouldBeNextYear) {
      return addYears(maxFluDate, 1);
    }
    return maxFluDate;
  }
}

export function checkIfIsMobileVan(locationId: string): boolean {
  const locationIdsString = process.env.REACT_APP_VAN_LOCATIONS || "";
  if (!locationIdsString) return false;
  const locationIds = locationIdsString.split(",");
  return locationIds.includes(locationId);
}
