import { gql } from "@apollo/client";

export type CalendarInfo = {
  id: string;
  locationId: string;
  name: string;
  deletedStatus: boolean;
  deletedTime: any | null;
  availability: {
    sun: {
      startTime: number | null;
      endTime: number | null;
    } | null;
    mon: {
      startTime: number | null;
      endTime: number | null;
    } | null;
    tue: {
      startTime: number | null;
      endTime: number | null;
    } | null;
    wed: {
      startTime: number | null;
      endTime: number | null;
    } | null;
    thu: {
      startTime: number | null;
      endTime: number | null;
    } | null;
    fri: {
      startTime: number | null;
      endTime: number | null;
    } | null;
    sat: {
      startTime: number | null;
      endTime: number | null;
    } | null;
  } | null;
};
export const CALENDAR_FRAGMENT = gql`
  fragment CalendarBlock on CalendarBlock {
    id
    locationId
    calendarId
    startDate
    endDate
    startTime
    endTime
    reason
    repeats
    repeat {
      frequency
      interval
      weekdays
      monthDay
      monthType
    }
  }
`;
export const UPDATE_CALENDAR_FRAGMENT = gql`
  fragment Calendar on Calendar {
    id
    locationId
    name
    deletedStatus
    deletedTime
    availability {
      sun {
        startTime
        endTime
      }
      mon {
        startTime
        endTime
      }
      tue {
        startTime
        endTime
      }
      wed {
        startTime
        endTime
      }
      thu {
        startTime
        endTime
      }
      fri {
        startTime
        endTime
      }
      sat {
        startTime
        endTime
      }
    }
  }
`;

export const GET_CALENDARS = gql`
  query getCalendars(
    $locationId: String!
    $deleted: Boolean
    $offset: Int
    $limit: Int
  ) {
    getCalendars(
      locationId: $locationId
      deleted: $deleted
      offset: $offset
      limit: $limit
    ) {
      id
      locationId
      name
      deletedStatus
      deletedTime
      availability {
        sun {
          startTime
          endTime
        }
        mon {
          startTime
          endTime
        }
        tue {
          startTime
          endTime
        }
        wed {
          startTime
          endTime
        }
        thu {
          startTime
          endTime
        }
        fri {
          startTime
          endTime
        }
        sat {
          startTime
          endTime
        }
      }
    }
  }
`;
export const UPDATE_CALENDARS = gql`
  mutation updateCalendar($input: UpdateCalendarInput) {
    updateCalendar(input: $input) {
      ...Calendar
    }
  }
  ${UPDATE_CALENDAR_FRAGMENT}
`;

export const GET_CALENDAR_AVAILABILITY = gql`
  query getCalendarBlocks($id: String!, $offset: Int, $limit: Int) {
    getCalendarBlocks(id: $id, offset: $offset, limit: $limit) {
      ...CalendarBlock
    }
  }
  ${CALENDAR_FRAGMENT}
`;

export const CREATE_CALENDAR_BLOCK = gql`
  mutation createCalendarBlock($input: CalendarInput!) {
    createCalendarBlock(input: $input) {
      ...CalendarBlock
    }
  }
  ${CALENDAR_FRAGMENT}
`;

export const UPDATE_CALENDAR_BLOCK = gql`
  mutation updateCalendarBlock($input: CalendarInput!) {
    updateCalendarBlock(input: $input) {
      ...CalendarBlock
    }
  }
  ${CALENDAR_FRAGMENT}
`;

export const DELETE_CALENDAR_BLOCK = gql`
  mutation deleteCalendarBlock($id: String!) {
    deleteCalendarBlock(id: $id) {
      ...CalendarBlock
    }
  }
  ${CALENDAR_FRAGMENT}
`;
