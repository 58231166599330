export function getFromLocalStorage<T = any>(key: string): T | null {
  const data = localStorage.getItem(key);
  if (!data) return null;
  return JSON.parse(data);
}

export function setToLocalStorage<T = any>(key: string, data: T): void {
  localStorage.setItem(key, JSON.stringify(data));
}

export function deleteFromLocalStorage(key: string): void {
  localStorage.removeItem(key);
}
