// amplitude events

export default {
  screening: {
    started: "screening started", // when the question page is first loaded before first screening question is fetched
    answeredQuestion: "screening answered question", // after next pressed when answering a question
    complete: "screening questions", // after the book appointment button is pressed
    ineligible: "ineligible", // after the user has answered a question, if the response from the API says the user is ineligible
  },
  bookAppointment: {
    failed: "failed to book appointment",
  },
  walkIn: {
    booked: "Booked", // When a walk in has been succesfully booked
  },
};

export const FLOW = {
  walkIn: "WalkIn",
};
