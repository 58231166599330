import React, { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { azureLogin, signIn } from "../../services/auth";
import LoginPage, { LoginForm } from "./LoginPage";
import { Link } from "@material-ui/core";
import { Spacing } from "@welldigital/components";
import LoginPageSSO from "./LoginPageSSO";

const LoginPageContainer: React.FC = () => {
  const isSsoShown = !!process.env.REACT_APP_SSO_LOGIN;
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<any | undefined>();
  const location = useLocation();

  const onSubmitEmail = useCallback(
    async (data: LoginForm) => {
      try {
        setErrorMessage(undefined);
        await signIn(data.email);
        history.push("/enter-secret-code", { email: data.email });
      } catch (e:any) {
        if (e.code === "UserNotFoundException") {
          setErrorMessage(
            <>
              Check you've entered your email address correctly. This should
              contain your store ID and end in @well.co.uk.
              <Spacing spacing={1} />
              If you're still having problems contact support on{" "}
              <Link
                href={"tel:01612599135"}
                color={"inherit"}
                underline={"always"}
              >
                0161 2599135
              </Link>
              ,{" "}
              <Link
                href={"tel:01612599136"}
                color={"inherit"}
                underline={"always"}
              >
                0161 2599136
              </Link>
              , or email{" "}
              <Link
                href={"mailto:wellpharmacyservices@well.co.uk"}
                color={"inherit"}
                underline={"always"}
              >
                wellpharmacyservices@well.co.uk
              </Link>
            </>,
          );
        } else {
          setErrorMessage(e.message);
        }
      }
    },
    [setErrorMessage, history],
  );

  const onSubmitSSO = useCallback((): void => {
    azureLogin();
  }, []);

  if (!isSsoShown || location.search.includes("email=true")) {
    return (
      <LoginPage
        onSubmit={onSubmitEmail}
        errorMessage={errorMessage}
        isSsoShown={false}
      />
    );
  }
  return <LoginPageSSO onSubmit={onSubmitSSO} />;
};

export default LoginPageContainer;
