import axios from "axios";

export interface PostcodeLookupAddress {
  line1: string;
  line2?: string;
  city: string;
  postcode: string;
  country: string;
}

export interface BookInitAppointment {
  locationId: string;
  serviceId: number;
  resourceId: number;
  startDateTime: Date;
  endDateTime: Date;
}

type AddressesByPostcodeResponse = {
  postcode: string;
  addresses: PostcodeLookupAddress[];
};

export async function getAddressByPostcodeAPI(
  postcode: string,
): Promise<PostcodeLookupAddress[]> {
  try {
    const { data } = await axios.get<AddressesByPostcodeResponse>(
      `${process.env.REACT_APP_POSTCODE_API}/postcodes/${postcode}/addresses`,
    );
    return data.addresses;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getProvinceByLocationAPI(
  locationId: string,
): Promise<any> {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_POSTCODE_API}/services/locations/${locationId}`,
    );
    return data.address.province;
  } catch (e) {
    console.error(e);
    return "";
  }
}

export async function initBookingAPI(
  booksInitial: BookInitAppointment[],

): Promise<any> {
  const { data } = await axios.post(`${process.env.REACT_APP_POSTCODE_API}/services/initialappointment`, booksInitial);
  return data;
}
