import React, { useMemo } from "react";
import {
  Button,
  PeriodTypes,
  SelectField,
  InputField,
  TimeRangeField,
} from "@welldigital/components";
import { makeStyles } from "@material-ui/core";
import WeekdaysField from "./CustomPeriodField/CustomPeriodField";

export type AvailabilityItemProps = {
  disabled?: boolean;
  baseName: string;
  onDelete: () => void;
  minTime: Date;
  maxTime: Date;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  primary: {
    display: "flex",
  },
  primaryType: {
    flex: 1,
  },
  primaryRange: {
    marginLeft: theme.spacing(2),
    "& *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  secondary: {
    margin: theme.spacing(1, 0),
    display: "flex",
    alignItems: "flex-start",
  },
  secondaryDays: {
    flex: 1,
  },
  secondaryAction: {
    flex: "0 0 auto",
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
}));

const AvailabilityItem: React.FC<AvailabilityItemProps> = ({
  baseName,
  disabled,
  onDelete,
  minTime,
  maxTime,
}) => {
  const classes = useStyles();
  const typeOptions = useMemo(
    () => [
      { label: "Custom", value: PeriodTypes.allWeek },
      { label: "Weekdays", value: PeriodTypes.weekdays },
      { label: "Weekends", value: PeriodTypes.weekends },
    ],
    [],
  );

  return (
    <div className={classes.root}>
      <div className={classes.primary}>
        <div className={classes.primaryType}>
          <InputField name={`${baseName}.blockId`} type={"hidden"} />
          <SelectField
            name={`${baseName}.periodType`}
            options={typeOptions}
            disabled={disabled}
            required
            spacingAfter={0}
            fullWidth
          />
        </div>
        <div className={classes.primaryRange}>
          <TimeRangeField
            name={`${baseName}.period`}
            hasAllDay
            disabled={disabled}
            spacingAfter={0}
            minTime={minTime}
            maxTime={maxTime}
          />
        </div>
      </div>
      <div className={classes.secondary}>
        <div className={classes.secondaryDays}>
          <WeekdaysField
            name={`${baseName}.weekdays`}
            disabled={disabled}
            periodTypeName={`${baseName}.periodType`}
          />
        </div>
        <div className={classes.secondaryAction}>
          <Button
            color={"primary"}
            variant={"text"}
            size={"small"}
            onClick={onDelete}
            disabled={disabled}
            minWidth={"60px"}
            children={"Delete"}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailabilityItem;
