import Amplify, { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import axios from "axios";
import { BOOKWELL_USER_GROUP } from "../constants/index";

let user = {};

Amplify.configure({
  Auth: {
    region: "eu-west-2",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_USER_POOL_DOMAIN,
      scope: ["profile", "openid", "email", "aws.cognito.signin.user.admin"],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "token",
    },
  },
});

export async function signIn(email: string): Promise<CognitoUser | any> {
  user = await Auth.signIn(email);
  return user;
}

export async function azureLogin(): Promise<CognitoUser | any> {
  try {
    user = await Auth.federatedSignIn({
      customProvider: "WellAzureAD",
    });
    return user;
  } catch (error) {
    console.log("error signing in", error);
  }
}

export async function answerCustomChallenge(answer: string): Promise<boolean> {
  try {
    await Auth.sendCustomChallengeAnswer(user, answer);
    return true;
  } catch (err) {
    console.log("error with code: ", err);
    return false;
  }
}

export async function isAuthenticated(): Promise<boolean> {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.isValid();
  } catch {
    return false;
  }
}

export async function signOut(): Promise<any> {
  return await Auth.signOut();
}

export async function getUserInfo(): Promise<CognitoUserInfo> {
  return await Auth.currentUserInfo();
}

export async function getLocationId(): Promise<string> {
  try {
    const session = await Auth.currentSession();
    const payload = session.getIdToken().decodePayload();

    return payload["custom:locationId"];
  } catch {
    return "";
  }
}

export async function getBookwellGroups(): Promise<BOOKWELL_USER_GROUP[]> {
  try {
    const userInfo = await Auth.currentUserInfo();
    const bookwellUserGroup = await axios.get(`${process.env.REACT_APP_USER_AUTHENTICATION_URL}/users/v3/${userInfo.attributes.sub}/usergroups`)
    return bookwellUserGroup.data.userGroups;
  } catch {
    return [];
  }
}

export async function getGroups(): Promise<string> {
  try {
    const session = await Auth.currentSession();
    const payload = session.getIdToken().decodePayload();

    return payload["groups"];
  } catch {
    return "";
  }
}

export interface CognitoUserInfo {
  username: string;
  attributes: {
    "custom:locationId": string;
    email: string;
    name: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string; // cognito userID
  };
}
