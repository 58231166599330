import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setOrderNoteAsync } from "../../reduxSlice/actionsAsync";
import { Typography } from "@welldigital/components";
import { TextField, Button } from "@material-ui/core";

import { OrderNotes as OrderNoteType } from "../../Types";
import { selectLoggedInUserInfo } from "../../reduxSlice/selector";
import { formatDate } from "utils/time";

const OrderNotes = ({
  notes,
  orderId,
}: {
  notes: OrderNoteType[];
  orderId: Number;
}) => {
  const dispatch = useAppDispatch();
  const [noteText, setOrderNote] = useState<String>("");
  const [showMore, setShowMore] = useState(false);
  let [errorText, setNoteError] = useState("");
  let [buttonDisable, setButtonDisable] = useState(true);
  const logInUser = useAppSelector(selectLoggedInUserInfo);

  function onChangeNoteText(val: String) {
    // console.log('val',val)
    if (val.trim().length < 2) {
      setButtonDisable(true);
      setNoteError("Enter valid note you want to add");
    } else {
      setButtonDisable(false);
      setNoteError("");
    }
    setOrderNote(val);
  }

  function handleAddNote() {
    const createdBy = logInUser?.attributes?.name;
    logInUser &&
      createdBy &&
      orderId &&
      dispatch(setOrderNoteAsync({ orderId, noteText, createdBy }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setOrderNote("");
  }

  const onShowMoreToggle = () => setShowMore((showMore) => !showMore);

  return (
    <div className="order_notes">
      <Typography
        variant="subtitle1"
        spacingAfter={0}
        className="order_notes_title"
      >
        Order notes
      </Typography>
      {notes
        ?.slice(0, showMore ? notes?.length : 2)
        .map((note: OrderNoteType) => (
          <>
            <Typography
              variant="subtitle2"
              spacingAfter={0}
              className="order_notes_item note_title"
            >
              <b className="text-lower">
                {formatDate(note.createAt, "dd/MM/yy HH:mm")} {note.createdBy}
              </b>
            </Typography>
            <Typography
              variant="caption"
              spacingAfter={2}
              className="order_notes_item"
            >
              {note.noteText}
            </Typography>
            <div className="hr"></div>
          </>
        ))}
      {notes?.length > 2 && (
        <>
          <Button
            onClick={onShowMoreToggle}
            className="show-more"
            variant="text"
          >
            Show {showMore ? "less" : `more (${notes.length - 2})`}
          </Button>
        </>
      )}
      <Typography variant="body1" className="add_note_title">
        Add a note
      </Typography>
      <TextField
        id="notes"
        value={noteText}
        variant="outlined"
        className="add_note_textField"
        onChange={(e) => onChangeNoteText(e.target.value)}
        error={errorText !== "" ? true : false}
        helperText={errorText}
      />
      <div className="add_note_buttonContainer">
        <Button
          type={"submit"}
          size={"medium"}
          disabled={buttonDisable}
          onClick={handleAddNote}
          className="button-primary"
        >
          Save note
        </Button>
      </div>
    </div>
  );
};

export default OrderNotes;
