import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Divider, Typography } from "@welldigital/components";
import { ApolloError } from "@apollo/client";
import React, { FC } from "react";

import { Question } from "./components";
import { ServiceQuestions_getQuestions } from "./__generated__/ServiceQuestions_getQuestions";

export interface ServiceQuestionsProps {
  isLoading?: boolean;
  error?: ApolloError;
  data?: ServiceQuestions_getQuestions;
}

const ServiceQuestions: FC<ServiceQuestionsProps> = (props) => {
  if (props.isLoading) return <p>Loading...</p>;
  if (props.error) return <p>Error: {props.error.message}</p>;
  if (!props.data?.getAppointment) return <p>no questions</p>;

  const questions = props.data.getAppointment.course.questions;
  const hasQuestions = questions.length > 0;

  if (hasQuestions) {
    return (
      <>
        <Divider spacingBefore={1} spacingAfter={2} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant={"h4"} spacingAfter={0}>
                    Pre-screen questions
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                {/* {updatedQuestions && updatedQuestions.length > 0 && (
                  <TableCell>Updated Answer</TableCell>
                )} */}
              </TableRow>
            </TableHead>

            <TableBody>
              {questions.map((q) => {
                return <Question key={`question-${q.number}`} question={q} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return null;
};

export default ServiceQuestions;
