import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../app/routers/LoggedInRoutes";
import { GuardianDetails } from "../../persister/types";
import { useAppointmentPersister } from "../../persister/useAppointmentPersister";
import { GuardianInput } from "../../__generated__/globalTypes";
import StartScreening, { StartScreeningProps } from "./StartScreening";
import GenericErrorMessage from "../Error/GenericErrorMessage";
import { useLocation } from "./hooks/useLocation";

function ScreeningWrapper() {
  const { appointment } = useAppointmentPersister();
  const history = useHistory();
  /*
    currently backend expects guardian however some users over 18 won't need a guardian
    so instead we default to empty strings
  */
  const guardianEmptyInput: GuardianInput = {
    firstName: "",
    lastName: "",
    title: "",
  };
  const customerDetails = appointment!.formData.customerDetails;
  const guardian: GuardianDetails | undefined = appointment!.formData
    .guardianDetails;

  const hasGuardian = typeof guardian !== "undefined";
  const guardianInput: GuardianInput = hasGuardian
    ? {
        firstName: guardian!.firstName,
        lastName: guardian!.lastName,
        title: guardian!.title,
      }
    : guardianEmptyInput;

  // we skip getting the location's email if this has been filled in from the form
  const shouldSkip: boolean = hasGuardian
    ? guardian!.email.length > 0
    : customerDetails.email.length > 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { loading, locationId, location, error } = useLocation(shouldSkip);

  useEffect(() => {
    if (!appointment) {
      history.push(ROUTES.walkIn.bookAppointment);
    }
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!locationId || error || (!location && !shouldSkip)) {
    console.error(error);
    return <GenericErrorMessage />;
  } else {
    // we don't expect everyone to have an email address so we fallback on to the store email
    const email: string =
      shouldSkip === true
        ? hasGuardian
          ? guardian!.email
          : customerDetails.email
        : location!.email ?? "";

    const props: StartScreeningProps = {
      location: {
        id: locationId ?? "",
      },
      service: {
        id: parseInt(appointment!.serviceId ?? "0", 10),
        flow: "test",
      },
      customerDetails: {
        ...customerDetails,
        email,
        phone: hasGuardian ? guardian!.phone : customerDetails.phone,
      },
      guardian: guardianInput,
    };
    return <StartScreening {...props} />;
  }
}

export default ScreeningWrapper;
