import React, { FC } from "react";
import { useQuery } from "@apollo/client/react";

import { GET_PREVIOUS_APPOINTMENTS } from "../../../../graphql/getPreviousAppointments";
import { getPreviousAppointments } from "../../../../graphql/__generated__/getPreviousAppointments";
import PreviousAppointments from "./PreviousAppointments";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

interface PreviousAppointmentsProps {
  appointmentId: string;
}

const PreviousAppointmentsContainer: FC<PreviousAppointmentsProps> = ({
  appointmentId,
}) => {
  const locationId = useAppSelector(selectLocationId);
  const { data } = useQuery<getPreviousAppointments>(
    GET_PREVIOUS_APPOINTMENTS,
    {
      variables: { appointmentId, locationId },
    },
  );

  if (data?.getPreviousAppointments) {
    return (
      <PreviousAppointments
        appointments={data.getPreviousAppointments?.slice()?.sort(
          (a, b) => a.number - b.number,
        )}
      />
    );
  }

  return null;
};

export default PreviousAppointmentsContainer;
