import React, { FC, memo } from "react";
import { makeStyles, Theme } from "@material-ui/core";

export interface IndentProps {
  className?: string;
  height?: string;
  size?: "small" | "medium";
  color?: "light" | "dark";
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
  },
  indent: {
    width: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  light: {
    background: theme.palette.grey[300],
  },
  dark: {
    background: theme.palette.grey[400],
  },
  small: {
    width: theme.spacing(0.5),
  },
  medium: {
    width: theme.spacing(1),
  },
  childContainer: {
    width: "100%",
  },
}));

export const Indent: FC<IndentProps> = memo(
  ({ children, className, height, size = "small", color = "dark" }) => {
    const classes = useStyles();
    return (
      <div className={`${classes.container} ${className}`}>
        <div
          className={`${classes.indent} ${classes[size]} ${classes[color]}`}
          style={{ height }}
        />
        {children && <div className={classes.childContainer}>{children}</div>}
      </div>
    );
  },
);
