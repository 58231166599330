import React, { FC } from "react";
import { Select, Spacing, Typography } from "@welldigital/components";
import { useServices } from "../../../../components/AvailabilityModal/IndividualServices/IndividualServices.hooks";
import { useAppointmentPersister } from "persister/useAppointmentPersister";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";

export type SelectAServiceProps = { ignoreCovid: boolean };

const SelectAService: FC<SelectAServiceProps> = (props) => {
  const { isLoading, error, services } = useServices(props.ignoreCovid);
  const { appointment, setServiceId } = useAppointmentPersister();

  const options: { label: string; value: number }[] = [];
  services?.forEach((service) => {
      options.push({
        label: service.serviceName,
        value: parseInt(service.serviceId),
      });
      
  });

  return error ? (
    <ErrorAlert message={"There was an error fetching available services."} />
  ) : (
    <>
      <Typography variant={"body2"}>Select a service</Typography>
      <Spacing spacing={1} responsive />
      <Select
        label={!appointment?.serviceId ? "Select a service" : undefined}
        value={appointment?.serviceId}
        required
        options={options}
        onChange={setServiceId}
        fullWidth
        disabled={isLoading}
      /> 
      <Spacing spacing={3} responsive />
    </>
  );
};

export default SelectAService;
