import React, {useEffect, useState} from "react";
import axios from "axios";
import { Grid, Box } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { formatDate, workOutAge } from "utils/time";
import { formatAddressLine } from "utils/address";
import capitalize from "lodash/capitalize";
export const API_HOST = process.env.REACT_APP_INTEG_API;

export interface CustomerDetailsType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  sex: string;
  dob: string;
  phone: string;
  nhsNumber: string;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    postcode: string;
  };
}

export interface CustomerDetailsProps {
  customer: CustomerDetailsType;
  id?: string,
  serviceId?: string
}

const CustomerDetails: React.FC<CustomerDetailsProps> = (props) => {

  const [gender, setGender] = useState("");
  useEffect(() => {
    async function getGenderFromTraversalApi(
    ): Promise<any> {
      try{
        let payload = {"appointment_id":props.id,"genderValue":"gender"}
        const response  = await axios.post<any>(
          `${API_HOST}traversals/detailedQuestionsFormBuilder`, payload
        );
      if (response.status === 200) {
        if(response?.data.length> 0)
        setGender(response?.data[1]);
      }else {
      }
    }catch(error){
    }
    }
    if(props.serviceId=== process.env.REACT_APP_WEGOVY_SERVICES)
      getGenderFromTraversalApi();
  }, [props.id, props.serviceId])
  return (
    <Box ml={3} mr={3} pt={3} pb={3}>
      <Typography variant={"body2"}>Customer details</Typography>
      <Typography spacingAfter={1}>
        {capitalize(props.customer.firstName)}{" "}
        {capitalize(props.customer.lastName)}
      </Typography>
      <Grid container>
        {<><Grid item xs={5}>
          <Typography variant={"body2"}>Date of birth</Typography>
          <Typography spacingAfter={1}>
            {formatDate(props.customer.dob)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"body2"}>Age</Typography>
          <Typography spacingAfter={1}>
            {workOutAge(props.customer.dob)} Yr
          </Typography>
        </Grid></>}
        <Grid item xs={4}>
          <Typography variant={"body2"}>Sex</Typography>
          <Typography spacingAfter={1}>
            {props?.serviceId=== process.env.REACT_APP_WEGOVY_SERVICES? 
            gender: capitalize(props.customer.sex)
          }
          </Typography>
        </Grid>
      </Grid>
      {props.customer.nhsNumber && (
        <>
          <Typography variant={"body2"}>NHS Number</Typography>
          <Typography spacingAfter={1}>{props.customer.nhsNumber}</Typography>
        </>
      )}
      {props.customer.phone && (
        <>
          <Typography variant={"body2"}>Phone</Typography>
          <Typography spacingAfter={1}>{props.customer.phone}</Typography>
        </>
      )}
      {props.customer.email && (
        <>
          <Typography variant={"body2"}>Email</Typography>
          <Typography spacingAfter={1}>{props.customer.email}</Typography>
        </>
      )}
      <Typography variant={"body2"}>Address</Typography>
      <Typography>{formatAddressLine(props.customer.address.line1)}</Typography>
      {props.customer.address.line2 !== "" && (
        <Typography>
          {formatAddressLine(props.customer.address.line2)}
        </Typography>
      )}
      <Typography>{formatAddressLine(props.customer.address.city)}</Typography>
      <Typography>{props.customer.address.postcode.toUpperCase()}</Typography>
      {/* <Button
        variant={"outlined"}
        color={"primary"}
        fullWidth
      >
        Edit customer details
      </Button> */}
    </Box>
  );
};

export default CustomerDetails;
