import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";

import "./index.scss";
import UpdateStatus from "../UpdateStatus/UpdateStatus";
import UpdateOrder from "../UpdateOrder/UpdateOrder";
import { OrderDetails as OrderDetailsTypes } from "pages/ClinicalReviewPage/Types";
import { formatDate } from "utils/time";

interface IdCheckProps{
  orderDetails :  OrderDetailsTypes;
  onUpdateSuccess : ()=>void;
  isUpdateOrderModalOpen : boolean;
}
 const IdCheckVerification = ({orderDetails,onUpdateSuccess,isUpdateOrderModalOpen} :IdCheckProps) => {
 
  const idCheckStatus=orderDetails?.idCheckStatus || "";

  return (
    <div className="id-check">
      <Grid container>
          <Typography variant="h3">
            ID check status: {idCheckStatus === "" ? `API failure` : idCheckStatus === "pass" ? `ID verified` : idCheckStatus.toUpperCase()}
          </Typography>
         
         {orderDetails?.orderStatus?.toLowerCase() === "Manual Verification Required".toLowerCase() ? 
         <>
         <div className={idCheckStatus?.toLowerCase() === "pass"?"hide-update status-update":"status-update"}>
           {idCheckStatus !== "" && <UpdateOrder idCheckStatus={idCheckStatus} onUpdateSuccess={onUpdateSuccess} orderId={parseInt(orderDetails.orderId)} isUpdateOrderModalOpen={isUpdateOrderModalOpen}/>}
            <UpdateStatus customerId={orderDetails.customerId} idCheckStatus={idCheckStatus} onUpdateSuccess={onUpdateSuccess}/>
         </div>
         </>:''}
      </Grid>
      {idCheckStatus !== "" && orderDetails.lnStatusUpdateAt && orderDetails.lnStatusUpdatedBy && orderDetails.idCheckPreviousStatus &&
         <div className="update-info-idchk">
            <p className="last-id-check">Updated {orderDetails.lnStatusUpdateAt ? formatDate(orderDetails.lnStatusUpdateAt, "dd/MM/yy") : ""}, {orderDetails.lnStatusUpdatedBy}. Previous status: {orderDetails.idCheckPreviousStatus}
            </p>
            <p className="id-check-ref"> Notes: {orderDetails.lnSearchRef}</p>
          </div> }
    </div>
  );
};

export default IdCheckVerification;

