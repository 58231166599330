import React from "react";
import { Theme, makeStyles, Grid } from "@material-ui/core";
import { Spacing, Typography } from "@welldigital/components";
import { Appointment } from "graphql/types";
import { Indent } from "components/Indent/Indent";
import CaptionedTextField from "components/CaptionedTextField/CaptionedTextField";
import { formatDate, formatPreviousAppointmentDateTime } from "utils/time";
import GpLetterPdf from "../GpLetterPdf";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    "&:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  padding: {
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(3),
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export interface CompletedAppointmentCardProps {
  appointment: Appointment;
  medicineView?: boolean;
}

const CompletedAppointmentCard: React.FC<CompletedAppointmentCardProps> = ({
  appointment,
  medicineView: onlyMedicine = false,
}) => {
  const classes = useStyles();
  const isFlu =
    appointment.course?.onSchedServiceId === process.env.REACT_APP_FLU;
  const locationId = useAppSelector(selectLocationId);
  return (
    <Indent size={"medium"} color={"light"} className={classes.container}>
      <div className={classes.padding}>
        <div className={classes.title}>
          <Typography
            variant={"h5"}
            gutterBottom
            data-testid={"CompletedAppointmentCard/appointment-index"}
          >
            Appointment {appointment.number}
          </Typography>
          <Typography
            variant={"caption"}
            color={"textSecondary"}
            data-testid={"CompletedAppointmentCard/previous-appointment-time"}
          >
            {formatPreviousAppointmentDateTime(appointment.startTime)}
          </Typography>
        </div>
        {!onlyMedicine && appointment.notes && (
          <div>
            <Spacing spacing={2} />
            <Typography variant={"h5"} gutterBottom>
              Pharmacist notes
            </Typography>
            <Typography
              data-testid={
                "CompletedAppointmentCard/previous-appointment-notes"
              }
              paragraph
            >
              {appointment.notes}
            </Typography>
          </div>
        )}
        <Grid container>
          {appointment?.guardian && appointment?.guardian?.lastName && (
            <>
              <Grid item xs={4}>
                <CaptionedTextField
                  caption={"Guardian name"}
                  value={`${appointment.guardian?.title} ${appointment.guardian?.firstName} ${appointment.guardian?.lastName}`}
                />
              </Grid>
              <Grid item xs={8}></Grid>
            </>
          )}

          <Grid item xs={4}>
            <CaptionedTextField
              caption={"Manufacturer"}
              value={appointment.medicine?.manufacturer || "None"}
            />
          </Grid>
          <Grid item xs={4}>
            <CaptionedTextField
              caption={"Administration site"}
              value={appointment.medicine?.method || "None"}
            />
          </Grid>
          <Grid item xs={4}>
            <CaptionedTextField
              caption={"Expiry date"}
              value={
                appointment.medicine?.expiryDate
                  ? formatDate(appointment.medicine?.expiryDate)
                  : "None"
              }
            />
          </Grid>
        </Grid>
        {!isFlu && (
          <div>
            <Spacing spacing={2} />
            <GpLetterPdf appointmentId={appointment.id} locationId={locationId} />
          </div>
        )}
      </div>
    </Indent>
  );
};

export default CompletedAppointmentCard;
