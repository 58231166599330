import React, { FC } from "react";
import { TableRow, TableCell } from "@material-ui/core";

export interface props {
  question: QuestionProps
}
export interface QuestionProps {
  traversal_id: string,
  identifier: string,
  label: string,
  traversal_response: {
    options: {
      checked: boolean,
      label: string
    }[]
  }
}
export interface option {
  checked: boolean,
  label: string
}

export const Question: FC<props> = ({ question }) => (
  <TableRow>
    <TableCell data-testid={"question"}>{question.label}</TableCell>
    {
      question.traversal_response.options.map((option: option) => {
        return option.checked === true && <TableCell data-testid={"answer"}>{option.label}</TableCell>
      })
    }
  </TableRow>
);
