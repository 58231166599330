import React, { useEffect, useRef, useState } from "react";
import { useForm, OnSubmit } from "react-hook-form";
import {
  Grid,
  MenuItem,
  TextField,
  Select,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import { ReactComponent as IcArrowDown } from "assets/icons/arrow-down.svg";
import "./index.scss";
import { Customer, CustomerBC, OrderDetails } from "pages/ClinicalReviewPage/Types";
import HookFormSelect from "./HookFormSelect";
import DateOfBirth from "./DateOfBirth";
import { customerSchema, customerSchemaWithoutGP } from "utils/validations";
import { workOutAge } from "utils/time";
import { updateCustomerDetails, updateCustomerAddressDetails } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import { useAppDispatch } from "redux/hooks";
import { getCustomerDetailsAsync } from "pages/ClinicalReviewPage/reduxSlice/actionsAsync";
import { useCurrentUserEmail } from "services/auth-hooks";

interface EditCustomerDetailsProps {
  customerId: number;
  customerDetails: Customer;
  onUpdateSuccess?: () => void;
  onClose?: (visible: boolean) => void;
  orderDetails?: OrderDetails | null;
}

const EditCustomerDetails = ({
  customerDetails,
  customerId,
  onUpdateSuccess,
  onClose,
  orderDetails,
}: EditCustomerDetailsProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const dialogRef = useRef<CustomDialogRef>(null);
  const dispatch = useAppDispatch();
  const [dob, setDOB] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const email = useCurrentUserEmail();
  useEffect(() => {
    dialogRef.current?.show();
  }, []);

  useEffect(() => {
    setDOB(customerDetails.dateOfBirth);
  }, [setDOB, customerDetails.dateOfBirth]);
  let customerDetailsUpdated = customerDetails;

  const customerBillingAddress = orderDetails?.customerBillingAddress;
  if (customerBillingAddress?.postalCode !== "") {
    customerDetailsUpdated = {
      ...customerDetails,
      addressDetails: {
        address1: customerBillingAddress?.address1 || customerDetails.addressDetails?.address1 || "",
        address2: customerBillingAddress?.address2 || customerDetails.addressDetails?.address2 || "",
        addressType: customerDetails.addressDetails?.addressType || "",
        city: customerBillingAddress?.city || customerDetails.addressDetails?.city || "",
        postalCode: customerBillingAddress?.postalCode || customerDetails.addressDetails?.postalCode || "",
        counrtyCode: customerBillingAddress?.countryIso2 || customerDetails.addressDetails?.counrtyCode || "",
        stateOrProvince: customerBillingAddress?.state || customerDetails.addressDetails?.stateOrProvince || "",
        addressID: customerDetails.addressDetails?.addressID || -1
      }
    }
  }
  const {
    register,
    handleSubmit,
    clearError,
    control,
    getValues,
    triggerValidation,
    errors,
    formState: { isValid },
  } = useForm<CustomerForm>({
    defaultValues: toCustomerForm(customerDetailsUpdated),
    validationSchema: !customerDetailsUpdated.gpDetails?.gpAddress1
      ? customerSchemaWithoutGP
      : customerSchema,
    mode: "onChange",
  });

  useEffect(() => {
    if (!getValues().dateOfBirth || !dob) {
      return;
    }

    triggerValidation("dateOfBirth");
  }, [dob, getValues, triggerValidation]);



  const onSubmit: OnSubmit<CustomerForm> = async (customerForm) => {
    setLoading(true);
    setError(false);
    const customer = fromCustomerForm(customerDetailsUpdated, customerForm);
    try {
      if (customerBillingAddress?.postalCode !== "") {
        const updatedBy = email || "Test";

        const payload: CustomerBC = {
          firstName: customer.firstName,
          lastName: customer.lastName,
          street1: customer?.addressDetails?.address1 || "",
          street2: customer?.addressDetails?.address2,
          city: customer?.addressDetails?.city || "",
          state: customer?.addressDetails?.stateOrProvince || "",
          zip: customer?.addressDetails?.postalCode || "",
          country: customerBillingAddress?.country || "",
          countryIso2: customer?.addressDetails?.counrtyCode || "",
          phone: customer.phone,
          addressType: customer?.addressDetails?.addressType || "",
          updatedBy: updatedBy,
          orderId: orderDetails?.orderId ? parseInt(orderDetails?.orderId) : 0,
          company: ""
        }
        await updateCustomerAddressDetails(customerId, payload);
        await updateCustomerDetails(customerId, customer, true);
      } else {
        await updateCustomerDetails(customerId, customer, false);
      }

      dispatch(getCustomerDetailsAsync({ customerId }));
      dialogRef.current?.hide();
      onUpdateSuccess?.();
      onClose?.(false);
    } catch (error) {
      setError(true);
      console.log("Error updating customer details", error);
    } finally {
      setLoading(false);
    }
  };

  const initializeForm = () => {
    const customerForm = toCustomerForm(customerDetailsUpdated);
    const keys = Object.keys(customerForm) as (keyof CustomerForm)[];
    clearError(keys);
  };

  return (
    <CustomDialog
      onOpen={initializeForm}
      onClose={() => onClose?.(false)}
      ref={dialogRef}
      title={
        <>
          Edit patient details
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
        </>
      }
      className="edit-customer-dialog"
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="edit-customer-container">
          <Grid item md={12}>
            <Typography className="font-weight-700 color-primary-hale mb-10">
              Name
            </Typography>
            <Grid md={12} className="row">
              <Grid item md={6}>
                <TextField
                  className="input"
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  className="input"
                  id="last-name"
                  fullWidth
                  variant="outlined"
                  name="lastName"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid md={12} className="row">
            <Grid item md={6}>
              <Typography className="font-weight-700 color-primary-hale mb-10">
                Date of Birth
              </Typography>
              <Grid md={12} className="row col-gap-12">
                <DateOfBirth
                  dob={dob}
                  onDOBChange={setDOB}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth?.message}
                />
                <TextField
                  className="hidden"
                  name="dateOfBirth"
                  value={dob}
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography className="font-weight-700 color-primary-hale mb-10">
                Sex
              </Typography>
              <HookFormSelect
                control={control}
                defaultValue={control.getValues().sex}
                name="sex"
              >
                <Select
                  IconComponent={() => (
                    <IcArrowDown className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  className="input"
                  variant="outlined"
                  value={control.getValues().sex}
                  error={!!errors.sex}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </HookFormSelect>
            </Grid>
          </Grid>
          <Grid item md={12} className="row">
            <Grid item md={6}>
              <Typography className="font-weight-700 color-primary-hale mb-10">
                Phone
              </Typography>
              <TextField
                className="input"
                fullWidth
                variant="outlined"
                name="phone"
                error={!!errors.phone}
                helperText={errors.phone?.message}
                inputRef={register}
              />
            </Grid>
            <Grid item md={6}>
              {/* This is to fix spacing */}
            </Grid>
          </Grid>
          <Grid item md={12} className="row">
            <Grid item md={6}>
              <Typography className="font-weight-700 color-primary-hale mb-10">
                Email
              </Typography>
              <TextField
                className="input"
                fullWidth
                variant="outlined"
                name="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                inputRef={register}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              {/* This is to fix spacing */}
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Typography className="font-weight-700 color-primary-hale mb-10">
              Address
            </Typography>
            <TextField
              className="input"
              fullWidth
              variant="outlined"
              name="postalCode"
              error={!!errors.postalCode}
              helperText={errors.postalCode?.message}
              inputRef={register}
            />
            <TextField
              className="input"
              fullWidth
              variant="outlined"
              name="address1"
              error={!!errors.address1}
              helperText={errors.address1?.message}
              inputRef={register}
            />
            <TextField
              className="input"
              fullWidth
              variant="outlined"
              name="address2"
              error={!!errors.address2}
              helperText={errors.address2?.message}
              inputRef={register}
            />
            <TextField
              className="input"
              fullWidth
              variant="outlined"
              name="city"
              error={!!errors.city}
              helperText={errors.city?.message}
              inputRef={register}
            />
          </Grid>
          {!!customerDetailsUpdated.gpDetails?.gpAddress1 && (
            <>
              <Grid item md={12}>
                <Typography className="font-weight-700 color-primary-hale mb-10">
                  GP Surgery
                </Typography>
                <TextField
                  className="input"
                  fullWidth
                  variant="outlined"
                  name="gpAddress1"
                  error={!!errors.gpAddress1}
                  helperText={errors.gpAddress1?.message}
                  inputRef={register}
                />
                <TextField
                  className="input"
                  fullWidth
                  variant="outlined"
                  name="gpAddress2"
                  error={!!errors.gpAddress2}
                  helperText={errors.gpAddress2?.message}
                  inputRef={register}
                />
                <TextField
                  className="input"
                  fullWidth
                  variant="outlined"
                  name="gpCity"
                  error={!!errors.gpCity}
                  helperText={errors.gpCity?.message}
                  inputRef={register}
                />
              </Grid>
            </>
          )}
        </Grid>
        {error && (
          <Typography variant="subtitle2" className="color-error">
            Error updating patient details
          </Typography>
        )}
        <div className="buttons-container">
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => dialogRef.current?.hide()}
          >
            Cancel
          </Button>
          <Button disabled={!isValid || loading} type="submit">
            Save changes
          </Button>
        </div>
      </form>
    </CustomDialog>
  );
};

export default EditCustomerDetails;

export interface CustomerForm {
  firstName: string;
  lastName: string;
  phone: string;
  sex: string;
  email: string;
  dateOfBirth: string;
  addressID: number;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  gpAddress1: string;
  gpAddress2: string;
  gpCity: string;
}

const toCustomerForm = ({
  firstName,
  lastName,
  dateOfBirth = "",
  phone,
  email,
  sex,
  addressDetails: { address1, address2, addressID, city, postalCode },
  gpDetails: { gpAddress1, gpAddress2, gpCity },
}: Customer): CustomerForm => ({
  firstName,
  lastName,
  phone,
  email,
  sex,
  dateOfBirth,
  address1,
  address2,
  addressID,
  city,
  postalCode,
  gpAddress1,
  gpAddress2,
  gpCity,
});

const fromCustomerForm = (
  customer: Customer,
  {
    address1,
    address2,
    addressID,
    city,
    dateOfBirth,
    firstName,
    lastName,
    gpAddress1,
    gpAddress2,
    gpCity,
    phone,
    postalCode,
    sex,
  }: CustomerForm,
): Customer => ({
  ...customer,
  firstName,
  lastName,
  dateOfBirth,
  phone,
  sex,
  age: Number(workOutAge(dateOfBirth)),
  addressDetails: {
    ...customer.addressDetails,
    address1,
    address2,
    addressID,
    city,
    postalCode,
  },
  gpDetails: {
    ...customer.gpDetails,
    gpAddress1,
    gpAddress2,
    gpCity,
  },
});