import React, { FC, useState, useMemo } from "react";
import {
  IRadioOption,
  RadioSelectField,
  Typography,
} from "@welldigital/components";
import { isUnder18 } from "../../utils";
import { Box } from "@material-ui/core";
import { PaymentType } from "__generated__/globalTypes";
import { useLocation } from "../../../../components/Screening/hooks/useLocation";
import { getProvinceByLocationAPI } from "../AddressFields/api.fetchers";
import { ServiceIds, Service } from '../../../../constants/service.constants';

export type FluPaymentFieldsProps = {
  birthDate: Date;
  service: Service;
};

const FluPaymentFields: FC<FluPaymentFieldsProps> = ({ birthDate, service }) => {
  const isFluPPV = ServiceIds.FluPPV === service.id;
  const isFluPrevenar13= ServiceIds.FluPrevenar13 === service.id;
  const allPaymentOptions: IRadioOption[] = [
    {
        label: "They’re eligible for a free NHS flu vaccination",
        description: "",
        id: (isFluPPV || isFluPrevenar13) ? PaymentType.exempt+"_payment" : PaymentType.exempt,
      },
      {
        label: "They have a voucher",
        description:
          "They might have a Well Pharmacy voucher. Make sure they bring this voucher with them to their appointment.",
          id: (isFluPPV || isFluPrevenar13) ? PaymentType.voucher+"_payment" : PaymentType.voucher,
      },
    {
      label: "Paying in pharmacy",
      description: "You can take their payment at the till.",
      id: PaymentType.in_store,
    },
  ];
  const location = useLocation(true);

  const [isNhsHidden, setisNhsHidden] = useState(false);
  if (location.locationId != null) {
    getProvinceByLocationAPI(location.locationId).then(province => {
        setisNhsHidden(['Scotland', 'NorthernIreland'].includes(province));
    })
  }
  const paymentOptions: IRadioOption[] = useMemo(() => {
    const options = [...allPaymentOptions];
    if (isUnder18(birthDate)) {
      options.splice(0, 1);
    }
    if (isNhsHidden) {
      options.splice(0, 1);
    }
    return options;
  }, [birthDate, isNhsHidden, allPaymentOptions]);

  return (
    <Box mt={2}>
      <Typography variant={"h4"} spacingAfter={3}>
        {"How are they paying for their flu vaccination?"}
      </Typography>
      <RadioSelectField
        name={"paymentType"}
        options={paymentOptions}
        variant={"contained"}
        required
      />
    </Box>
  );
};

export default FluPaymentFields;
