import React, { useCallback, useEffect } from "react";
import { makeStyles, Theme, CircularProgress } from "@material-ui/core";
import { ApolloError } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../app/routers/LoggedInRoutes";
import { useIsFirstRender } from "../../../hooks/useIsFirstRender";
import {KEY_INIT_API, useBookAppointment } from "../hooks/useBookAppointment";
import { analytics } from "@welldigital/ui-common"; 
import events from "../../../utils/events";
import {
  clearScreeningQuestion,
  LOCAL_STORAGE_SCREENING_QUESTION,
  useScreeningQuestions,
} from "../hooks/useScreeningQuestions";
import { postAppointmentVariables } from "../../../graphql/__generated__/postAppointment";
import { Alert } from "@welldigital/components";
import { useAppointmentPersister } from "../../../persister/useAppointmentPersister";
import { PaymentType } from "../../../__generated__/globalTypes";
import { formatDateTimeAws } from "../../../utils/time";
import { getFromLocalStorage } from "utils/localStorageUtils";
import {traversalSavetAPI} from "./api.fetchers";

const useStyles = makeStyles((_theme: Theme) => ({
  loadingContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));

/**
 * Books an appointment using customer and screening information
 * After a successful booking it redirects to a confirmation page
 */
function BookAppointment({...props}) {
  const service = props.service;
  const traversalId = props.traversalId;
  const classes = useStyles();
  const history = useHistory();
  const { screeningQuestion } = useScreeningQuestions();
  const { appointment } = useAppointmentPersister();

  interface postProps {
    postAppointment : dataProps
  }
  interface dataProps {
    userID : string,
    appointmentID : string
  }

  const onCompleted = useCallback((data : postProps) => {
    analytics.trackEvent({
      flow: service.flow,
      event: events.screening.complete,
    });
    clearScreeningQuestion();
    if (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) {
      let traversalSavePayload ={
        traversal_id: traversalId,
        appointment_id: data?.postAppointment?.appointmentID ? data?.postAppointment?.appointmentID : ''
      };
      traversalSavetAPI(traversalSavePayload).then(() => {
        history.push(ROUTES.walkIn.confirmation);
      });
    }else {
      history.push(ROUTES.walkIn.confirmation);
    }
  }, [history, service.flow, service.id, traversalId]);

  const onError = useCallback(
    (error: ApolloError) => {
      window.localStorage.removeItem(LOCAL_STORAGE_SCREENING_QUESTION);
      analytics.trackEvent({
        flow: service.flow,
        event: events.bookAppointment.failed,
        metadata: {
          error: error.message,
        },
      });
    },
    [service.flow],
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { bookAppointment, loading, error } = useBookAppointment({
    onCompleted,
    onError,
  });

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender && !loading) {
      const { bookedSlot, formData } = appointment as any;
      console.log('helllo bookedSlot', bookedSlot);
      const variables: postAppointmentVariables = {
        input: {
          screeningID: screeningQuestion!.screeningId,
          paymentType:
            formData.paymentType || PaymentType.in_store,
          resourceID: parseInt(bookedSlot!.resourceId),
          startDate: formatDateTimeAws(bookedSlot!.startTime),
          endDate: formatDateTimeAws(bookedSlot!.endTime),
          initiator: "pharmacist",
          initialappointment: getFromLocalStorage(KEY_INIT_API),
        },
      };
      bookAppointment({ variables });
    }
  }, [appointment, bookAppointment, isFirstRender, loading, screeningQuestion]);

  if (error) {
    return <Alert type={"error"} message={error.message} />;
  }

  if (loading) {
    return (
      <div
        data-testid={"BookAppointment/loading"}
        className={classes.loadingContainer}
      >
        <CircularProgress color={"secondary"} />
      </div>
    );
  }

  return null;
}

export default BookAppointment;
