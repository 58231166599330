import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { Spacing, wellColors } from "@welldigital/components";

const PharmacyLoader = (props: any) => {
  const ref = useRef<any>();
  const [width, setWidth] = useState<number>(722);
  const height = 24;

  useEffect(() => {
    if (!ref.current) return () => {};
    const onResize = () => setWidth(ref.current.parentNode.clientWidth);
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref]);

  return (
    <div ref={ref}>
      <ContentLoader
        display={"block"}
        width={"100%"}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={wellColors.greyscale[100]}
        foregroundColor={wellColors.greyscale[400]}
        speed={1}
        {...props}
      >
        <rect
          x={"0"}
          y={height / 2 - 10}
          rx={"5"}
          ry={"5"}
          width={width - 50}
          height={"20"}
        />
      </ContentLoader>
      <Spacing spacing={3} />
    </div>
  );
};

export default PharmacyLoader;
