/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppointmentStatus {
  cancelled = "cancelled",
  completed = "completed",
  created = "created",
  in_progress = "in_progress",
}

export enum PaymentType {
  exempt = "exempt",
  in_store = "in_store",
  payment = "payment",
  voucher = "voucher",
}

export interface BookAppointmentInput {
  screeningID: string;
  paymentNonce?: string | null;
  paymentType?: string | null;
  resourceID: number;
  startDate: any;
  endDate: any;
  notes?: string | null;
  groupID?: string | null;
  initiator: string | null;
  initialappointment?: string | null | undefined;
}

export interface CalendarInput {
  id: string;
  startDate: any;
  endDate: any;
  startTime?: number | null;
  endTime?: number | null;
  reason: string;
  allDay?: boolean | null;
  repeats?: boolean | null;
  repeat?: RepeatInput | null;
}

export interface CovidAnswerInput {
  number: number;
  question: string;
  type: number;
  answer: string;
}

export interface DayInput {
  date: any;
}

export interface GuardianInput {
  title: string;
  firstName: string;
  lastName: string;
}

export interface MedicineInput {
  batchNumber: string;
  manufacturer: string;
  expiryDate: any;
  method: string;
}

export interface NextScreeningInput {
  screeningID: string;
  currentQuestionNumber: number;
  patientAnswer: string;
  nextQuestionNumber: number;
}

export interface PharmacistInput {
  title: string;
  name: string;
  gphcNumber: string;
}

export interface QuestionInput {
  answer: string;
  number: number;
  question: string;
  questionType: number;
}

export interface RepeatInput {
  frequency: string;
  interval: number;
  weekdays?: string | null;
  monthDay?: number | null;
  monthType?: string | null;
}

export interface StartScreeningInput {
  email: string;
  phone: string;
  nhsNumber?: string | null;
  surgeryName?: string | null;
  surgeryAddress?: string | null;
  dob: any;
  sex?: string | null;
  locationID: string;
  serviceID: number;
  firstNames: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string | null;
  town: string;
  county?: string | null;
  postcode: string;
  guardian?: GuardianInput | null;
  allowMarketing: boolean;
}

export interface UpdateCalendarInput {
  id: string;
  locationId: string;
  name: string;
  type: string;
  interval: number;
  bookingsPerSlot: number;
  resourceGroupId: string;
  availability?: WeekdaysInput | null;
}

export interface WeekdayInput {
  startTime?: number | null;
  endTime?: number | null;
}

export interface WeekdaysInput {
  sun?: WeekdayInput | null;
  mon?: WeekdayInput | null;
  tue?: WeekdayInput | null;
  wed?: WeekdayInput | null;
  thu?: WeekdayInput | null;
  fri?: WeekdayInput | null;
  sat?: WeekdayInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
