import React, { ChangeEvent, useRef } from "react";
import { AxiosError } from "axios";
import { Button, LinearProgress, TextField } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import "./index.scss";
import { ProductStatus } from "pages/ClinicalReviewPage/Types";
import { updateApproveOrReject } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectLoggedInUserInfo } from "pages/ClinicalReviewPage/reduxSlice/selector";
import { getOrderDetailsAsync } from "pages/ClinicalReviewPage/reduxSlice/actionsAsync";

interface AddCommentProps {
  orderId: number;
  wCount?: number;
  productId: number;
  isLastProductToReview?: boolean;
  idCheckStatus: string;
  isApprovedAllGSL: boolean;
  idCheckApiStatus: string;
  restrictedItemsApproval?: boolean;
  isApprovebuttonEnable: boolean;
}

const AddComment = ({ orderId, productId, wCount = 0, idCheckStatus, idCheckApiStatus, isApprovedAllGSL, restrictedItemsApproval, isApprovebuttonEnable }: AddCommentProps) => {
  const [orderNote, setOrderNote] = React.useState<string>("");
  const [orderNoteError, setOrderNoteError] = React.useState<string>("");
  const [approveRejectError, setApproveRejectError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const approveDialogRef = useRef<CustomDialogRef>(null);
  const rejectDialogRef = useRef<CustomDialogRef>(null);
  const errorDialogRef = useRef<CustomDialogRef>(null);
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectLoggedInUserInfo);

  const onApproveClick = () => {
    if (wCount > 0 && orderNote.trim().length < 2) {
      setOrderNoteError("Please submit a review comment");
      return;
    }
    approveDialogRef.current?.show?.();
  };
  const onRejectClick = () => {
    if (orderNote.trim().length < 2) {
      setOrderNoteError("Please submit a review comment");
      return;
    }
    rejectDialogRef.current?.show?.();
  };

  const onApproveOrReject = async (status: ProductStatus) => {
    try {
      setLoading(true);
      await updateApproveOrReject(
        orderId,
        productId,
        status,
        orderNote,
        user!.attributes.name,
      );
      postApproveRejectAction();
    } catch (error) {
      const err = error as AxiosError<{ message: string }>;

      setApproveRejectError(
        err.response?.status === 400
          ? err.response?.data?.message
          : `failed to ${status} product`,
      );
      errorDialogRef.current?.show?.();
    } finally {
      setLoading(false);
    }
  };

  const postApproveRejectAction = () => {
    dispatch(getOrderDetailsAsync(orderId));
    setApproveRejectError("");
  };

  const onOrderNoteChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOrderNote(value);
    orderNoteError && setOrderNoteError("");
  };

  return (
    <>
      <div className="crp-addcomment">
        <Typography>
          You must submit a review note before{" "}
          {wCount > 0 ? `approving or ` : ""}rejecting this item
        </Typography>
        <TextField
          value={orderNote}
          variant="outlined"
          multiline
          minRows={4}
          maxRows={4}
          className="crp-comment-textarea"
          error={!!orderNoteError}
          helperText={orderNoteError}
          onChange={onOrderNoteChange}
        />
        <div className="crp-comment-buttons">
          <Button
            variant="outlined"
            size={"medium"}
            onClick={onRejectClick}
            className="button_reject"
            disabled={idCheckApiStatus === "failure" || (idCheckStatus !== "" && idCheckStatus !== "pass" && !restrictedItemsApproval)}
          >
            Reject Item
          </Button>
          <Button disabled={idCheckApiStatus === "failure" || (idCheckStatus !== "" && idCheckStatus !== "pass" && !restrictedItemsApproval) || !isApprovebuttonEnable} onClick={onApproveClick} size={"medium"}>
            Approve Item
          </Button>
        </div>
      </div>
      <CustomDialog
        ref={rejectDialogRef}
        confirmButtonLabel="Reject item"
        disableButton={loading}
        onConfirm={() => onApproveOrReject("REJECTED")}
      >
        <>
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
          <Typography
            variant="h3"
            className="color-primary-hale font-weight-700"
            spacingAfter={3}
          >
            Are you sure you want to reject this item?
          </Typography>
          <Typography variant="body1" spacingAfter={0}>
            Please ensure you have spoken to the customer to tell them you are
            rejecting this item.
          </Typography>
        </>
      </CustomDialog>
      <CustomDialog
        ref={approveDialogRef}
        confirmButtonLabel="Approve item"
        disableButton={loading}
        onConfirm={() => onApproveOrReject("APPROVED")}
      >
        <>
          {loading && (
            <div className="mt-20 mb-20">
              <LinearProgress />
            </div>
          )}
          <Typography
            variant="h3"
            className="color-primary-hale font-weight-700"
            spacingAfter={3}
          >
            Are you sure you want to approve this item?
          </Typography>
          <Typography variant="body1" spacingAfter={0}>
            By approving this item you are confirming:
          </Typography>
          <ul className="mt-10 mb-0">
            <li>
              <Typography variant="body1" spacingAfter={1}>
                You have reviewed the completed consultation form and if
                applicable viewed any images uploaded by the customer
              </Typography>
            </li>
            <li>
              <Typography variant="body1" spacingAfter={1}>
                You have reviewed the customers details and previous order
                history
              </Typography>
            </li>
            <li>
              <Typography variant="body1" spacingAfter={1}>
                You have documented any notes from any communication with the
                customer
              </Typography>
            </li>
            <li>
              <Typography variant="body1" spacingAfter={1}>
                You are confident that the requested item is clinically
                appropriate and safe for this customer
              </Typography>
            </li>
          </ul>
        </>
      </CustomDialog>
      <CustomDialog
        ref={errorDialogRef}
        title=""
        onClose={postApproveRejectAction}
      >
        <>
          <Typography variant="body1" spacingAfter={0}>
            Message: {approveRejectError}
          </Typography>
        </>
      </CustomDialog>
    </>
  );
};

export default AddComment;
