import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function Loader({ loading, className = "" }: LoaderProps) {
  if (!loading) {
    return null;
  }

  return (
    <div
      className={`w-full row align-items-center justify-center ${className}`}
    >
      <CircularProgress />
    </div>
  );
}

interface LoaderProps {
  className?: string;
  loading?: boolean;
}
