import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import "./index.scss";
import Button from "./Button";

export interface PaginationProps {
  status?:string | undefined;
  category?:string | undefined;
  totalItems: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}
const pageNumberLimit = 10;
const Pagination: FC<PaginationProps> = ({
  status,
  category,
  totalItems = 0,
  pageSize,
  currentPage,
  onPageChange,
}) => {
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
 useEffect(()=>{
  if(status || category){
    setMaxPageLimit(10);
    setMinPageLimit(0);
  }
 },[status,category]);

  const onNextClick = useCallback(() => {
    if(currentPage+1 > maxPageLimit){
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
  }
    onPageChange(currentPage + 1);
   
  }, [onPageChange, currentPage,maxPageLimit,minPageLimit]);

  const onPrevClick = useCallback(() => {
    if((currentPage-1) % pageNumberLimit === 0){
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
  }
    onPageChange(currentPage - 1);
    
  }, [onPageChange, currentPage,maxPageLimit,minPageLimit]);

  const onPageClick = useCallback(
    (pageNumber: number) => onPageChange(pageNumber),
    [onPageChange],
  );
  const pageNumbers = useMemo(() => {
    const totalPages = Math.ceil(totalItems / pageSize);
    return new Array(totalPages).fill(0).map((_, index) => index + 1);
  }, [totalItems, pageSize]);

    let pageIncrementEllipses = null;
    if(pageNumbers.length > maxPageLimit){
        pageIncrementEllipses = <div
            onClick={onNextClick}
          >&hellip;</div>
    }
    let pageDecremenEllipses = null;
    if(minPageLimit >=1){
        pageDecremenEllipses = <div
            onClick={onPrevClick}>&hellip;</div>
    }
  return (
    <Box className="pg-container">
      <Button
        label="Prev"
        isDisabled={currentPage === 1}
        onClick={onPrevClick}
      />
      <div className="page-container">
        {pageDecremenEllipses}
        {pageNumbers.map((page) => 
          <>
          {page <= maxPageLimit  && page > minPageLimit &&<button
            key={`page-${page}`}
            type="button"
            onClick={() => onPageClick(page)}
            className={`page ${page === currentPage ? "active" : ""}`}
          >
            {page}
          </button>}
          </>
        )}
        {pageIncrementEllipses}
      </div>
      <Button
        label="Next"
        isDisabled={!pageNumbers.length || pageNumbers.length === currentPage}
        onClick={onNextClick}
      />
    </Box>
  );
};

export default Pagination;
