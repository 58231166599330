import { gql } from "@apollo/client";

export const UPDATE_APPOINTMENT_NOTES = gql`
  mutation updateAppointmentNotes($appointmentId: String!, $notes: String!, $locationId: String!) {
    updateAppointmentNotes(appointmentId: $appointmentId, notes: $notes,locationId: $locationId) {
      id
    }
  }
`;

export const PUT_COURSE_QUESTIONS = gql`
  mutation putCourseQuestions(
    $courseId: String!
    $questions: [QuestionInput!]!
  ) {
    putCourseQuestions(courseId: $courseId, questions: $questions) {
      number
    }
  }
`;

export const UPDATE_APPOINTMENT_PHARMACIST = gql`
  mutation updateAppointmentPharmacist(
    $appointmentId: String!
    $pharmacist: PharmacistInput!
    $locationId: String!
  ) {
    updateAppointmentPharmacist(
      appointmentId: $appointmentId
      pharmacist: $pharmacist
      locationId: $locationId
    ) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT_GUARDIAN = gql`
  mutation updateAppointmentGuardian(
    $appointmentId: String!
    $guardian: GuardianInput!
    $locationId: String!
  ) {
    updateAppointmentGuardian(
      appointmentId: $appointmentId
      guardian: $guardian
      locationId: $locationId
    ) {
      id
    }
  }
`;
