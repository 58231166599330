import React, { useState } from "react";
import {
  Container,
  Link,
  makeStyles,
  Theme,
  Box,
  Grid,
} from "@material-ui/core";
import { Button, Typography, Divider, Spacing, Modal, ModalActions, ModalTitle, } from "@welldigital/components";
import { Link as RouterLink } from "react-router-dom";
import CustomerDetails, {
  CustomerDetailsType,
} from "../CustomerDetails/CustomerDetails";
import GpDetails, { GpDetailsType } from "../GpDetails/GpDetails";
import { Appointment } from "graphql/types";
import { ExecutionResult } from "graphql";
import CancelAppointmentModal from "components/CancelAppointmentModal/CancelAppointmentModal";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
  rescheduleLink: {
    color: theme.palette.primary.light,
  },
}));

export interface ServiceSplitScreenProps {
  appointment: Appointment;
  isActionDisabled?: boolean;
  actionButtonText?: string;
  onActionSubmit?: () => void;
  isActionPanelHidden?: boolean;
  showWarning?: boolean;
  isActionButtonHidden?: boolean;

  onCancelAppointment?(
    shouldRefund: boolean,
    reason: string,
  ): Promise<ExecutionResult<any>>;
}

const ServiceSplitScreen: React.FC<ServiceSplitScreenProps> = ({
  appointment,
  actionButtonText,
  children,
  isActionDisabled,
  isActionPanelHidden,
  onActionSubmit,
  isActionButtonHidden,
  showWarning = false,
  onCancelAppointment,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [cancelModalOpen, setCancelModalOpen] = useState(false);


  const [openRescheduleError, setOpenRescheduleError] = React.useState(false);
  const handleRescheduleErrorOpen = () => setOpenRescheduleError(true);
  const handleRescheduleErrorClose = () => setOpenRescheduleError(false);

  return (
    <Box mt={3}>
      <Grid container>
        <Grid item md={3} className={classes.container}>
          <CustomerDetails
            customer={
              {
                id: appointment.customer?.id,
                firstName: appointment.customer?.firstName,
                lastName: appointment.customer?.lastName,
                email: appointment.customer?.email,
                sex: appointment.customer?.sex,
                phone: appointment.customer?.phone,
                nhsNumber: appointment.customer?.nhsNumber,
                address: appointment.customer?.address,
                dob: appointment.customer?.dob,
              } as CustomerDetailsType
              
            }
            id = {appointment.id}
            serviceId= {appointment?.course?.onSchedServiceId}

          />
          {appointment.customer?.surgeryName && (
            <>
              <Divider spacingBefore={2} spacingAfter={1} />
              <GpDetails
                gp={
                  {
                    surgeryName: appointment.customer?.surgeryName,
                    surgeryAddress: appointment.customer?.surgeryAddress,
                  } as GpDetailsType
                }
              />
            </>
          )}
        </Grid>
        <Grid item md={6} className={classes.container}>
          <Container maxWidth={"md"}>
            <Box p={4}>{children}</Box>
          </Container>
        </Grid>
        <Grid item md={3}>
          <Box mt={3} ml={3}>
            {!isActionButtonHidden && (
              <Button
                data-testid={"serviceSplitScreen/action-button"}
                onClick={onActionSubmit}
                disabled={isActionDisabled}
                fullWidth
              >
                {actionButtonText}
              </Button>
            )}
            {showWarning && (
              <>
                <Spacing spacing={2} />
                <div>
                  {appointment.status === "cancelled" ?
                  <>
                  <span
                    className={classes.rescheduleLink}
                    onClick={handleRescheduleErrorOpen}
                  >
                    Reschedule
                  </span>
                  <Modal maxWidth={"xs"} open={openRescheduleError} onClose={handleRescheduleErrorClose}>
                    <ModalTitle
                      label={"Cancelled Appointment"}
                      variant={"subtitle"}
                    />
                    <Typography variant={"body1"} spacingAfter={2}>
                      This appointment has already been cancelled and cannot be rescheduled. You'll need to create a new appointment.
                    </Typography>
                    <ModalActions fullWidth>
                    <Button variant={"outlined"} color={"primary"} onClick={handleRescheduleErrorClose}>
                      Close
                    </Button>
                  </ModalActions>
                  </Modal>
                  </>
                  :
                  <Link
                    className={classes.rescheduleLink}
                    data-testid={"serviceSplitScreen/reschedule-link"}
                    key={"link-reschedule"}
                    component={RouterLink}
                    to={`${appointment.id}/reschedule`}
                  >
                    Reschedule
                  </Link>
                  }
                </div>
                {onCancelAppointment && (
                  <div>
                    <Spacing spacing={2} />
                    <Link
                      className={classes.rescheduleLink}
                      data-testid={"serviceSplitScreen/cancelAppointment-link"}
                      key={"link-cancelAppointment"}
                      onClick={() => setCancelModalOpen(true)}
                    >
                      Cancel appointment
                    </Link>
                    <CancelAppointmentModal
                      cancelAppointment={onCancelAppointment}
                      closeModal={() => setCancelModalOpen(false)}
                      isModalOpen={cancelModalOpen}
                      paymentType={appointment.course?.paymentType!}
                    />
                  </div>
                )}
                <Divider spacingBefore={2} spacingAfter={2} />
                {!isActionButtonHidden && <Typography>
                  Please note: only start the appointment once the patient is
                  with you and ready to begin
                </Typography>}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSplitScreen;
