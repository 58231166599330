import React, { useEffect, useState } from "react";
import {
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { subYears } from "date-fns";
import { formatDateAws } from "utils/time";
import { selectLocationId } from "redux/reduxSlice/selector";
import { useAppSelector } from "redux/hooks";
import { fetchServiceBlock } from "./api.fetchers";

const useStyles = makeStyles((theme: Theme) => ({
  serviceBlocks: {
    "&& p": {
      padding: "0px",
      margin: "0px",
    },
  },
  serviceBlocksPause: {
    color: "red",
  },
}));

const ServiceBlockIndividualServices: React.FC = () => {
  const INFINITE_END_DATE = "9999-12-31";
  const locationId = useAppSelector(selectLocationId);
  const classes = useStyles();
  const [newServiceBlockWegovy, setNewServiceBlockWegovy] = useState([]);
  const [serviceBlockSaxenda, SetServiceBlockSaxenda] = useState([]);
  const [serviceBlockFluPPV, setServiceBlockFluPPV] = useState([]);
  const [serviceBlockServiceFluPrevnar, setServiceBlockServiceFluPrevnar] =
    useState([]);
  const [serviceBlockPcrTest, setServiceBlockPcrTest] = useState([]);
  const [serviceBlockCovidGreenPack, setServiceBlockCovidGreenPack] = useState(
    [],
  );
  const [serviceBlockChickenPox, setServiceBlockChickenPox] = useState([]);
  const [serviceBlockDataHPV, setServiceBlockDataHPV] = useState([]);
  const [serviceDataFlu, setServiceDataFlu] = useState([]);
  const [serviceBlockMenbTrumenba, setServiceBlockMenbTrumenba] = useState([]);
  const [serviceBlockMenbBexsero, setServiceBlockMenbBexsero] = useState([]);
  const [serviceBlockPnewmoniaWalkin, setServiceBlockPnewmoniaWalkin] =
    useState([]);
  const [serviceBlockPnewmoniaPrevinar, setServiceBlockPnewmoniaPrevinar] =
    useState([]);
  const [serviceBlockPnewmoniaPPV, setServiceBlockPnewmoniaPPV] = useState([]);
  const [serviceBlockGardasilSingleDose, setServiceBlockGardasilSingleDose] =
    useState([]);
  const [serviceBlockGardasilThreeDoses, setServiceBlockGardasilThreeDoses] =
    useState([]);

  useEffect(() => {
    async function getServiceBlocks() {
      try {
        const [
          responsePPV,
          responsePCV,
          responsePneumoniaWalkin,
          resonseMenbBexsero,
          responseMenbTrumenba,
          responseFlu,
          responseHpv,
          responseHPVSingledose,
          responseHpvThreedose,
          responseChickenpox,
          responseCovidGreenPack,
          responseCovidPCRTest,
          responseFluPcv,
          responseFluPpv,
          responseSaxenda,
          responseWegovy,
        ] = await Promise.all([
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_PNEUMONIA_PPV || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_PNEUMONIA_PREVNAR_13 || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_PNEUMONIA_WALK_IN || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_MENB_BEXSERO || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_MENB_TRUMENBA || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_FLU || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_HPV || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_HPV_SINGLE_DOSE || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_HPV_THREE_DOSES || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_CHICKENPOX || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_COVID_GREEN_PACK || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_COVID_PCR_TEST || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_FLU_PREVNAR || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_FLU_PPV || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_SAXENDA || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
          fetchServiceBlock({
            serviceId: process.env.REACT_APP_WEGOVY_SERVICES || "",
            startDate: formatDateAws(subYears(new Date(), 1)),
            endDate: INFINITE_END_DATE,
            locationId: locationId,
          }),
        ]);
        setServiceBlockPnewmoniaPPV(responsePPV?.service_blocks);
        setServiceBlockPnewmoniaPrevinar(responsePCV?.service_blocks);
        setServiceBlockChickenPox(responseChickenpox?.service_blocks);
        setServiceBlockCovidGreenPack(responseCovidGreenPack?.service_blocks);
        setServiceBlockDataHPV(responseHpv?.service_blocks);
        setServiceBlockFluPPV(responseFluPpv?.service_blocks);
        setServiceBlockGardasilSingleDose(
          responseHPVSingledose?.service_blocks,
        );
        setServiceBlockGardasilThreeDoses(responseHpvThreedose?.service_blocks);
        setServiceBlockMenbBexsero(resonseMenbBexsero?.service_blocks);
        setServiceBlockMenbTrumenba(responseMenbTrumenba?.service_blocks);
        setServiceBlockPcrTest(responseCovidPCRTest?.service_blocks);
        setServiceDataFlu(responseFlu?.service_blocks);
        setServiceBlockPnewmoniaWalkin(responsePneumoniaWalkin?.service_blocks);
        setServiceBlockServiceFluPrevnar(responseFluPcv?.service_blocks);
        SetServiceBlockSaxenda(responseSaxenda?.service_blocks);
        setNewServiceBlockWegovy(responseWegovy?.service_blocks);
      } catch (error) {}
    }
    getServiceBlocks();
  }, [locationId]);

  //if (!service || !blockData) return <div>Loading...</div>;
  const getDays = (block: any) => {
    let Mon = "";
    let Tue = "";
    let Wed = "";
    let Thur = "";
    let Fri = "";
    let Sat = "";
    let Sun = "";

    if (block.includes("1")) {
      Mon = "Mon";
    }
    if (block.includes("2")) {
      Tue = "Tue";
    }
    if (block.includes("3")) {
      Wed = "Wed";
    }
    if (block.includes("4")) {
      Thur = "Thur";
    }
    if (block.includes("5")) {
      Fri = "Fri";
    }
    if (block.includes("6")) {
      Sat = "Sat";
    }
    if (block.includes("0")) {
      Sun = "Sun";
    }
    const daysDisplay =
      Mon +
      " " +
      Tue +
      " " +
      Wed +
      " " +
      Thur +
      " " +
      Fri +
      " " +
      Sat +
      " " +
      Sun;
    return daysDisplay;
  };

  const timeFormat = function (timeData: any) {
    if (timeData === 0) {
      return timeData;
    } else {
      return (
        timeData.toString().slice(0, -2) + ":" + timeData.toString().slice(-2)
      );
    }
  };

  return (
    <>
      <TableBody className={classes.serviceBlocks}>
        {serviceBlockPnewmoniaPPV && serviceBlockPnewmoniaPPV?.length !== 0 && (
          <TableRow>
            <TableCell>Pneumonia PPV vaccination</TableCell>
            <TableCell>
              {serviceBlockPnewmoniaPPV.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}

        {serviceBlockPnewmoniaPrevinar &&
          serviceBlockPnewmoniaPrevinar.length !== 0 && (
            <TableRow>
              <TableCell>Pneumonia PCV (Prevenar13) vaccination</TableCell>
              <TableCell>
                {serviceBlockPnewmoniaPrevinar.map((block: any) => (
                  <div key={block?.id}>
                    {block.reason &&
                      block.reason?.toLowerCase()?.includes("pause") && (
                        <p className={classes.serviceBlocksPause}>
                          Service Paused
                        </p>
                      )}
                    {block?.repeat &&
                      block.repeat.weekdays &&
                      !block.reason?.toLowerCase()?.includes("pause") && (
                        <p>
                          {getDays(block.repeat.weekdays)} :{" "}
                          {timeFormat(block.startTime)} to{" "}
                          {timeFormat(block.endTime)}{" "}
                        </p>
                      )}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}

        {serviceBlockPnewmoniaWalkin &&
          serviceBlockPnewmoniaWalkin?.length !== 0 && (
            <TableRow>
              <TableCell>Pneumococcal - walk in</TableCell>
              <TableCell>
                {serviceBlockPnewmoniaWalkin.map((block: any) => (
                  <div key={block?.id}>
                    {block.reason &&
                      block.reason?.toLowerCase()?.includes("pause") && (
                        <p className={classes.serviceBlocksPause}>
                          Service Paused
                        </p>
                      )}
                    {block?.repeat &&
                      block.repeat.weekdays &&
                      !block.reason?.toLowerCase()?.includes("pause") && (
                        <p>
                          {getDays(block.repeat.weekdays)} :{" "}
                          {timeFormat(block.startTime)} to{" "}
                          {timeFormat(block.endTime)}{" "}
                        </p>
                      )}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}

        {serviceBlockMenbBexsero && serviceBlockMenbBexsero?.length !== 0 && (
          <TableRow>
            <TableCell>Meningitis-B vaccination</TableCell>
            <TableCell>
              {serviceBlockMenbBexsero?.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block?.repeat.weekdays !== "" &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}

        {serviceBlockMenbTrumenba && serviceBlockMenbTrumenba?.length !== 0 && (
          <TableRow>
            <TableCell>Meningitis B Trumenba</TableCell>
            <TableCell>
              {serviceBlockMenbTrumenba?.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}

        {serviceDataFlu && serviceDataFlu?.length !== 0 && (
          <TableRow>
            <TableCell>Flu vaccination</TableCell>
            <TableCell>
              {serviceDataFlu?.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}

        {serviceBlockDataHPV && serviceBlockDataHPV?.length !== 0 && (
          <TableRow>
            <TableCell>Gardasil 9 (two doses)</TableCell>
            <TableCell>
              {serviceBlockDataHPV?.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}
        {serviceBlockGardasilSingleDose &&
          serviceBlockGardasilSingleDose?.length !== 0 && (
            <TableRow>
              <TableCell>Gardasil 9 (single dose)</TableCell>
              <TableCell>
                {serviceBlockGardasilSingleDose?.map((block: any) => (
                  <div key={block?.id}>
                    {block.reason &&
                      block.reason?.toLowerCase()?.includes("pause") && (
                        <p className={classes.serviceBlocksPause}>
                          Service Paused
                        </p>
                      )}
                    {block?.repeat &&
                      block.repeat.weekdays &&
                      !block.reason?.toLowerCase()?.includes("pause") && (
                        <p>
                          {getDays(block.repeat.weekdays)} :{" "}
                          {timeFormat(block.startTime)} to{" "}
                          {timeFormat(block.endTime)}{" "}
                        </p>
                      )}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}
        {serviceBlockGardasilThreeDoses &&
          serviceBlockGardasilThreeDoses?.length !== 0 && (
            <TableRow>
              <TableCell>Gardasil 9 (three doses)</TableCell>
              <TableCell>
                {serviceBlockGardasilThreeDoses?.map((block: any) => (
                  <div key={block?.id}>
                    {block.reason &&
                      block.reason?.toLowerCase()?.includes("pause") && (
                        <p className={classes.serviceBlocksPause}>
                          Service Paused
                        </p>
                      )}
                    {block?.repeat &&
                      block.repeat.weekdays &&
                      !block.reason?.toLowerCase()?.includes("pause") && (
                        <p>
                          {getDays(block.repeat.weekdays)} :{" "}
                          {timeFormat(block.startTime)} to{" "}
                          {timeFormat(block.endTime)}{" "}
                        </p>
                      )}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}
        {serviceBlockChickenPox && serviceBlockChickenPox?.length !== 0 && (
          <TableRow>
            <TableCell>Chickenpox vaccination</TableCell>
            <TableCell>
              {serviceBlockChickenPox.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}
        {serviceBlockCovidGreenPack &&
          serviceBlockCovidGreenPack?.length !== 0 && (
            <TableRow>
              <TableCell>COVID-19 Green travel package</TableCell>
              <TableCell>
                {serviceBlockCovidGreenPack.map((block: any) => (
                  <div key={block?.id}>
                    {block.reason &&
                      block.reason?.toLowerCase()?.includes("pause") &&
                      block.reason?.toLowerCase()?.includes("pause") && (
                        <p className={classes.serviceBlocksPause}>
                          Service Paused
                        </p>
                      )}
                    {block?.repeat &&
                      block.repeat.weekdays &&
                      !block.reason?.toLowerCase()?.includes("pause") && (
                        <p>
                          {getDays(block.repeat.weekdays)} :{" "}
                          {timeFormat(block.startTime)} to{" "}
                          {timeFormat(block.endTime)}{" "}
                        </p>
                      )}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}
        {serviceBlockPcrTest && serviceBlockPcrTest?.length !== 0 && (
          <TableRow>
            <TableCell>COVID-19 PCR test</TableCell>
            <TableCell>
              {serviceBlockPcrTest.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}
        {serviceBlockServiceFluPrevnar &&
          serviceBlockServiceFluPrevnar?.length !== 0 && (
            <TableRow>
              <TableCell>
                Flu and pneumonia PCV (Prevenar 13) vaccinations
              </TableCell>
              <TableCell>
                {serviceBlockServiceFluPrevnar.map((block: any) => (
                  <div key={block?.id}>
                    {block.reason &&
                      block.reason?.toLowerCase()?.includes("pause") && (
                        <p className={classes.serviceBlocksPause}>
                          Service Paused
                        </p>
                      )}
                    {block?.repeat &&
                      block.repeat.weekdays &&
                      !block.reason?.toLowerCase()?.includes("pause") && (
                        <p>
                          {getDays(block.repeat.weekdays)} :{" "}
                          {timeFormat(block.startTime)} to{" "}
                          {timeFormat(block.endTime)}{" "}
                        </p>
                      )}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}
        {serviceBlockFluPPV && serviceBlockFluPPV?.length !== 0 && (
          <TableRow>
            <TableCell>Flu and pneumonia PPV vaccinations</TableCell>
            <TableCell>
              {serviceBlockFluPPV.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}
        {serviceBlockSaxenda && serviceBlockSaxenda?.length !== 0 && (
          <TableRow>
            <TableCell>Medicated Weight Loss Management</TableCell>
            <TableCell>
              {serviceBlockSaxenda.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}
        {newServiceBlockWegovy && newServiceBlockWegovy.length !== 0 && (
          <TableRow>
            <TableCell>Medicated Weight Management – Wegovy</TableCell>
            <TableCell>
              {newServiceBlockWegovy?.map((block: any) => (
                <div key={block?.id}>
                  {block.reason &&
                    block.reason?.toLowerCase()?.includes("pause") && (
                      <p className={classes.serviceBlocksPause}>
                        Service Paused
                      </p>
                    )}
                  {block?.repeat &&
                    block.repeat.weekdays &&
                    !block.reason?.toLowerCase()?.includes("pause") && (
                      <p>
                        {getDays(block.repeat.weekdays)} :{" "}
                        {timeFormat(block.startTime)} to{" "}
                        {timeFormat(block.endTime)}{" "}
                      </p>
                    )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

export default ServiceBlockIndividualServices;
