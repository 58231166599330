import React, { FC } from "react";
import { useQuery } from "@apollo/client/react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Box,
  makeStyles,
  Theme
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Typography } from "@welldigital/components";
import { format } from "date-fns";
import {
  formatDate,
} from "../../../../utils/time";
import { 
  GET_RESOURCE_BLOCKS
} from "../../../../components/AvailabilityModal/gql";
import {
  getResourceBlocks,
  getResourceBlocksVariables,
} from "../../../../components/AvailabilityModal/__generated__/getResourceBlocks";
import ServiceBlockIndividualServices from "./ServiceBlockIndividualServices";
import {
  getCalendars,
  getCalendarsVariables,
} from "components/AvailabilityModal/ServiceAvailability/WorkingHours/__generated__/getCalendars";
import { GET_CALENDARS } from "components/AvailabilityModal/ServiceAvailability/WorkingHours/gql";
//import { getLocationId } from "services/auth";
import useServicePause from "components/AvailabilityModal/ServiceAvailability/hooks/useServicePause";
import useLunchHours from "components/AvailabilityModal/ServiceAvailability/hooks/useLunchHours";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";

const ServiceBlockAvailability: FC<{locationId:string}> = (locationId) => {
  const history = useHistory();

  const useStyles = makeStyles((theme: Theme) => ({
    serviceAvailabilitySection: {
      padding: theme.spacing(2, 0),
      width: "100%", 
      overflow: "hidden",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#1A5E79",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#1A5E79",
      },
    },
  }));

  const classes = useStyles();

  const { areAllPaused } = useServicePause();
  const { lunchHours } = useLunchHours();
  const hourFormat = "kmm";
  const endDate = format(
    new Date().setDate(new Date().getDate() + 90),
    "yyyy-MM-dd",
  );
  //const locationId = useAppSelector(selectLocationId);

  const { loading: isLoading, error, data: getData } = useQuery<
  getResourceBlocks,
  getResourceBlocksVariables
  >(GET_RESOURCE_BLOCKS, {
    variables: {
      limit: 0,
      offset: 0,
      startDate: formatDate(new Date(), "yyyy-MM-dd"),
      endDate,
      locationId: locationId.locationId,
    },
  });
  
  //const [locationId, setLocationId] = useState<string>();

  // useEffect(() => {
  //   (async () => {
  //     setLocationId(await getLocationId());
  //   })();
  // }, [setLocationId]);

  const { data: getDataCalender }  =  useQuery<
  getCalendars, getCalendarsVariables
  >(GET_CALENDARS, {
    variables: {
      locationId: locationId.locationId,
      deleted: false,
      limit: 100,
      offset: 0,
    },
  });

  const resourceBlockData = getData?.getResourceBlocks || [];  

  if ((error?.networkError as any)?.statusCode === 401) {
    history.push("/login");
  }

  if (error)
    return (
      <Typography variant={"body1"} color={"error"}>
        Error: {error.message}
      </Typography>
    );

  if (isLoading) return <Typography>Loading..</Typography>;
  if (resourceBlockData?.length === 0 && lunchHours === null) return <Typography>No blocks available</Typography>;

  const timeFormat = function(timeData:any) {
    return timeData.toString().slice(0, -2) + ":" + timeData.toString().slice(-2);
  }

  let validTime:boolean = true;
  if(!lunchHours?.startTime || !lunchHours?.endTime) {
    validTime = false;
  }

    return (
      <Box mt={2} className={classes.serviceAvailabilitySection} >
      <TableContainer style={{ height: "350px" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        {
        areAllPaused && 
          <TableBody>
            <TableRow>
              <TableCell style= {{color: '#f44336'}}>All services are paused</TableCell>
            </TableRow>
          </TableBody>
      }
        <TableBody>
        {resourceBlockData?.map((block:any) => (
            <TableRow
              key={`serviceBlock-${block?.id}`}
              hover
            >
              <TableCell>{block?.reason}</TableCell>
              <TableCell>{`${formatDate(block?.startDate)} to ${formatDate(block?.endDate)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableBody>
            <TableRow hover>
              <TableCell>Lunch Hours</TableCell>
              {lunchHours && validTime ?
                <TableCell>{`${timeFormat(parseInt(format(lunchHours.startTime, hourFormat)))} to ${timeFormat(parseInt(format(lunchHours.endTime, hourFormat)))}`}</TableCell>
                :
                <TableCell>Lunch Hours not updated</TableCell>
              }
            </TableRow>
        </TableBody>
        <TableBody>
            <TableRow>
              <TableCell>Appointment Hours</TableCell>
              <TableCell>
                {getDataCalender && getDataCalender?.getCalendars!.map((block:any) => (
                   <div key={block?.id}>
                    {block.availability.mon && <div>Mon: {timeFormat(block.availability.mon.startTime)} to {timeFormat(block.availability.mon.endTime)}</div>}
                    {block.availability.tue && <div>Tue: {timeFormat(block.availability.tue.startTime)} to {timeFormat(block.availability.tue.endTime)}</div>}
                    {block.availability.wed && <div>Wed: {timeFormat(block.availability.wed.startTime)} to {timeFormat(block.availability.wed.endTime)}</div>}
                    {block.availability.thu && <div>Thur: {timeFormat(block.availability.thu.startTime)} to {timeFormat(block.availability.thu.endTime)}</div>}
                    {block.availability.fri && <div>Fri: {timeFormat(block.availability.fri.startTime)} to {timeFormat(block.availability.fri.endTime)}</div>}
                    {block.availability.sat && <div>Sat: {timeFormat(block.availability.sat.startTime)} to {timeFormat(block.availability.sat.endTime)}</div>}
                    {block.availability.sun && <div>Sun: {timeFormat(block.availability.sun.startTime)} to {timeFormat(block.availability.sun.endTime)}</div>}
                   </div>
                ))}
              </TableCell>
            </TableRow>
        </TableBody>
        {!areAllPaused &&
          <ServiceBlockIndividualServices />
        }
      </Table>
    </TableContainer>
    
      </Box>
    );
  
};

export default ServiceBlockAvailability;
