import React, { useMemo, FC } from "react";
import {
  InputField,
  IRadioOption,
  RadioSelectField,
  Typography,
} from "@welldigital/components";
import { Box } from "@material-ui/core";
import { Genders } from "persister/types";
import { isUnder18 } from "pages/WalkInCustomerDetails/utils";
import { Service } from "../../../../constants/service.constants";

export type CustomerFieldsProps = {
  birthDate: Date;
  service: Service;
};

export const CustomerFields: FC<CustomerFieldsProps> = ({ birthDate, service }) => {
  const genderOptions: IRadioOption[] = useMemo(
    () => [
      { label: "Male", id: Genders.male },
      { label: "Female", id: Genders.female },
    ],
    [],
  );

  return (
    <>
      {!isUnder18(birthDate) && (
        <>
          <Typography variant={"h4"} spacingAfter={2}>
            Contact information
          </Typography>
          <Typography
            variant={"body1"}
            color={"textSecondary"}
            spacingAfter={2}
          >
            Please provide the email address and phone number.
          </Typography>
          <InputField
            name={"customerDetails.email"}
            label={"Email address"}
            email
          />
          <InputField
            name={"customerDetails.phone"}
            label={"Phone number"}
            required
          />
        </>
      )}
      <Typography variant={"h4"}>Enter their name</Typography>
      <InputField
        name={"customerDetails.firstName"}
        label={"First name"}
        required
        fullWidth
      />
      <InputField
        name={"customerDetails.lastName"}
        label={"Last name"}
        required
        fullWidth
      />
      {service?.id?.toString() === process.env.REACT_APP_WEGOVY_SERVICES ?
      null :   <Box mt={2}>
      <Typography variant={"h4"} spacingAfter={1}>
        {"Are they male or female?"}
      </Typography>
      <RadioSelectField
        name={"customerDetails.gender"}
        options={genderOptions}
        required
        spacingAfter={1}
        fullWidth
      />
    </Box>}
    </>
  );
};

export default CustomerFields;
