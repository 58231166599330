import React, { useCallback, useEffect, useState } from "react";
import {
  CircularProgress,
  makeStyles,
  Theme,
  Box,
  Checkbox,
} from "@material-ui/core";
import { Button, Typography } from "@welldigital/components";
import { useCovidQuestions } from "./hooks/useCovidQuestions";
import CovidQuestion from "./CovidQuestion";
import { isYes, SPLITTER } from "./utils";
import CovidPreInformation from "./CovidPreInformation";
import BookAppointment from "../Screening/components/BookAppointment";
import { CovidAnswerInput } from "../../__generated__/globalTypes";
import { useHistory } from "react-router-dom";
import { analytics } from "@welldigital/ui-common";
import events from "../../utils/events";
import { Service } from "../Screening/types";
import { useScreeningQuestions } from "../Screening/hooks/useScreeningQuestions";
import { Alert } from "@welldigital/components";
import { ROUTES } from "../../app/routers/LoggedInRoutes";

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export interface CovidQuestionsProps {
  service: Service;
}

function CovidQuestions({ service }: CovidQuestionsProps) {
  const classes = useStyles();
  const history = useHistory();
  const { screeningQuestion } = useScreeningQuestions();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [understandTTR, setUnderstandTTR] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { loading, data, error, covidMutation } = useCovidQuestions();

  const [covidAnswers, setCovidAnswers] = useState<CovidAnswerInput[]>([]);
  const [
    covidMutateFn,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { loading: submitLoading, data: postCovidData, error: submitError },
  ] = covidMutation;

  const covidScreeningQuestions = data?.getCovidQuestions ?? [];

  const answerQuestion = useCallback(
    (updatedQuestion: CovidAnswerInput) => {
      const csq = [...covidScreeningQuestions];
      let questionIndex = csq.findIndex(
        (q) => q && q.number === updatedQuestion.number,
      );
      if (questionIndex < 0) {
        return;
      }
      covidAnswers[questionIndex] = updatedQuestion;
      setCovidAnswers([...covidAnswers]);
    },
    [covidAnswers, covidScreeningQuestions],
  );

  const handleUnderstandTTR = useCallback(() => {
    setUnderstandTTR(!understandTTR);
  }, [setUnderstandTTR, understandTTR]);

  const onNext = useCallback(async () => {
    const standardQuestions = covidAnswers.filter((q) => q && q.number !== 8);
    const isIneligible = standardQuestions.some((q) => isYes(q.answer));

    if (isIneligible) {
      analytics.trackEvent({
        event: events.screening.ineligible,
        flow: service.flow,
        metadata: {
          reason: "answered yes",
        },
      });
      history.push(ROUTES.walkIn.ineligible);
      return;
    }

    if (screeningQuestion) {
      analytics.trackEvent({
        event: events.screening.answeredQuestion,
        flow: service.flow,
        metadata: {
          question: screeningQuestion.questionText,
        },
      });
      try {
        await covidMutateFn({
          variables: {
            screeningID: screeningQuestion?.screeningId ?? "",
            answers: covidAnswers,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [covidAnswers, covidMutateFn, history, screeningQuestion, service.flow]);

  useEffect(() => {
    if (covidAnswers.length === 0 && covidScreeningQuestions.length > 0) {
      setCovidAnswers(
        covidScreeningQuestions.map((c) => ({
          number: c?.number ?? 0,
          question: c?.question ?? "",
          type: c?.type ?? 1,
          answer: "",
        })),
      );
    }
  }, [covidAnswers, covidScreeningQuestions]);

  if (loading || submitLoading) {
    return (
      <div
        data-testid={"CovidQuestions/loading"}
        className={classes.loadingContainer}
      >
        <CircularProgress color={"secondary"} />
      </div>
    );
  }

  if (error) {
    return <Alert message={error.message} type={"error"} />;
  }

  if (postCovidData) {
    return <BookAppointment service={service} />;
  }

  const hasUnansweredQuestions =
    covidAnswers.filter((q) => !q.answer).length > 0;

  return (
    <div data-testid={"CovidQuestion/container"}>
      <CovidPreInformation />
      {covidAnswers.map((cq) => {
        if (!cq) {
          // This condition shouldn't happen and indicates a type error in the backend as Questions[] should be mandatory
          console.error("Server didn't respond with the question");
          return null;
        }
        const questionParts = cq?.question ? cq.question.split(SPLITTER) : [];
        const question = questionParts.length > 0 ? questionParts[0] : "";
        return (
          <CovidQuestion
            key={`covidQuestion-${cq.number}`}
            answerQuestion={answerQuestion}
            answer={cq.answer}
            question={question}
            number={cq.number}
          />
        );
      })}
      <Typography spacingAfter={3}>
        <Checkbox
          color={"primary"}
          data-testid={"covidAntigenQuestionsPage/checkbox"}
          name={"understandTTR"}
          onChange={handleUnderstandTTR}
          checked={understandTTR}
        />{" "}
        I understand that this service is not suitable for TTR.
      </Typography>
      <Button
        data-testid={"covidAntigenQuestionsPage/next-button"}
        variant={"contained"}
        color={"primary"}
        onClick={onNext}
        disabled={hasUnansweredQuestions}
      >
        Next
      </Button>
      {submitError && (
        <Box mt={2}>
          <Typography color={"error"} spacingAfter={0}>
            {submitError.message}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default CovidQuestions;
