import React from "react";
import { Spacing, Typography, Alert } from "@welldigital/components";
import BreadCrumbs from "../BreadCrumbs/Breadcrumbs";
import { ApolloError } from "@apollo/client";
import ServiceSplitScreen from "components/ServiceSplitScreen/ServiceSplitScreen";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { getAvailability } from "graphql/__generated__/getAvailability";
import { Availability, FetchAvailabilityInput } from "../AppointmentSelector";
import SelectADate from "./SelectADate";
import { Box } from "@material-ui/core";


export interface AvailabilitySelectorScreenProps {
  appointmentId: string;
  isLoading?: boolean;
  isFetchingAvailability?: boolean;
  error?: ApolloError;
  appointmentData?: getAppointment;
  availabilityData?: getAvailability;
  setSelectedAvailability(a: Availability | undefined): void;
  selectedAvailability: Availability | undefined;
  onSubmit(): void;
  fetchAvailability(input: FetchAvailabilityInput): void;
  errorMessage?: string;
  now?: Date;
}

const AvailabilitySelectorScreen: React.FC<AvailabilitySelectorScreenProps> = (
  props,
) => {
  const { onSubmit } = props;

  if (props.isLoading) return <p>Loading...</p>;
  if (props.error) return <p>Error: {props.error.message}</p>;
  if (!props.appointmentData?.getAppointment)
    return <p>Appointment not found</p>;
  const { getAppointment: appointment } = props.appointmentData;

  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `/customer-details/${appointment.customer.id}`,
            name: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
          },
          {
            url: `/service-details/${appointment.id}`,
            name: `${appointment.course?.name}`,
          },
        ]}
      />
      <ServiceSplitScreen
        actionButtonText={"Confirm"}
        appointment={appointment}
        onActionSubmit={onSubmit}
        isActionDisabled={
          !props.selectedAvailability || props.isFetchingAvailability
        }
      >
        <Spacing spacing={1} />
        <Typography variant={"h3"} spacingAfter={1}>
          Book {appointment.course?.name}
        </Typography>
        <SelectADate
          serviceId={appointment.course?.onSchedServiceId}
          setBookedSlot={props.setSelectedAvailability}
          bookedSlot={props.selectedAvailability}
        />
          {props?.errorMessage && (
          <Box mt={2}>
            <Alert type={"error"} message={props?.errorMessage} />
          </Box>
        )}
      </ServiceSplitScreen>
    </>
  );
};

export default AvailabilitySelectorScreen;
