import { gql } from "@apollo/client";

export const GET_AVAILABILITY = gql`
  query getAvailability(
    $serviceId: ID!
    $startDate: AWSDateTime!
    $endDate: AWSDateTime!
    $locationId: locationId
  ) {
    getAvailability(
      serviceId: $serviceId
      startDate: $startDate
      endDate: $endDate
      locationId: $locationId
    ) {
      startTime
      endTime
      resourceId
    }
  }
`;
