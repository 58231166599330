import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) => ({
  flexPane: {
    display: "flex",
    height: "auto",
    flex: "1",
    flexDirection: "column",
    overflow: "auto",
  },
}));

/**
 * Flex pane component used to define a flexibly sized panel,
 * made to be used in conjunction with Splitter and FixedPane,
 * but could be used independently
 */
const FlexPane: FC = (props) => {
  const { children, ...rest } = props;
  const classes = useStyles();

  return (
    <div {...rest} className={classes.flexPane}>
      {props.children}
    </div>
  );
};

export default FlexPane;
