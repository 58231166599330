import React, { FC } from "react";
import { Chip, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { Typography, Spacing, Divider } from "@welldigital/components";
import ReactMarkdown from "react-markdown";
import GpLetterPdf from "../../../../components/GpLetterPdf";
import { Appointment } from "../../../../graphql/types";
import { formatTime, formatDate } from "../../../../utils/time";
import PharmacyDetails from "../../../../components/PharmacyDetails/PharmacyDetails";
import ServiceQuestions from "../ServiceQuestions";
import WegovyServiceQuestions from "../WegovyServiceQuestions";
import PreviousAppointments from "../PreviousAppointments";
import { formatMarkdown } from "../../../../utils/markdown";
import AppointmentCountChip from "../../../../components/AppointmentCountChip/AppointmentCountChip";
import { PaymentType, AppointmentStatus } from "__generated__/globalTypes";
import GuardianDetails from "components/GuardianDetails/GuardianDetails";
import { differenceInYears } from "date-fns";
import { ServiceIds } from '../../../../constants/service.constants';
import { mockServices } from '../../../../constants/service.mockData';
import { useAppSelector } from "redux/hooks";
import { selectBookwellGroup, selectLocationId } from "redux/reduxSlice/selector";

const CONFIRM_STATEMENT_LABEL =
  "I confirm this patient meets the service specification.";

export interface AppointmentDetailsProps {
  appointment: Appointment;
  isQuickAppointment: boolean;
}

const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  appointment,
  isQuickAppointment,
}) => {
  let paymentType: string | null = null;

  switch (appointment.course?.paymentType) {
    case PaymentType.payment:
      paymentType = "Paid by card";
      break;
    case PaymentType.exempt:
      paymentType = "NHS";
      break;
    case PaymentType.voucher:
      paymentType = "Voucher";
      break;
    case PaymentType.in_store:
      paymentType = "In Store";
  }

  const isCompleted = appointment.status === AppointmentStatus.completed;
  const isCancelled = appointment.status === AppointmentStatus.cancelled;

  const isUnder18 =
    differenceInYears(new Date(), new Date(appointment.customer?.dob)) < 18;

  const isAbove65 =
  differenceInYears(new Date(), new Date(appointment.customer?.dob)) >= 65;

  const isFluPPV = appointment.course?.name === 'Flu and pneumonia PPV vaccinations';
  const isFluPCV = appointment.course?.name === 'Flu and pneumonia PCV (Prevenar 13) vaccinations';
 
  const PneumoniaPrevenar13 = mockServices.find(
    ({ id }) => id === ServiceIds.PneumoniaPrevenar13,
  )!;

  const PneumoniaPPV = mockServices.find(
    ({ id }) => id === ServiceIds.PneumoniaPPV,
  )!;

  const Flu = mockServices.find(
    ({ id }) => id === ServiceIds.Flu,
  )!;

  const locationId = useAppSelector(selectLocationId);
  const bookwellGroup = useAppSelector(selectBookwellGroup);
  return (
    <>
      {!isCompleted && !isCancelled && (
        <AppointmentCountChip appointmentId={appointment.id} />
      )}
      {isCompleted && <Chip label={"COMPLETED"} color={"secondary"} />}
      {isCancelled && <Chip label={"CANCELLED"} color={"default"} />}
      <Spacing spacing={1} />
      <Typography variant={"h3"} spacingAfter={1}>
        {appointment.course?.name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant={"caption"}>Appointment time</Typography>
          <Typography>{formatTime(appointment.startTime)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"caption"}>Appointment date</Typography>
          <Typography>{formatDate(appointment.startTime)}</Typography>
        </Grid>
      </Grid>
      <Typography variant={"caption"}>Creation date</Typography>
      <Typography spacingAfter={1}>
        {formatDate(appointment.creationDate)}
      </Typography>
      {!!paymentType && (
        <>
          <Typography variant={"caption"} spacingAfter={0}>
            Payment status
          </Typography>
          <Typography data-testid={"payment-status"} spacingAfter={1}>
           {isFluPCV || isFluPPV ? 
                <text>
                  <ul>
                    <li> Flu ({appointment.customer.nhsNumber}
                       {isAbove65? "":`,£${Flu.fee}`}
                      ,{isAbove65? "Free":paymentType+"_payment"})
       
                    </li>                  
                    <li> Pneumonia ( {isFluPCV?`£${PneumoniaPrevenar13.fee}`:`£${PneumoniaPPV.fee}`},  {paymentType+"_payment"})</li>  
                  </ul>
                </text> : paymentType} 
          </Typography>
        </>
      )}

      <PharmacyDetails pharmacist={appointment.pharmacist} />

      {isUnder18 && (
        <>
          <Divider spacingBefore={1} spacingAfter={2} />
          <GuardianDetails guardian={appointment.guardian} />{" "}
        </>
      )}

      {!bookwellGroup &&  (appointment?.course.onSchedServiceId === process.env.REACT_APP_WEGOVY_SERVICES? 
     <WegovyServiceQuestions appointmentId={appointment.id} serviceId= {appointment?.course.onSchedServiceId} />
     :  <ServiceQuestions appointmentId={appointment.id}/> 
      )}

      {!isCompleted && appointment.totalNumber > 1 && (
        <>
          <Divider spacingBefore={2} spacingAfter={2} />
          <PreviousAppointments appointmentId={appointment.id} />
        </>
      )}
      {appointment.course?.pharmacistInstructions && !bookwellGroup && (
        <>
          <Divider spacingBefore={2} spacingAfter={2} />

          <Typography variant={"h5"} spacingAfter={0}>
            Pharmacist instructions
          </Typography>
          <ReactMarkdown
            source={formatMarkdown(appointment.course?.pharmacistInstructions)}
          />
        </>
      )}

      {isCompleted && (
        <>
          {appointment?.medicine && (
            <>
              <Divider spacingBefore={0} spacingAfter={2} />

              <Typography variant={"h5"} spacingAfter={3}>
                Medication information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant={"h6"} spacingAfter={0}>
                    Manufacturer / Brand Name
                  </Typography>
                  <Typography variant={"h5"} spacingAfter={2}>
                    {appointment?.medicine?.manufacturer}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"h6"} spacingAfter={0}>
                    Expiry Date
                  </Typography>
                  <Typography variant={"h5"} spacingAfter={2}>
                    {formatDate(appointment?.medicine?.expiryDate)}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {!isQuickAppointment && (
            <>
              <div>
                <GpLetterPdf appointmentId={appointment.id} locationId={locationId}/>
              </div>
              <Divider spacingBefore={2} spacingAfter={2} />
              <Typography variant={"h5"} spacingAfter={1}>
                Confirmation statement
              </Typography>
              <FormControlLabel
                data-testid={"CompletedServiceDetails/confirmation-checkbox"}
                control={<Checkbox color={"primary"} checked disabled />}
                label={CONFIRM_STATEMENT_LABEL}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AppointmentDetails;
