import React, { FC } from "react";
import { ReactComponent as IcWarning } from "assets/icons/warning-outlined.svg";
import { TraversalSummaryResponse } from "pages/ClinicalReviewPage/Types";

interface QAProps {
  question: string;
  response: TraversalSummaryResponse[];
  message?: string;
  exclusion?: boolean;
  warning?: boolean;
  height? : string;
  weight? : string;
  bmiValue?: number
}

const QA: FC<QAProps> = ({
  question,
  response,
  message,
  exclusion,
  warning,height,weight, bmiValue

}) => {
  return (
    <div
      className={`qa-container ${exclusion ? "qa-exclusion" : ""} ${
        warning ? "qa-warning" : ""
      }`}
    >
      {(exclusion || warning) && (
        <div className="icon-container">
          <IcWarning />
        </div>
      )}
      <div className="content">
        {
          height && weight && bmiValue && question === '[BMI calculation] ' ? 
          <div className= "question">
          <span>Height: {height} Weight: {weight} BMI: {bmiValue}</span>
          </div>
          :  
        <div
        className="question"
        dangerouslySetInnerHTML={{ __html: question }}
      />
        }
        {response.map((response, index) => (
          <p className="response" key={`${question}-${index}`}>
            {response.value}
          </p>
        ))}
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default QA;
