import React from "react";
import { Switch } from "react-router-dom";
import DashboardPage from "../../pages/DashboardPage";
import ServiceDetailsPage from "../../pages/ServiceDetailsPage";
import EditServiceDetailsPage from "../../pages/EditServiceDetailsPage";
import CompleteServiceDetailsPage from "../../pages/CompleteServiceDetailsPage";
import CustomerDetailsPage from "../../pages/CustomerDetailsPage/CustomerDetailsPage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ConfirmServiceDetailsPage from "pages/ConfirmServiceDetailsPage";
import AppointmentBookedPage from "pages/AppointmentBookedPage";
import RescheduleServicePage from "pages/RescheduleServicePage/RescheduleServicePage";
import BookNextServicePage from "pages/BookNextServicePage/BookNextServicePage";
import WalkInPage from "pages/WalkInPage";
import BookAnAppointment from "../../pages/BookAnAppointment";
import ConfirmationPage from "pages/ConfirmationPage";
import WalkInLayout from "app/layout/WalkInLayout/WalkInLayout";
import WalkInCustomerDetails from "pages/WalkInCustomerDetails/WalkInCustomerDetailsPage";
import ScreeningWrapper from "../../components/Screening/ScreeningWrapper";
import IneligibleForService from "../../pages/IneligibleForService/IneligibleForService";
import CentralVisibilityPage from "pages/CentralVisibilityPage/CentralVisibilityPage";

// exposed so we can handle re-directs from other pages/components by reference
export const ROUTES = {
  dashboard: "/dashboard/:locationId",
  centralVisibility: "/central-visibility",
  walkIn: {
    confirmation: "/walk-in/confirmation",
    screening: "/walk-in/screening",
    bookAppointment: "/walk-in/book-an-appointment",
    customerDetails: "/walk-in/enter-customer-details",
    ineligible: "/walk-in/ineligible",
  },
};

const Routes = () => (
  <Switch>
    <ProtectedRoute exact path={"/"}>
    <DashboardPage />
    </ProtectedRoute>
    <ProtectedRoute path={ROUTES.dashboard}>
    <DashboardPage />
    </ProtectedRoute>
    <ProtectedRoute exact path={ROUTES.centralVisibility}>
      <CentralVisibilityPage />
    </ProtectedRoute>
    {/* Walk-in routes */}
    <ProtectedRoute path={ROUTES.walkIn.confirmation}>
      <WalkInLayout>
        <ConfirmationPage />
      </WalkInLayout>
    </ProtectedRoute>
    <ProtectedRoute path={ROUTES.walkIn.screening}>
      <WalkInLayout>
        <ScreeningWrapper />
      </WalkInLayout>
    </ProtectedRoute>
    <ProtectedRoute path={ROUTES.walkIn.bookAppointment}>
      <WalkInLayout>
        <BookAnAppointment />
      </WalkInLayout>
    </ProtectedRoute>
    <ProtectedRoute path={ROUTES.walkIn.customerDetails}>
      <WalkInLayout>
        <WalkInCustomerDetails />
      </WalkInLayout>
    </ProtectedRoute>
    <ProtectedRoute path={ROUTES.walkIn.ineligible}>
      <WalkInLayout>
        <IneligibleForService />
      </WalkInLayout>
    </ProtectedRoute>

    {/* Old Walk-in route */}
    <ProtectedRoute path={"/walk-in/:id"}>
      <WalkInPage />
    </ProtectedRoute>
    <ProtectedRoute path={"/customer-details/:id"}>
      <CustomerDetailsPage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id/book-next"}>
      <BookNextServicePage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id/reschedule"}>
      <RescheduleServicePage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id/complete"}>
      <CompleteServiceDetailsPage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id/confirm"}>
      <ConfirmServiceDetailsPage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id/booked"}>
      <AppointmentBookedPage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id/edit"}>
      <EditServiceDetailsPage />
    </ProtectedRoute>
    <ProtectedRoute path={"/service-details/:id"}>
      <ServiceDetailsPage />
    </ProtectedRoute>
  </Switch>
);

export default Routes;
