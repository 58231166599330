import { gql } from "@apollo/client";

export const GET_APPOINTMENT = gql`
  query getAppointment(
    $appointmentId: String!
    $locationId: String!
    ) {
    getAppointment(
      appointmentId: $appointmentId
      locationId: $locationId
      ) {
      id
      number
      totalNumber
      startTime
      modifiedBy
      modifiedAt
      creationDate
      endTime
      notes
      course {
        id
        name
        paymentType
        patientAdvice
        pharmacistInstructions
        onSchedServiceId
        questions {
          answer
          number
          question
          questionType
          updatedDate
        }
      }
      pharmacist {
        title
        name
        gphcNumber
      }
      customer {
        id
        firstName
        lastName
        email
        dob
        phone
        nhsNumber
        sex
        surgeryName
        surgeryAddress
        address {
          line1
          line2
          city
          postcode
        }
      }
      status
      medicine {
        expiryDate
        manufacturer
        method
        batchNumber
      }
      guardian {
        title
        firstName
        lastName
      }
    }
  }
`;
