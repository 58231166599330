import {
  Divider,
  ModalTitle,
  Spacing,
  Typography,
} from "@welldigital/components";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import ResourceBlockWalkInFluClinicForm from "../ResourceBlockForm/ResourceBlockWalkInFluClinicForm";
import { useMutation } from "@apollo/client/react";
import {
  CREATE_RESOURCE_BLOCK,
  DELETE_COVER_PHARMACIST_BLOCK,
  DELETE_RESOURCE_BLOCK,
  GET_COVER_PHARMACIST_DAYS,
  GET_RESOURCE_BLOCKS,
} from "../../gql";
import {
  getResourceBlocks,
  getResourceBlocks_getResourceBlocks,
} from "../../__generated__/getResourceBlocks";
import {
  createResourceBlock,
  createResourceBlockVariables,
} from "../../__generated__/createResourceBlock";
import {
  deleteCoverPharmacistDays,
  deleteCoverPharmacistDaysVariables,
} from "../../__generated__/deleteCoverPharmacistDays";
import {
  deleteResourceBlock,
  deleteResourceBlockVariables,
} from "../../__generated__/deleteResourceBlock";
import { DayInput } from "../../../../__generated__/globalTypes";
import {
  coverPharmacistDaysToSlots,
  coverPharmacistSlotsToInput,
} from "../../coverPharmacistSlots";
import { getCoverPharmacistDays } from "../../__generated__/getCoverPharmacistDays";
import { WALK_IN_FLU_CLINIC } from "./WalkInFluClinicContainer";
import Slot from "./Slot";
import Block from "./Block";
import { AlertContext } from "../../../../pages/DashboardPage/AlertContext.context";
import { formatDate } from "../../../../utils/time";
import { AddBlockRequestVariables } from "../ResourceBlockForm/types";
import { add, areIntervalsOverlapping, set } from "date-fns";
import { useSnackbar } from "notistack";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";

export const WALK_IN_FLU_CLINIC_TYPO = {
  title: "Book a walk-in flu clinic",
  blurb: "",
  buttons: {
    back: "Back",
    delete: "Delete",
    save: "Save",
  },
};

export interface WalkInFluClinicProps {
  blocks: getResourceBlocks["getResourceBlocks"];
  coverDays: getCoverPharmacistDays;
  onClose: () => void;
  locationId: string;
}

export type WalkInFluClinicFormType = Omit<
  AddBlockRequestVariables,
  "startTime" | "endTime"
> & {
  startTime: Date;
  endTime: Date;
};

const time = new Date();

export const setStartDate = () => {
  const d = new Date();
  d.setHours(9, 0);
  return d;
};

export const setNewEndDate = () => {
  const d = new Date();
  d.setHours(17, 0);
  return d;
};

export const createInput = () => {
  return Object.assign({}, {
    reason: WALK_IN_FLU_CLINIC,
    startDate: add(time, { days: 1 }),
    endDate: add(time, { days: 1 }),
    repeat: { frequency: "", monthDay: 0 },
    startTime: setStartDate(),
    endTime: setNewEndDate(),
    allDay: false,
  } as WalkInFluClinicFormType);
};

function WalkInFluClinic({ blocks, coverDays, onClose,locationId }: WalkInFluClinicProps) {
  const { enqueueAlert } = useContext(AlertContext);
  const { enqueueSnackbar } = useSnackbar();

  const slots = useMemo(() => coverPharmacistDaysToSlots(coverDays), [
    coverDays,
  ]);
  const currentDate = new Date();
  const futureSlots = slots.filter((s) => s.until > currentDate);

  const history = useHistory();
  //const locationId = useAppSelector(selectLocationId);

  const [addWalkInFluClinic] = useMutation<
    createResourceBlock,
    createResourceBlockVariables
  >(CREATE_RESOURCE_BLOCK, {
    onCompleted: () => {
      enqueueAlert({
        type: "success",
        message: "Successfully added Walk-in flu clinic",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar("Failed to add Walk-in flu clinic", { variant: "error" });
      console.error(error);
    },
  });
  const [deleteWalkInFluClinic] = useMutation<
    deleteResourceBlock,
    deleteResourceBlockVariables
  >(DELETE_RESOURCE_BLOCK, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted Walk-in flu clinic", {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar("Failed to delete Walk-in flu clinic", { variant: "error" });
      console.error(error);
    },
  });

  const [selectedWalkInFluClinic] = useState<WalkInFluClinicFormType>(
    createInput,
  );
  
  const [deleteCoverPharmacist] = useMutation<
    deleteCoverPharmacistDays,
    deleteCoverPharmacistDaysVariables
  >(DELETE_COVER_PHARMACIST_BLOCK, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted cover pharmacist", {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar("Failed to delete cover pharmacist", {
        variant: "error",
      });
      console.error(error);
    },
  });

  const getStartDate = (item:any) => {
    let startDateNew = new Date(item?.startDate);
    let checkStartTime = item?.startTime;
    if(checkStartTime === 0 || checkStartTime === 15 || checkStartTime === 30 || checkStartTime === 45) {
      checkStartTime = 100;
    }
    let startTimeNew = set(startDateNew,{hours:parseInt(String(checkStartTime).slice(0,-2))});
    let min = parseInt(String(item?.startTime).slice(-2))
    if(min !== 0){
      startTimeNew = set(startTimeNew,{minutes:min});
    }
    return startTimeNew;
  }

  const getEndDate = (item:any) => {
    let startDateNew = new Date(item?.endDate);
    let startTimeNew = set(startDateNew,{hours:parseInt(String(item?.endTime).slice(0,-2))});
     let min = parseInt(String(item?.endTime).slice(-2))
    if(min !== 0){
      startTimeNew = set(startTimeNew,{minutes:min});
    }
    return startTimeNew;
  }
    
  const [blockDisplay, setBlockDisplay] = useState("")
  const onSave = useCallback(
    async (values: AddBlockRequestVariables) => {
      let checkDateBlocks: boolean=false;
      blocks?.map(
        (item:any) => {
          if(areIntervalsOverlapping( 
            { start: getStartDate(item), end: getEndDate(item) }, 
            { start: getStartDate(values), end: getEndDate(values) }, 
          )) {
            setBlockDisplay("Block already booked");
            checkDateBlocks = true;
          }
        }
      )
      if(checkDateBlocks) {
        await Promise.reject('error');  
      }
      values.reason = WALK_IN_FLU_CLINIC  +':'+ localStorage.getItem("ageCheck");
        await addWalkInFluClinic({
          variables: {...values, locationId:locationId},
          refetchQueries: [
            {
              query: GET_RESOURCE_BLOCKS,
              variables: {
                limit: 25,
                offset: 0,
                startDate: formatDate(new Date(), "yyyy-MM-dd"),
                endDate: "9999-01-01",
                locationId: locationId,
              },
            },
          ],
        });
        localStorage.removeItem("ageCheck");
      },
    [addWalkInFluClinic, blocks, locationId],
  );

  const onDelete = useCallback(
    async (block: getResourceBlocks_getResourceBlocks) => {
      await deleteWalkInFluClinic({
        variables: { blockId: block.id.toString() },
        refetchQueries: [
          {
            query: GET_RESOURCE_BLOCKS,
            variables: {
              limit: 25,
              offset: 0,
              startDate: formatDate(new Date(), "yyyy-MM-dd"),
              endDate: "9999-01-01",
              locationId: locationId,
            },
          },
        ],
      });
    },
    [deleteWalkInFluClinic,locationId],
  );

  const onDeletePharmacyCover = useCallback(
    async (slot) => {
      const days: DayInput[] = coverPharmacistSlotsToInput(slot);
      await deleteCoverPharmacist({
        variables: { days },
        refetchQueries: [
          {
            query: GET_COVER_PHARMACIST_DAYS,
            variables: { locationId: locationId }
          },
        ],
      });
    },
    [deleteCoverPharmacist,locationId],
  );

  return (
    <>
      <ModalTitle
        label={WALK_IN_FLU_CLINIC_TYPO.title}
        variant={"subtitle"}
        onBack={history.goBack}
      />
      
      <Typography
        color={"error"}
      >
        {blockDisplay}
      </Typography>

      <Typography
        data-testid={"blurb-text"}
        variant={"body1"}
        color={"textSecondary"}
        spacingAfter={3}
      >
        {WALK_IN_FLU_CLINIC_TYPO.blurb}
      </Typography>
      <ResourceBlockWalkInFluClinicForm
        block={selectedWalkInFluClinic}
        formTitle={"Select clinic type"}
        onSubmit={onSave}
      />
      <Spacing spacing={3} />
      <Typography
        spacingAfter={0}
        data-testid={"resourceBlockForm/title"}
        variant={"h5"}
      >
        Walk-In Flu Clinic 
      </Typography>
      <Divider spacingBefore={1} />
      {blocks && blocks.length > 0 && (
        <div data-testid={"WalkInFluClinic/blocks"}>
          {blocks.map((block, i) => (
            <Block key={i} block={block!} onDelete={onDelete} />
          ))}
        </div>
      )}
      {futureSlots && futureSlots.length > 0 && (
        <div data-testid={"WalkInFluClinic/slots"}>
          {futureSlots.map((slot, i) => (
            <Slot key={i} slot={slot} onDelete={onDeletePharmacyCover} />
          ))}
        </div>
      )}
    </>
  );
}

export default WalkInFluClinic;