import React, { useRef } from "react";
import { LinearProgress, Button } from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import ImageCarosule from "./ImageCarosule";
import { ImagesInterface } from "pages/ClinicalReviewPage/Types";
import { getImages } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import CustomDialog, {
  CustomDialogRef,
} from "pages/ClinicalReviewPage/components/CustomDialog";
import "./viewImages.scss";

interface ViewImagesIterface {
  secondaryTag: string;
  orderId: Number;
}

const ViewImagesDialog = ({ secondaryTag, orderId }: ViewImagesIterface) => {
  const [imageList, setImageList] = React.useState<ImagesInterface[]>([]);
  const [current, setCurrent] = React.useState(1);
  const [isImageLoading, setIsImageLoading] = React.useState<boolean>(true);
  const [imageCount, setImageCount] = React.useState(0);
  const modalRef = useRef<CustomDialogRef>(null);

  const viewImages = (index: number) => {
    modalRef.current?.show();
    setCurrent(index);
    setIsImageLoading(true);
    getImages(orderId, secondaryTag, index - 1)
      .then((res: any) => {
        setImageCount(res.total_images_count);
        let images = res?.images?.map((image: any) => {
          return { title: image?.image_title, src: image?.src, created_by: image?.created_by, created_at: image.created_at };
        });

        setImageList(images);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsImageLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="text"
        onClick={() => viewImages(1)}
        color={"primary"}
        style={{ borderRadius: "25px" }}
        size={"medium"}
        className="button_view"
      >
        View images
      </Button>
      <CustomDialog ref={modalRef} className="view_images-dialog">
        <div className="view_images" style={{ width: "400", height: "400" }}>
          {!isImageLoading && imageList?.length > 0 && (
            <div className="prevNextContainer">
              <KeyboardArrowLeft
                color={current <= 1 ? "disabled" : "primary"}
                className="prev"
                onClick={() => current - 1 >= 1 && viewImages(current - 1)}
              />
              <ImageCarosule
                imageList={imageList}
                current={current}
                imageUploaded={(src: string) => { }}
                imageCount={imageCount}
              />

              <KeyboardArrowRight
                color={current + 1 <= imageCount ? "primary" : "disabled"}
                className="next"
                onClick={() =>
                  current + 1 <= imageCount && viewImages(current + 1)
                }
              />
            </div>
          )}
          {isImageLoading && (
            <div className="mt-20">
              <LinearProgress />
            </div>
          )}
          {!isImageLoading && imageList?.length <= 0 && (
            <p className="imageTitle">No images uploaded</p>
          )}
        </div>
      </CustomDialog>
    </>
  );
};

export default ViewImagesDialog;
