import React from "react";
import {
  Button,
  ModalActions,
  ModalTitle,
  Typography,
  InfoListItem,
} from "@welldigital/components";
import ManageServices from "./ManageServices";

export type ServiceAvailabilityProps = {
  onClose: () => void;
};

const ServiceAvailability: React.FC<ServiceAvailabilityProps> = ({
  onClose,
}) => {
  return (
    <div data-testid={"Modal-component"}>
      <ModalTitle label={"Service availability"} />
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        Any changes to service availability must be made at least 24 hours in
        advance.
      </Typography>
      <InfoListItem label={"Lunch hours"} href={"/lunchhours"} />
      <InfoListItem label={"Appointment hours"} href={"/appointmenthours"} />
      <InfoListItem label={"Scheduled leave"} href={"/scheduledleave"} />
      <ManageServices />
      <InfoListItem label={"Walk-in flu clinic"}  href={"/walkInfluclinic"} />
      <ModalActions>
        <Button
          variant={"outlined"}
          color={"primary"}
          children={"Close"}
          size={"medium"}
          onClick={onClose}
        />
      </ModalActions>
    </div>
  );
};

export default ServiceAvailability;
