import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";

import "./index.scss";
import { formatDate } from "utils/time";

interface CommentProps {
  reviewComment: string;
  reviewDate: string;
  reviewedBy: string;
}

const CommentSection = (comment: { comment: CommentProps[]; }) => {
  const commentData = comment.comment.map((item: CommentProps) => {
    return (<div style={{ paddingBottom: '10px' }}><Typography
      variant="h4"
      className="crp_comments_by text-lower"
      spacingAfter={0}
    >
      {formatDate(item.reviewDate, "dd/MM/yy HH:mm")} {item.reviewedBy}
    </Typography>
      <Typography variant="h6" className="crp_comments_note" spacingAfter={0}>
        {item.reviewComment}
      </Typography>
    </div>)
  })
  return (
    <Grid item xs={12} className="crp_comments_inner">
      <Typography variant="h6" className="crp_comments_title" spacingAfter={1}>
        Clinical notes
      </Typography>
      {commentData}
    </Grid>
  );
};
export default CommentSection;
