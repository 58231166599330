import React, { FC } from "react";
import { useParams } from "react-router-dom";

import WalkInPage from "./WalkInPage";
//import { getLocationId } from "services/auth";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const WalkInContainer: FC = () => {
  const { id } = useParams<{ id: string }>();
 // const [locationId, setLocationId] = useState<string>();
  
  const locationId = useAppSelector(selectLocationId);

  // useEffect(() => {
  //   if (!locationId) {
  //     const getLocId = async () => {
  //       const location = await getLocationId();

  //       setLocationId(location);
  //     };

  //     getLocId();
  //   }
  // }, [locationId, setLocationId]);

  return <WalkInPage serviceId={id} locationId={locationId} />;
};

export default WalkInContainer;
