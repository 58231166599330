import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";

import AddComment from "./CommentSection/AddComment";
import CommentSection from "./CommentSection/CommentSection";
import ViewImagesDialog from "./ViewImageDialog/ViewImagesDialog";
import ViewConsultation from "./ViewConsultation/ViewConsultation";

import OrderItemsForReviewWrapper, {
  PropsTypes,
} from "./OrderItemsForReviewWrapper";

import "./index.scss";
import { formatDate } from "utils/time";
import { formatPrice } from "utils/formatPrice";
import { useAppSelector } from "redux/hooks";
import { selectUserGroup } from "pages/ClinicalReviewPage/reduxSlice/selector";
import { CLINICAL_REVIEW_USER_GROUP } from "../../../../constants";
import { Products } from "pages/ClinicalReviewPage/Types";
import UploadImage from "../UploadImageContainer/UploadImage";
import ConfirmBMI from "../ConfirmBMI/ConfirmBMI";

export const OrderItemsForReview = ({
  product,
  orderId,
  orderDate,
  subscriptionBaseOrderId,
  showHistory,
  idCheckStatus,
  idCheckApiStatus,
  isApprovedAllGSL,
  restrictedItemsApproval,
  onUpdateImages,
  orderNotesForConfirmBMI
}: PropsTypes) => {
  const {
    reviewComment,
    reviewDate,
    reviewedBy,
    orderItemId,
    warningCount = 0,
    exclusionCount,
    lastTimeOrderedOn,
  } = product;
  const [isConfirmBMI, setIsConfirmBMI] = useState(true);
  const userGroup = useAppSelector(selectUserGroup);
  const comment = reviewComment && reviewDate && reviewedBy ? [{ reviewComment, reviewDate, reviewedBy }] : [];
  const isApprovebuttonEnable = (!product.isPhotoUploadSkipped || (orderNotesForConfirmBMI.length > 0));

  orderNotesForConfirmBMI.forEach(notes => {
    comment.push({ reviewComment: notes.noteText, reviewDate: notes.createAt, reviewedBy: notes.createdBy })
  });
  useEffect(() => {
    if (orderNotesForConfirmBMI.length > 0) {
      setIsConfirmBMI(false);
    } else {
      setIsConfirmBMI(product.isPhotoUploadSkipped || false);
    }
  }, [product.isPhotoUploadSkipped, orderNotesForConfirmBMI]);

  return (
    <>
      <div>
        <Grid container className="crp_order_item_container">
          <Grid item xs={12} sm={6} md={4} className="flex-column">
            <Typography variant="subtitle2" spacingAfter={1}>
              Name
            </Typography>
            <Typography variant="caption">{product.productTitle}</Typography>
            {product.productVariation && (
              <Typography variant="caption">
                {product.productVariation}
              </Typography>
            )}
            {showHistory && !!product.subscriptionId && (
              <>
                <Typography variant="caption">
                  Subs Id: {product.subscriptionId}
                </Typography>
                <Typography variant="caption">
                  Issue number: {product.subscriptionIssueNumber}
                </Typography>
              </>
            )}
            {lastTimeOrderedOn && (
              <Typography className="color-warning" variant="h6">
                Last ordered on:{" "}
                {formatDate(lastTimeOrderedOn, "dd/MM/yy hh:mm aa")}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="subtitle2" spacingAfter={1}>
              Price
            </Typography>
            <Typography variant="caption">
              {formatPrice(+product.productPrice)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="subtitle2" spacingAfter={1}>
              Qty
            </Typography>
            <Typography variant="caption" spacingAfter={1}>
              {product.productQuantity}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              variant="subtitle2"
              className="warning_box"
              spacingAfter={1}
            >
              Warnings
            </Typography>
            <Typography
              variant="caption"
              className="warning_box"
              spacingAfter={1}
            >
              {warningCount !== 0 && isConfirmBMI ? warningCount + 1 : warningCount === 0 ? "None" : warningCount}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} justifyContent="flex-end">
            <Typography
              variant="subtitle2"
              className="warning_box"
              spacingAfter={1}
            >
              Exclusions
            </Typography>
            <Typography
              variant="caption"
              className="warning_box"
              spacingAfter={1}
            >
              {product.exclusionCount || "None"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="crp_comments">
          {(product?.reviewStatus.toLowerCase() === "approved" ||
            product?.reviewStatus.toLowerCase() === "rejected" || comment.length > 0) && (
              <CommentSection
                comment={comment}
              />
            )}
          <Grid item className="crp_order_item_button">
            {(product?.secondaryTag.trim().toLowerCase() === "onlinedoctoracne" ||
              product?.secondaryTag.trim().toLowerCase() === "onlinedoctoreczema" ||
              product?.secondaryTag.trim().toLowerCase() === "onlinedoctorhairloss" ||
              product?.secondaryTag.trim().toLowerCase() === "onlinedoctorweightloss") && (<UploadImage
                productId={product.productId}
                orderId={subscriptionBaseOrderId ?? orderId}
                onUpdateImages={onUpdateImages}
                categoryType={product?.secondaryTag.trim().toLowerCase()}
              />)}
            {isConfirmBMI && product?.secondaryTag.trim().toLowerCase() === "onlinedoctorweightloss" && <ConfirmBMI productId={product.productId}
              orderId={subscriptionBaseOrderId ?? orderId} onUpdateImages={onUpdateImages} />}
            {!showHistory &&
              !product.isPhotoUploadSkipped && (product?.secondaryTag.trim().toLowerCase() === "onlinedoctoracne" ||
                product?.secondaryTag.trim().toLowerCase() === "onlinedoctoreczema" ||
                product?.secondaryTag.trim().toLowerCase() === "onlinedoctorhairloss" ||
                product?.secondaryTag.trim().toLowerCase() === "onlinedoctorweightloss") && (
                  <ViewImagesDialog
                  secondaryTag={product.secondaryTag}
                    orderId={subscriptionBaseOrderId ?? orderId}
                  />
              )}
            <ViewConsultation
              orderDate={orderDate}
              traversalId={product.traversalId}
              secondaryTag={product.secondaryTag}
            />
          </Grid>

          {!showHistory &&
            hasAccessToApproveOrDecline(product, userGroup) &&
            (product?.reviewStatus.toLowerCase() === "awaiting review" ||
              product?.reviewStatus.toLowerCase() ===
              "awaiting clinical review") && (
              <Grid item className="crp_order_item_commentbox">
                <AddComment
                  orderId={orderId}
                  productId={orderItemId}
                  wCount={warningCount || exclusionCount}
                  idCheckStatus={idCheckStatus}
                  idCheckApiStatus={idCheckApiStatus}
                  isApprovedAllGSL={isApprovedAllGSL}
                  restrictedItemsApproval={restrictedItemsApproval}
                  isApprovebuttonEnable={isApprovebuttonEnable}
                />
              </Grid>
            )}
        </Grid>
      </div>
    </>
  );
};

export default OrderItemsForReviewWrapper(OrderItemsForReview);

function hasAccessToApproveOrDecline(
  product: Products,
  userGroup?: CLINICAL_REVIEW_USER_GROUP,
) {
  /**
   * Permission: Can approve/decline POMs
   * Pharmacist: No
   * Independent Prescriber: Yes
   * Customer Service Agent: No
   */
  if (
    product.primaryTag?.trim().toLowerCase() === "pom" &&
    [CLINICAL_REVIEW_USER_GROUP.CR_INDEPENDENT_PRESCRIBER].includes(userGroup!)
  ) {
    return true;
  }

  /**
   * Permission: Can approve/decline PMEDs
   * Pharmacist: Yes
   * Independent Prescriber: Yes
   * Customer Service Agent: No
   */
  if (
    product.primaryTag?.trim().toLowerCase() === "pmed" &&
    [
      CLINICAL_REVIEW_USER_GROUP.CR_INDEPENDENT_PRESCRIBER,
      CLINICAL_REVIEW_USER_GROUP.CR_PHARMACIST,
    ].includes(userGroup!)
  ) {
    return true;
  }

  return false;
}
