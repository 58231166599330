import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { EditQuestion } from "../EditQuestion/EditQuestion";
import { Question } from "graphql/types";
import { QuestionAudit } from "utils/question";

export interface EditQuestionsTableProps {
  questionAudits: QuestionAudit[];
  canHighlight?: boolean;
  showAudits?: boolean;
  handleQuestionUpdate: (q: Question) => void;
}

const EditQuestionsTable: FC<EditQuestionsTableProps> = (props) => {
  const { questionAudits, handleQuestionUpdate } = props;

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={"h4"}>Pre-screen questions</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {questionAudits &&
              questionAudits.map((questionAudit: QuestionAudit) => {
                return (
                  <EditQuestion
                    key={`question-${questionAudit.questionNumber}`}
                    question={questionAudit.lastQuestion}
                    updatedQuestion={questionAudit.updatedQuestion}
                    previousQuestions={questionAudit.previousQuestions}
                    handleQuestionUpdate={handleQuestionUpdate}
                    canHighlight={props.canHighlight}
                    showAudits={props.showAudits}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EditQuestionsTable;
