import React, { FC, MouseEvent, useCallback, useState } from "react";
import { ApolloError } from "@apollo/client";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Link,
  Box
} from "@material-ui/core";
import { Button, Modal, ModalActions, Typography, } from "@welldigital/components";
import { Link as RouterLink } from "react-router-dom";
import AppointmentActions from "../../../../components/AppointmentActions/AppointmentActions";
import { formatDateAws, formatDateToInt, formatDate } from '../../../../utils/time';
import { GetAppointments } from "./__generated__";
import { formatTime } from "utils/time";
import { dashboard_getAppointments_getAppointments } from "./__generated__/dashboard_getAppointments";
import { AppointmentStatus } from "__generated__/globalTypes";
import { differenceInYears } from 'date-fns';

export interface SummaryModalContainerProps {
  isModalOpen: boolean;
  closeModal(): void;
}
export interface AppointmentsTableProps {
  isLoading: boolean;
  error?: ApolloError;
  data?: GetAppointments;
  goToServiceDetails(id: string): () => void;
  selectedDate: string;
}

  const AppointmentsTable: FC<AppointmentsTableProps> = (props) => {
  const stopPropagation = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);


  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const closeAvailabilityModal = useCallback(() => {
  setIsModalOpen(false);
  }, [setIsModalOpen]);

  const { isLoading, error, data, goToServiceDetails, selectedDate } = props;
  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;
  if (!data?.getAppointments?.length) {
    return <Typography>No appointments booked</Typography>;
  }
  const { getAppointments: appointmentsUnfiltered } = data;
  const appointments = appointmentsUnfiltered.filter(
    (a: dashboard_getAppointments_getAppointments) => {
      return a.status !== AppointmentStatus.cancelled;
    },
  );
  function getNamedSum(Name: string){
  const countFlu = appointments.reduce((result:number,a) => {
     return (a.course?.name===Name ? result+1 : result)
     },0);
   return countFlu   
  }
   
  const isOver65 = appointments.reduce((result:number,a) => {
    return (a.course?.name?.split(" ").includes("Flu") && calculateAge(a.customer.dob) >= 65 ? result+1 : result)
   },0);
    

  const isUnder65 = appointments.reduce((result:number,a) => {
    return (a.course?.name?.split(" ").includes("Flu") && calculateAge(a.customer.dob) < 65 ? result+1 : result)
    },0);
      
  const validAppointments = appointments.filter((a) => {
    return formatDateAws(a.startTime) === formatDateAws(selectedDate);
  });
  if (validAppointments.length === 0) {
    return <Typography>No appointments booked</Typography>;
  }
  const sortedAppointments = validAppointments.sort(
    (
      a: dashboard_getAppointments_getAppointments,
      b: dashboard_getAppointments_getAppointments,
    ) => formatDateToInt(a.startTime) - formatDateToInt(b.startTime),
  );

  function calculateAge (dob: string){
    return differenceInYears(new Date(selectedDate), new Date(dob) );
  }

  return (
    <Box mt={2}>   
    <Modal
      open={isModalOpen}
      onClose={ closeAvailabilityModal }
      >
     <TableContainer>
        <h2>  Flu Apponintment Summary for {formatDate(selectedDate, "dd-MM-yyyy")}</h2>
        <Table>    
            <TableBody>   
              <TableRow>
                <TableCell>Flu jab (Over-65):</TableCell>
                <TableCell> {isOver65} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Flu jab (Under-65): </TableCell>
                <TableCell>{isUnder65}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PPV</TableCell>
                <TableCell>{getNamedSum("Pneumonia PPV vaccination")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prevenar</TableCell>
                <TableCell>{getNamedSum("Pneumonia PCV (Prevenar13) vaccination")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b> Total Services </b></TableCell>
                <TableCell>{isOver65 + isUnder65 + getNamedSum("Pneumonia PPV vaccination") + getNamedSum("Pneumonia PCV (Prevenar13) vaccination")}</TableCell>
              </TableRow>
            </TableBody> 
        </Table>
      </TableContainer>

        <ModalActions>
          <Button
            variant={"outlined"}
            color={"primary"}
            children={"Close"}
            size={"medium"}
            onClick={()=> setIsModalOpen(isModalOpen ? false :true )}
          />
        </ModalActions>
    </Modal>   
    <Typography
          variant={"h6"}
          color={"secondary"}
          onClick={() =>setIsModalOpen(isModalOpen ? false : true)}
          spacingAfter={0}
        >
          See summary
        </Typography>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Time Slot</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Group Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAppointments.map((appointment) => (
            <TableRow
              key={`appointment-${appointment.id}`}
              hover
            >
              <TableCell onClick={goToServiceDetails(appointment.id)}>
                <Link
                  color={"inherit"}
                  component={RouterLink}
                  onClick={stopPropagation}
                  to={`/customer-details/${appointment.customer.id}`}
                >
                  {`${appointment.customer.firstName} ${appointment.customer.lastName}`}
                </Link>
              </TableCell>

              <TableCell onClick={goToServiceDetails(appointment.id)}>
                {calculateAge(appointment.customer.dob)}
              </TableCell>

              <TableCell onClick={goToServiceDetails(appointment.id)}>
                {`${formatTime(appointment.startTime)} - ${formatTime(
                  appointment.endTime,
                )}`}
              </TableCell>
              <TableCell onClick={goToServiceDetails(appointment.id)}>
              {calculateAge(appointment.customer.dob) >= 65 ? appointment.course?.name + '(Over-65)': appointment.course?.name +'(Under-65)'}
            
              </TableCell>
              <TableCell>
                {appointment.groupId?.split('-').pop() || 'Single Booking'}
              </TableCell>
              <TableCell>
                <AppointmentActions
                  appointmentId={appointment.id}
                  paymentType={appointment.course?.paymentType!}
                  appointmentStatus={appointment.status}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
};

export default AppointmentsTable;
