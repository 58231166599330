import { Button, Typography } from "@welldigital/components";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../app/routers/LoggedInRoutes";
import WalkInActions from "../../components/WalkInActions/WalkinActions";
import { useLocation } from "../../components/AvailabilityModal/ServiceAvailability/hooks/useLocation";
import PharmacyLoader from "./Loader/Loader";

/**
 * When a user (customer) is ineligible to pay for a service (e.g. covid green pack) then this page should be shown
 * A user can be ineligible for a service if there age does not meet the minimum that the service requires
 */
function IneligibleForService() {
  const history = useHistory();
  const { location } = useLocation();

  const goBack = useCallback(() => {
    history.push(ROUTES.walkIn.customerDetails);
  }, [history]);

  const redirectToWalkIns = useCallback(() => {
    history.replace(ROUTES.walkIn.bookAppointment);
  }, [history]);

  return (
    <>
      <Typography variant={"h3"} spacingAfter={3}>
        We’re sorry, we’re unable to complete your booking
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        The information you provided suggests this service might not be suitable
        for you. If you would like more information about this vaccination,
        please speak to the pharmacy team:
      </Typography>
      {!location ? (
        <PharmacyLoader />
      ) : (
        <Typography variant={"body1"} spacingAfter={3}>
          {location.name}
          {location.phone ? ` - ${location.phone}` : ""}
        </Typography>
      )}
      <Typography variant={"body1"} color={"textSecondary"}>
        You can go back if you need to make any changes to your details.
      </Typography>

      <WalkInActions display={"flex"} justifyContent={"center"}>
        <Button
          onClick={goBack}
          variant={"text"}
          size={"medium"}
          children={"Back"}
        />
        <Button
          onClick={redirectToWalkIns}
          variant={"contained"}
          size={"medium"}
          minWidth={"320px"}
          children={"Explore other services"}
        />
      </WalkInActions>
    </>
  );
}

export default IneligibleForService;
