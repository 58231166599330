import { gql } from "@apollo/client";

export const GET_APPOINTMENTS = gql`
  query dashboard_getAppointments($startDate: AWSDate!, $endDate: AWSDate!, $locationId: String! ) {
    getAppointments(startDate: $startDate, endDate: $endDate, locationId: $locationId) {
      id
      startTime
      endTime
      customer {
        id
        firstName
        lastName
        dob
      }
      course {
        id
        name
        paymentType
      }
      status
      groupId
    }
  }
`;
