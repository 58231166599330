import React, { FC } from "react";
import { DatePickerField, Typography } from "@welldigital/components";

const BirthDateField: FC = () => {
  return (
    <>
      <Typography variant={"h4"} spacingAfter={2}>
        Enter their date of birth
      </Typography>
      <DatePickerField
        name={"customerDetails.dob"}
        required
        spacingAfter={3}
        placeholder={"Date of birth"}
        disableFuture
      />
    </>
  );
};
export default BirthDateField;
