import React, { useCallback, useState } from "react";
import {
  Switch,
  Typography,
  ModalTitle,
  ModalActions,
  Button,
  Form,
} from "@welldigital/components";
import useServicePause from "../hooks/useServicePause";
import { useHistory } from "react-router-dom";

export type EmergencyPauseProps = {
  onClose: () => void;
};

const EmergencyPause: React.FC<EmergencyPauseProps> = ({ onClose }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { areAllPaused, updateAllPaused } = useServicePause();
  const history = useHistory();

  const [isPaused, setIsPaused] = useState(areAllPaused);

  const onSubmit = useCallback(async () => {
    await updateAllPaused(isPaused);
    onClose();
    window.location.reload();
  }, [updateAllPaused, isPaused, onClose]);

  const toggleSwitch = useCallback(() => setIsPaused(!isPaused), [
    setIsPaused,
    isPaused,
  ]);
  return (
    <>
      <ModalTitle
        label={"Emergency service change"}
        variant={"subtitle"}
        onBack={history.goBack}
        data-testid={"emergency-modal"}
      />
      <Typography variant={"body1"} color={"error"} spacingAfter={3}>
        This will stop customers booking all services until you switch them on
        again. This should only be used in exceptional circumstances when you
        can't take any appointments.
      </Typography>
      <Form onSubmit={onSubmit}>
        <Switch
          data-testid={"pause-switch"}
          label={"Pause all services"}
          value={isPaused}
          onChange={toggleSwitch}
          variant={"contained"}
          fullWidth
          reversed
        />

        <ModalActions>
          <Button
            data-testid={"cancel-btn"}
            variant={"outlined"}
            color={"primary"}
            children={"Cancel"}
            size={"medium"}
            onClick={onClose}
          />
          <Button
            data-testid={"submit-btn"}
            type={"submit"}
            variant={"contained"}
            color={"secondary"}
            children={"Save"}
            size={"medium"}
          />
        </ModalActions>
      </Form>
    </>
  );
};

export default EmergencyPause;
