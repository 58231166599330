import React from "react";
import { Typography } from "@welldigital/components";
import Question1 from "./Question1";
import Question3 from "./Question3";
import { Screening } from "../../../graphql/fragments/__generated__/Screening";

export interface QuestionsRowProps {
  count: number;
  answer: string;
  onChange(value: string): void;
  question: Screening;
}

const QuestionRow: React.FC<QuestionsRowProps> = ({
  count,
  question,
  answer,
  onChange,
}) => {
  return (
    <>
      <Typography variant={"h5"} spacingAfter={1}>
        Question {count}
      </Typography>
      <Typography
        variant={"body1"}
        data-testid={"QuestionRow/questionText"}
        paragraph
      >
        {question?.questionText}
      </Typography>
      {question?.questionType === 1 && (
        <Question1
          setAnswer={onChange}
          keyId={`${question.currentQuestionNumber}`}
          error={!!!answer}
        />
      )}
      {(question?.questionType === 3 || question?.questionType === 5 )&& (
          <Question3
            setAnswer={onChange}
            keyId={`${question.currentQuestionNumber}`}
            error={!!!answer}
          />
        )}
      {question?.questionType !== 1 &&
        question?.questionType !== 3 &&
        question?.questionType !== 5 && (
          <Typography>Unknown Question Type</Typography>
        )}
    </>
  );
};

export default QuestionRow;
