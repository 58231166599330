import React from "react";
import {
  Alert,
  Button,
  InfoListItem,
  ModalActions,
  ModalTitle,
  Typography,
} from "@welldigital/components";
import { useHistory } from "react-router-dom";
import { useServices } from "./IndividualServices.hooks";

export const LOADING_TEXT = "Loading...";

const IndividualServices: React.FC = () => {
  const history = useHistory();
  const { services } = useServices(false);

  if (!services) return <div>{LOADING_TEXT}</div>;
  return (
    <>
      <ModalTitle
        label={"Manage individual services"}
        variant={"subtitle"}
        onBack={history.goBack}
      />
      <Typography
        data-testid={"services/sub-header"}
        variant={"body1"}
        color={"textSecondary"}
        spacingAfter={2}
      >
        Manage when specific services are available. Any changes will only apply
        to the service you select.
      </Typography>
      {services?.length ? (
        services.map((service, k) => (
          <InfoListItem
            label={service.serviceName}
            key={k}
            href={`/services/${service.serviceId}`}
            {...service}
          />
        ))
      ) : (
        <Alert
          type={"info"}
          message={"The pharmacy does not have any services assigned"}
        />
      )}
      <ModalActions>
        <Button
          variant={"outlined"}
          color={"primary"}
          children={"Back"}
          size={"medium"}
          onClick={history.goBack}
        />
      </ModalActions>
    </>
  );
};

export default IndividualServices;
