import { Grid, Link, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { PharmacyLocationProps } from "../PharmacyLocations";
import { Spacing } from "@welldigital/components";

const useStyles = makeStyles((theme: Theme) => ({
    alphaLink: {
        flex: "1 1 6%",
        color: "#0061F2",
    },
    alphaLinkBlack: {
        flex: "1 1 6%",
        color: "#000000",
    },
    alphabetsAtoN: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0px",
        height: "50px",
      },
      alphabetsMtoZ: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0px 100px",
        height: "50px",
      },
      spanAlpha: {
        fontSize: "32px",
        fontWeight: 600,
        fontStyle: "normal",
        width: "30px",
        textAlign: "center",
        lineHeight: "32px",
      },
      pharmacy: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "32px",
        color: "rgba(0, 0, 0, 0.9)",
        height: "32px",
      },
}),
);
export const AlphabetNav: React.FC<{ locationData: PharmacyLocationProps[] }> = ({ locationData }) => {
    const alphabets: string[] = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"
    ]
    const alphabetsOtoZ: string[] = [
        "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ]
    const classes = useStyles();
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const list: any[] = [];
    const listOtoZ: any[] = [];
    const filterData = locationData.map(item => item.title);

    alphabets.forEach((item) => {
        if (filterData.includes(item)) {
            list.push(<span className= {classes.spanAlpha}><Link key={item} className={classes.alphaLink} href={`#${item}`}>
                {item}
            </Link></span>)
        } else {
            list.push(<span className= {classes.spanAlpha}><Link key={item} className={classes.alphaLinkBlack} href={`#${item}`} onClick={preventDefault}>
                {item}
            </Link></span>)
        }
    });
    alphabetsOtoZ.forEach((item) => {
        if (filterData.includes(item)) {
            listOtoZ.push(<span className= {classes.spanAlpha}><Link key={item} className={classes.alphaLink} href={`#${item}`}>
                {item}
            </Link></span>)
        } else {
            listOtoZ.push(<span className= {classes.spanAlpha}><Link key={item} className={classes.alphaLinkBlack} href={`#${item}`} onClick={preventDefault}>
                {item}
            </Link></span>)
        }
    });
    return (
        <Grid>
            <div className={classes.pharmacy}>Pharmacies</div>
            <Spacing spacing={3} />
            <div className= {classes.alphabetsAtoN}>{list}</div>
            <div className= {classes.alphabetsMtoZ}>{listOtoZ}</div>
        </Grid>
    )
}
