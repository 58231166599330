import { Typography } from "@welldigital/components";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {
  SubscriptionHistoryType,
  SubscriptionHistoryTypeResponse,
} from "../../Types";
import { Products as ProductType } from "../../Types";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../utils/time";
import "./subscription.scss";
import { getSubscriptionHistory } from "pages/ClinicalReviewPage/ClinicalReviewApi";
import Pagination from "pages/ClinicalReviewPage/components/Pagination";
import { formatOrderStatus } from "utils/user";

const ORDER_LIMIT = 10;

const SubscriptionHistory = ({ products }: { products: ProductType[] }) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState<
    SubscriptionHistoryType[]
  >([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [subscriptionIds, setSubscriptionIds] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setSubscriptionIds(
      products
        ?.filter((item) => !!item.subscriptionId)
        ?.reduce((arr, p) => {
          if (!arr.find((a) => a.orderId === p.orderId)) {
            arr.push(p);
          }
          return arr;
        }, [] as ProductType[])
        .map((item) => item.subscriptionId),
    );
  }, [products]);

  useEffect(() => {
    const handleSubscriptionHistory = async () => {
      const subscriptionDetailsReqs = subscriptionIds.map(
        (item: string) =>
          getSubscriptionHistory(
            item,
            ORDER_LIMIT,
            currentPage,
          ) as Promise<SubscriptionHistoryTypeResponse>,
      );

      try {
        const promises = await Promise.allSettled(subscriptionDetailsReqs);
        const [total, history] = promises
          .map((promise) =>
            promise.status === "fulfilled" ? promise.value : undefined,
          )
          .filter(Boolean)
          .reduce(
            ([total, history], data) => [
              total + data!.total,
              [...history, ...data!.orders],
            ],
            [0, []] as [number, SubscriptionHistoryType[]],
          );
        setTotalItems(total);
        setSubscriptionHistory(history);
      } catch (error) {
        console.error("Error fetching subscription history", error);
      }
    };
    if (subscriptionIds.length > 0) {
      handleSubscriptionHistory();
    }
  }, [subscriptionIds, currentPage]);

  return (
    <div>
      {subscriptionIds.length === 0 ? (
        ""
      ) : (
        <div className="w-subscription-history-container">
          <Typography className="w-subscription-history-heading">
            Subscription history
          </Typography>
          <Table>
            <TableHead className="w-subscription-history-tableheading">
              <TableRow>
                <TableCell className="class_header">Date</TableCell>
                <TableCell className="class_header">Order ID</TableCell>
                <TableCell className="class_header">Activity</TableCell>
                <TableCell className="class_header">Reviewed by</TableCell>
                <TableCell className="class_header">Clinical notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionHistory?.map((order) =>
                order.products?.map((product, index: number) => (
                  <TableRow
                    key={`subs_history_${product.subscriptionId}`}
                    className={index % 2 === 0 ? "class1" : "class2"}
                  >
                    <TableCell>
                      {order?.orderdate &&
                        formatDate(order?.orderdate, "dd/MM/yy")}
                    </TableCell>
                    <TableCell>{order?.id}</TableCell>
                    <TableCell>
                      {product?.reviewStatus?.toUpperCase() ===
                      "AWAITING CLINICAL REVIEW"
                        ? "Order created"
                        : formatOrderStatus(product?.reviewStatus)}
                    </TableCell>
                    <TableCell className="text-lower">
                      {product?.reviewedBy}
                    </TableCell>
                    <TableCell>{product?.reviewComment}</TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>

          <hr className="horizonal_rule mt-20" />

          <Pagination
            totalItems={totalItems}
            pageSize={ORDER_LIMIT}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionHistory;
