import { useQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import React, { FC } from "react";
import ServiceQuestions from "./ServiceQuestions";
import { ServiceQuestions_getQuestions} from "./__generated__/ServiceQuestions_getQuestions";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export interface ServiceQuestionsContainerProps {
  appointmentId: string;
}

export const GET_QUESTIONS = gql`
  query ServiceQuestions_getQuestions($appointmentId: String!, $locationId: String!) {
    getAppointment(appointmentId: $appointmentId, locationId: $locationId) {
      id
      course {
        id
        questions {
          answer
          number
          question
        }
      }
    }
  }
`;
const ServiceQuestionsContainer: FC<ServiceQuestionsContainerProps> = (
  props,
) => {
  const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, data, error } = useQuery<
    ServiceQuestions_getQuestions
  >(GET_QUESTIONS, {
    variables: {
      appointmentId: props.appointmentId,
      locationId: locationId
    },
  });
  return <ServiceQuestions isLoading={isLoading} data={data} error={error} />;
};

export default ServiceQuestionsContainer;
