import { useQuery, useMutation } from "@apollo/client/react";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import CompleteServiceDetailsPage from "./CompleteServiceDetailsPage";
import { COMPLETE_APPOINTMENT } from "./gql";
import { MedicineInput } from "../../__generated__/globalTypes";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { trackServiceComplete } from "utils/trackServiceComplete";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const CompleteServiceDetailsContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, error, data } = useQuery<getAppointment>(
    GET_APPOINTMENT,
    {
      variables: { appointmentId: id, locationId: locationId },
    },
  );
  const [completeAppointment] = useMutation(COMPLETE_APPOINTMENT);
  const serviceId = data?.getAppointment?.course.id!;

  const handleComplete = useCallback(
    (medicine: MedicineInput) => {
      trackServiceComplete(serviceId,locationId);

      return completeAppointment({
        variables: {
          appointmentId: id,
          medicine,
          locationId
        },
      });
    },
    [serviceId, completeAppointment, id, locationId],
  );

  return (
    <CompleteServiceDetailsPage
      appointmentId={id}
      data={data}
      error={error}
      isLoading={isLoading}
      onSubmit={handleComplete}
    />
  );
};

export default CompleteServiceDetailsContainer;
