import React, { useState, useCallback } from "react";
import { ApolloError } from "@apollo/client";
import { ExecutionResult } from "graphql";
import ServiceSplitScreen from "components/ServiceSplitScreen/ServiceSplitScreen";
import AppointmentDetails from "./components/AppointmentDetails";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { AppointmentStatus } from "__generated__/globalTypes";
import ChooseBookNextAppointmentModal from "../../components/ChooseBookNextAppointmentModal/ChooseBookNextAppointment";
import { selectBookwellGroup } from "redux/reduxSlice/selector";
import { useAppSelector } from "redux/hooks";

export interface ServiceDetailsPageProps {
  isQuickAppointment: boolean;
  isLoading?: boolean;
  error?: ApolloError;
  data?: getAppointment;
  onSubmit: () => void;
  onCancelAppointment?(
    shouldRefund: boolean,
    reason: string,
  ): Promise<ExecutionResult<any>>;
}

const ServiceDetailsPage: React.FC<ServiceDetailsPageProps> = ({
  data,
  isQuickAppointment,
  isLoading,
  error,
  onSubmit,
  onCancelAppointment,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bookwellGroup = useAppSelector(selectBookwellGroup);
  const onButtonAction = useCallback(() => {
    const apptmt = data!.getAppointment;
    const isSaxenda =
      process.env.REACT_APP_SAXENDA === apptmt?.course.onSchedServiceId;
    if (isSaxenda && apptmt!.number < apptmt!.totalNumber) {
      setIsModalOpen(true);
    } else {
      onSubmit();
    }
  }, [onSubmit, setIsModalOpen, data]);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data) return <p>no data</p>;
  const { getAppointment: appointment } = data;
  if (!appointment) return <p>Appointment not found</p>;
  
  const isActionPanelHidden =
    appointment.status === AppointmentStatus.completed ||
    appointment.status === AppointmentStatus.cancelled;

  return (
    <>
      <BreadCrumbs
        aria-label={"breadcrumb"}
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `/customer-details/${appointment.customer.id}`,
            name: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
          },
          {
            url: `/service-details/${appointment.id}`,
            name: `${appointment.course?.name}`,
          },
        ]}
      />
      <ServiceSplitScreen
        actionButtonText={
          isQuickAppointment ? "Mark as complete" : "Start appointment"
        }
        appointment={appointment}
        isActionDisabled={false}
        onActionSubmit={onButtonAction}
        isActionPanelHidden={isActionPanelHidden}
        isActionButtonHidden={!!bookwellGroup}
        showWarning
        onCancelAppointment={onCancelAppointment}
      >
        <AppointmentDetails
          appointment={appointment}
          isQuickAppointment={isQuickAppointment}
        />
      </ServiceSplitScreen>
      <ChooseBookNextAppointmentModal
        isModalOpen={isModalOpen}
        appointment={appointment}
        completeWithoutMedicine={onSubmit}
      />
    </>
  );
};

export default ServiceDetailsPage;
