import { ApolloError } from "@apollo/client/errors";
import { useEffect, useState } from "react";
import { mockServices } from "../constants/service.mockData";
import { Service } from "../constants/service.constants";

export type ServicesState = {
  isLoading: boolean;
  services: Service[];
  error?: ApolloError;
};

// TODO: remove when implemented
const serviceStore: { services: Service[] | undefined } = {
  services: undefined,
};

export default function useServices(): ServicesState {
  // const { data, error, loading } = useQuery(GET_SERVICES); // TODO: uncomment when backend implemented
  const [data, setData] = useState<Service[] | undefined>(
    serviceStore.services,
  );
  const [error] = useState<ApolloError | undefined>(); // TODO: remove when implemented
  const [isLoading, setIsLoading] = useState<boolean>(true); // TODO: remove when implemented

  // TODO: remove when implemented
  useEffect(() => {
    // remove when implemented
    setTimeout(() => {
      serviceStore.services = mockServices;
      setData(mockServices);
      setIsLoading(false);
    }, 100);
  }, []);

  return {
    isLoading,
    services: data as Service[],
    error,
  };
}
