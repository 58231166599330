import {
  Days,
  formatNumberTimeToString,
  PeriodTypes,
} from "@welldigital/components";
import { format, parse } from "date-fns";
import {
  getServiceBlocks,
  getServiceBlocks_getServiceBlocks,
} from "../__generated__/getServiceBlocks";
import {
  BLOCK_REASON,
  DATE_FORMAT,
  FULL_DAY_MAX_TIME,
  FULL_DAY_MIN_TIME,
  INFINITE_END_DATE,
  PAUSED_REASON,
  ServiceBlock,
  TIME_FORMAT,
} from "./IndividualService.types";

export function getPausedBlock(locationId: string, gqlServiceBlocks?: getServiceBlocks["getServiceBlocks"]
): getServiceBlocks_getServiceBlocks | null {
  if (!gqlServiceBlocks) return null;
  return (
    gqlServiceBlocks.find((block) => block?.locationId=== locationId && block?.reason?.toLowerCase()?.includes('pause')) || null
  );
}

export function parseWeekdays(weekdays: string): Days[] {
  const weekdaysParseMap: { [key: string]: Days } = {
    "0": Days.sun,
    "1": Days.mon,
    "2": Days.tue,
    "3": Days.wed,
    "4": Days.thu,
    "5": Days.fri,
    "6": Days.sat,
  };
  return (weekdays.split("") || []).map((weekday) => weekdaysParseMap[weekday]);
}

export function formatWeekdays(weekdays: Days[]): string {
  const weekdaysFormatMap: { [key in Days]: string } = {
    [Days.sun]: "0",
    [Days.mon]: "1",
    [Days.tue]: "2",
    [Days.wed]: "3",
    [Days.thu]: "4",
    [Days.fri]: "5",
    [Days.sat]: "6",
  };
  return weekdays.map((weekday: Days) => weekdaysFormatMap[weekday]).join("");
}

export function parsePeriodType(weekdays: Days[]) {
  let periodType: PeriodTypes;
  if (
    weekdays.length === 2 &&
    weekdays.includes(Days.sat) &&
    weekdays.includes(Days.sun)
  ) {
    periodType = PeriodTypes.weekends;
  } else if (
    weekdays.length === 5 &&
    weekdays.includes(Days.mon) &&
    weekdays.includes(Days.tue) &&
    weekdays.includes(Days.wed) &&
    weekdays.includes(Days.thu) &&
    weekdays.includes(Days.fri)
  ) {
    periodType = PeriodTypes.weekdays;
  } else {
    periodType = PeriodTypes.allWeek;
  }
  return periodType;
}

export function getDateFromTime(time: number): Date {
  const formattedTime = formatNumberTimeToString(time);
  return parse(formattedTime, TIME_FORMAT, new Date());
}

export function getTimeFromDate(date: Date): number {
  return parseInt(format(date, TIME_FORMAT).replace(":", ""));
}

export function parseBlocks(locationId: string,
  gqlServiceBlocks: getServiceBlocks["getServiceBlocks"],
): ServiceBlock[] {
  return gqlServiceBlocks
    .filter((item) => item && item.locationId=== locationId  && !(item.reason?.toLowerCase()?.includes('pause')))
    .map((item) => {
      const block = item as getServiceBlocks_getServiceBlocks;
      const weekdays = block.repeat?.weekdays
        ? parseWeekdays(block.repeat.weekdays)
        : [];
      return {
        blockId: block.id,
        period:
          block.startTime === FULL_DAY_MIN_TIME &&
          block.endTime === FULL_DAY_MAX_TIME
            ? {}
            : {
                startTime: getDateFromTime(block.startTime),
                endTime: getDateFromTime(block.endTime),
              },
        weekdays,
        periodType: parsePeriodType(weekdays),
      };
    });
}

export function formatBlock<T>(
  block: ServiceBlock,
  serviceId?: string,
  locationId?: string,
  isPauseBlock?: boolean,
): T {
  return ({
    serviceBlockId: block.blockId || undefined,
    serviceId,
    locationId,
    startTime: block.period?.startTime
      ? getTimeFromDate(block.period.startTime)
      : FULL_DAY_MIN_TIME,
    endTime: block.period?.endTime
      ? getTimeFromDate(block.period.endTime)
      : FULL_DAY_MAX_TIME,
    startDate: format(new Date(), DATE_FORMAT),
    endDate: INFINITE_END_DATE,
    reason: isPauseBlock ? PAUSED_REASON : BLOCK_REASON,
    repeats: true,
    repeat: {
      frequency: "W",
      interval: 1,
      weekdays: formatWeekdays(block.weekdays),
    },
  } as unknown) as T;
}

export function createPausedBlock(): ServiceBlock {
  return {
    periodType: PeriodTypes.allWeek,
    weekdays: [
      Days.mon,
      Days.tue,
      Days.wed,
      Days.thu,
      Days.fri,
      Days.sat,
      Days.sun,
    ],
  };
}
