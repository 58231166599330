import React, { FC, memo, useEffect, useState} from "react";
import {
  TableRow,
  TableCell,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  FormControl,
  Typography,
} from "@material-ui/core";
import axios from 'axios';

export const API_HOST = process.env.REACT_APP_INTEG_API;

export interface EditQuestionProps {
  question: QuestionProps;
}
export interface QuestionProps {
    traversal_id: string,
    identifier: string,
    label: string,
    formType: string,
    gender: string,
    age: string,
    selfOrder: string,
    sortkey: string,
    index_sl: string,
    multi_select: [],
    traversal_response: {
      id: string,
      options: {
        checked: boolean,
        label: string,
        correct: boolean
      }[]
    }
  }
  export interface option {
    checked: boolean,
    label: string
  }

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    minWidth: "80px",
  },
  highlight: {
    background: "#EAF2FE;",
  },
}));
export const updateAnswerApi =(payload: any) => {
  return axios.put(`${API_HOST}traversals/updatequestion`, payload);
};

export const EditWegovyQuestion: FC<EditQuestionProps> = memo((props) => {
  const { question} = props;
  const [selectedValue, setSelectedValue] = useState('');
  
  const classes = useStyles();
  useEffect(()=> {
  const finalOption = question.traversal_response.options.filter((item)=> item.checked === true);
  setSelectedValue(finalOption[0]?.label);
  }, [question.traversal_response.options])


  const updateAnswer = ( event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>,)=> {
    setSelectedValue(event.target.value as string);
    question.traversal_response.options.forEach(option => {
      if(option.label === event.target.value as string){
        option.checked = true;
        option.correct = true;
      } else {
        option.checked = false;
        option.correct = false;
      }
    })
    let updateAnswerAPIPayload = [
      { 
        age: question.age,
        gender: question.gender,
        type: question.formType,
        traversal_id: question.traversal_id,
        identifier: question.identifier,
        label: question.label,
        index_sl: question.index_sl,
        multi_select: question.multi_select,
        sortkey: question.sortkey,
        selfOrder: question.selfOrder,
        traversal_responses:
          {
          id: question.traversal_response.id,
          options: question.traversal_response.options
          }
        ,
      }
    ];
    updateAnswerApi(updateAnswerAPIPayload).then(() => {
      console.log('question is updated');
    }).catch(error => {
      console.log('Error is', error);

    })
  }
  return (
    <TableRow
    >
         <TableCell data-testid={"question"} style= {{minWidth: "325px"}}>
        <Typography variant={"body1"}>{question.label}</Typography>
        </TableCell>
      <TableCell data-testid={"updated-answer"} size={"medium"}>
        {
          <FormControl variant={"outlined"}>
            <Select
              inputProps={{
                "data-testid": `editQuestion/answer-select`,
              }}
              value = {selectedValue}
              onChange= {updateAnswer}
              className={classes.input}
              autoWidth
            >
             { question.traversal_response.options.map((option: option) => {
              return  <MenuItem value = {option.label}>{option.label}</MenuItem>
      })}
            </Select>
          </FormControl>
        }
      </TableCell>
    </TableRow>
  );
});
