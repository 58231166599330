import React, { FC } from "react";
import { Breadcrumbs as MuiBreadCrumbs, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { createPortal } from "react-dom";
import usePortal from "../../utils/usePortal";

interface BreadCrumbsProps {
  links: {
    url: string;
    name: string;
  }[];
}

const BreadCrumbs: FC<BreadCrumbsProps> = (props) => {
  return (
    <MuiBreadCrumbs data-testid={"breadcrumbs"} aria-label={"breadcrumb"}>
      {props.links.map((link, index) => (
        <Link
          data-testid={"breadcrumb-link"}
          key={`link-${index}`}
          color={index === props.links.length - 1 ? "textPrimary" : "inherit"}
          aria-current={index === props.links.length - 1 && "page"}
          component={RouterLink}
          to={link.url}
        >
          {link.name}
        </Link>
      ))}
    </MuiBreadCrumbs>
  );
};

const BreadCrumbsPortal: FC<BreadCrumbsProps> = (props) => {
  const breadcrumbPortal = usePortal("breadcrumbs-portal");

  return createPortal(<BreadCrumbs {...props} />, breadcrumbPortal);
};

export default BreadCrumbsPortal;
export * from "./components/container";
