import React from "react";
import { Alert, Button, ButtonProps } from "@welldigital/components";
import { ChevronLeft } from "@material-ui/icons";
import { Link, LinkProps } from "react-router-dom";

export type ErrorAlertProps = {
  message: any;
  className?: string;
  button?: ButtonProps & Partial<LinkProps>;
};

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  message,
  button,
  className = "",
}) => {
  return (
    <Alert
      type={"error"}
      className={className}
      message={
        <>
          {message} <br />
          {button && (
            <>
              <br />
              <Button
                size={"small"}
                variant={"outlined"}
                color={"inherit"}
                startIcon={<ChevronLeft />}
                component={button.to ? Link : undefined}
                {...button}
              />
            </>
          )}
        </>
      }
      spacingAfter={2}
    />
  );
};

export default ErrorAlert;
