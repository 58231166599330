import React, { FC, useCallback } from "react";
import {
  AutoCompleteField,
  AutoCompleteOption,
  InputField,
  Typography,
} from "@welldigital/components";
import { getAddressByPostcodeAPI } from "./api.fetchers";
import { Box } from "@material-ui/core";
import { useFormContext } from "hooks/useFormContext";

export type AddressFieldsProps = {
  isOwner: boolean;
};

export interface PostcodeLookupAddress {
  line1: string;
  line2?: string;
  city: string;
  postcode: string;
  country: string;
}

const AddressFields: FC = () => {
  const { form } = useFormContext();

  const loadData = useCallback(async (searchText) => {
    const addresses = await getAddressByPostcodeAPI(searchText);
    return addresses.map((address) => {
      const { line1, line2, city } = address;
      return {
        label: `${line1}${line2 ? `, ${line2}` : ""}, ${city}`,
        value: address,
      };
    }) as AutoCompleteOption[];
  }, []);

  const handleItemSelect = useCallback(
    (data: Partial<PostcodeLookupAddress>) => {
      const { postcode, line1, line2, city } = data;
      form.setValue("customerDetails.address.postcode", postcode);
      form.setValue("customerDetails.address.line1", line1);
      form.setValue("customerDetails.address.line2", line2);
      form.setValue("customerDetails.address.city", city);
    },
    [form],
  );

  return (
    <Box mt={2}>
      <Typography variant={"h4"} spacingAfter={2}>
        {"Enter their address"}
      </Typography>

      <AutoCompleteField
        name={"customerDetails.address.postcode"}
        label={"Postcode"}
        onItemSelect={handleItemSelect}
        afterSelectChange={({ value }: AutoCompleteOption) => value.postcode}
        loadData={loadData}
        popper={{placement:null}}
        required
      />
      <InputField
        name={"customerDetails.address.line1"}
        label={"Address line 1"}
        required
      />
      <InputField
        name={"customerDetails.address.line2"}
        label={"Address line 2"}
      />
      <InputField
        name={"customerDetails.address.city"}
        label={"City"}
        required
      />
    </Box>
  );
};

export default AddressFields;
