import { gql } from "@apollo/client";

const covidFragment = gql`
  fragment CovidQuestion on CovidQuestion {
    number
    question
    type
  }
`;

export const GET_COVID_QUESTIONS = gql`
  query getCovidQuestions {
    getCovidQuestions {
      ...CovidQuestion
    }
  }
  ${covidFragment}
`;

export const POST_COVID_QUESTIONS = gql`
  mutation postCovidQuestions(
    $screeningID: String!
    $answers: [CovidAnswerInput]
  ) {
    postCovidQuestions(screeningID: $screeningID, answers: $answers) {
      ok
    }
  }
`;
