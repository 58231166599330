import { Appointment, Question } from "graphql/types";
import { getAppointment_getAppointment_course_questions } from "graphql/__generated__/getAppointment";

export function getQuestionAudits(
  appointment: Appointment | null | undefined,
): QuestionAudit[] {
  const questionAudits: QuestionAudit[] = [];

  const questions = appointment?.course.questions;
  if (!questions) {
    throw new Error("could not find any questions");
  }

  for (
    let questionIndex = 0;
    questionIndex < questions.length;
    questionIndex++
  ) {
    const q = questions[questionIndex];
    const questionNumber = q.number;
    const audit = questionAudits.find((a: QuestionAudit) => {
      return a.questionNumber === questionNumber;
    });
    if (!audit) {
      questionAudits.push(getQuestionAudit(questions, questionNumber));
    }
  }
  return questionAudits;
}

function getQuestionAudit(
  questions: Question[],
  questionNumber: number,
): QuestionAudit {
  const previousQuestions = questions.filter((q: Question) => {
    return q.number === questionNumber;
  });
  const lastQuestion = previousQuestions[previousQuestions.length - 1];
  const questionAudit: QuestionAudit = {
    questionNumber: questionNumber,
    updatedQuestion: null,
    previousQuestions,
    lastQuestion,
  };
  return questionAudit;
}

export interface QuestionAudit {
  questionNumber: number;
  updatedQuestion: Question | null;
  previousQuestions: Question[];
  lastQuestion: Question;
}

export function getQuestionAuditIndex(
  questionAudits: QuestionAudit[],
  questionNumber: number,
): number {
  for (let index = 0; index < questionAudits.length; index++) {
    const qa = questionAudits[index];
    if (qa.questionNumber === questionNumber) {
      return index;
    }
  }
  throw new Error("could not find question audit");
}

export function insertQuestionAuditAt(
  array: QuestionAudit[],
  index: number,
  qa: QuestionAudit,
) {
  array.splice(index, 0, qa);
}

export function getUpdatedQuestionAudits(
  questionAudits: QuestionAudit[],
  question: getAppointment_getAppointment_course_questions,
): QuestionAudit[] {
  const questionAuditIndex = getQuestionAuditIndex(
    questionAudits,
    question.number,
  );

  const questionAudit = questionAudits[questionAuditIndex];

  const updatedQuestion =
    questionAudit.lastQuestion.answer !== question.answer
      ? {
          ...question,
          updatedDate: new Date(),
        }
      : null;

  const newQuestionAudit = {
    ...questionAudits[questionAuditIndex],
    updatedQuestion,
  };
  const newQuestionAudits = questionAudits.filter((qa: QuestionAudit) => {
    return qa.questionNumber !== question.number;
  });

  insertQuestionAuditAt(
    newQuestionAudits,
    questionAuditIndex,
    newQuestionAudit,
  );
  return newQuestionAudits;
}
