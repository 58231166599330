import React from "react";
import { Grid, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import OtherOrderItemsModal from "./OtherItemsModal/OtherOrderItemsModal";

import { OrderNotes, Products as ProductType } from "../../Types";
import { formatOrderStatus } from "utils/user";

export interface PropsTypes {
  product: ProductType;
  orderId: number;
  orderDate: string;
  subscriptionBaseOrderId?: number;
  showHistory: Boolean;
  idCheckStatus: string;
  idCheckApiStatus: string;
  isApprovedAllGSL: boolean;
  restrictedItemsApproval?: boolean;
  onUpdateImages:(update:boolean) => void;
  orderNotesForConfirmBMI: OrderNotes[];
}

export default (WrappedComponent: React.FC<PropsTypes>) =>
  ({
    products,
    orderDate,
    idCheckStatus,
    idCheckApiStatus,
    restrictedItemsApproval,
    onUpdateImages,
    orderNotesForConfirmBMI
  }: {
    products: ProductType[];
    orderDate: string;
    idCheckStatus: string;
    idCheckApiStatus: string;
    restrictedItemsApproval?: boolean;
    onUpdateImages:(update:boolean) => void;
    orderNotesForConfirmBMI: OrderNotes[];
  }) => {
    // Shop Appointment changes - Vaccines should not appear in 
    // Items to review
    const productToReview = products.filter(
      ({ primaryTag }) =>
        !["GSL", "VACCINE"].includes(primaryTag.toUpperCase()),
    );

    const gslProducts = products.filter(
      ({ primaryTag }) => primaryTag === "GSL",
    );
    const isApprovedAllGSL = gslProducts
      .filter(({ ageRestricted }) => ageRestricted === true)
      .every(({ reviewStatus }) => reviewStatus.toLowerCase() === "approved");

    const isGSLOnly = productToReview.length > 0 ? false : true;
    return (
      <>
        {isGSLOnly ? (
          <>
            <Typography variant="h3" className="order_items_title">
              Items in order
            </Typography>
            <Table className="gsl-items">
              <TableBody>
                {products?.map(
                  ({
                    productTitle,
                    productPrice,
                    productQuantity,
                    productVariation,
                    ageRestricted,
                  }) => (
                    <TableRow key={`gsl-item-${productTitle}`}>
                      <TableCell>
                        <div className="flex-column">
                          <span>
                            {productTitle}{" "}
                            {ageRestricted === true && <span>*</span>}
                          </span>
                          {/* <span>{productVariation}</span> */}
                        </div>
                      </TableCell>
                      <TableCell align="right" width={80}>
                        Qty {productQuantity}
                      </TableCell>
                      <TableCell align="right" width={80}>
                        £{productPrice}
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </>
        ) : (
          <div>
            <Grid container className="order_items">
              <Grid item xs={6} sm={6}>
                <Typography variant="h3" className="order_items_title">
                  Items for review
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <OtherOrderItemsModal products={gslProducts} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {productToReview &&
                  productToReview.map((product) => (
                    <div
                      key={`item_${product.orderItemId}`}
                      className="crp_order_item"
                    >
                      <div className="crp_order_item_header">
                        <Typography
                          variant="subtitle1"
                          className="crp_order_item_header_item"
                        >
                          {product.primaryTag}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="crp_order_item_header_item"
                        >
                          {formatOrderStatus(product.reviewStatus)}
                        </Typography>
                      </div>
                      <WrappedComponent
                        product={product}
                        orderDate={orderDate}
                        orderId={product.orderId}
                        subscriptionBaseOrderId={
                          product.subscriptionBaseOrderId
                        }
                        showHistory={false}
                        idCheckStatus={idCheckStatus}
                        idCheckApiStatus={idCheckApiStatus}
                        isApprovedAllGSL={isApprovedAllGSL}
                        restrictedItemsApproval={restrictedItemsApproval}
                        onUpdateImages={onUpdateImages}
                        orderNotesForConfirmBMI={orderNotesForConfirmBMI}
                      />
                    </div>
                  ))}
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  };
