import React, { useCallback} from "react";
import { signOut } from "services/auth";
import { useCurrentUserEmail } from "services/auth-hooks";
import Header from "./Header";
import { ORIGIN_URL } from "../../constants";
import { ROUTES } from "app/routers/ClinicalReviewRoutes";

const ConnectedHeader: React.FC = () => {
  const email = useCurrentUserEmail();

  const signOutAction = useCallback(() => {
    sessionStorage.setItem(ORIGIN_URL, ROUTES.clinical.login);
    return signOut();
  }, []);

  return <Header email={email || ""} signOut={signOutAction}/>;
};

export default ConnectedHeader;
