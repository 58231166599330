import { useCallback, useState } from "react";
import {
  FORMTYPE_WG,
  traversalLoad_FB,
  traversalRespond_FB
} from "../api";

import {errorMessages, ageQuestion, genderQuestion, selfOrderQuestion} from "../common/util";

export interface questionType {
  label: string,
  element: string,
  isChild: boolean,
  dirty: boolean,
  id: string,
  field_name: string,
  content: string,
  options: option[]
}
export interface option {
  label: string,
  text: string,
  value: string,
  redirectQid: string,
  correct:boolean
}
export default function useWGForm() {
  const [error, setError] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [formId, setFormId] = useState("");
  
  const [questionId, setQuestionId] = useState("");
  const [radioAnswer, setRadioAnswer] = useState<string[]>([]);
  const [answer, setAnswer] = useState<any[]>([]);
  const [enableButton, setEnableButton] = useState(true);
  const [textArea, setTextArea] = useState("");
  const [textAnswer, setTextAnswer] = useState<any[]>([]);
  const [questionData, setQuestionData] = useState<questionType[]>([]);
  const [responseAnswered, setResponseAnswered] = useState<any>([]);
  const [traversalId, setTraversalId] = useState("");
  const [lastQuestion, setLastQuestion] = useState(false);
  const [terminalQuestion, setTerminalQuestion] = useState(false);
  const [redirection, setRedirection] = useState(false);
  const [terminalQuestionData, setTerminalQuestionData] = useState<questionType[]>([]);
  const [terminalQuestionId, setTerminalQuestionId] = useState("");

  const loadPost = useCallback(async (formname) => {
    try {  
      setLoading(true);

      let payload = { 
        type:  FORMTYPE_WG ,
        formname: formname,
      };

      let res = await traversalLoad_FB(payload);
      if(res?.status === 200) {
        setFormId(res.data.identifier);
       setTraversalId(res.data.traversal_id);
        setQuestionData(res.data.questions);
        setQuestionId(res.data?.questions?.[0]?.id);
      }
    } catch (e:any) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const callRespondApi = useCallback(async () => {
    try {  
      const respondAPIPayload = [
        {
          type: FORMTYPE_WG,
          traversal_id: traversalId,
          identifier: formId,
          content: terminalQuestionData?.[0]?.content,
          label: terminalQuestionData?.[0]?.label,
          traversal_responses:[
            {
            id: terminalQuestionId,
            options: terminalQuestionData?.[0]?.options
            }
          ],
        }
      ];
      let res = await traversalRespond_FB(respondAPIPayload);
      if(res?.status === 200) {
        setRedirection(true);
      }
    } catch (e:any) {
      setError(e);
    } finally {
      
    }
  }, [formId, terminalQuestionData, terminalQuestionId, traversalId]);
  const saveRadioAnswer = useCallback((option, options, row) => {
    setError("");
    setEnableButton(false);
    const newItems = [...options];

    newItems.forEach(elm => {
      if(elm.key === option.key){
        elm.checked = true;
        elm.correct = true;
      } else {
        elm.checked = false;
        elm.correct = false;
      }
    });

    if(newItems){
      setRadioAnswer([...newItems]);
    }
  },[])

  const saveTextAns = (textFieldValue: string, option: any) => {
    setError("");
    setEnableButton(false);
    setTextArea(textFieldValue);
    option.correct = true;
    option.value = textArea;
    setTextAnswer([option]);
  }

  const saveCheckedAns = (checked:boolean, ansId:string, text:string, option:any, questionId:string, className:string, options:any) => {
    setError("");
    setEnableButton(false);
    const newOptions = [...options];
    if(className === 'radio'){
      newOptions.forEach(option => {
        if(option.key === ansId){
          option.correct = true;
          option.checked = true;
          option.radioChecked = true;
        } else {
          option.correct = false;
          option.checked = false;
          if(option.hasOwnProperty('radioChecked')) delete option.radioChecked;
        }
      })
    } else {
      newOptions.forEach(option => {
        if(option.key === ansId){
          if(option.checked === true){
            option.checked = false;
            option.correct = false;
          } else {
            option.checked = true;
            option.correct = true;
          }
        } else {
          if(option.hasOwnProperty('radioChecked')){
            delete option.radioChecked;
            option.checked = false;
            option.correct = false;
          }
        }
      })
    };

    setAnswer(newOptions);
  }

  const submitAnswer = useCallback(async (fieldType, totalLength, currentItem) => {

    let answerChecked = false;
    let elementType = "";
    questionData.forEach((question) => {
      elementType = question.element;
      question.options.forEach(option => {
        if(option.correct === true){
          answerChecked = true;
        }
      })
    })

    if(answerChecked){
      setError("");
      try{
        setEnableButton(true);
        setLoading(true);
        var optionData = [];
        if(fieldType === "Dropdown" || fieldType === "Checkboxes") {
          optionData = answer;
          setAnswer([]);
        } else
        if(fieldType === "RadioButtons"){
          optionData = radioAnswer;
          setRadioAnswer([]);
        } else 
        if(fieldType === "TextArea" || fieldType === "ShortText"){
          optionData = textAnswer;
          setTextArea("");
          setTextAnswer([]);
        } else {
          optionData = questionData?.[0]?.options;
        }

        let gender = "none";
        let age = "0";
        let selfOrder = "yes";

        // When we received multiple response from api, we need to loop and render
      
        if(totalLength > 1){
          if(currentItem !== totalLength-1){
            const answered = [
              {
                type: FORMTYPE_WG,
                traversal_id: traversalId,
                identifier: formId,
                content: questionData?.[currentItem]?.content,
                label: questionData?.[currentItem]?.label,
                traversal_responses:[
                  {
                  id: questionId,
                  options: optionData
                  }
                ],
              }
            ];
            setResponseAnswered([...responseAnswered, ...answered]);
            setQuestionId(questionData?.[currentItem + 1]?.id);
          } else {
            const answeredFinal = [
              {
                type: FORMTYPE_WG,
                traversal_id: traversalId,
                identifier: formId,
                content: questionData?.[currentItem]?.content,
                label: questionData?.[currentItem]?.label,
                traversal_responses:[
                  {
                  id: questionId,
                  options: optionData
                  }
                ],
              }
            ];

            const respondAPIPayload = [...responseAnswered, ...answeredFinal];

            let res = await traversalRespond_FB(respondAPIPayload);
            if(res?.status === 200) {
              setResponseAnswered([]);
              setLoading(false);
              if(res?.data?.questions?.length === 0){
                setTraversalId(res?.data?.traversal_id);
                setLastQuestion(true);
                setTerminalQuestion(false);
              } else {
                if(res?.data?.questions?.length > 1){
                  const filteredQnsData = res?.data?.questions?.filter((question:questionType) => question.isChild === true);
                  setQuestionData(filteredQnsData);
                  setQuestionId(filteredQnsData?.[0]?.id);
                } else if(res?.data?.questions?.[0]?.element === "TerminalText"){
                  setTerminalQuestionData(res?.data?.questions);
                  setTerminalQuestionId(res?.data?.questions?.[0]?.id);
                  setTerminalQuestion(true);
                } else {
                  setQuestionData(res?.data?.questions);
                  setQuestionId(res?.data?.questions?.[0]?.id);
                }
              }
            }
          }
        } else {
          if(genderQuestion.includes(questionData?.[0]?.label.trim())){
            if(fieldType === "RadioButtons"){
              optionData.forEach(option => {
                if(option.correct === true && option.value === 'Yes'){
                  gender = "Male";
                };
              });
            };
          };
          if(ageQuestion.includes(questionData?.[0]?.label.trim())){
            if(fieldType === "RadioButtons"){
              optionData.forEach(option => {
                if(option.correct === true && option.value === 'Yes'){
                  age = "18";
                };
              });
            };
          };
          if(selfOrderQuestion.includes(questionData?.[0]?.label.trim())){
            if(fieldType === "RadioButtons"){
              optionData.forEach(option => {
                if(option.correct === true && option.value === 'Yes'){
                  selfOrder = "yes";
                };
                if(option.correct === true && option.value === 'No'){
                  selfOrder = "no";
                };
              });
            };
          };

          let respondAPIPayload = [
            { 
              type: FORMTYPE_WG,
              traversal_id: traversalId,
              identifier: formId,
              content: questionData?.[0]?.content,
              label: questionData?.[0]?.label,
              traversal_responses:[
                {
                id: questionId,
                options: optionData
                }
              ],
              gender: gender,
              age: age,
              selfOrder: selfOrder,
            }
          ];
    
          let res = await traversalRespond_FB(respondAPIPayload);
          if(res?.status === 200) {
            setResponseAnswered([]);
            setLoading(false);
            if(res?.data?.questions?.length === 0){
              setTraversalId(res?.data?.traversal_id);
              setLastQuestion(true);
              setTerminalQuestion(false);
            } else {
              if(res?.data?.questions?.length > 1){
                const filteredQnsData = res?.data?.questions?.filter((question:questionType) => question.isChild === true);
                setQuestionData(filteredQnsData);
                setQuestionId(filteredQnsData?.[0]?.id);
              } else if(res?.data?.questions?.[0]?.element ==="TerminalText"){
                setTerminalQuestionData(res?.data?.questions);
                setTerminalQuestionId(res?.data?.questions?.[0]?.id);
                setTerminalQuestion(true);
              } else {
                setQuestionData(res?.data?.questions);
                setQuestionId(res?.data?.questions?.[0]?.id);
              }
            }
          }
        } 
    
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
    } else {
      let errorMessage = ""
      if(elementType === 'RadioButtons'){
        errorMessage = 'Select one of the options';
        errorMessage = errorMessages.radioError;
      };
      if(elementType === 'TextArea' || elementType === 'ShortText'){
        errorMessage = errorMessages.textError
      };
      if(elementType === 'Checkboxes' || elementType === 'Dropdown'){
        if(questionData[0]?.options?.length> 1)
        errorMessage = errorMessages.checkboxError 
        else
        errorMessage = errorMessages.singleCheckboxError 
      };
      setError(errorMessage);
    }
  },[traversalId, formId, questionData, questionId, answer, radioAnswer, textAnswer, responseAnswered]);

  return [
    loadPost,
    loading,
    error,
    questionId,
    saveRadioAnswer,
    saveCheckedAns,
    enableButton,
    saveTextAns,
    textArea,
    submitAnswer,
    questionData,
    lastQuestion,
    traversalId,
    terminalQuestion,
    redirection,
    callRespondApi,
    setEnableButton
  ];
}
