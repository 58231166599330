import { useMutation } from "@apollo/client/react";
import { format } from "date-fns";
import { useCallback, useEffect, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { UPDATE_CALENDARS } from "../WorkingHours/gql";
import { AlertContext } from "../../../../pages/DashboardPage/AlertContext.context";
import { updateCalendarVariables } from "../WorkingHours/__generated__/updateCalendar";
//import { getLocationId } from "services/auth";
import { useQuery } from "@apollo/client/react";
import {
  getCalendars,
  getCalendarsVariables,
} from "../WorkingHours/__generated__/getCalendars";
import { CalendarInfo, GET_CALENDARS } from "../WorkingHours/gql";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export type UseWorkingHoursResult = {
  workingHours: IWorkingHours | undefined;
  saveWorkingHours: (block: IWorkingHours[]) => Promise<void>;
  isWorkingHoursSaving: boolean;
};

export interface IWorkingHours {
  startTime: any;
  endTime: any;
  weekdays: any;
}
export type WorkingHoursState = {
  calendarID: (CalendarInfo | null)[] | null | undefined;
  locationId?: string;
};

export function useWorkHours(): WorkingHoursState {
 // const [locationId, setLocationId] = useState<string>();
  const locationId = useAppSelector(selectLocationId);

  // useEffect(() => {
  //   (async () => {
  //     setLocationId(await getLocationId());
  //   })();
  // }, [setLocationId]);

  const { data: dataCalendar } = useQuery<getCalendars, getCalendarsVariables>(
    GET_CALENDARS,
    {
      variables: {
        locationId: locationId as string,
        deleted: false,
        limit: 100,
        offset: 0,
      },
    },
  );

  return {
    calendarID: dataCalendar?.getCalendars,
    locationId,
  };
}
export default function useWorkingHours(): UseWorkingHoursResult {
  const { calendarID, locationId } = useWorkHours();
  const { enqueueAlert } = useContext(AlertContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isWorkingHoursSaving, setWorkingHoursSaving] = useState(false);
  const [workingHours] = useState<UseWorkingHoursResult["workingHours"]>(
    undefined,
  );

  const [updateCalendar] = useMutation<updateCalendarVariables>(
    UPDATE_CALENDARS,
  );

  const saveWorkingHours = useCallback(
    async (workingHours: IWorkingHours[]) => {
      setWorkingHoursSaving(true);
      try {
        let appointmentHours = {
          mon: {
            startTime: 0,
            endTime: 0,
          },
          tue: {
            startTime: 0,
            endTime: 0,
          },
          wed: {
            startTime: 0,
            endTime: 0,
          },
          thu: {
            startTime: 0,
            endTime: 0,
          },
          fri: {
            startTime: 0,
            endTime: 0,
          },
          sat: {
            startTime: 0,
            endTime: 0,
          },
          sun: {
            startTime: 0,
            endTime: 0,
          },
        };
        if (!workingHours) {
          return;
        } else {
          workingHours.forEach((days) => {
            days.weekdays.forEach((day: any) => {
              if (day === "mon") {
                appointmentHours.mon.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.mon.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              } else if (day === "tue") {
                appointmentHours.tue.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.tue.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              } else if (day === "wed") {
                appointmentHours.wed.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.wed.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              } else if (day === "thu") {
                appointmentHours.thu.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.thu.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              } else if (day === "fri") {
                appointmentHours.fri.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.fri.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              } else if (day === "sat") {
                appointmentHours.sat.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.sat.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              } else {
                appointmentHours.sun.startTime = parseInt(
                  format(days.startTime, "HHmm"),
                );
                appointmentHours.sun.endTime = parseInt(
                  format(days.endTime, "HHmm"),
                );
              }
            });
          });
        }

        await updateCalendar({
          variables: {
            input: {
              id:
                calendarID && calendarID[0]?.id
                  ? calendarID[0]?.id?.toString()
                  : "1",
              locationId: locationId,
              name: "Main",
              type: "resource",
              interval: 1,
              bookingsPerSlot: 1,
              resourceGroupId: "",
              availability: {
                mon: {
                  startTime: appointmentHours.mon.startTime,
                  endTime: appointmentHours.mon.endTime,
                },
                tue: {
                  startTime: appointmentHours.tue.startTime,
                  endTime: appointmentHours.tue.endTime,
                },
                wed: {
                  startTime: appointmentHours.wed.startTime,
                  endTime: appointmentHours.wed.endTime,
                },
                thu: {
                  startTime: appointmentHours.thu.startTime,
                  endTime: appointmentHours.thu.endTime,
                },
                fri: {
                  startTime: appointmentHours.fri.startTime,
                  endTime: appointmentHours.fri.endTime,
                },
                sat: {
                  startTime: appointmentHours.sat.startTime,
                  endTime: appointmentHours.sat.endTime,
                },
                sun: {
                  startTime: appointmentHours.sun.startTime,
                  endTime: appointmentHours.sun.endTime,
                },
              },
            },
          },
        });
        enqueueAlert({
          type: "success",
          message: "You have successfully added appointment hours",
        });
      } catch (e) {
        enqueueSnackbar("Appointment hours not saved", { variant: "error" });
      }
      setWorkingHoursSaving(false);
    },
    [calendarID, locationId, enqueueAlert, enqueueSnackbar, updateCalendar],
  );

  useEffect(() => {
    if (isWorkingHoursSaving) return;
  }, [isWorkingHoursSaving]);
  return { workingHours, saveWorkingHours, isWorkingHoursSaving };
}
