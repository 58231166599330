import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { TableCellProps } from "@material-ui/core";
import ClinicalReviewPageWrapper from "./ClinicalReviewPageWrapper";
import { Order as OrderTypes, ProductDetails ,IdChecks, PreApprovedProductCustomerList} from "./Types";
import "./index.scss";
import { Typography } from "@welldigital/components";
import CustomTable, { CustomTableHeaderProps } from "./components/Table";
import { formatOrderStatus } from "utils/user";
import { formatDate } from "utils/time";
import Pagination, { PaginationProps } from "./components/Pagination";

export interface ClinicalReviewPageProps extends PaginationProps {
  orderList: OrderTypes[];
  status: "COMPLETED" | "AWAITING_REVIEW" | "AWAITING_ID_CHECK" | "ID_CHECK_COMPLETED" | "PREAPPROVEDPRODUCT";   // "PREAPPROVEDPRODUCT" is not any status, this is to differenciate the PREAPPROVEDPRODUCT landing page.
  category: string | undefined;
  sortKey: string;
  isAscending: boolean;
  loading?: boolean;
  onSortChange: (sortKeyName: string) => void;
  idChecks : IdChecks[];
  preApprovedProductCustomerList: PreApprovedProductCustomerList[];
}

const ClinicalReviewOrderList: React.FC<ClinicalReviewPageProps> = ({
  orderList,
  status,
  category,
  loading,
  sortKey,
  isAscending,
  onSortChange,
  idChecks,
  preApprovedProductCustomerList,
  ...paginationProps
}) => {
  const columns: CustomTableHeaderProps<OrderTypes>[] = useMemo(() => {
   
    const columns: CustomTableHeaderProps<OrderTypes>[] = (category !== "id-check"  && category !== "preapprovedproduct") ? [
      {
        header: "Order ID",
        keyExtractor: "id",
        cellProps: { ...CELL_PROPS, width: "22%" },
        transform: (data) => {
          const subId = data.products?.find(
            (product) => !!product.subscriptionId,
          );
          return (
            <>
              <Link to={`/clinical-review/order/${data.id}`}>{data.id}</Link>
              <Typography>
                {subId ? `Contains subscription product` : ""}
              </Typography>
            </>
          );
        },
      },
      {
        header: "POM/PMed",
        keyExtractor: "categories",
        cellProps: { ...CELL_PROPS, width: "12%" },
        isSortable: true,
        sortKeyName: "category",
        onSortChange,
        transform: (data) => {
          //   const issueNumber = data.products?.find(
          //     (product) => !!product.subscriptionIssueNumber,
          //   );
          return (
            <>
              {status !== "COMPLETED" ? (
                <>
                  {data?.categories?.length === 1 && data.categories[0] === "GSL" ? data.categories[0] : data.categories
                    ?.filter((categ) => !["GSL", "VACCINE"].includes(categ.toUpperCase()))
                    ?.join(" and ")}
                  {/* <Typography>
                    {issueNumber
                      ? `Subscription issue ${issueNumber.subscriptionIssueNumber}`
                      : ""}
                  </Typography> */}
                </>
              ) : (
                <>
                  {filterProducts(data.products)?.map((product, index) => (
                    <div className="multi-line-info" key={`category_${index}`}>
                      {product.primaryTag}
                      {/* <Typography>
                        {issueNumber
                          ? `Subscription issue ${issueNumber.subscriptionIssueNumber}`
                          : ""}
                      </Typography> */}
                    </div>
                  ))}
                </>
              )}
            </>
          );
        },
      },
      {
        header: "Status",
        keyExtractor: "orderStatus",
        cellProps: { ...CELL_PROPS, width: "13%" },
        transform: (data) => formatOrderStatus(data.orderStatus),
      },
      {
        header: "Order Date",
        keyExtractor: "orderdate",
        isSortable: true,
        sortKeyName: "date",
        onSortChange,
        cellProps: { ...CELL_PROPS, width: "12%" },
        transform: (data) => formatDate(data.orderdate, "dd/MM/yy"),
      },
    ] : [];
    if(category !== "id-check" && category !== "preapprovedproduct"){
    if (status === "COMPLETED") {
      columns.push(
        {
          header: "Item",
          keyExtractor: "products",
          transform: (data) =>
            filterProducts(data.products)?.map((product, index) => (
              <div className="multi-line-info" key={`item_${data.id}_${index}`}>
                {product.productTitle}
              </div>
            )),
        },
        {
          header: "Approved/Rejected",
          keyExtractor: "products",
          transform: (data) =>
            filterProducts(data.products)?.map((product, index) => (
              <div
                className="multi-line-info"
                key={`status_${data.id}_${index}`}
              >
                <span>{formatOrderStatus(product.reviewStatus)}</span>
                <span>
                  {product.reviewDate &&
                    formatDate(product.reviewDate, "dd/MM/yy HH:mm")}{" "}
                  <span className="text-lower">{product.reviewedBy}</span>
                </span>
              </div>
            )),
        },
      );
    } else {
      columns.push(
        {
          header: "Shipping method",
          keyExtractor: "shippingMethod",
          cellProps: { ...CELL_PROPS, width: "10%" },
          transform: (data) => data.shippingMethod,
        },
        {
          header: "Warnings",
          keyExtractor: "idCheckStatus" || "warnings",
          cellProps: { ...CELL_PROPS, width: "8%" },
          transform: (data) => (data.warnings ? "Warnings" : (data?.idCheckStatus !=="" && data?.idCheckStatus.toLowerCase()!=="pass" ) ? data.idCheckStatus : data?.idCheckApiStatus?.toLowerCase()==="failure" ? "API failure" : "" ),
        },
        {
          header: "Comments",
          keyExtractor: "comment",
          transform: (data) =>
            data.comment?.text ? (
              <span>
                {data.comment?.createdOn &&
                  formatDate(data.comment?.createdOn, "dd/MM/yy HH:mm")}{" "}
                <span className="text-lower">{data.comment?.createdBy}</span>{" "}
                {data.comment?.text}
              </span>
            ) : (
              ""
            ),
        },
      );
    }
  }
    return columns;
 
  }, [status,category, onSortChange]);

  const idCheckColumns : CustomTableHeaderProps<IdChecks>[] = useMemo(()=>{
    const idCheckColumns : CustomTableHeaderProps<IdChecks>[]= category === "id-check"?[
      {
        header: "User ID",
        keyExtractor: "userId",
        cellProps: { ...CELL_PROPS, width: "13%" },
       // transform: (data) => formatOrderStatus(data.userId),
      },
      {
        header: "Order ID",
        keyExtractor: "orderId",
        cellProps: { ...CELL_PROPS, width: "13%" },
        transform: (data) =>  <Link to={`/clinical-review/order/${data.orderId}`}>{data.orderId}</Link>,
      },
      {
        header: "Order date",
        keyExtractor: "orderDate",
        cellProps: { ...CELL_PROPS, width: "13%" },
        transform: (data) => formatDate(data.orderDate, "dd/MM/yy"),
      }
    ] : [];
    if(category === "id-check"){
    if(status === "AWAITING_ID_CHECK"){
      idCheckColumns.push({
          header: "Warnings",
          keyExtractor: "idCheckApiStatus",
          cellProps: { ...CELL_PROPS, width: "13%" },
          transform: (data) => { return  data.idCheckApiStatus?.toLowerCase() === "failure" ? 'API failure' : '' },
        });
    }else{
      idCheckColumns.push({
        header: "ID check status",
        keyExtractor: "idCheckStatus",
        cellProps: { ...CELL_PROPS, width: "13%" },
       // transform: (data) => formatOrderStatus(data.orderStatus),
      });
    }
  }
    return idCheckColumns;
  },[status,category]);


  const preApprovedProductColumns : CustomTableHeaderProps<PreApprovedProductCustomerList>[] = useMemo(()=>{
    const preApprovedProductColumns : CustomTableHeaderProps<PreApprovedProductCustomerList>[]= category === "preapprovedproduct"?[
      {
        header: "User ID",
        keyExtractor: "cognitoId",
        cellProps: { ...CELL_PROPS, width: "30%" },
        transform: (data) =>  <Link to={`/clinical-review/customer/${data.id}?treatment=${data.treatmentName}`}>{data.cognitoId}</Link>,
      },
      {
        header: "Customer name",
        keyExtractor: "firstName",
        cellProps: { ...CELL_PROPS, width: "20%" },
        transform: (data) =>  `${data.firstName} ${data.lastName}`,
      },
      {
        header: "Treatment",
        keyExtractor: "treatmentName",
        cellProps: { ...CELL_PROPS, width: "10%" },
        transform: (data) =>  `${data.treatmentName}`,
      },
      {
        header: "Last order approval date",
        keyExtractor: "lastApprovalDate",
        isSortable: true,
        sortKeyName: "lastApprovalDate",
        onSortChange,
        cellProps: { ...CELL_PROPS, width: "20%" },
        transform: (data) => data?.lastApprovalDate && formatDate(data.lastApprovalDate, "dd/MM/yy"),
      },
      {
        header: "Next review due",
        keyExtractor: "nextReviewDate",
        isSortable: true,
        sortKeyName: "nextReviewDate",
        onSortChange,
        cellProps: { ...CELL_PROPS, width: "20%" },
        transform: (data) => data?.nextReviewDate && formatDate(data.nextReviewDate, "dd/MM/yy"),
      }
    ] : [];
    
    return preApprovedProductColumns;
  },[category,onSortChange])
  
  const title = useMemo(() => {
    return status === "COMPLETED"
      ? `Completed Orders` 
      : status === "AWAITING_ID_CHECK" ? `Awaiting ID check`: status === "ID_CHECK_COMPLETED" ? `ID check completed` : status === "PREAPPROVEDPRODUCT" ? `Weight management patients` : `Awaiting clinical review`;
  }, [status]);

  const categoryTitle = useMemo(() => {
    return  category === "id-check" || category === "preapprovedproduct" ? `` : category ? `(${category}s)` : "(all)";
  }, [category]);

  return (
    <div className="crp-dashboard-container">
      <Typography className="crp-dashboard-title">
        {title} {categoryTitle}
      </Typography>
      {category==="id-check" ?<CustomTable
        sortKey={sortKey}
        isAscending={isAscending}
        data={idChecks}
        loading={loading}
        columns={idCheckColumns}
      />
      :
      category==="preapprovedproduct" ? <CustomTable
        sortKey={sortKey}
        isAscending={isAscending}
        data={preApprovedProductCustomerList}
        loading={loading}
        columns={preApprovedProductColumns}
      />
    :
    <CustomTable
        sortKey={sortKey}
        isAscending={isAscending}
        data={orderList}
        loading={loading}
        columns={columns}
      />}
      

      <Pagination status={status} category={category} {...paginationProps} />
    </div>
  );
};

export default ClinicalReviewPageWrapper(ClinicalReviewOrderList);

const CELL_PROPS: TableCellProps = { align: "left" };

const filterProducts = (products?: ProductDetails[]) => {
  const gslFiltered = products?.filter(
    (product) => product.primaryTag !== "GSL",
  );

  return gslFiltered;
};
