import {
  Button,
  Divider,
  ModalTitle,
  Spacing,
  Typography,
} from "@welldigital/components";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import ResourceBlockForm from "../ResourceBlockForm/ResourceBlockForm";
import { useMutation } from "@apollo/client/react";
import {
  CREATE_COVER_PHARMACIST,
  CREATE_RESOURCE_BLOCK,
  DELETE_COVER_PHARMACIST_BLOCK,
  DELETE_RESOURCE_BLOCK,
  GET_COVER_PHARMACIST_DAYS,
  GET_RESOURCE_BLOCKS,
} from "../../gql";
import {
  getResourceBlocks,
  getResourceBlocks_getResourceBlocks,
} from "../../__generated__/getResourceBlocks";
import {
  createCoverPharmacistDays,
  createCoverPharmacistDaysVariables,
} from "../../__generated__/createCoverPharmacistDays";
import {
  createResourceBlock,
  createResourceBlockVariables,
} from "../../__generated__/createResourceBlock";
import {
  deleteCoverPharmacistDays,
  deleteCoverPharmacistDaysVariables,
} from "../../__generated__/deleteCoverPharmacistDays";
import {
  deleteResourceBlock,
  deleteResourceBlockVariables,
} from "../../__generated__/deleteResourceBlock";
import { DayInput } from "../../../../__generated__/globalTypes";
import {
  coverPharmacistDaysToSlots,
  coverPharmacistSlotsToInput,
} from "../../coverPharmacistSlots";
import { getCoverPharmacistDays } from "../../__generated__/getCoverPharmacistDays";
import { SCHEDULED_LEAVE_REASON } from "./ScheduledLeaveContainer";
import Slot from "./Slot";
import Block from "./Block";
import { makeStyles, Theme } from "@material-ui/core";
import { AlertContext } from "../../../../pages/DashboardPage/AlertContext.context";
import { formatDate } from "../../../../utils/time";
import { AddBlockRequestVariables } from "../ResourceBlockForm/types";
import { getCoverDays } from "../AddCoverPharmacist/utils";
import { add } from "date-fns";
import { useSnackbar } from "notistack";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";

export const SCHEDULED_LEAVE_TYPO = {
  title: "Scheduled leave",
  blurb: "Customers will not be able to book appointments during this time.",
  buttons: {
    back: "Back",
    delete: "Delete",
    save: "Save",
  },
};

const styles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export interface ScheduledLeaveProps {
  blocks: getResourceBlocks["getResourceBlocks"];
  coverDays: getCoverPharmacistDays;
  onClose: () => void;
  locationId: string;
}

export type ScheduledLeaveFormType = Omit<
  AddBlockRequestVariables,
  "startTime" | "endTime"
> & {
  startTime: Date;
  endTime: Date;
};

const time = new Date();

export const setStartDate = () => {
  const d = new Date();
  d.setHours(9, 0);
  return d;
};

export const setNewEndDate = () => {
  const d = new Date();
  d.setHours(17, 0);
  return d;
};

export const createInput = () => {
  return Object.assign({}, {
    reason: SCHEDULED_LEAVE_REASON,
    startDate: add(time, { days: 1 }),
    endDate: add(time, { days: 1 }),
    repeat: { frequency: "", monthDay: 0 },
    startTime: setStartDate(),
    endTime: setNewEndDate(),
    allDay: false,
  } as ScheduledLeaveFormType);
};

function ScheduledLeave({ blocks, coverDays, onClose, locationId}: ScheduledLeaveProps) {
  const classes = styles();
 // const locationId = useAppSelector(selectLocationId);
  const { enqueueAlert } = useContext(AlertContext);
  const { enqueueSnackbar } = useSnackbar();

  const slots = useMemo(() => coverPharmacistDaysToSlots(coverDays), [
    coverDays,
  ]);
  const currentDate = new Date();
  const futureSlots = slots.filter((s) => s.until > currentDate);

  const history = useHistory();

  const [addScheduledLeave] = useMutation<
    createResourceBlock,
    createResourceBlockVariables
  >(CREATE_RESOURCE_BLOCK, {
    onCompleted: () => {
      enqueueAlert({
        type: "success",
        message: "Successfully added schedule leave",
      });
      onClose();
      window.location.reload();
    },
    onError: (error) => {
      enqueueSnackbar("Failed to add scheduled leave", { variant: "error" });
      console.error(error);
    },
  });
  const [deleteScheduledLeave] = useMutation<
    deleteResourceBlock,
    deleteResourceBlockVariables
  >(DELETE_RESOURCE_BLOCK, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted schedule leave", {
        variant: "success",
      });
      window.location.reload();
    },
    onError: (error) => {
      enqueueSnackbar("Failed to delete scheduled leave", { variant: "error" });
      console.error(error);
    },
  });

  const [selectedScheduledLeave] = useState<ScheduledLeaveFormType>(
    createInput,
  );
  const [createCoverPharmacist] = useMutation<
    createCoverPharmacistDays,
    createCoverPharmacistDaysVariables
  >(CREATE_COVER_PHARMACIST, {
    onCompleted: () => {
      enqueueAlert({
        type: "success",
        message: "Successfully created cover pharmacist",
      });
      onClose();
      window.location.reload();
    },
    onError: (error) => {
      enqueueSnackbar("Failed to create cover pharmacist", {
        variant: "error",
      });
      console.error(error);
    },
  });
  const [deleteCoverPharmacist] = useMutation<
    deleteCoverPharmacistDays,
    deleteCoverPharmacistDaysVariables
  >(DELETE_COVER_PHARMACIST_BLOCK, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted cover pharmacist", {
        variant: "success",
      });
      window.location.reload();
    },
    onError: (error) => {
      enqueueSnackbar("Failed to delete cover pharmacist", {
        variant: "error",
      });
      console.error(error);
    },
  });

  const onSave = useCallback(
    async (values: AddBlockRequestVariables, locum: boolean) => {
      if (locum) {
        const days = getCoverDays(values.startDate, new Date(values.endDate));
        createCoverPharmacist({
          variables: { days },
          refetchQueries: [
            {
              query: GET_COVER_PHARMACIST_DAYS,
              variables: { locationId },
            },
          ],
        });
      } else {
        values.reason = SCHEDULED_LEAVE_REASON;
        values.locationId = locationId;
        await addScheduledLeave({
          variables: values,
          refetchQueries: [
            {
              query: GET_RESOURCE_BLOCKS,
              variables: {
                limit: 25,
                offset: 0,
                startDate: formatDate(new Date(), "yyyy-MM-dd"),
                endDate: "9999-01-01",
                locationId: locationId
              },
            },
          ],
        });
      }
    },
    [addScheduledLeave, createCoverPharmacist,locationId],
  );

  const onDelete = useCallback(
    async (block: getResourceBlocks_getResourceBlocks) => {
      await deleteScheduledLeave({
        variables: { blockId: block.id.toString() },
        refetchQueries: [
          {
            query: GET_RESOURCE_BLOCKS,
            variables: {
              limit: 25,
              offset: 0,
              startDate: formatDate(new Date(), "yyyy-MM-dd"),
              endDate: "9999-01-01",
              locationId: locationId
            },
          },
        ],
      });
    },
    [deleteScheduledLeave,locationId],
  );

  const onDeletePharmacyCover = useCallback(
    async (slot) => {
      const days: DayInput[] = coverPharmacistSlotsToInput(slot);
      await deleteCoverPharmacist({
        variables: { days },
        refetchQueries: [
          {
            query: GET_COVER_PHARMACIST_DAYS,
            variables: { locationId },
          },
        ],
      });
    },
    [deleteCoverPharmacist,locationId],
  );

  return (
    <>
      <ModalTitle
        label={SCHEDULED_LEAVE_TYPO.title}
        variant={"subtitle"}
        onBack={history.goBack}
      />
      <Typography
        data-testid={"blurb-text"}
        variant={"body1"}
        color={"textSecondary"}
        spacingAfter={3}
      >
        {SCHEDULED_LEAVE_TYPO.blurb}
      </Typography>
      <ResourceBlockForm
        block={selectedScheduledLeave}
        formTitle={"Enter leave below"}
        shouldShowLocumField
        shouldHideReason
        onSubmit={onSave}
      />
      <Spacing spacing={3} />
      <Typography
        spacingAfter={0}
        data-testid={"resourceBlockForm/title"}
        variant={"h5"}
      >
        {SCHEDULED_LEAVE_TYPO.title}
      </Typography>
      <Divider spacingBefore={1} />
      {blocks && blocks.length > 0 && (
        <div data-testid={"ScheduledLeave/blocks"}>
          {blocks.map((block, i) => (
            <Block key={i} block={block!} onDelete={onDelete} />
          ))}
        </div>
      )}
      {futureSlots && futureSlots.length > 0 && (
        <div data-testid={"ScheduledLeave/slots"}>
          {futureSlots.map((slot, i) => (
            <Slot key={i} slot={slot} onDelete={onDeletePharmacyCover} />
          ))}
        </div>
      )}
      <div className={classes.buttonContainer}>
        <Button
          data-testid={"back-button"}
          onClick={history.goBack}
          variant={"outlined"}
          color={"primary"}
        >
          {SCHEDULED_LEAVE_TYPO.buttons.back}
        </Button>
      </div>
    </>
  );
}

export default ScheduledLeave;
