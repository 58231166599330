import React, { useState, useCallback } from "react";
import { formatDate } from "utils/time";
import { getDay } from "date-fns";
import {
  Button,
  Form,
  FormConfirmationModalProps,
  InputField,
  Input,
  ModalActions,
  SelectField,
  TimeSlotsField,
  Typography,
  Checkbox,
} from "@welldigital/components";
import { AddBlockRequestVariables } from "./types";
import { getRepeatInput } from "./utils";
import {
  ScheduledLeaveFormType,
  SCHEDULED_LEAVE_TYPO,
  setNewEndDate,
  setStartDate,
} from "../ScheduledLeave/ScheduledLeave";
import { FrequencyCodes } from "../../types/frequency";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import RescheduleConfirmation from "../../IndividualService/RescheduleConfirmation/RescheduleConfirmation";


export interface ResourceBlockFormProps {
  onSubmit: (values: AddBlockRequestVariables, locum: boolean) => void;
  block?: ScheduledLeaveFormType | null;
  mockTime?: Date;
  formTitle?: string;
  shouldShowLocumField?: boolean;
  shouldHideReason?: boolean;
}

// e.g. 9am becomes 900
function getNumberHoursAndMins(d: Date): number {
  return d.getHours() * 100 + d.getMinutes();
}

export function convertInputBlockToVars(
  values: ScheduledLeaveFormType,
): AddBlockRequestVariables {
  const weekdayCode = getDay(new Date(values.startDate)).toString();
  const monthDay = new Date(values.startDate).getDate();

  const repeat = getRepeatInput(
    values.repeat?.frequency ?? "",
    weekdayCode,
    monthDay,
  );

  const startDateTime = new Date(values.startTime ?? setStartDate());
  const endDateTime = new Date(values.endTime ?? setNewEndDate());

  return {
    ...values,
    startDate: formatDate(values.startDate, "yyyy-MM-dd"),
    endDate: formatDate(values.endDate, "yyyy-MM-dd"),
    repeat,
    repeats: !!repeat,
    startTime: getNumberHoursAndMins(startDateTime),
    endTime: getNumberHoursAndMins(endDateTime),
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  subDividerText: {
    marginBottom: theme.spacing(2),
  },
  selectBoxes: {
    width: "50%",
    marginBottom: theme.spacing(1),
  },
  freeTextContainer: {
    marginTop: theme.spacing(1),
  },
  centeredTypography: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
  },
  inlineTypog: {
    margin: theme.spacing(1, 1.5, 0, 0),
  },
}));

const frequencyOptions = [
  { label: "Weekdays", value: FrequencyCodes.Weekdays },
  { label: "Daily", value: FrequencyCodes.Daily },
  { label: "Weekly", value: FrequencyCodes.Weekly },
  { label: "Monthly", value: FrequencyCodes.Monthly },
  { label: "No Frequency", value: "" },
];

function ResourceBlockForm({
  block,
  onSubmit,
  ...props
}: ResourceBlockFormProps) {
  // a locum is a substitute pharmacist who only delivers the flu service
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [locumPresent, setLocumPresent] = useState(false);
  const [isAllDay, setAllDay] = useState(false);
  const [defaultEndDate, setDefaultEndDate] = useState("");

  const {
    formTitle,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shouldShowLocumField: showLocumField = false,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shouldHideReason: hideReason = false,
  } = props;

  const title = formTitle ?? "Add a blocked time";

  const classes = useStyles();

  const handleLocumPresentCheckbox = useCallback(() => {
    setLocumPresent(!locumPresent);
  }, [setLocumPresent, locumPresent]);

  const handleAllDayCheckbox = useCallback(() => {
    setAllDay(!isAllDay);
  }, [setAllDay, isAllDay]);




  const updateStartDate = useCallback(
    (values: ScheduledLeaveFormType) => {
      ;
      const time = new Date(values.toString());
      var numberOfDaysToAdd = 0;
      var date = time.setDate(time.getDate() + numberOfDaysToAdd);
      var defaultValue = new Date(date).toISOString().split("T")[0];
      setDefaultEndDate(defaultValue);
    }, []);


  const updateEndDate = useCallback(
    (values: ScheduledLeaveFormType) => {
      setDefaultEndDate(values.toString());
    }, []);
  const doSubmit = useCallback(
    (values: ScheduledLeaveFormType,) => {
      try {
        const formValues = convertInputBlockToVars({
          ...values,
          allDay: isAllDay,
          endDate: defaultEndDate,
        });

        onSubmit(formValues, locumPresent);
      } catch (e) {
        console.error("failed to submit form: ", e);
      }
    },
    [onSubmit, isAllDay, locumPresent, defaultEndDate],
  );

  const renderConfirmationModal = useCallback(
    (props: FormConfirmationModalProps) => (
      <RescheduleConfirmation {...props} />
    ),
    [],
  );
  return (
    <Form
      initialValues={block}
      onSubmit={doSubmit}
      renderConfirmationModal={renderConfirmationModal}
    >
      <Typography
        data-testid={"resourceBlockForm/title"}
        variant={"h5"}
        className={classes.subDividerText}
      >
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <InputField
            type={"date"}
            name={"startDate"}
            id={"resourceBlockForm/selectedStartDate-datePicker"}
            data-testid={"resourceBlockForm/selectedStartDate-datePicker"}
            label={"Start Date"}
            onChange={updateStartDate}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.centeredTypography}>to</Typography>
        </Grid>
        <Grid item xs={5}>
          <Input
            name={"endDate"}
            type={"date"}
            label={"End Date"}
            onChange={updateEndDate}
            value={defaultEndDate}
          />
        </Grid>
        {!isAllDay && (
          <>
            <Grid item xs={5}>
              <TimeSlotsField
                name={"startTime"}
                required
                label={"Start Time"}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.centeredTypography}>to</Typography>
            </Grid>
            <Grid item xs={5}>
              <TimeSlotsField
                name={"endTime"}
                required
                label={"End Time"}
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={4}>
          <Checkbox onChange={handleAllDayCheckbox} label={"All day"} />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={"repeat.frequency"}
            id={"demo-simple-select-outlined"}
            options={frequencyOptions}
            label={"No Frequency"}
          />
        </Grid>
        {showLocumField && (
          <Grid item xs={12}>
            <Checkbox
              data-testid={"resourceBlockForm/locum-checkbox"}
              onChange={handleLocumPresentCheckbox}
              label={"A Locum is present"}
            />
          </Grid>
        )}
        {!hideReason && (
          <Grid item xs={12}>
            <InputField
              name={"reason"}
              inputProps={{
                "data-testid": "resourceBlockForm/reason-field",
                maxLength: "50",
              }}
              className={classes.freeTextContainer}
              size={"medium"}
              placeholder={"Please add a reason for blocking time"}
              variant={"outlined"}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ModalActions>
            <Button
              data-testid={"resourceBlockForm/save"}
              type={"submit"}
              variant={"contained"}
              color={"secondary"}
              children={SCHEDULED_LEAVE_TYPO.buttons.save}
              size={"medium"}
              fullWidth
            />
          </ModalActions>
        </Grid>
      </Grid>
    </Form>
  );
}

export default ResourceBlockForm;
