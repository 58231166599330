import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import { useParams } from "react-router";

import { OrderDetails as OrderDetailsTypes, WarningsType } from "../Types";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getCustomerDetailsAsync,
  getIdVerificationAsync,
  getOrderDetailsAsync,
  getOrderWarningsAsync,
} from "../reduxSlice/actionsAsync";
import {
  selectCustomerDetails,
  selectCustomerDetailsLoaded,
  selectNoteAdded,
  selectOrderDetails,
  selectOrderedForOthersWarning,
  selectOrderWarnings,
  selectOrderWarningsLoader,
} from "../reduxSlice/selector";
import { useHistory } from "react-router-dom";
import "./index.scss";

export interface PropsTypes {
  details: OrderDetailsTypes;
  warnings: WarningsType[];
  warningsLoading?: boolean;
  orderedForOthersWarning?: WarningsType;
  onUpdateSuccess: (showUpdateOrder?: boolean) => void;
  isUpdateOrderModalOpen: boolean;
  subscriptionProductTitle: string;
  onUpdateImages: (upload: boolean) => void;
}
const ORDER_LIMIT = 10;
export default (WrappedComponent: React.FC<PropsTypes>) => () => {
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector(selectOrderDetails);
  const customerDetails = useAppSelector(selectCustomerDetails);
  const noteAdded = useAppSelector(selectNoteAdded);
  const warnings = useAppSelector(selectOrderWarnings);
  const warningsLoader = useAppSelector(selectOrderWarningsLoader);
  const customerDetailsLoader = useAppSelector(selectCustomerDetailsLoaded);
  const orderedForOthersWarning = useAppSelector(selectOrderedForOthersWarning);
  const [isUpdateOrderModalOpen, setIsUpdateOrderModalOpen] = useState<boolean>(false);
  const [subscriptionProductTitle, seSubscriptionProductTitle] = useState('');
  const [isBMIOrUploadImages, setIsBMIOrUploadPhotoImages] = useState(false);
  const history = useHistory();
  const [orderWarnings, setOrderWarnings] = useState<WarningsType[]>([]);
  const { id: orderId } = useParams<{ id?: string }>();

  useEffect(() => {
    orderId && dispatch(getOrderDetailsAsync(parseInt(orderId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, noteAdded, isBMIOrUploadImages, customerDetails?.addressDetails]);

  useEffect(() => {
    if (orderDetails) {
      const subscriptionProduct = orderDetails?.products
        ?.filter((item) => item.subscriptionId && item.subscriptionBaseOrderId && parseInt(orderDetails.orderId) !== item.subscriptionBaseOrderId);
      subscriptionProduct.length > 0 && seSubscriptionProductTitle(subscriptionProduct[0].productTitle)
      getWarnings(subscriptionProduct[0]?.subscriptionBaseOrderId);
    }

    // orderDetails && 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  const getWarnings = (subscriptionBaseOrderId: number | undefined) => {
    dispatch(
      getOrderWarningsAsync({
        orderId: subscriptionBaseOrderId || parseInt(orderDetails!.orderId),
        customerId: orderDetails!.customerId,
      }),
    );
  }


  useEffect(() => {
    // orderDetails?.customerId && dispatch(getOrderDetailsAsync(parseInt(orderDetails.orderId)));
    orderDetails?.customerId && dispatch(getCustomerDetailsAsync({ customerId: orderDetails.customerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails?.customerId]);

  const onUpdateSuccess = (showUpdateOrder?: boolean) => {
    dispatch(getOrderDetailsAsync(parseInt(orderDetails!.orderId)));
    dispatch(getIdVerificationAsync({
      page: 1,
      limit: ORDER_LIMIT,
      status: "awaiting"
    }));
    if (showUpdateOrder) {
      setIsUpdateOrderModalOpen(true);
    }
  };

  useEffect(() => {
    if (orderDetails && (warnings.length > 0 || subscriptionProductTitle !== "")) {
      const orderNotesForConfirmBMI = orderDetails?.orderNotes?.length > 0 ? orderDetails?.orderNotes?.filter(notes => notes.productId !== 0) : [];

      const isPhotoUploadSkippedProduct = (orderDetails.products.filter(product => product.isPhotoUploadSkipped && product.secondaryTag.toLowerCase() === "onlinedoctorweightloss")).length > 0 ? true : false;
      const warningsLocal = subscriptionProductTitle !== "" ? warnings.filter(item => item.header?.toLowerCase() === subscriptionProductTitle.toLowerCase()) : warnings;
      if (isPhotoUploadSkippedProduct && orderNotesForConfirmBMI.length === 0) {
        setOrderWarnings([...warningsLocal, {
          id: 'SkipPhoto',
          header: "Image upload skipped, medicated weight loss",
          descriptions: ["Please verify the patient's BMI before approving item. Request and upload images if required."]
        }])
      } else {
        setOrderWarnings([...warningsLocal])
      }
    }

  }, [orderDetails, warnings, subscriptionProductTitle,isBMIOrUploadImages]);

  return (
    <>
      <button className="back-button mt-16 mb-16" onClick={history.goBack}>
        Back
      </button>
      <Grid container className="crp_container">
        <Grid xs={12} sm={4} lg={3} className="crp_customer_details">
          <CustomerDetails
            customerId={orderDetails?.customerId!}
            customerDetails={customerDetails}
            loading={customerDetailsLoader}
            onUpdateSuccess={() => getWarnings}
            orderDetails={orderDetails}
          />
        </Grid>
        <Grid xs={12} sm={8} lg={9}>
          {orderDetails && (
            <WrappedComponent
              details={orderDetails}
              warnings={orderWarnings}
              warningsLoading={warningsLoader}
              orderedForOthersWarning={orderedForOthersWarning}
              onUpdateSuccess={onUpdateSuccess}
              isUpdateOrderModalOpen={isUpdateOrderModalOpen}
              subscriptionProductTitle={subscriptionProductTitle}
              onUpdateImages={setIsBMIOrUploadPhotoImages}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};