import React from "react";
import { Grid, Link, Typography, makeStyles, Theme } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Divider, Spacing } from "@welldigital/components";

const useStyles = makeStyles((theme: Theme) => ({
    locationTitle: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "32px",
        color: "#000000",
      },
      locationName: {
       fontStyle: "normal",
       fontWeight: 600,
       fontSize: "14px",
       lineHeight: "24px",
       color: "#0061F2",
       width: "90%",
       wordWrap: "break-word"
      }
}),
);
export interface PharmacyLocationProps  {
    title: string;
    locations:{
        locationId: string;
        locationName: string;
        branchID: string;
    }[];
}
export const PharmacyLocation: React.FC<PharmacyLocationProps> = (location) =>{
    const classes = useStyles();
    return <>
        <Typography id={`${location.title}`} className= {classes.locationTitle}>
        {location.title}
        </Typography>
        <Spacing spacing={2} />
        <Typography>
        <div style={{ padding: 20 }}>
        <Grid container spacing={1}>
  
       {location.locations.map((data)=>(<Grid container item xs={3} spacing={3} style={{marginBottom:"12px"}}><Link className = {classes.locationName}component={RouterLink}
                to={`/dashboard/${data.locationId}`}
             >{data.locationName}-{data.branchID}</Link></Grid>))}
      
       </Grid>
       </div>
       </Typography>
       <Divider spacingBefore={0} spacingAfter={2} />
        
    </>
}
