import React, { FC } from "react";
import { InputField, SelectField, Typography } from "@welldigital/components";
import { isUnder18 } from "pages/WalkInCustomerDetails/utils";
import { Service } from "../../../../constants/service.constants";
export type GuardianFieldsProps = {
  birthDate: Date;
  service: Service;
};

const titleOptions = [
  { label: "Mr", value: "Mr" },
  { label: "Mrs", value: "Mrs" },
  { label: "Miss", value: "Miss" },
  { label: "Ms", value: "Ms" },
  { label: "Mx", value: "Mx" },
  { label: "Sir", value: "Sir" },
  { label: "Dr", value: "Dr" },
];

export const GuardianFields: FC<GuardianFieldsProps> = ({ birthDate, service }) => {
  return !isUnder18(birthDate) ? null : (
    <>
    {service?.id?.toString() === process.env.REACT_APP_WEGOVY_SERVICES?  <Typography paragraph>
      Because you are under 18, you are unable to book an appointment for this service.
      </Typography> :
       <Typography paragraph>
       Because this person is under the age of 18, they must be accompanied by
       their legal guardian when they attend the appointment.
     </Typography>
      }
      <Typography variant={"h4"}>
        Please enter the guardian’s full name
      </Typography>
      <InputField
        name={"guardianDetails.firstName"}
        label={"First name"}
        required
      />
      <InputField
        name={"guardianDetails.lastName"}
        label={"Last name"}
        required
      />
      <SelectField
        required
        name={"guardianDetails.title"}
        label={"Title"}
        options={titleOptions}
      />
      <Typography variant={"h4"} spacingAfter={2}>
        Guardian contact information
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        Please provide the guardian’s email address and phone number.
      </Typography>
      <InputField
        name={"guardianDetails.email"}
        label={"Email address"}
        email
      />
      <InputField
        name={"guardianDetails.phone"}
        label={"Phone number"}
        required
      />
      <Typography variant={"h2"}>{"Continue with customer details"}</Typography>
    </>
  );
};

export default GuardianFields;
