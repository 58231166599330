import { gql } from "@apollo/client";

export const GET_GP_LETTER = gql`
  query getAppointmentGpLetter($appointmentId: String!, $locationId: String!) {
    getAppointment(appointmentId: $appointmentId, locationId: $locationId) {
      id
      gpLetterPdf
    }
  }
`;
