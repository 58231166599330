import React, { useCallback, useEffect } from "react";
import { useIsFirstRender } from "../../hooks/useIsFirstRender";
import { GuardianInput } from "../../__generated__/globalTypes";
import { analytics } from "@welldigital/ui-common"; 
import { startScreeningVariables } from "../../graphql/__generated__/startScreening";
import {
  LOCAL_STORAGE_SCREENING_QUESTION,
  useScreeningQuestions,
} from "./hooks/useScreeningQuestions";
import { Location, Service } from "./types";
import ScreeningStepContainer from "./ScreeningStepContainer";
import events from "../../utils/events";
import GenericErrorMessage from "../Error/GenericErrorMessage";
import { CustomerDetails } from "../../persister/types";
import { formatDateAws } from "../../utils/time";
/**
 * This component handles the starting of the MPS screening questions
 * It takes in customer/GP information and starts a series of questions
 * for the user to fill out before booking an appointment.
 */

export interface StartScreeningProps {
  location: Location;
  service: Service;
  customerDetails: CustomerDetails;
  guardian: GuardianInput;
}

const StartScreening: React.FC<StartScreeningProps> = ({
  location,
  service,
  customerDetails,
  guardian,
}) => {
  const startScreeningVars = useCallback(
    (customerDetails: CustomerDetails): startScreeningVariables => {
      return {
        input: {
          guardian,
          firstNames: customerDetails.firstName,
          locationID: location.id,
          serviceID: service.id,
          dob: formatDateAws(customerDetails.dob),
          email: customerDetails.email,
          lastName: customerDetails.lastName,
          phone: customerDetails.phone,
          nhsNumber: customerDetails.nhsNumber,
          sex: customerDetails.gender,
          surgeryAddress: customerDetails.surgeryAddress,
          surgeryName: customerDetails.surgeryName,
          addressLine1: customerDetails.address.line1,
          addressLine2: customerDetails.address.line2,
          postcode: customerDetails.address.postcode,
          town: customerDetails.address.city,
          allowMarketing: false,
        },
      };
    },
    [guardian, location.id, service.id],
  );

  const isFirstRender = useIsFirstRender();
  const {
    startScreening,
    screeningQuestion: question,
    error,
    loading: isLoading,
  } = useScreeningQuestions();

  useEffect(() => {
    if (isFirstRender && !question && !error && !isLoading) {
      analytics.trackEvent({
        flow: service.flow,
        event: events.screening.started,
      });
      startScreening(startScreeningVars(customerDetails));
    }

    if (error) {
      window.localStorage.removeItem(LOCAL_STORAGE_SCREENING_QUESTION);
    }
  }, [
    customerDetails,
    error,
    isFirstRender,
    isLoading,
    question,
    service.flow,
    startScreening,
    startScreeningVars,
  ]);

  if (error) {
    console.error(error);
    return <GenericErrorMessage />;
  }

  if (isLoading) {
    return <p data-testid={"StartScreening/loading"}>Loading</p>;
  }
  if (question) {
    return <ScreeningStepContainer screening={question} service={service} />;
  }
  return <GenericErrorMessage />;
};

export default StartScreening;
