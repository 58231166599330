import React, { FC, MouseEvent, useCallback } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Link,
  makeStyles,
  Box,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { format } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import AppointmentActions from "../../../../components/AppointmentActions/AppointmentActions";
import { AppointmentStatus } from "__generated__/globalTypes";
import { formatDate, formatTime } from "../../../../utils/time";
import { utcToZonedTime } from "date-fns-tz";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  tableHeading: {
    color: "#0061F2",
  },
}));

export interface AppointmentsTableProps {
  data?: any;
  goToServiceDetails(id: string): () => void;
}

const ToBeRescheduledTable: FC<AppointmentsTableProps> = ({
  data: appointmentsUnfiltered,
  goToServiceDetails,
}) => {
  const classes = useStyles();
  const stopPropagation = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  const appointments = appointmentsUnfiltered.filter((a: any) => {
    return a.status !== AppointmentStatus.cancelled;
  });

  if (!appointments?.length) {
    return <Typography>No appointments booked</Typography>;
  }
  function formatDates(date: string | Date): string {
    return format(utcToZonedTime(date, timezone), "yyyyMMddHHmm");
  }

  function formatDatesToInt(date: Date | string): number {
    const timeString = formatDates(date);

    return parseInt(timeString);
  }

  const sortedAppointments: any[] = appointments.sort(
    (a: any, b: any) =>
      formatDatesToInt(a.startTime) - formatDatesToInt(b.startTime),
  );
  return (
    <>
      <Typography className={classes.tableHeading}>
        Customers have already booked appointments during this change to service
        availability. Please call these customers to cancel or reschedule their
        appointments.
      </Typography>
      <Box mt={2}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Service</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAppointments.map((appointment) => (
                <TableRow key={`appointment-${appointment.id}`} hover>
                  <TableCell onClick={goToServiceDetails(appointment.id)}>
                    <Link
                      color={"inherit"}
                      component={RouterLink}
                      onClick={stopPropagation}
                      to={`/customer-details/${appointment.customer.id}`}
                    >
                      {`${appointment.customer.firstName} ${appointment.customer.lastName}`}
                    </Link>
                  </TableCell>
                  <TableCell onClick={goToServiceDetails(appointment.id)}>
                    {`${formatTime(appointment.startTime)} - ${formatTime(
                      appointment.endTime,
                    )}`}
                  </TableCell>
                  <TableCell onClick={goToServiceDetails(appointment.id)}>
                    {formatDate(appointment.startTime)}
                  </TableCell>
                  <TableCell onClick={goToServiceDetails(appointment.id)}>
                    {appointment.course?.name}
                  </TableCell>
                  <TableCell>
                    <AppointmentActions
                      appointmentId={appointment.id}
                      paymentType={appointment.course?.paymentType}
                      appointmentStatus={appointment.status}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ToBeRescheduledTable;
