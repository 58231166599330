import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BOOKWELL_USER_GROUP } from "../../../src/constants";

export interface BookwellGroup {
  bookwellGroup?: BOOKWELL_USER_GROUP;
  locationId: string | "";
}

const initialState: BookwellGroup = {
  bookwellGroup: undefined,
  locationId: "",
};
export const bookwellSlice = createSlice({
  name: "bookwell",
  initialState,
  reducers: {
    setBookwellGroup: (
      state,
      { payload }: PayloadAction<BOOKWELL_USER_GROUP | undefined>,
    ) => {
      state.bookwellGroup = payload;
    },
    setLocationId: (
      state,
      { payload }: PayloadAction<string | "">,
    ) => {
      state.locationId = payload;
    },
  },
});

export default bookwellSlice.reducer;
export const bookwellActions = bookwellSlice.actions;
