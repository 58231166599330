import { useMutation } from "@apollo/client/react";
import {
  CREATE_SERVICE_BLOCK,
  DELETE_SERVICE_BLOCK,
  UPDATE_SERVICE_BLOCK,
} from "../gql";
import { subYears } from "date-fns";
import { formatDateAws } from "utils/time";
import { useCallback, useContext, useEffect, useState } from "react";
import { createServiceBlockVariables } from "../__generated__/createServiceBlock";
import { updateServiceBlockVariables } from "../__generated__/updateServiceBlock";
import { deleteServiceBlockVariables } from "../__generated__/deleteServiceBlock";
import {
  createPausedBlock,
  formatBlock,
  getPausedBlock,
  parseBlocks,
} from "./IndividualService.utils";
import {
  IndividualServiceParams,
  INFINITE_END_DATE,
  ServiceData,
} from "./IndividualService.types";
import { AlertContext } from "../../../pages/DashboardPage/AlertContext.context";
import { useSnackbar } from "notistack";
import { fetchServiceBlock } from "pages/DashboardPage/components/ServiceBlockAvailability/api.fetchers";

export type IndividualServiceState = {
  data?: ServiceData;
  updateData: (data: ServiceData) => Promise<void>;
  errorMessage?: string;
};

export default function useIndividualService({
  id: serviceId,
  locationId,
}: IndividualServiceParams): IndividualServiceState {
  const { enqueueAlert } = useContext(AlertContext);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [createServiceBlock, { error: createError }] = useMutation<
    any,
    createServiceBlockVariables
  >(CREATE_SERVICE_BLOCK, {
    onCompleted: () => {
      enqueueAlert({
        type: "success",
        message: "Successfully added manage individual service",
      });
    },
  });
  const [updateServiceBlock, { error: updateError }] = useMutation<
    any,
    updateServiceBlockVariables
  >(UPDATE_SERVICE_BLOCK, {
    onCompleted: () => {
      enqueueSnackbar("Successfully updated manage individual service", {
        variant: "success",
      });
    },
  });
  const [deleteServiceBlock, { error: deleteError }] = useMutation<
    any,
    deleteServiceBlockVariables
  >(DELETE_SERVICE_BLOCK, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted manage individual service", {
        variant: "success",
      });
    },
  });

  useEffect(() => {
    async function getServiceBlocks() {
      const data = await fetchServiceBlock({
        serviceId: serviceId,
        startDate: formatDateAws(subYears(new Date(), 1)),
        endDate: INFINITE_END_DATE,
        locationId: locationId || "",
      });
      setData(data?.service_blocks);
    }
    getServiceBlocks();
  }, [locationId, serviceId]);

  const blocks = parseBlocks(locationId || "", data || []);

  const errorMessage =
    createError?.message || updateError?.message || deleteError?.message;

  const updateData: IndividualServiceState["updateData"] = useCallback(
    async ({ isPaused, pausedBlockId, blocks: newBlocks }) => {
      if (!locationId) return;
      const blocksToBeDeleted: deleteServiceBlockVariables[] = [];
      const blocksToBeCreated: createServiceBlockVariables[] = [];
      const blocksToBeUpdated: updateServiceBlockVariables[] = [];

      if (isPaused) {
        blocksToBeCreated.push(
          formatBlock<createServiceBlockVariables>(
            createPausedBlock(),
            serviceId,
            locationId,
            true,
          ),
        );
      } else if (!isPaused && pausedBlockId) {
        blocksToBeDeleted.push({ serviceBlockId: pausedBlockId.toString() });
      }

      const newBlockIds = newBlocks
        .filter(({ blockId }) => blockId)
        .map(({ blockId }) => blockId);

      blocksToBeDeleted.push(
        ...blocks
          .filter(({ blockId }) => blockId && !newBlockIds.includes(blockId))
          .map(({ blockId }) => ({
            serviceBlockId: blockId as string,
          })),
      );

      newBlocks.forEach((block) => {
        if (block.blockId) {
          blocksToBeUpdated.push(
            formatBlock<updateServiceBlockVariables>(block, serviceId),
          );
        } else {
          blocksToBeCreated.push(
            formatBlock<createServiceBlockVariables>(
              block,
              serviceId,
              locationId,
            ),
          );
        }
      });

      await Promise.all([
        ...blocksToBeCreated.map((variables) =>
          createServiceBlock({ variables }),
        ),
        ...blocksToBeUpdated.map((variables) =>
          updateServiceBlock({ variables }),
        ),
        ...blocksToBeDeleted.map((variables) =>
          deleteServiceBlock({ variables }),
        ),
      ]).catch(() => {});
      async function getServiceBlocks() {
        const data = await fetchServiceBlock({
          serviceId: serviceId,
          startDate: formatDateAws(subYears(new Date(), 1)),
          endDate: INFINITE_END_DATE,
          locationId: locationId || "",
        });
        setData(data?.service_blocks);
        window.location.reload();
      }
      getServiceBlocks();
      // the error is caught above
    },
    [
      locationId,
      blocks,
      createServiceBlock,
      updateServiceBlock,
      serviceId,
      deleteServiceBlock,
    ],
  );

  const pausedBlock = getPausedBlock(locationId || "", data);
  return {
    data: data
      ? {
          isPaused: !!pausedBlock,
          pausedBlockId: pausedBlock?.id,
          blocks,
        }
      : undefined,
    updateData,
    errorMessage,
  };
}
