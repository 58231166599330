import { Button, Typography } from "@welldigital/components";
import React from "react";
import { formatDate } from "../../../../utils/time";
import { CoverPharmacistSlot } from "../../coverPharmacistSlots";
import { WALK_IN_FLU_CLINIC_TYPO } from "./WalkInFluClinic";
import { makeStyles, Theme } from "@material-ui/core";

interface SlotProps {
  slot: CoverPharmacistSlot;
  onDelete: (slot: CoverPharmacistSlot) => void;
}

export const styles = makeStyles((theme: Theme) => ({
  inlineFlex: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 0),
  },
  centerSelf: {
    alignSelf: "center",
  },
  inline: {
    display: "inline",
  },
}));

function Slot({ slot, onDelete }: SlotProps) {
  const classes = styles();
  return (
    <div className={classes.inlineFlex}>
      <Typography className={classes.centerSelf} variant="h6">
        {formatDate(slot.from)} until {formatDate(slot.until)}
      </Typography>
      <Button
        className={classes.inline}
        variant="text"
        color="primary"
        onClick={() => onDelete(slot)}
      >
        {WALK_IN_FLU_CLINIC_TYPO.buttons.delete}
      </Button>
    </div>
  );
}

export default Slot;
