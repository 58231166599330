import React, {
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Dialog, DialogProps, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Typography } from "@welldigital/components";
import "./index.scss";

export interface CustomDialogRef {
  show: () => void;
  hide: () => void;
}

export interface CustomDiaglogProps extends Pick<DialogProps, "onClose"> {
  className?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  title?: string | ReactNode;
  disableButton?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
}

const CustomDialog = forwardRef<
  CustomDialogRef,
  PropsWithChildren<CustomDiaglogProps>
>(
  (
    {
      children,
      title,
      className = "",
      confirmButtonLabel = "Confirm",
      cancelButtonLabel = "Cancel",
      disableButton = false,
      onOpen,
      onClose,
      onConfirm,
      onCancel,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        setOpen(true);
        onOpen?.();
      },
      hide: close,
    }));

    const close = () => {
      setOpen(false);
      onClose?.();
    };

    const confirmAction: MouseEventHandler<HTMLButtonElement> = async (e) => {
      await onConfirm?.(e);
      close();
    };

    const cancelAction: MouseEventHandler<HTMLButtonElement> = (e) => {
      onCancel?.(e);
      close();
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        className={`custom-dialog ${className}`}
      >
        <Close className="close" onClick={close} />
        <DialogContent className="custom-dialog-content">
          {title && (
            <Typography variant="h2" className="title" spacingAfter={0}>
              {title}
            </Typography>
          )}
          <div className="content-container scroll">
            {children}
            {onConfirm && (
              <div className="custom-dialog-buttons">
                <Button
                  variant="outlined"
                  className="button-secondary"
                  onClick={cancelAction}
                  disabled={disableButton}
                >
                  {cancelButtonLabel}
                </Button>
                <Button onClick={confirmAction} disabled={disableButton}>{confirmButtonLabel}</Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

export default CustomDialog;
