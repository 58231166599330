import { RootState } from "redux/store";

export const selectOrderDetails = (state: RootState) =>
  state.clinicalReview.orderDetails;

export const selectOrderList = (state: RootState) =>
  state.clinicalReview.orderList;

export const selectIdChecks = (state: RootState) =>
  state.clinicalReview.idChecks;

export const selectPreApprovedProductCustomerList = (state: RootState) =>
  state.clinicalReview.preApprovedProductCustomerList;

export const selectTotalPreApprovedProductCustomer = (state: RootState) =>
  state.clinicalReview.totalPreApprovedProductCustomer;

export const selectPreApprovedProducts = (state: RootState) =>
  state.clinicalReview.preApprovedProducts;

export const selectIdChecksPreApprovedProduct= (state: RootState) =>
  state.clinicalReview.idChecksPreApprovedProduct;

export const selectTotalIdChecks = (state: RootState) =>
  state.clinicalReview.totalIdChecks;

export const selectAwaitCountIdChecks = (state: RootState) =>
  state.clinicalReview.awaitCountIdChecks;
  
export const selectOrderListLoader = (state: RootState) =>
  state.clinicalReview.orderListLoader;

export const selectOrderHistoryList = (state: RootState) =>
  state.clinicalReview.orderHistory;

export const selectCustomerDetails = (state: RootState) =>
  state.clinicalReview.customerDetails;

export const selectCustomerDetailsLoaded = (state: RootState) =>
  state.clinicalReview.customerDetailsLoader;

export const selectTotalOrders = (state: RootState) =>
  state.clinicalReview.totalOrders;

export const selectUserGroup = (state: RootState) =>
  state.clinicalReview.userGroup;

export const selectNoteAdded = (state: RootState) =>
  state.clinicalReview.noteAdded;

export const selectLoggedInUserInfo = (state: RootState) =>
  state.clinicalReview.userDetails;

export const selectOrderWarnings = (state: RootState) =>
  state.clinicalReview.warnings;

export const selectOrderWarningsLoader = (state: RootState) =>
  state.clinicalReview.warningsLoader;

export const selectSubscriptionDetails = (state: RootState) =>
  state.clinicalReview.subscription;

export const selectOrderedForOthersWarning = (state: RootState) =>
  state.clinicalReview.orderForOthersWarning;
