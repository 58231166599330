import ClinicalReviewLayout from "app/layout/ClinicalReviewLayout/ClinicalReviewLayout";
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { isAuthenticated } from "services/auth";

import LoggedInLayout from "../layout/LoggedInLayout/LoggedInLayout";
import LoginLayout from "../layout/LoginLayout/LoginLayout";

const Routes = () => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffect(() => {
    isAuthenticated().then(setIsAuth);
  }, [setIsAuth]);

  //   This is to hide Bookwell dashboard poping up while login to Clinical Review
  if (isAuth === null) {
    return <></>;
  }

  return (
    <Switch>
      {/* Logged out routes */}
      <Route path={"/clinical-review/login"}>
        <LoginLayout />
      </Route>
      <Route path={"/login"}>
        <LoginLayout />
      </Route>
      <Route path={"/enter-secret-code"}>
        <LoginLayout />
      </Route>
      <Route path={"/clinical-review"}>
        <ClinicalReviewLayout />
      </Route>
      {/* Logged in routes */}
      <Route path={"/"}>
        <LoggedInLayout />
      </Route>
    </Switch>
  );
};

export default Routes;
