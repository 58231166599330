/**
 * List of service ID's that do not go through the full
 * service appointment flow
 */
export const quickAppointments = [
  String(process.env.REACT_APP_FLU),
  String(process.env.REACT_APP_COVID_PCR_TEST),
  String(process.env.REACT_APP_COVID_GREEN_PACK),
  String(process.env.REACT_APP_COVID_AMBER_PACK),
  String(process.env.REACT_APP_SAXENDA),
];
