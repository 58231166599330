import {
  MutationTuple,
  QueryResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import { GET_COVID_QUESTIONS, POST_COVID_QUESTIONS } from "../gql";
import { getCovidQuestions } from "../__generated__/getCovidQuestions";
import {
  postCovidQuestions,
  postCovidQuestionsVariables,
} from "../__generated__/postCovidQuestions";

type UseCovidQuestionsHookData = QueryResult<getCovidQuestions> & {
  covidMutation: MutationTuple<postCovidQuestions, postCovidQuestionsVariables>;
};

export function useCovidQuestions(): UseCovidQuestionsHookData {
  const queryData = useQuery<getCovidQuestions>(GET_COVID_QUESTIONS);

  const covidMutation = useMutation<
    postCovidQuestions,
    postCovidQuestionsVariables
  >(POST_COVID_QUESTIONS);

  return {
    ...queryData,
    covidMutation,
  };
}
