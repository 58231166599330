import { Button } from "@material-ui/core";
import React, { useState } from "react";
import UpdateStatusDetails from "./UpdateStatusDetails";

interface PropsTypes {
  loading?: boolean;
  onUpdateSuccess: (showUpdateOrder?: boolean) => void;
  idCheckStatus: string;
  customerId: number;
}

const UpdateStatus: React.FC<PropsTypes> = ({idCheckStatus,customerId,onUpdateSuccess}) => {
  const [visible, setVisible] = useState(false);
 return (
    <>
     {/* <Loader loading={loading} /> */}
     <Button
              type={"submit"}
              size={"medium"}
              //disabled={buttonDisable}
              onClick={() => setVisible(true)}
              className="button-primary"
            >
              Update Status
            </Button>
    {visible && (
        <UpdateStatusDetails
          onClose={setVisible}
          onUpdateSuccess={onUpdateSuccess}
          idCheckStatus={idCheckStatus}
          customerId={customerId}
        />
      )}
    </>
  );
};

export default UpdateStatus;
