import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { Appointment } from "graphql/types";

export interface PharmacyDetailsProps {
  pharmacist?: Appointment["pharmacist"];
}

const PharmacyDetails: React.FC<PharmacyDetailsProps> = (props) => {
  const { pharmacist } = props;

  if (pharmacist?.name) {
    return (
      <>
        <Typography variant={"h4"} spacingAfter={2}>
          Pharmacist information
        </Typography>
        <Grid container data-testid={"PharmacyDetails/container"}>
          <Grid item xs={6}>
            <Typography variant={"h6"} spacingAfter={0}>
              Pharmacist information
            </Typography>
            <Typography
              data-testid={"PharmacyDetails/pharmacist-information-title"}
              spacingAfter={2}
            >
              {pharmacist?.title ? pharmacist.title : "-"}
            </Typography>
            <Typography variant={"h6"} spacingAfter={0}>
              Pharmacist name
            </Typography>
            <Typography
              data-testid={"PharmacyDetails/pharmacist-information-name"}
              spacingAfter={2}
            >
              {pharmacist?.name ? pharmacist.name : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"h6"} spacingAfter={0}>
              GPhC number
            </Typography>
            <Typography
              data-testid={"PharmacyDetails/pharmacist-information-number"}
              spacingAfter={2}
            >
              {pharmacist?.gphcNumber ? pharmacist.gphcNumber : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant={"h6"} spacingAfter={0}>
          Pharmacy name
        </Typography>
        <Typography
          data-testid={"PharmacyDetails/pharmacy-name"}
          spacingAfter={2}
        >
          Well
        </Typography>
        <Typography
          data-testid={"PharmacyDetails/pharmacy-address-header"}
          variant={"h6"}
          spacingAfter={0}
        >
          Pharmacy address
        </Typography>
        <Typography
          data-testid={"PharmacyDetails/pharmacy-address"}
          spacingAfter={2}
        >
          12 Merchants Warehouse, Manchester, Greater Manchester, M3 4LZ
        </Typography>
      </>
    );
  }

  return null;
};

export default PharmacyDetails;
