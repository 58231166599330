import { gql } from "@apollo/client";

export const COMPLETE_APPOINTMENT = gql`
  mutation CompleteServiceDetailsPage_completeAppointment(
    $appointmentId: String!
    $medicine: MedicineInput!
    $locationId: String!
  ) {
    updateAppointmentStatus(appointmentId: $appointmentId, status: completed, locationId: $locationId) {
      id
    }
    updateAppointmentMedicine(
      appointmentId: $appointmentId
      medicine: $medicine
      locationId: $locationId
    ) {
      id
    }
  }
`;
