// import { useEffect, useState } from "react";
// import { getLocationId } from "../../../services/auth";
import { useQuery } from "@apollo/client/react";
import {
  GET_LOCATIONS_QUERY,
  LocationQueryData,
  LocationQueryVars,
  LocationService,
} from "./gql";
import { ApolloError } from "@apollo/client";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export type ServicesState = {
  services?: LocationService[];
  locationId?: string;
  isLoading: boolean;
  error: ApolloError | undefined;
};

export function useServices(ignoreCovid: Boolean = false): ServicesState {

  const locationId = useAppSelector(selectLocationId);
  // const [locationId, setLocationId] = useState<string>();

  // useEffect(() => {
  //   (async () => {
  //     setLocationId(await getLocationId());
  //   })();
  // }, [setLocationId]);

  const { loading: isLoading, error, data } = useQuery<
    LocationQueryData,
    LocationQueryVars
  >(GET_LOCATIONS_QUERY, {
    variables: { locationId: locationId as string, ignoreCovid: ignoreCovid },
    skip: !locationId,
  });

  return {
    services: data?.getLocationServices,
    locationId,
    isLoading,
    error,
  };
}
