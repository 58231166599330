import React from "react";
import { Theme, makeStyles } from "@material-ui/core";

import LoginCorner from "./components/LogInCorner/LogInCorner";
import LoginRoutes from "../../routers/LoginRoutes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
  },
  mainPanel: {
    position: "absolute",
    top: "50%",
    left: "25%",
    transform: "translate3d(-25%, -50%, 0)",
    maxWidth: 600,
  },
}));

const LoginLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.mainPanel}>
        <LoginRoutes />
      </div>
      <LoginCorner />
    </div>
  );
};

export default LoginLayout;
