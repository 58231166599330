import { Typography } from "@welldigital/components";
import React from "react";
import { Radio, TextField } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, Theme } from "@material-ui/core";
import { CovidAnswerInput } from "../../__generated__/globalTypes";
import {
  getFollowUpQuestion,
  isYes,
  NO_ANSWER,
  SPLITTER,
  YES_ANSWER,
} from "./utils";

const useStyles = makeStyles((theme: Theme) => ({
  area: {
    paddingBottom: theme.spacing(5),
  },
  paddingRight: {
    paddingRight: theme.spacing(4),
  },
  textField: {
    maxWidth: "480px",
    display: "block",
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
}));

export interface CovidQuestionProps {
  answerQuestion: (updatedQuestion: CovidAnswerInput) => void;
  question: string;
  number: number;
  answer: string;
}
// mostly copy pasta
function CovidQuestion({
  answerQuestion,
  question,
  number,
  answer,
}: CovidQuestionProps) {
  const classes = useStyles();
  const onYesNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAnswer = (event.target as HTMLInputElement).value;
    answerQuestion({
      question,
      number,
      type: 1,
      answer: newAnswer,
    });
  };

  const followUpQuestion = getFollowUpQuestion(question.split(SPLITTER));
  const answerParts = answer.split(SPLITTER);
  const followUpQuestionAnswer = answerParts.length > 1 ? answerParts[1] : "";

  const onFollowUpAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAnswer = (event.target as HTMLInputElement).value;

    answerQuestion({
      question,
      number,
      type: 1,
      answer: YES_ANSWER + SPLITTER + newAnswer,
    });
  };

  return (
    <div className={classes.area} key={`covid-question-${number}`}>
      <Typography variant={"h4"}>{question}</Typography>
      <FormControl>
        <RadioGroup
          row
          aria-label={`covid-question-${number}`}
          name={`covid-question-${number}`}
          onChange={onYesNoChange}
          value={answer}
          className={classes.paddingTop}
        >
          <FormControlLabel
            data-testid={`covidQuestion/radio-${number}-yes`}
            value={YES_ANSWER}
            control={<Radio />}
            label={"Yes"}
            className={classes.paddingRight}
          />
          <FormControlLabel
            data-testid={`covidQuestion/radio-${number}-no`}
            value={NO_ANSWER}
            control={<Radio color={"primary"} />}
            label={"No"}
          />
        </RadioGroup>
      </FormControl>
      {followUpQuestion && isYes(answer) && (
        <>
          <Typography
            data-testid={`CovidQuestion/FollowUpQuestion-${number}`}
            variant={"body1"}
          >
            {followUpQuestion}
          </Typography>
          <TextField
            id={"outlined-multiline-static"}
            fullWidth
            multiline
            variant={"outlined"}
            className={classes.textField}
            value={followUpQuestionAnswer}
            onChange={onFollowUpAnswerChange}
          />
        </>
      )}
    </div>
  );
}

export default CovidQuestion;
