import axios from "axios";
import React, { FC, useState, useEffect} from "react";
import WegovyServiceQuestions from "./WegovyServiceQuestions";
export const API_HOST = process.env.REACT_APP_INTEG_API;

export interface ServiceQuestionsWegovyProps {
  appointmentId: string;
  serviceId: string
}

  export interface dataProps {
    traversal_id: string,
    identifier: string,
    label: string,
    traversal_response: {
      options: {
        checked: boolean,
        label: string
      }[]
    }
  }
const ServiceQuestionsContainerWG: FC<ServiceQuestionsWegovyProps> = (
  props,
) => {
  const [data, setData] = useState<dataProps[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function traversalDetailsWegovyApi(
    ): Promise<any> {
      try{
      setLoading(true);
      let payload = {"appointment_id":props.appointmentId,"genderValue":"nogender"}
      const response  = await axios.post<any>(
        `${API_HOST}traversals/detailedQuestionsFormBuilder`, payload
      );
      if (response.status === 200) {
        setLoading(false);
        setData(response.data);
      }else {
        setLoading(false);
      }
    }catch(error){
      setLoading(false);
    }
    }
    traversalDetailsWegovyApi();
  }, [props.appointmentId])


  return <WegovyServiceQuestions  loading = {loading} traversalData={data && data.length> 0 ? data : []} />;
};

export default ServiceQuestionsContainerWG;
