import React, {useState, useEffect} from "react";
import {makeStyles, Theme, Link } from "@material-ui/core";
import axios from "axios";
import { selectLocationId } from "redux/reduxSlice/selector";
import { useAppSelector } from "redux/hooks";
import { Link as RouterLink } from "react-router-dom";
export const API_HOST = process.env.REACT_APP_CV_API_BASE_URL;

export interface locationProps  {
    locationId: string;
    locationName: string;
    branchID: string;
}

export interface SubHeaderProps  {
  hideLocation: boolean;
  isBookwell: string
}

const useStyles = makeStyles((theme: Theme) => ({
    accountCircle: {
        width :"100%",
        backgroundColor: "cadetblue",
        height: "24px"
      },
}),
);
const SubHeader: React.FC<SubHeaderProps> = (props)=> {
    const locationId = useAppSelector(selectLocationId);
    const classes = useStyles();
    const { hideLocation , isBookwell} = props;
    const [locationData, setLocationData] = useState<locationProps>();

    useEffect(() => {
      async function getLocation() {
        try{
        const response = await axios.get(`${API_HOST}/services/masterlocations/${locationId}`);
        if (response.status === 200) {
          setLocationData(response.data)
        }
      }catch(error) {
        console.log('error', error);
      }
      }
      if(locationId){
        getLocation();
      }
    }, [locationId])

    return (
        <>
        <div className={classes.accountCircle}>
        {!hideLocation && <span style= {{padding : "0 0 0 47px", fontSize: "13px"}}><Link style= {{color:"white"}}component={RouterLink}
                to={!isBookwell ? "/":`/dashboard/${locationData?.locationId}`}>{locationData?.locationName}-{locationData?.branchID}</Link></span>}
      </div>
      </>
    );
}
export default SubHeader;