import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import classNames from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  fixedPane: {
    display: "flex",
    height: "auto",
    flex: "0 0 auto",
    overflow: "auto",
    flexDirection: "column",
  },
  small: {
    width: 340,
  },
  medium: {
    width: 420,
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

interface FixedPaneProps {
  variant: "small" | "medium";
  className?: string;
  includeBorderLeft?: boolean;
  includeBorderRight?: boolean;
}

/**
 * Fixed pane component used to define a predefined sized panel,
 * made to be used in conjunction with Splitter and FlexPane,
 * but could be used independently
 */
const FixedPane: FC<FixedPaneProps> = (props) => {
  const {
    children,
    variant,
    includeBorderLeft,
    includeBorderRight,
    ...rest
  } = props;
  const classes = useStyles();
  const fixedPaneClasses = classNames(
    classes.fixedPane,
    `${classes[props.variant]}`,
    {
      [classes.borderLeft]: includeBorderLeft,
      [classes.borderRight]: includeBorderRight,
    },
    props.className,
  );

  return (
    <div {...rest} className={fixedPaneClasses}>
      {children}
    </div>
  );
};

export default FixedPane;
