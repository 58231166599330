import React from "react";
import { useQuery } from "@apollo/client/react";
import { getAppointmentGpLetter } from "./__generated__/getAppointmentGpLetter";
import { GET_GP_LETTER } from "./gql";
import GpLetterPdf from "./GpLetterPdf";

export interface GpLetterPdfContainerProps {
  appointmentId: string;
  locationId: string;
}

const GpLetterPdfContainer: React.FC<GpLetterPdfContainerProps> = (
  props: GpLetterPdfContainerProps,
) => {
  const { refetch } = useQuery<getAppointmentGpLetter>(GET_GP_LETTER, {
    variables: { appointmentId: props.appointmentId, locationId: props.locationId },
    skip: true,
    fetchPolicy: "network-only",
  });

  return <GpLetterPdf refetch={refetch} appointmentId={props.appointmentId} locationId={props.locationId} />;
};

export default GpLetterPdfContainer;
