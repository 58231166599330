import { ORIGIN_URL } from "../../constants";
import ClinicalReviewOrderList from "pages/ClinicalReviewPage/ClinicalReviewPage";
import ClinicalReviewOrderDetails from "pages/ClinicalReviewPage/OrderDetails/OrderDetails";
import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import ClinicalReviewProtectedRoute from "./components/ProtectedRoute/ClinicalReviewProtectedRoute";
import { Auth } from "aws-amplify";
import { apiInstance } from "constants/api";
import PreApprovedProductOrderDetails from "pages/ClinicalReviewPage/OrderDetails/PreApprovedProductOrderDetails";
// exposed so we can handle re-directs from other pages/components by reference
export const ROUTES = {
  clinical: {
    dashboard: "/",
    awaiting: "/awaiting",
    completed: "/completed",
    login: "/clinical-review/login",
  },
};

const Routes = () => {
  useEffect(() => {
    sessionStorage.removeItem(ORIGIN_URL);
    updateToken();
  }, []);

  async function updateToken() {
    try {
      const session = await Auth.currentSession();
      apiInstance.defaults.headers.common.Authorization = `Bearer ${session
        .getIdToken()
        .getJwtToken()}`;
    } catch (error) {
      console.log("Error updating token", error);
    }
  }

  return (
    <Switch>
      <ClinicalReviewProtectedRoute exact path={"/clinical-review"}>
        <ClinicalReviewOrderList />
      </ClinicalReviewProtectedRoute>
      <ClinicalReviewProtectedRoute exact path={"/clinical-review/preapprovedproduct"}>
        <ClinicalReviewOrderList />
      </ClinicalReviewProtectedRoute>
      <ClinicalReviewProtectedRoute exact path={"/clinical-review/order/:id"}>
        <ClinicalReviewOrderDetails />
      </ClinicalReviewProtectedRoute>
      <ClinicalReviewProtectedRoute exact path={"/clinical-review/customer/:customerId"}>
        <PreApprovedProductOrderDetails />
      </ClinicalReviewProtectedRoute>
      <ClinicalReviewProtectedRoute
        exact
        path={"/clinical-review/:category/:status"}
      >
        <ClinicalReviewOrderList />
      </ClinicalReviewProtectedRoute>
    </Switch>
  );
};

export default Routes;
