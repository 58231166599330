import { gql } from "@apollo/client";
import { ScreeningFragment } from "./fragments/screening";

export const START_SCREENING = gql`
  mutation startScreening($input: StartScreeningInput!) {
    startScreening(input: $input) {
      ...Screening
    }
  }
  ${ScreeningFragment}
`;
