import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";

import "./index.scss";
import { formatDate } from "utils/time";
import { IdCheckPreApprovedProduct } from "../PreApprovedProductOrderDetailsWrapper";


 const IdCheckVerificationPreApprovedProduct = ({idChecksPreApprovedProduct} :{idChecksPreApprovedProduct:IdCheckPreApprovedProduct}) => {
 
  const idCheckStatus=idChecksPreApprovedProduct?.idCheckStatus || "";

  return (
    <div className="id-check">
      <Grid container>
          <Typography variant="h3">
            ID check status: {idCheckStatus === "" ? `API failure` : idCheckStatus === "pass" ? `ID verified` : idCheckStatus.toUpperCase()}
          </Typography>
      </Grid>
      {idCheckStatus !== "" && idChecksPreApprovedProduct?.lnStatusUpdateAt && idChecksPreApprovedProduct.lnStatusUpdatedBy && idChecksPreApprovedProduct.idCheckPreviousStatus &&
         <div className="update-info-idchk">
            <p className="last-id-check">Updated {idChecksPreApprovedProduct.lnStatusUpdateAt ? formatDate(idChecksPreApprovedProduct.lnStatusUpdateAt, "dd/MM/yy") : ""}, {idChecksPreApprovedProduct.lnStatusUpdatedBy}. Previous status: {idChecksPreApprovedProduct.idCheckPreviousStatus}
            </p>
            <p className="id-check-ref"> Notes: {idChecksPreApprovedProduct.lnSearchRef}</p>
          </div> }
    </div>
  );
};

export default IdCheckVerificationPreApprovedProduct;

