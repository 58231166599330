/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDropzone } from 'react-dropzone';


function DropBox({ onDrop }: { onDrop: (acceptedFiles: any, rejectedFiles: any) => void }) {

  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
    isDragActive
  } = useDropzone({
    accept: { accepted: ['image/png', 'image/jpeg', 'image/HEIC'] },
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <div className='photo-upload-presentation' {...getRootProps({ isDragAccept, isFocused, isDragReject })}>
        <input {...getInputProps()} />

        <div className="uploadbox" onClick={isDragActive ? () => { } : () => open()} >
          <span className="uploadIcon center" />
          {
            isDragActive ?
              <p className="uploadBoxDescription">Drop the files here ...</p> :
              <>
                <p className="uploadBoxDescription">Drop your photo here or   <a href="#" style={{ marginLeft: "5px", color: "#2359BF" }}> browse</a></p>
                <p className="center uploadBoxDescription" >Maximum file size 25MB</p>
              </>
          }
        </div>

      </div>
    </>
  );
}
export default DropBox;