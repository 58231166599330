import { getCoverPharmacistDays } from "components/AvailabilityModal/__generated__/getCoverPharmacistDays";
import { addDays, areSameDay, formatDateAws } from "utils/time";
import { DayInput } from "__generated__/globalTypes";

export interface CoverPharmacistSlot {
  dates: Date[];
  from: Date;
  until: Date;
}

export function coverPharmacistDaysToSlots(
  coverPharmacistDays: getCoverPharmacistDays | undefined,
): CoverPharmacistSlot[] {
  if (
    !coverPharmacistDays ||
    !coverPharmacistDays.getCoverPharmacistDays ||
    !coverPharmacistDays.getCoverPharmacistDays.length
  ) {
    return [];
  }

  const coverPharmacistSlots: CoverPharmacistSlot[] = [];

  for (
    let index = 0;
    index < coverPharmacistDays.getCoverPharmacistDays.length;
    index++
  ) {
    const day = coverPharmacistDays.getCoverPharmacistDays[index];
    if (!day) {
      continue;
    }
    const date = new Date(day.date);
    const slot = findExistingSlotForDate(coverPharmacistSlots, date);
    if (slot) {
      addToSlot(slot, date);
      continue;
    }
    coverPharmacistSlots.push({
      dates: [date],
      from: date,
      until: date,
    });
  }
  return coverPharmacistSlots;
}

function findExistingSlotForDate(
  coverPharmacistSlots: CoverPharmacistSlot[],
  date: Date,
): CoverPharmacistSlot | null {
  date.setHours(0, 0, 0, 0);
  for (let index = 0; index < coverPharmacistSlots.length; index++) {
    const slot = coverPharmacistSlots[index];
    slot.until.setHours(0, 0, 0, 0);
    const until = addDays(new Date(slot.until), 1);
    if (areSameDay(date, until)) {
      return slot;
    }

    slot.from.setHours(0, 0, 0, 0);
    const from = addDays(new Date(slot.from), -1);
    if (areSameDay(date, from)) {
      return slot;
    }
  }
  return null;
}

function addToSlot(slot: CoverPharmacistSlot, date: Date) {
  slot.dates.push(date);
  if (date < slot.from) {
    slot.from = date;
    return;
  }
  slot.until = date;
}

export function coverPharmacistSlotsToInput(
  slot: CoverPharmacistSlot,
): DayInput[] {
  return slot.dates.map((d) => {
    return {
      date: formatDateAws(d),
    };
  });
}
