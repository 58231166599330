import React from "react";
import { Theme, makeStyles, Grid, Link } from "@material-ui/core";
import { Typography, Divider, Spacing, Button } from "@welldigital/components";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import ServiceSplitScreen from "components/ServiceSplitScreen/ServiceSplitScreen";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { formatAppointmentDateTime } from "../../utils/time";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    padding: theme.spacing(2, 6),
  },
  editLink: {
    color: theme.palette.primary.light,
  },
}));

export interface AppointmentBookedPageProps {
  data?: getAppointment;
  appointmentId: string;
  isLoading: boolean;
  error?: ApolloError;
}

const AppointmentBookedPage: React.FC<AppointmentBookedPageProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isLoading, error, data } = props;

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data?.getAppointment) return <p>Appointment not found</p>;
  const { getAppointment: appointment } = data;
  const appointmentDate = formatAppointmentDateTime(appointment.startTime);


  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `/customer-details/${appointment.customer.id}`,
            name: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
          },
          {
            url: `/service-details/${appointment.id}`,
            name: `${appointment.course?.name}`,
          },
          {
            url: `/service-details/${appointment.id}/reschedule`,
            name: `Reschedule ${appointment.course?.name}`,
          },
        ]}
      />
      <ServiceSplitScreen appointment={appointment} isActionButtonHidden>
        <div className={classes.contentContainer}>
          <Spacing spacing={1} />
          <Typography
            variant={"h3"}
            spacingAfter={0}
            data-testid={"booking-confirmation-title"}
          >
            {appointment.course?.name} appointment booked
          </Typography>
          <Spacing spacing={2} />
          <Typography>
            The customer will receive an email confirming their appointment date
            and time.
          </Typography>
          <Spacing spacing={2} />
          <Typography variant={"body2"} spacingAfter={1}>
            Please ensure that you have the relevant product in stock.
          </Typography>
          <Spacing spacing={2} />
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <Typography data-testid={"appointment-date"}>
                {appointmentDate}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Link
                className={classes.editLink}
                data-testid={"edit-link"}
                component={RouterLink}
                to={`/service-details/${appointment.id}/reschedule`}
              >
                <Typography variant={"h5"}>Edit</Typography>
              </Link>
            </Grid>
          </Grid>
          <Divider spacingBefore={0} spacingAfter={2} />
          <Button
            variant={"outlined"}
            color={"primary"}
            data-testid={"customer-details-return"}
            onClick={() =>
              history.push(`/customer-details/${appointment.customer.id}`)
            }
          >
            Return to customer details
          </Button>
        </div>
      </ServiceSplitScreen>
    </>
  );
};

export default AppointmentBookedPage;
