import React, { FC, memo } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

export interface QuestionAuditItemProps {
  questionNumber: number;
  fromAnswer: string;
  toAnswer: string;
  updatedDate: Date;
}

const useStyles = makeStyles((theme: Theme) => ({
  smallGreyText: {
    fontStyle: "Medium",
    fontSize: "12px",
    color: theme.palette.grey[700],
  },
  padding: {
    paddingBottom: theme.spacing(0.5),
  },
}));

export const QuestionAuditItem: FC<QuestionAuditItemProps> = memo((props) => {
  const classes = useStyles();

  return (
    <div className={classes.padding}>
      <Typography className={classes.smallGreyText}>
        Change: {props.fromAnswer} to {props.toAnswer}
      </Typography>
      <Typography className={classes.smallGreyText}>
        Edited: {new Date(props.updatedDate).toDateString()}
      </Typography>
    </div>
  );
});
