import { Days, PeriodTypes } from "@welldigital/components";

export const PAUSED_REASON = "Service Pause";
export const BLOCK_REASON = "Service Block";
export const DATE_FORMAT = "yyyy-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const INFINITE_END_DATE = "9999-12-31";
export const FULL_DAY_MIN_TIME = 0;
export const FULL_DAY_MAX_TIME = 2345;

export type ServiceData = {
  isPaused: boolean;
  pausedBlockId?: string;
  blocks: ServiceBlock[];
};

export type ServiceBlock = {
  blockId?: string;
  period?: {
    startTime?: Date;
    endTime?: Date;
  };
  periodType: PeriodTypes;
  weekdays: Days[];
};

export type IndividualServiceParams = {
  id: string;
  locationId?: string;
};
