import React, { useCallback, useEffect, useState } from "react";
import { FormControl, Grid, MenuItem, Select, Button } from "@material-ui/core";
import { Alert, Typography } from "@welldigital/components";
import PreApprovedProductOrderDetailsWrapper, { PropsTypes, PreApprovedProductOption } from "./PreApprovedProductOrderDetailsWrapper";
import OrderHistoryDetails from "./OrderHistoryDetails/OrderHistoryDetails";
import IdCheckVerificationPreApprovedProduct from "./IdCheckVerification/IdCheckVerificationPreApprovedProduct";
import { ReactComponent as IcArrowDown } from "assets/icons/arrow-down.svg";
import { CreatCartPayloadType, createCart, deletePreApprovedProductPatient } from "../ClinicalReviewApi";
import Loader from "pages/ClinicalReviewPage/components/Loader/Loader";
import RemovePreApprovedProductPatientModal from "./PreApprovedProduct/RemovePreApprovedProductPatientModal";
import { formatDate } from "utils/time";
import ChangeTreatment from "./ChangeTreatment/ChangeTreatment";

const PreApprovedProductOrderDetails = ({
  preApprovedProduct,
  customerId,
  idChecksPreApprovedProduct,
  treatmentName,
  previousTreatmentName,
  treatmentUpdatedBy,
  treatmentUpdatedAt,
  treatmentWarning
}: PropsTypes) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [noteText, setOrderNote] = useState<string>('');
  const [cartId, setCartId] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsgRemovePatient, setSuccessMsgRemovePatient] = useState<string>('');
  const [isloading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [preApprovedProductCurrentList, setPreApprovedProductCurrentList] = useState<PreApprovedProductOption[]>([]);
  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
    ) => {
      setSelectedValue(parseInt(event.target.value as string));
    },
    [setSelectedValue],
  );

  const onChangeNoteText = (val: string) => {
    setOrderNote(val);
  }

  const handleCreateCart = async () => {
    setIsLoading(true)
    const selectedDose = preApprovedProductCurrentList?.filter(product=> product.id === selectedValue);
    try {
      const payload: CreatCartPayloadType = {
        items: [
          {
            quantity: 1,
            id: selectedValue,  //2914
            doseNumber: selectedDose?.[0]?.doseNumber ,
            treatmentName: selectedDose?.[0]?.treatmentName
          }
        ]
      }
      const response = await createCart(customerId, payload);
      if (response?.status === 200) {
        setCartId(response.data.cartId)
      } else {
        setErrorMsg(`${response.statusText} : ${response.status}`);
      }
    } catch (error) {
      setErrorMsg("Error in creating the Checkout link");
      console.log("Error in creating the Checkout link", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleRemovePatient = async () => {
    setIsLoading(true)
    try {
      const response = await deletePreApprovedProductPatient(customerId, treatmentName, {
        comment: noteText
      });
      if (response?.status === 204) {
        setSuccessMsgRemovePatient("This customer has been removed from the PreApprovedProduct patient list.")
      } else {
        setErrorMsg(`${response.statusText} : ${response.status}`);
      }
    } catch (error) {
      setErrorMsg("Error in Removing Patient");
      console.log("Error in Removing Patient", error);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }
  const productName = preApprovedProduct.filter(d => selectedValue !== 0 && d.id === selectedValue).map(product => product.name)?.[0];
  useEffect(() => {
    setSelectedValue(0)
    const list = preApprovedProduct.filter(product => product.treatmentName === treatmentName);
    setPreApprovedProductCurrentList(list)
    list.length === 1 && setSelectedValue(list[0].id)
  }, [preApprovedProduct, treatmentName])

  return (
    <div className="wegovy-container">
      {errorMsg && <Grid className="mb-20">
        <Alert message={errorMsg} type={"error"} />
      </Grid>}
      {treatmentWarning && <Grid className="mb-20">
        <Alert message={'Current treatment has not yet been issued. Check customer order history for details.'} type={"info"} />
      </Grid>
      }
      <Grid container className="mb-20">
        <Grid xs={12} sm={6} md={6}>
          <div className="id-check">
            <Grid container>
              <Typography variant="h3">
                Treatment: {treatmentName}
              </Typography>
            </Grid>
            {treatmentUpdatedAt !=='' && treatmentUpdatedBy !== '' &&
              <div className="update-info-idchk">
                <p className="last-id-check">Updated {treatmentUpdatedAt ? formatDate(treatmentUpdatedAt, "dd/MM/yy") : ""}, {treatmentUpdatedBy}
                </p>
                {previousTreatmentName !== '' && <p className="id-check-ref">Previous treatment: {previousTreatmentName}</p>}
              </div>}
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={6}>
          {(idChecksPreApprovedProduct?.idCheckStatus) ? <IdCheckVerificationPreApprovedProduct idChecksPreApprovedProduct={idChecksPreApprovedProduct} /> : ""}
        </Grid>
      </Grid>
      <Loader loading={isloading} />
      {!isloading && <><div className='wegovy-content'>
        <Grid container className="mb-20">
          <Grid item xs={12} sm={6} md={12}>
            <Typography variant="h3" spacingAfter={0} className="font-weight-700 color-primary-hale mb-10">
              Create a pre-approved {treatmentName} cart
            </Typography>
          </Grid>
          {cartId !== '' ? <Grid item xs={12} sm={6} md={12}>
            <Typography className="font-weight-700 color-primary-hale mb-10">
              Cart {cartId} ({productName}) created. A checkout link has been sent to the patient.
            </Typography>
          </Grid> : <Grid item xs={12} sm={6} md={12}>
            {preApprovedProductCurrentList.length > 1 ? <><Grid item xs={12} sm={6} md={6}>
              <Typography className="font-weight-700 color-primary-hale mb-10">
                Select product
              </Typography>
              <FormControl fullWidth>
                <Select
                  IconComponent={() => (
                    <IcArrowDown className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  variant="outlined"
                  value={selectedValue}
                  onChange={handleChange}>
                  <MenuItem value={0}>Select {treatmentName} dose</MenuItem>
                  {preApprovedProductCurrentList && preApprovedProductCurrentList.map((option: PreApprovedProductOption) => {
                    return <MenuItem value={option.id}>{option.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid></> : ''}
            <Typography className="font-weight-500 color-primary-hale mb-10 mt-10">
              When patient pays for this order it will be sent for dispatch. Please update the patient record in PharmOutcomes.
            </Typography>
            <Grid item xs={12} sm={6} md={12} className="preapproved-buttons">
              <ChangeTreatment treatment={treatmentName} customerId={customerId} preApprovedProduct={preApprovedProduct} />
              <Button
                type={"submit"}
                size={"medium"}
                disabled={(selectedValue === 0 && !isloading) || successMsgRemovePatient !== ''}
                onClick={handleCreateCart}
                className="button-primary"
                style={{ width: '198px', margin: '10px' }}
              >
                Create cart
              </Button>
            </Grid>
          </Grid>}
        </Grid>
      </div>
        <div className='wegovy-content'>
          <Grid container className="mb-20">
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h3" spacingAfter={0} className="font-weight-700 color-primary-hale mb-10">
                Remove from weight management patient list
              </Typography>
              {!successMsgRemovePatient ? <><Typography className="font-weight-500 color-primary-hale mb-10">
                If the patient wishes to restart medicated weight loss treatment in future, they must place a new order via the website. They will need to complete the consultation form and upload images again.
              </Typography>
                <textarea
                  id="notes"
                  value={noteText}
                  className="add_note_textField"
                  onChange={(e) => onChangeNoteText(e.target.value)}
                  style={{ width: '100%', marginBottom: "10px" }}
                /></> : <Typography className="font-weight-500 color-primary-hale mb-10">
                {successMsgRemovePatient}
              </Typography>}

            </Grid>

            {!successMsgRemovePatient && <Grid item xs={12} sm={6} md={12}>
              <Button
                type={"submit"}
                size={"medium"}
                disabled={cartId !== ''}
                onClick={() => setVisible(true)}
                className="button-primary"
                style={{ float: 'right', width: '198px' }}
              >
                Remove patient
              </Button>
            </Grid>}
            {visible && <RemovePreApprovedProductPatientModal onClose={setVisible} onRemovePatient={handleRemovePatient} />}
          </Grid>
        </div>
      </>}
      {customerId && (
        <OrderHistoryDetails
          orderId={0}
          customerId={customerId}
        />
      )}
    </div>
  );
};
export default PreApprovedProductOrderDetailsWrapper(PreApprovedProductOrderDetails);