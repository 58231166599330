import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { Customer as CustomerTypes, OrderDetails as OrderDetailsTypes } from "../../Types";
import { formatDOB } from "utils/time";
import EditCustomerDetails from "./EditCustomerDetails";
import { formatPhoneNumber } from "utils/user";
import Loader from "pages/ClinicalReviewPage/components/Loader/Loader";

interface PropsTypes {
  customerId: number;
  loading?: boolean;
  onUpdateSuccess?: () => void;
  customerDetails: CustomerTypes | null;
  orderDetails?: OrderDetailsTypes | null;
}

const CustomerDetails: React.FC<PropsTypes> = ({
  customerId,
  customerDetails,
  loading,
  onUpdateSuccess,
  orderDetails
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Grid className="crp_customer_container">
        <Typography
          variant="h5"
          className="crp_customer_heading"
          spacingAfter={2}
        >
          Customer details
        </Typography>
        <Loader loading={loading} />
        {customerDetails && (
          <>
            {/* userName */}
            <Typography
              variant="caption"
              className="crp_customer_caption"
              spacingAfter={0}
            >
              Customer Name
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={1}
            >
              {customerDetails.firstName} {customerDetails.lastName}
            </Typography>

            {/* DOB */}
            <Grid container className="crp_customer_dob_container">
              <Grid sm={12} md={12} lg={6} xs={12}>
                <Typography
                  variant="caption"
                  className="crp_customer_caption"
                  spacingAfter={0}
                >
                  Date of birth
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle"
                  spacingAfter={1}
                >
                  {formatDOB(customerDetails.dateOfBirth)}
                </Typography>
              </Grid>
              <Grid sm={12} md={6} lg={3} xs={12}>
                <Typography
                  variant="caption"
                  className="crp_customer_caption"
                  spacingAfter={0}
                >
                  Age
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle"
                  spacingAfter={1}
                >
                  {customerDetails.age}
                </Typography>
              </Grid>
              <Grid sm={12} md={6} lg={3} xs={12}>
                <Typography
                  variant="caption"
                  className="crp_customer_caption"
                  spacingAfter={0}
                >
                  Sex
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle text-capitalize"
                  spacingAfter={1}
                >
                  {customerDetails.sex}
                </Typography>
              </Grid>
            </Grid>
            {/* phone */}
            <Typography
              variant="caption"
              className="crp_customer_caption"
              spacingAfter={0}
            >
              Phone
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={1}
            >
              {customerDetails.phone &&
                formatPhoneNumber(customerDetails.phone)}
            </Typography>

             {/* email */}
             <Typography
              variant="caption"
              className="crp_customer_caption"
              spacingAfter={0}
            >
              Email
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={1}
            >
              {customerDetails.email &&
                customerDetails.email}
            </Typography>

            {/* address */}
            <Typography
              variant="caption"
              className="crp_customer_caption"
              spacingAfter={0}
            >
              Address
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={0}
            >
              {orderDetails?.customerBillingAddress.address1 || customerDetails.addressDetails.address1}
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={0}
            >
              {orderDetails?.customerBillingAddress.address2 || customerDetails.addressDetails.address2}
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={0}
            >
              {orderDetails?.customerBillingAddress.city || customerDetails.addressDetails.city}
            </Typography>
            <Typography
              variant="subtitle2"
              className="crp_customer_subtitle"
              spacingAfter={3}
            >
              {orderDetails?.customerBillingAddress.postalCode || customerDetails.addressDetails.postalCode}
            </Typography>
            {!!customerDetails.gpDetails?.gpAddress1 && (
              <>
                {/* GP Details */}
                <Typography
                  variant="h5"
                  className="crp_customer_gp"
                  spacingAfter={2}
                >
                  GP details
                </Typography>
                {/* GP surgery */}
                <Typography
                  variant="caption"
                  className="crp_customer_caption"
                  spacingAfter={0}
                >
                  GP surgery
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  spacingAfter={1}
                >
                  {customerDetails.gpDetails?.gpSurgery}
                </Typography>

                {/*gp address */}
                <Typography
                  variant="caption"
                  className="crp_customer_caption"
                  spacingAfter={0}
                >
                  Address
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle"
                  spacingAfter={0}
                >
                  {customerDetails.gpDetails?.gpAddress1}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle"
                  spacingAfter={0}
                >
                  {customerDetails.gpDetails?.gpAddress2}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle"
                  spacingAfter={0}
                >
                  {customerDetails.gpDetails?.gpCity}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="crp_customer_subtitle"
                  spacingAfter={3}
                >
                  {customerDetails.gpDetails?.gpPostalCode}
                </Typography>
              </>
            )}
            <Button
              type={"button"}
              className="crp_customer_button"
              size={"medium"}
              onClick={() => setVisible(true)}
            >
              Edit patient details
            </Button>
          </>
        )}
      </Grid>
      {visible && (
        <EditCustomerDetails
          customerId={customerId}
          customerDetails={customerDetails!}
          onClose={setVisible}
          onUpdateSuccess={onUpdateSuccess}
          orderDetails={orderDetails}
        />
      )}
    </>
  );
};

export default CustomerDetails;
