import { gql } from "@apollo/client";

export const GET_RESOURCE_BLOCKS = gql`
  query getResourceBlocks(
    $limit: Int!
    $offset: Int!
    $startDate: AWSDate
    $endDate: AWSDate
    $locationId: String!
  ) {
    getResourceBlocks(
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
      locationId: $locationId
    ) {
      id
      resourceId
      startDate
      endDate
      startTime
      endTime
      reason
      repeats
      repeat {
        frequency
        interval
        weekdays
        monthDay
        monthType
      }
    }
  }
`;

export const CREATE_RESOURCE_BLOCK = gql`
  mutation createResourceBlock(
    $startDate: AWSDate!
    $startTime: Int!
    $endTime: Int!
    $reason: String!
    $endDate: AWSDate
    $allDay: Boolean
    $repeats: Boolean
    $repeat: RepeatInput
    $locationId: String!
  ) {
    createResourceBlock(
      startDate: $startDate
      startTime: $startTime
      endTime: $endTime
      reason: $reason
      endDate: $endDate
      allDay: $allDay
      repeats: $repeats
      repeat: $repeat
      locationId: $locationId
    ) {
      id
    }
  }
`;

export const UPDATE_RESOURCE_BLOCK = gql`
  mutation updateResourceBlock(
    $blockId: ID!
    $startDate: AWSDate!
    $startTime: Int!
    $endTime: Int!
    $reason: String!
    $endDate: AWSDate
    $allDay: Boolean
    $repeats: Boolean
    $repeat: RepeatInput
    $locationId: String!
  ) {
    updateResourceBlock(
      blockId: $blockId
      startDate: $startDate
      startTime: $startTime
      endTime: $endTime
      reason: $reason
      endDate: $endDate
      allDay: $allDay
      repeats: $repeats
      repeat: $repeat
      locationId: $locationId
    ) {
      id
    }
  }
`;

export const DELETE_RESOURCE_BLOCK = gql`
  mutation deleteResourceBlock($blockId: ID!) {
    deleteResourceBlock(blockId: $blockId) {
      id
    }
  }
`;

export const GET_COVER_PHARMACIST_DAYS = gql`
  query getCoverPharmacistDays($locationId: String!) {
    getCoverPharmacistDays(locationId: $locationId) {
      date
    }
  }
`;

export const CREATE_COVER_PHARMACIST = gql`
  mutation createCoverPharmacistDays($days: [DayInput!]!) {
    createCoverPharmacistDays(days: $days) {
      date
    }
  }
`;

export const DELETE_COVER_PHARMACIST_BLOCK = gql`
  mutation deleteCoverPharmacistDays($days: [DayInput!]!) {
    deleteCoverPharmacistDays(days: $days)
  }
`;

export const CREATE_SERVICE_BLOCK = gql`
  mutation createServiceBlock(
    $serviceId: String!
    $locationId: String!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $startTime: Int!
    $endTime: Int!
    $reason: String!
    $repeat: RepeatInput
    $repeats: Boolean
  ) {
    createServiceBlock(
      serviceId: $serviceId
      locationId: $locationId
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      reason: $reason
      repeat: $repeat
      repeats: $repeats
    ) {
      id
    }
  }
`;
export const UPDATE_SERVICE_BLOCK = gql`
  mutation updateServiceBlock(
    $serviceBlockId: String!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $startTime: Int!
    $endTime: Int!
    $reason: String!
    $repeat: RepeatInput
    $repeats: Boolean
  ) {
    updateServiceBlock(
      serviceBlockId: $serviceBlockId
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      reason: $reason
      repeat: $repeat
      repeats: $repeats
    ) {
      id
    }
  }
`;

export const GET_SERVICE_BLOCKS = gql`
  query getServiceBlocks(
    $offset: Int!
    $limit: Int!
    $id: ID!
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getServiceBlocks(
      offset: $offset
      limit: $limit
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      locationId
      serviceId
      startDate
      endDate
      startTime
      endTime
      reason
      repeats
      repeat {
        frequency
        interval
        weekdays
        monthDay
        monthType
      }
    }
  }
`;

export const GET_SERVICE_BLOCK = gql`
  query getServiceBlock($id: ID!) {
    getServiceBlock(id: $id) {
      id
      locationId
      serviceId
      startDate
      endDate
      startTime
      endTime
      reason
      repeats
      repeat {
        frequency
        interval
        weekdays
        monthDay
        monthType
      }
    }
  }
`;

export const DELETE_SERVICE_BLOCK = gql`
  mutation deleteServiceBlock($serviceBlockId: String!) {
    deleteServiceBlock(serviceBlockId: $serviceBlockId) {
      id
    }
  }
`;
