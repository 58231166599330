import create from "zustand";

import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from "utils/localStorageUtils";

import { DeepPartial } from "react-hook-form";
import {
  BookingSlot,
  Appointment,
  PersisterLocalStorageKeys,
  FormValues,
} from "./types";

export interface AppointmentPersister {
  appointment: Appointment | null;
  setBookedSlot: (slot: BookingSlot | null) => void;
  setServiceId: (serviceId: string) => void;
  setFormData: (formData: FormValues) => void;
  removeAppointment: () => void;
}

export const useAppointmentPersister = create<AppointmentPersister>((set) => {
  const handleUpdateAppointment = (
    cb: (oldAppointment: Appointment) => DeepPartial<Appointment>,
  ) => {
    const appointment = getFromLocalStorage<Appointment>(
      PersisterLocalStorageKeys.appointment,
    );
    const newAppointment = cb(appointment as Appointment) as Appointment;
    setToLocalStorage(PersisterLocalStorageKeys.appointment, newAppointment);
    (window as any).appointment = newAppointment;
    set({ appointment: newAppointment });
  };
  (window as any).appointment = getFromLocalStorage<Appointment>(
    PersisterLocalStorageKeys.appointment,
  );

  return {
    appointment: getFromLocalStorage<Appointment>(
      PersisterLocalStorageKeys.appointment,
    ),

    removeAppointment() {
      set({ appointment: null });
      deleteFromLocalStorage(PersisterLocalStorageKeys.appointment);
    },

    setBookedSlot(bookedSlot) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        bookedSlot,
      }));
    },

    setServiceId(serviceId) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        serviceId,
      }));
    },

    setFormData(formData) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        formData,
      }));
    },
  } as AppointmentPersister;
});
