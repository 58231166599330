import { useQuery, useMutation } from "@apollo/client/react";
import React, { useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BOOK_NEXT_APPOINTMENT } from "./gql";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { GET_AVAILABILITY } from "graphql/getAvailability";
import { getAvailability } from "graphql/__generated__/getAvailability";
import {
  bookNextAppointmentVariables,
  bookNextAppointment_bookNextAppointment,
} from "./__generated__/bookNextAppointment";
import AvailabilitySelectorScreen from "components/AvailabilitySelectorScreen/AvailabilitySelectorScreen";
import {
  Availability,
  FetchAvailabilityInput,
} from "../../components/AppointmentSelector";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const now = new Date();

const BookNextServicePageContainer: React.FC = () => {
  const locationId = useAppSelector(selectLocationId);
  const { id } = useParams<{ id: string }>();

  const getAppointmentQuery = useQuery<getAppointment>(GET_APPOINTMENT, {
    variables: { appointmentId: id, locationId: locationId },
    fetchPolicy: "network-only",
  });

  const [selectedAvailability, setSelectedAvailability] = useState<
    Availability
  >();

  const [availability, setAvailability] = useState<Availability[]>();
  const [fetchAvailabilityInput, setFetchAvailabilityInput] = useState<
    FetchAvailabilityInput
  >();

  const [isFetching, setIsFetching] = useState<boolean>();

  const getAvailabilityQuery = useQuery<getAvailability>(GET_AVAILABILITY, {
    skip:
      !fetchAvailabilityInput?.serviceId ||
      !fetchAvailabilityInput.start ||
      !fetchAvailabilityInput.end ||
      !getAppointmentQuery?.data?.getAppointment?.course.onSchedServiceId ||
      !locationId,
    variables: {
      serviceId:
        getAppointmentQuery?.data?.getAppointment?.course.onSchedServiceId,
      startDate: now,
      endDate: now,
      locationId: locationId,
    },
  });

  const fetchAvailability = useCallback(
    (input: FetchAvailabilityInput) => {
      setFetchAvailabilityInput(input);
      setIsFetching(true);
      getAvailabilityQuery.fetchMore({
        variables: {
          serviceId: input.serviceId,
          startDate: input.start.toISOString(),
          endDate: input.end.toISOString(),
          locationId: locationId,
        },
        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
          const newAvailability = fetchMoreResult?.getAvailability;
          setIsFetching(false);
          if (!fetchMoreResult || !newAvailability?.length) {
            return prev;
          }
          setAvailability(newAvailability as Availability[]);
          return {
            ...fetchMoreResult,
            ...prev,
          };
        },
      });
    },
    [getAvailabilityQuery, locationId],
  );

  const error = getAppointmentQuery.error || getAvailabilityQuery.error;
  const isFetchingAvailability = getAvailabilityQuery.loading || isFetching;
  const isLoading = getAppointmentQuery.loading || getAvailabilityQuery.loading;

  const history = useHistory();
  const [bookNextAppointment] = useMutation(BOOK_NEXT_APPOINTMENT);
  const handleComplete = useCallback(() => {
    if (!selectedAvailability) {
      throw new Error("no availability selected");
    }
    if (!getAppointmentQuery?.data?.getAppointment) {
      throw new Error("appointment was not defined");
    }
    const variables: bookNextAppointmentVariables = {
      courseId: getAppointmentQuery?.data?.getAppointment?.course.id,
      startTime: selectedAvailability?.startTime,
      endTime: selectedAvailability?.endTime,
      resourceId: selectedAvailability?.resourceId,
      locationId: locationId,
    };
    setIsFetching(true);
    bookNextAppointment({
      variables,
    }).then((result: any) => {
      setIsFetching(false);
      const responseData: bookNextAppointment_bookNextAppointment =
        result.data.bookNextAppointment;
      history.push(`/service-details/${responseData.id}/booked`);
    });
  }, [selectedAvailability, getAppointmentQuery, bookNextAppointment, history, locationId]);

  return (
    <AvailabilitySelectorScreen
      appointmentId={id}
      appointmentData={getAppointmentQuery.data}
      availabilityData={
        availability
          ? ({ getAvailability: availability } as getAvailability)
          : getAvailabilityQuery.data
      }
      fetchAvailability={fetchAvailability}
      selectedAvailability={selectedAvailability}
      setSelectedAvailability={setSelectedAvailability}
      isFetchingAvailability={isFetchingAvailability}
      error={error}
      isLoading={isLoading}
      onSubmit={handleComplete}
    />
  );
};

export default BookNextServicePageContainer;
