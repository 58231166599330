import * as yup from "yup";
import { parse, isValid, isFuture, differenceInCalendarYears } from "date-fns";
import { CustomerForm } from "pages/ClinicalReviewPage/OrderDetails/CustomerDetails/EditCustomerDetails";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "validateDate",
  function (errorMessage: string) {
    return this.test(`date-validity`, errorMessage, function (value: string) {
      if (!value) {
        return true;
      }
      const { path, createError } = this;
      const date = parse(value, "yyyy-MM-dd", new Date());
      return (
        (isValid(date) && !isFuture(date)) ||
        createError({ path, message: errorMessage })
      );
    });
  },
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "maxAge",
  function (errorMessage: string) {
    return this.test(
      `max-age-validity`,
      errorMessage,
      function (value: string) {
        if (!value) {
          return true;
        }
        const { path, createError } = this;
        const date = parse(value, "yyyy-MM-dd", new Date());
        return (
          differenceInCalendarYears(new Date(), date) < 120 ||
          createError({ path, message: errorMessage })
        );
      },
    );
  },
);

// @ts-ignore
export const customerSchema: yup.Schema<Partial<CustomerForm>> = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    address1: yup.string().required("Address line 1 is required"),
    address2: yup.string(),
    city: yup.string().required("City is required"),
    postalCode: yup.string().required("Postal Code is required"),
    gpAddress1: yup.string().required("Address line 1 is required"),
    gpAddress2: yup.string().required("Address line 2 is required"),
    gpCity: yup.string().required("City is required"),
    phone: yup
      .string()
      .required("Phone number is required"),
    dateOfBirth: yup
      .string()
      // @ts-ignore
      .validateDate("Invalid date")
      // @ts-ignore
      .maxAge("Max age is 120"),
    //   .required("Invalid date of birth"),
  });

// @ts-ignore
export const customerSchemaWithoutGP: yup.Schema<Partial<CustomerForm>> = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    address1: yup.string().required("Address line 1 is required"),
    address2: yup.string(),
    city: yup.string().required("City is required"),
    postalCode: yup.string().required("Postal Code is required"),
    phone: yup
      .string()      
      .required("Phone number is required"),
    dateOfBirth: yup
      .string()
      // @ts-ignore
      .validateDate("Invalid date")
      // @ts-ignore
      .maxAge("Max age is 120"),
    //   .required("Invalid date of birth"),
  });

  // @ts-ignore
  export const updateStatusSchema: yup.Schema<Partial<updateStatusForm>> = yup
  .object()
  .shape({
    status: yup.string().notOneOf(["select"]).required("Status is required"),
   // lnSearchRef: yup.string().required("LN search reference is required"),
   lnSearchRef:yup.string().test('len', 'Maximum 100 characters are allowed', val => val.length <= 100),
  });

  // @ts-ignore
  export const ChangeTreatmentSchema: yup.Schema<Partial<updateStatusForm>> = yup
  .object()
  .shape({
    treatment: yup.string().notOneOf(["select"]).required("Treatment is required"),
  });

  // @ts-ignore
  export const updateOrderSchema: yup.Schema<Partial<updateStatusForm>> = yup
  .object()
  .shape({
    status: yup.string().notOneOf(["select"]).required("Status is required"),
  });
