import React, { useCallback } from "react";
import { Theme, makeStyles, TextField } from "@material-ui/core";
import { Divider, Spacing, Typography } from "@welldigital/components";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import ReactMarkdown from "react-markdown";
import { ApolloError } from "@apollo/client";
import { formatMarkdown } from "utils/markdown";
import ServiceSplitScreen from "components/ServiceSplitScreen/ServiceSplitScreen";
import {
  getAppointment,
  getAppointment_getAppointment_guardian,
} from "graphql/__generated__/getAppointment";
import { Question } from "graphql/types";
import { QuestionAudit } from "utils/question";
import EditQuestionsTable from "../../components/EditQuestionsTable/EditQuestionsTable";
import AppointmentCountChip from "../../components/AppointmentCountChip/AppointmentCountChip";
import EditPharmacist from "../../components/EditPharmacist/EditPharmacist";
import { PharmacistInput } from "../../__generated__/globalTypes";
import EditGuardian from "components/EditGuardian/EditGuardian";
import EditWegovyQuestionsTable from "components/EditWegovyQuestionsTable/EditWegovyQuestionsTable";
import { differenceInYears } from "date-fns";

const useStyles = makeStyles((theme: Theme) => ({
  highlight: {
    background: "#EAF2FE;",
  },
}));

export interface ConfirmServiceDetailsPageProps {
  appointmentId: string;
  isLoading?: boolean;
  error?: ApolloError;
  data?: getAppointment;
  notes?: string | null;
  questionAudits: QuestionAudit[];
  onNotesChange: (n: string) => void;
  onSubmit: () => void;
  onQuestionUpdate: (q: Question) => void;
  pharmacist: PharmacistInput;
  handleUpdatePharmacist: (pharmacist: PharmacistInput) => void;
  guardian: getAppointment_getAppointment_guardian | null;
  handleUpdateGuardian: (
    g: getAppointment_getAppointment_guardian | null,
  ) => void;
}

const ConfirmServiceDetailsPage: React.FC<ConfirmServiceDetailsPageProps> = (
  props,
) => {
  const {
    onNotesChange,
    onSubmit,
    questionAudits,
    onQuestionUpdate,
    handleUpdatePharmacist,
    pharmacist,
    guardian,
    handleUpdateGuardian,
  } = props;
  const classes = useStyles();
  const handleAppointmentNotesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onNotesChange(event.target.value);
    },
    [onNotesChange],
  );

  if (props.isLoading) return <p>Loading...</p>;
  if (props.error) return <p>Error: {props.error.message}</p>;
  if (!props.data?.getAppointment) return <p>no data</p>;
  const { getAppointment: appointment } = props.data;

  const existingAppointmentNotes = appointment.notes || "";
  const existingPharmacist = appointment.pharmacist || {
    title: "",
    name: "",
    gphcNumber: "",
  };
  const hasNotesEdit =
    props.notes != null && props.notes !== existingAppointmentNotes;
  const hasPharmacistEdit =
    pharmacist != null &&
    (pharmacist.title !== existingPharmacist.title ||
      pharmacist.name !== existingPharmacist.name ||
      pharmacist.gphcNumber !== existingPharmacist.gphcNumber);

  const isUnder18 =
    differenceInYears(new Date(), new Date(appointment.customer?.dob)) <= 18;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const disableAction =
    !pharmacist.gphcNumber || !pharmacist.name || !pharmacist.title;

  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `/customer-details/${appointment.customer.id}`,
            name: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
          },
          {
            url: `/service-details/${appointment.id}`,
            name: `${appointment.course?.name}`,
          },
          {
            url: `/service-details/${appointment.id}/edit`,
            name: "Appointment in progress",
          },
        ]}
      />
      <ServiceSplitScreen
        actionButtonText={"Next"}
        appointment={appointment}
        onActionSubmit={onSubmit}
        isActionDisabled={disableAction}
      >
        <AppointmentCountChip appointmentId={appointment.id} />
        <Spacing spacing={1} />
        <Typography variant={"h3"} spacingAfter={1}>
          Confirm your changes
        </Typography>
        <Typography variant={"body1"} spacingAfter={1}>
          Some pre-screen questions have been edited. Please check your changes
          are accurate and the patient is still eligible for the treatment.
        </Typography>
        <Typography variant={"h4"} spacingAfter={1}>
          Pharmacist information
        </Typography>
        <EditPharmacist
          isEdited={hasPharmacistEdit}
          pharmacist={pharmacist ? pharmacist! : existingPharmacist!}
          appointment={appointment}
          handleUpdatePharmacist={handleUpdatePharmacist}
        />
        <Divider spacingBefore={2} spacingAfter={2} />

        {isUnder18 && (
          <>
            <EditGuardian
              appointment={appointment}
              guardian={guardian}
              handleUpdateGuardian={handleUpdateGuardian}
              allowHighlight
            />
            <Divider spacingBefore={2} spacingAfter={2} />
          </>
        )}

{ appointment?.course.onSchedServiceId === process.env.REACT_APP_WEGOVY_SERVICES? 
    <div>
     <EditWegovyQuestionsTable
      appointmentId={appointment.id}
     /> </div>
     :  <div>
     <EditQuestionsTable
            questionAudits={questionAudits}
            handleQuestionUpdate={onQuestionUpdate}
            canHighlight
            showAudits
          />
   </div> 
      }

        {appointment.course?.pharmacistInstructions && (
          <>
            <Divider spacingBefore={2} spacingAfter={2} />
            <div>
              <Typography variant={"h5"} spacingAfter={0}>
                Pharmacist instructions
              </Typography>
              <ReactMarkdown
                source={formatMarkdown(
                  appointment.course?.pharmacistInstructions,
                )}
              />
            </div>
          </>
        )}

        <Divider spacingBefore={0} spacingAfter={2} />

        <Typography variant={"h5"} spacingAfter={1}>
          Pharmacist notes
        </Typography>
        <TextField
          className={`${hasNotesEdit ? classes.highlight : ""}`}
          inputProps={{
            "data-testid": "confirmServiceDetails/notes-input",
          }}
          multiline
          rows={6}
          fullWidth
          value={hasNotesEdit ? props.notes : existingAppointmentNotes}
          onChange={handleAppointmentNotesChange}
        />
      </ServiceSplitScreen>
    </>
  );
};

export default ConfirmServiceDetailsPage;
