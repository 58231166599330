import LoginPageContainer from "pages/ClinicalReviewPage/Login";
import React from "react";
import { Switch, Route } from "react-router-dom";

import LoginPage from "../../pages/LoginPage";
import SecretCodePage from "../../pages/SecretCodePage";
import { ROUTES } from "./ClinicalReviewRoutes";

const Routes = () => (
  <Switch>
    <Route path={ROUTES.clinical.login}>
        <LoginPageContainer />
    </Route>
    <Route path={"/login"}>
      <LoginPage />
    </Route>
    <Route path={"/enter-secret-code"}>
      <SecretCodePage />
    </Route>
  </Switch>
);

export default Routes;
