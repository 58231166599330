import React from "react";
import { signOut } from "services/auth";

import Header from "./Header";
import { useCurrentUserEmail } from "services/auth-hooks";

const ConnectedHeader: React.FC = () => {
  const email = useCurrentUserEmail();
  return <Header email={email || ""} signOut={signOut} />;
};

export default ConnectedHeader;
