import { GET_LOCATION, LocationQueryVars } from "../../IndividualServices/gql";
//import { getLocationId } from "../../../../services/auth";
import { useQuery } from "@apollo/client/react";
import {
  getLocation_getLocation,
  getLocation,
  getLocation_getLocation_businessHours_mon,
} from "../../IndividualServices/__generated__/getLocation";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export type LocationState = {
  location?: getLocation_getLocation | null;
  minTimeNumber: number;
  maxTimeNumber: number;
};

const DEFAULT_MIN_TIME = 0;
const DEFAULT_MAX_TIME = 2400;

export function useLocation(): LocationState {
 // const [locationId, setLocationId] = useState<string>();
  const locationId = useAppSelector(selectLocationId);
  // console.log("locationId useLocationHook",locationID);
  // useEffect(() => {
  //  // (async () => {
  //     setLocationId(locationID);
  // //  })();
  // }, [setLocationId]);

  const { data } = useQuery<getLocation, LocationQueryVars>(GET_LOCATION, {
    variables: { locationId: locationId as string, ignoreCovid: false },
    skip: !locationId,
  });

  const businessHours = (data?.getLocation?.businessHours || {}) as any;
  const dayKeys = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const { minTime, maxTime } = dayKeys.reduce(
    (map, day: any) => {
      const dayBusinessHours = businessHours[
        day
      ] as getLocation_getLocation_businessHours_mon;

      if (!dayBusinessHours || !dayBusinessHours.isOpen) {
        return map;
      }

      const { startTime, endTime, is24Hours } = dayBusinessHours;
      if (is24Hours) {
        map.minTime = DEFAULT_MIN_TIME;
        map.maxTime = DEFAULT_MAX_TIME;
        return map;
      }

      if (
        startTime !== null &&
        (map.minTime === undefined || startTime < map.minTime)
      ) {
        map.minTime = startTime;
      }
      if (
        endTime !== null &&
        (map.maxTime === undefined || endTime > map.maxTime)
      ) {
        map.maxTime = endTime;
      }
      return map;
    },
    { minTime: undefined, maxTime: undefined } as {
      minTime?: number;
      maxTime?: number;
    },
  );

  return {
    location: data?.getLocation,
    minTimeNumber: minTime === undefined ? DEFAULT_MIN_TIME : minTime,
    maxTimeNumber: maxTime === undefined ? DEFAULT_MAX_TIME : maxTime,
  };
}
