import React from "react";
import { TextField } from "@material-ui/core";

export interface Question3TypeProps {
  setAnswer(value: string): void;
  keyId: string;
  error: boolean;
}

const Question3: React.FC<Question3TypeProps> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setAnswer((event.target as HTMLInputElement).value);
  };

  return (
    <TextField
      key={props.keyId}
      id="standard-number"
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={onChange}
      error={props.error}
      helperText={props.error && "Enter a valid number"}
    />
  );
};

export default Question3;
