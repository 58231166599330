import { useQuery } from "@apollo/client/react";
import React from "react";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";
import { formatDate } from "../../../../utils/time";
import { GET_RESOURCE_BLOCKS, GET_COVER_PHARMACIST_DAYS } from "../../gql";
import { getCoverPharmacistDays } from "../../__generated__/getCoverPharmacistDays";
import {
  getResourceBlocks,
  getResourceBlocksVariables,
} from "../../__generated__/getResourceBlocks";
import WalkInFluClinic from "./WalkInFluClinic";

export const WALK_IN_FLU_CLINIC = "Walk-in flu clinic";

function WalkInFluClinicContainer({ onClose, locationId }: { onClose: () => void, locationId: string }) {
 // const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, data } = useQuery<
    getResourceBlocks,
    getResourceBlocksVariables
  >(GET_RESOURCE_BLOCKS, {
    variables: {
      limit: 25,
      offset: 0,
      startDate: formatDate(new Date(), "yyyy-MM-dd"),
      endDate: "9999-01-01",
      locationId:locationId,
    },
  });
  const coverPharmacistDays = useQuery<getCoverPharmacistDays>(
    GET_COVER_PHARMACIST_DAYS,
    {
      variables: { locationId: locationId },
    },
  );
  if (isLoading || coverPharmacistDays.loading) {
    return <p data-testid="WalkInFluClinicContainer/loading">Loading...</p>;
  }

  /* 
        HACKS BE HERE!

        doesnt seem like the backend is setup to filter by the reason or get the scheduled leave data by itself
        so instead we get all resource blocks (first 25) then filter by the reason
    */
  const blocks: getResourceBlocks["getResourceBlocks"] =
    data?.getResourceBlocks?.filter(
      (block:any) => block?.reason.includes(WALK_IN_FLU_CLINIC),
    ) ?? [];

  return (
    <WalkInFluClinic
      data-testid="WalkInFluClinicContainer/content"
      blocks={blocks}
      coverDays={coverPharmacistDays.data!}
      onClose={onClose}
      locationId={locationId}
    />
  );
}

export default WalkInFluClinicContainer;
