import React, { useState } from "react";
import { Button } from "@material-ui/core";

import "./index.scss";
import UploadImageContainer, { PropsTypes } from "./UploadImageContainer";


const UploadImage = ({ productId, orderId, onUpdateImages, categoryType }: PropsTypes) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <Button
        variant="text"
        onClick={() => setVisible(true)}
        color={"primary"}
        size={"medium"}
        className="button_view"
      >

        Upload Images
      </Button>
      {visible && (<>
        <UploadImageContainer productId={productId} orderId={orderId} onClose={setVisible} onUpdateImages={onUpdateImages} categoryType={categoryType} />
      </>
      )}
    </>
  );
};

export default UploadImage;
