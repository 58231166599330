import {
  differenceInYears,
  format,
  isFuture,
  isToday,
  parse,
  isValid,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function workOutAge(date: string): string {
  return differenceInYears(new Date(), new Date(date)).toFixed(0);
}

export function formatDate(
  date: string | Date,
  formatter = "dd/MM/yyyy",
): string {
  return format(utcToZonedTime(date, timezone), formatter);
}

export function formatTime(date: string | Date): string {
  return format(utcToZonedTime(date, timezone), "HH:mm");
}

export function formatAppointmentDateTime(date: string | Date): string {
  return format(utcToZonedTime(date, timezone), "EEEE, d MMMM y, h:mmaaaa");
}

export function formatConfirmationDateTime(date: string | Date): string {
  return format(utcToZonedTime(date, timezone), "EEEE d MMMM 'at' HH.mm aa");
}

export function formatPreviousAppointmentDateTime(date: string): string {
  return format(utcToZonedTime(date, timezone), "dd/MM/yyyy, hh:mm");
}

export function dateIsInFuture(date: string): boolean {
  return isFuture(utcToZonedTime(date, timezone));
}

export function dateIsToday(date: string): boolean {
  return isToday(utcToZonedTime(date, timezone));
}

export function formatWeekday(date: string | Date): string {
  return format(utcToZonedTime(date, timezone), "EEEE");
}

export function formatWeekdayDateMonth(date: string | Date): string {
  return format(utcToZonedTime(date, timezone), "EEEE do MMMM");
}

export function formatNumberTimeToString(numberTime: number) {
  const time = `${numberTime}`;
  if (numberTime === 0 || numberTime === 2400) {
    return "00:00";
  } else if (numberTime < 60) {
    return `00:${time}`;
  } else if (numberTime < 1000) {
    return `0${time.substring(0, time.length - 2)}:${time.substring(
      time.length - 2,
    )}`;
  } else {
    return `${time.substring(0, time.length - 2)}:${time.substring(
      time.length - 2,
    )}`;
  }
}

export function formatDateToInt(date: Date | string): number {
  const timeString = formatTime(date);
  const timeInt = timeString.replace(":", "");
  return parseInt(timeInt);
}

export function formatDateAws(date: string | Date): string {
  return format(utcToZonedTime(date, timezone), "yyyy-MM-dd");
}

export function formatDateTimeAws(date: string | Date): string {
  return utcToZonedTime(date, timezone).toISOString();
}

export function createBlockedTimeSlots(): number[] {
  let timeslots: any[] = [];
  for (let i = 0; i < 24; i++) {
    timeslots.push(i * 100, i * 100 + 15, i * 100 + 30, i * 100 + 45);
  }

  return timeslots;
}
/**
 * Tries to convert a string to an ISO date by checking if the input matches
 * a UK style date, otherwise returns its original input
 */
export function ukDateToIso(date: string): string {
  const parsedDateSlashes = parse(date, "d/M/yy", new Date());
  const parsedDateDots = parse(date, "d.M.yy", new Date());
  const parsedFullDateSlashes = parse(date, "d/M/yyyy", new Date());
  const parsedFullDateDots = parse(date, "d.M.yyyy", new Date());
  const validDates = [
    {
      isValid: isValid(parsedDateSlashes),
      parsed: parsedDateSlashes,
    },
    {
      isValid: isValid(parsedDateDots),
      parsed: parsedDateDots,
    },
    {
      isValid: isValid(parsedFullDateSlashes),
      parsed: parsedFullDateSlashes,
    },
    {
      isValid: isValid(parsedFullDateDots),
      parsed: parsedFullDateDots,
    },
  ];
  const validDate = validDates.find((v) => v.isValid);

  if (validDate) {
    return format(validDate.parsed, "yyyy-MM-dd");
  }

  return date;
}

export function getDaysBetweenDate(
  startDateInput: Date,
  endDate: Date,
): Date[] {
  let dateArray = new Array<Date>();
  let currentDate = new Date(startDateInput);
  currentDate.setHours(0, 0, 0, 0);

  endDate.setHours(0, 0, 0, 0);
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export function addDays(date: Date, days: number): Date {
  return new Date(date.setDate(date.getDate() + days));
}

export function areSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function formatDOB(dob: string = ""): string {
  if (!dob) {
    return "";
  }
  const parsedDate = parse(dob, "yyyy-M-d", new Date());

  return formatDate(parsedDate);
}
