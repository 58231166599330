export const YES_ANSWER = "Y";
export const NO_ANSWER = "N";
export const SPLITTER = ":";

export function isYes(answer: string): boolean {
  return answer === YES_ANSWER;
}

export function getFollowUpQuestion(questionParts: string[]): string {
  if (questionParts.length > 1) {
    let followUpQ = questionParts[1].replace("If yes, ", "");
    return capitaliseFirstLetter(followUpQ);
  }
  return "";
}

export function capitaliseFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
