import { Button } from "@material-ui/core";
import React, { useState } from "react";
import ConfirmBMIDetails from "./ConfirmBMIDetails";
import { PropsTypes } from "../UploadImageContainer/UploadImageContainer";

const ConfirmBMI: React.FC<PropsTypes> = ({ orderId, productId, onUpdateImages }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      {/* <Loader loading={loading} /> */}
      <Button
        variant="text"
        onClick={() => setVisible(true)}
        color={"primary"}
        style={{ borderRadius: "25px" }}
        size={"medium"}
        className="button_view"
      >
        Confirm BMI
      </Button>
      {visible && (
        <ConfirmBMIDetails
          orderId={orderId}
          productId={productId}
          onClose={setVisible}
          onUpdateImages={onUpdateImages}
        />
      )}
    </>
  );
};

export default ConfirmBMI;
