import { MutationHookOptions, useMutation } from "@apollo/client";
import { POST_APPOINTMENT } from "../../../graphql/postAppointment";
import {
  postAppointment,
  postAppointmentVariables,
} from "../../../graphql/__generated__/postAppointment";

export const initErrorMessage:string="The appointment time you selected is no longer available. Please pick another time."
export const KEY_INIT_API='sdwegrtw'

export function useBookAppointment({
  onError,
  onCompleted,
}: Pick<MutationHookOptions, "onError" | "onCompleted">) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [bookAppointment, { loading, error }] = useMutation<
    postAppointment,
    postAppointmentVariables
  >(POST_APPOINTMENT, { onError, onCompleted });
  return {
    bookAppointment,
    loading,
    error,
  };
}
