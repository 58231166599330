import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Theme, CardContent } from "@material-ui/core";
import { analytics } from "@welldigital/ui-common";
import { Typography } from "@welldigital/components";
import { NextQuestionButton, QuestionRow } from "./components";
import { useHistory } from "react-router-dom";
import events from "utils/events";
import {
  LOCAL_STORAGE_SCREENING_QUESTION,
  useScreeningQuestions,
} from "./hooks/useScreeningQuestions";
import { nextScreeningVariables } from "../../graphql/__generated__/nextScreening";
import { Service } from "./types";
import BookAppointment from "./components/BookAppointment";
import GenericErrorMessage from "../Error/GenericErrorMessage";
import { ROUTES } from "../../app/routers/LoggedInRoutes";

const useStyles = makeStyles((theme: Theme) => ({
  questionContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
}));
export interface ScreeningPageProps {
  service: Service;
}

const ScreeningPage: React.FC<ScreeningPageProps> = ({ service }) => {
  const classes = useStyles();
  const [answer, setAnswer] = useState<string>("");
  const history = useHistory();
  const [isComplete, setIsComplete] = useState(false);

  const {
    nextScreening,
    screeningQuestion: question,
    error,
    loading: isLoading,
    count,
  } = useScreeningQuestions();
  const isFinished =
    !!question?.hasResult &&
    !!question.isAllowedService &&
    !!question.patientAdvice;

  const fetchNextQuestion = useCallback(
    async (vars: nextScreeningVariables) => {
      await nextScreening(vars);
    },
    [nextScreening],
  );

  const fetchNext = useCallback(async () => {
    if (question) {
      analytics.trackEvent({
        flow: service.flow,
        event: events.screening.answeredQuestion,
        metadata: {
          question: question.questionText,
        },
      });
      await fetchNextQuestion({
        input: {
          nextQuestionNumber: question.nextQuestionNumber!,
          currentQuestionNumber: question.currentQuestionNumber!,
          patientAnswer: answer,
          screeningID: question.screeningId!,
        },
      });
    }
    setAnswer("");
  }, [answer, fetchNextQuestion, question, service.flow]);

  useEffect(() => {
    if (
      !!question?.hasResult &&
      !question.isAllowedService &&
      !!question.patientAdvice &&
      !isLoading
    ) {
      analytics.trackEvent({
        flow: service.flow,
        event: events.screening.ineligible,
        metadata: {
          reason: "failed screening",
        },
      });
      window.localStorage.removeItem(LOCAL_STORAGE_SCREENING_QUESTION);
      history.push(ROUTES.walkIn.ineligible);
    }
  }, [question, isLoading, history, service.flow]);

  console.log(`isFinished`, isFinished);
  useEffect(() => {
    if (!isFinished) return;
    setIsComplete(true);
  }, [isFinished]);

  if (isComplete) {
    return <BookAppointment service={service} />;
  }

  if ((error && typeof error != "undefined") || (!question && !isLoading)) {
    window.localStorage.removeItem(LOCAL_STORAGE_SCREENING_QUESTION);
    return <GenericErrorMessage />;
  }

  return (
    <>
      <Typography
        data-testid={"ScreeningPage/ScreeningQuestionHeader"}
        variant={"h3"}
        spacingAfter={0}
        gutterBottom
      >
        Screening questions
      </Typography>
      {!question || isLoading ? (
        <Typography data-testid={"ScreeningPage/loading"}>Loading</Typography>
      ) : (
        <CardContent className={classes.questionContainer}>
          {!question.hasResult && (
            <QuestionRow
              count={count}
              answer={answer}
              onChange={setAnswer}
              question={question}
            />
          )}
          {!question.hasResult && (
            <NextQuestionButton
              fetchNextQuestion={fetchNext}
              disabled={!answer || isLoading}
            />
          )}
        </CardContent>
      )}
    </>
  );
};

export default ScreeningPage;
