import React from "react";
import { Theme, makeStyles, Grid, Link } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import LogoSvg from "../Logo/Logo";
// import { WEBSITE_NAME } from "../../constants";
import './Footer.scss';

export const footerHeight = 181;

const useStyles = makeStyles((theme: Theme) => ({
  footerArea: {
    position: "relative",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: footerHeight,
    padding: theme.spacing(5),
    clear: "both",
    width: "100%",
  },
  gridContainer: {
    justifyContent: "center",
  },
  text: {
    color: "white",
    paddingBottom: theme.spacing(2),
    maxWidth: "75%",
    fontSize: "16px",
    lineHeight: "24px",
  },
  text2: {
    color: "white",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    maxWidth: "305px",
  },
  give_feedback: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "10px",
  },
  feedback_link : {
    color: "#fff",
    
  }
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footerArea}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={3}>
          <LogoSvg />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography className={classes.text2} variant={"body2"}>
            {/* This is a trial and your help is vital. If you have any feedback on
            how we could improve {WEBSITE_NAME}, please let us know. */}
            If you have any feedback on how we could improve this service, please let us know.
          </Typography>
          <Typography variant={"h5"} className={classes.give_feedback}>
            <Link
              href={
                "https://docs.google.com/forms/d/e/1FAIpQLScSWQQfF-YWeyD0KMOZeDQYQhHo0WmkKSi4LEU3aJBdFsRBmg/viewform?usp=sf_link"
              }
              className={classes.feedback_link}
              data-testid={"footer/feedback-link"}
            >
              Give Feedback
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.text2} variant={"body2"}>
            For support contact:
          </Typography>
          <Typography className={classes.give_feedback} variant={"h5"} spacingAfter={0}>
          <Link
              href={"tel:0123 456 6789"}
              data-testid={"footer/tel-link"}
              className={classes.text}
            >0123 456 6789</Link>
          </Typography>
          <Typography variant={"h5"} spacingAfter={0}>
            <Link
              href={"mailto:help@digital.well.co.uk"}
              data-testid={"footer/email-link"}
              className={classes.text}
            >
              help@digital.well.co.uk
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
