import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import capitalize from "lodash/capitalize";

export interface GpDetailsType {
  surgeryName: string;
  surgeryAddress: string;
}
export interface GpDetailsProps {
  gp: GpDetailsType;
}

const GpDetails: React.FC<GpDetailsProps> = (props) => {
  return (
    <Box ml={3} mr={3} pt={1} pb={3}>
      <Typography variant={"body2"}>GP name</Typography>
      <Typography spacingAfter={1}>
        {capitalize(props.gp.surgeryName)}
      </Typography>
      <Typography variant={"body2"}>Address</Typography>
      <Typography>{capitalize(props.gp.surgeryAddress)}</Typography>
      {/* <Button
        variant={"outlined"}
        color={"primary"}
        fullWidth
      >
        Edit GP details
      </Button> */}
    </Box>
  );
};

export default GpDetails;
