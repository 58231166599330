import React, { FC, useCallback } from "react";
import { useQuery } from "@apollo/client/react";
import { useHistory } from "react-router-dom";
import { GET_APPOINTMENTS } from "../AppointmentsTable/gql";
import ToBeRescheduledTable from "./ToBeRescheduledTable";
import { Alert, Typography } from "@welldigital/components";
import { format } from "date-fns";
import {
  formatDate,
  formatDateAws,
  formatDateToInt,
} from "../../../../utils/time";
import { GET_RESOURCE_BLOCKS } from "../../../../components/AvailabilityModal/gql";
//import { getLocationId } from "services/auth";
import {
  getResourceBlocks,
  getResourceBlocksVariables,
} from "../../../../components/AvailabilityModal/__generated__/getResourceBlocks";
// import { useAppSelector } from "redux/hooks";
// import { selectLocationId } from "redux/reduxSlice/selector";

const ToBeRescheduledTableContainer: FC<{locationId:string}> = (locationId) => {
  const history = useHistory();
  const goToServiceDetails = useCallback(
    (id: string) => () => history.push(`/service-details/${id}`),
    [history],
  );
 // const locationId = useAppSelector(selectLocationId);

  // useEffect(() => {
  //   if (!locationId) {
  //     const getLocId = async () => {
  //       const location = await getLocationId();
  //       setLocationId(location);
  //     };

  //     getLocId();
  //   }
  // }, [locationId, setLocationId]);

  const startDate = format(new Date(), "yyyy-MM-dd");
  const endDate = format(
    new Date().setFullYear(new Date().getFullYear() + 1),
    "yyyy-MM-dd",
  );
  const { loading: isLoading, error, data } = useQuery<any>(GET_APPOINTMENTS, {
    variables: {
      startDate,
      endDate,
      locationId: locationId.locationId,
    },
  });

  const { data: getData } = useQuery<
    getResourceBlocks,
    getResourceBlocksVariables
  >(GET_RESOURCE_BLOCKS, {
    variables: {
      limit: 25,
      offset: 0,
      startDate: formatDate(new Date(), "yyyy-MM-dd"),
      endDate: "9999-01-01",
      locationId: locationId.locationId
    },
  });

  const resourceBlockData = getData?.getResourceBlocks || [];

  if ((error?.networkError as any)?.statusCode === 401) {
    history.push("/login");
  }

  if (error)
    return (
      <Typography variant={"body1"} color={"error"}>
        Error: {error.message}
      </Typography>
    );
  const result: any[] = [];
  data?.getAppointments?.forEach((appointment: any) => {
    resourceBlockData?.forEach((resource) => {
      const resourceEndDate = formatDateAws(resource?.endDate || "0");
      const resourceStartDate = formatDateAws(resource?.startDate || "0");
      const appointmentEndDate = formatDateAws(appointment.endTime);
      const appointmentStartDate = formatDateAws(appointment.startTime);

      const isAppointmentTimeGreaterThanResourceTime =
        (resource?.startTime || 0) <= formatDateToInt(appointment.startTime);
      const isResourceTimeGreaterThanAppointmentTime =
        (resource?.endTime || 0) >= formatDateToInt(appointment.endTime);
      const isAppointmentDateGreaterThanResourceDate =
        resourceStartDate <= appointmentStartDate;
      const isResourceDateGreaterThanAppointmentDate =
        resourceEndDate >= appointmentEndDate;

      if (
        isAppointmentTimeGreaterThanResourceTime &&
        isResourceTimeGreaterThanAppointmentTime &&
        isAppointmentDateGreaterThanResourceDate &&
        isResourceDateGreaterThanAppointmentDate
      ) {
        result.push(appointment);
      }
    });
  });

  const onlyRescheduledAppointments = Array.from(
    new Set(result.map((r) => r.id)),
  ).map((id) => {
    return result.find((r) => r.id === id);
  });

  if (isLoading || !data?.getAppointments?.length) return null;

  if (resourceBlockData.length === 0) return null;

  if (onlyRescheduledAppointments.length > 0) {
    return (
      <Alert
        message={`You have ${onlyRescheduledAppointments.length} appointments waiting to be rescheduled`}
        type={"info"}
        moreInfo={
          <ToBeRescheduledTable
            data={onlyRescheduledAppointments}
            goToServiceDetails={goToServiceDetails}
          />
        }
      />
    );
  } else {
    return null;
  }
};

export default ToBeRescheduledTableContainer;
