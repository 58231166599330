import React from "react";
import { useQuery } from "@apollo/client/react";
import { useParams } from "react-router-dom";
import AppointmentBookedPage from "./AppointmentBookedPage";
import { GET_APPOINTMENT } from "graphql/getAppointment";
import { getAppointment } from "graphql/__generated__/getAppointment";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const AppointmentBookedContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const locationId = useAppSelector(selectLocationId);
  const { loading: isLoading, error, data } = useQuery<getAppointment>(
    GET_APPOINTMENT,
    {
      variables: { appointmentId: id, locationId: locationId },
    },
  );

  return (
    <AppointmentBookedPage
      data={data}
      appointmentId={id}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default AppointmentBookedContainer;
