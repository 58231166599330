import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { Button } from "@welldigital/components";
import { useHistory } from "react-router-dom";
import { CANCEL_APPOINTMENT } from "graphql/cancelAppointment";
import { useMutation } from "@apollo/client/react";
import CancelAppointmentModal from "components/CancelAppointmentModal/CancelAppointmentModal";
import {
  AppointmentStatus,
  PaymentType,
} from "../../__generated__/globalTypes";
import { MoreHoriz } from "@material-ui/icons";

import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";
import { useCurrentUserEmail } from "services/auth-hooks";

export interface AppointmentActionsProps {
  appointmentId: string;
  paymentType: PaymentType;
  appointmentStatus: string;
}

const AppointmentActions = (props: AppointmentActionsProps) => {
  const email = useCurrentUserEmail();
  const locationId = useAppSelector(selectLocationId);
  const { appointmentId } = props;
  const history = useHistory();
  const goToServiceDetails = useCallback(
    (id: string) => () => history.push(`/service-details/${id}`),
    [history],
  );
  
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event?.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleReschedule = useCallback(
    (appointmentId: string) => () =>
      history.push(`/service-details/${appointmentId}/reschedule`),
    [history],
  );



  const [cancelAppointment] = useMutation(CANCEL_APPOINTMENT);
  const onCancelAppointment = useCallback(
    (shouldRefund: boolean, reason: string) => {
      return cancelAppointment({
        variables: {
          appointmentId,
          shouldRefund,
          reason,
          locationId,
          cancelledBy: email
        },
      });
    },
    [cancelAppointment, appointmentId,locationId,email],
  );

  const menuAnchorRef = useRef(null);
  const isAppointmentActionAllowed = useMemo(() => {
    if (
      props.appointmentStatus === AppointmentStatus.cancelled ||
      props.appointmentStatus === AppointmentStatus.completed
    ) {
      return false;
    } else {
      return true;
    }
  }, [props.appointmentStatus]);

  return (
    <>
      <Button
        data-testid={"appointmentActions/options-button"}
        onClick={handleMenuOpen}
        ref={menuAnchorRef}
        variant={"text"}
        color={"primary"}
      >
        <MoreHoriz />
      </Button>
      <Menu
        data-testid={"appointmentActions/modal-menu"}
        open={isOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          data-testid={"appointmentActions/view-service-details"}
          onClick={goToServiceDetails(appointmentId)}
        >
          View details
        </MenuItem>
        {isAppointmentActionAllowed && (
          <div>
            <MenuItem
              data-testid={"appointmentActions/reschedule"}
              onClick={handleReschedule(appointmentId)}
            >
              Reschedule
            </MenuItem>
            <MenuItem
              data-testid={"appointmentActions/cancel"}
              onClick={() => setCancelModalOpen(true)}
            >
              Cancel appointment
            </MenuItem>
          </div>
        )}
      </Menu>
      <CancelAppointmentModal
        cancelAppointment={onCancelAppointment}
        closeModal={() => {setCancelModalOpen(false);window.location.pathname.includes('customer-details') && window.location.reload();}}
        isModalOpen={cancelModalOpen}
        paymentType={props.paymentType}
      />
    </>
  );
};

export default AppointmentActions;
