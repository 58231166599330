import React, { useCallback } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@welldigital/components";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
export interface GenericErrorMessageProps {
  clearError?: () => void;
}

const GenericErrorMessage: React.FC<GenericErrorMessageProps> = ({
  clearError,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const goHome = useCallback(() => history.push("/"), [history]);

  return (
    <div>
      <Typography variant={"h2"} className={classes.text}>
        Something went wrong
      </Typography>
      <Typography className={classes.text}>
        Please refresh your page or go back to try again.
      </Typography>
      <Typography className={classes.text}>
        If you are still having problems, please email hello@well.co.uk. Please
        provide as much information as you can (for example, the steps you took
        to get to this screen, the browser you're using) so that our tech team
        can investigate.
      </Typography>
      <Button
        variant={"contained"}
        data-testid={"questionsPage/goBack-button"}
        color={"primary"}
        onClick={clearError ?? goHome}
      >
        <Typography variant={"h6"} align={"left"}>
          Go back
        </Typography>
      </Button>
    </div>
  );
};

export default GenericErrorMessage;
