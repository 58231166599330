import React from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import OrderNotes from "./OrderNotes/OrderNotes";
import OrderDetailsWrapper, { PropsTypes } from "./OrderDetailsWrapper";
import OrderHistoryDetails from "./OrderHistoryDetails/OrderHistoryDetails";
import OrderItemsForReview from "./OrderItemsForReview/OrderItemsForReview";
import IdCheckVerification from "./IdCheckVerification/IdCheckVerification";
import { formatOrderStatus } from "utils/user";
import { formatDate } from "utils/time";
import WarningBox from "./WarningBox";
import SubscriptionDetails from "./SubscriptionDetails/SubscriptionDetails";
import SubscriptionHistory from "./SubscriptionDetails/SubscriptionHistory";
import { formatPrice } from "utils/formatPrice";

const OrderDetails = ({
  details: { orderNotes, customerId, products, idCheckStatus, idCheckApiStatus = "", ...details },
  warnings,
  warningsLoading = false,
  // subscription,
  // subscriptionId,
  orderedForOthersWarning,
  onUpdateSuccess,
  isUpdateOrderModalOpen,
  subscriptionProductTitle,
  onUpdateImages
}: PropsTypes) => {

  
  const lowerCaseIdCheckStatus = idCheckStatus?.toLowerCase();
  const orderDetails = { orderNotes, customerId, products, idCheckStatus: lowerCaseIdCheckStatus || "", idCheckApiStatus, ...details };
  idCheckApiStatus = idCheckApiStatus?.toLowerCase();
  const orderStatus = (details.orderStatus.toLowerCase() === "Manual Verification Required".toLowerCase() && idCheckApiStatus === "failure") ? 'Awaiting ID check' : formatOrderStatus(details.orderStatus);
  const showLNWarning = (idCheckApiStatus === "failure" || (lowerCaseIdCheckStatus !== "" && lowerCaseIdCheckStatus !== "pass")) ? true : false;
  const orderNotesForConfirmBMI = orderDetails?.orderNotes?.length > 0 ? orderDetails?.orderNotes?.filter(notes => notes.productId !== 0) : [];


  return (
    <div className="crp_order_details">
      <Grid container className="mb-20">
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant="h1"
            className="crp_order_details_heading"
            spacingAfter={0}
          >
            Order ID: <b>{details.orderId}</b>
          </Typography>
          <Typography
            variant="h2"
            spacingAfter={0}
            className="crp_order_details_heading"
          >
            Placed: <b>{formatDate(details.orderDate, "dd/MM/yy")}</b>
          </Typography>
          {details?.shippingMethod && (
            <>
              {
                <Typography variant="body1" spacingAfter={0}>
                  Shipping method:{" "}
                  <b
                    className={`${details?.shippingMethod
                      ?.toLocaleLowerCase()
                      ?.includes("express")
                      ? "crp_order_details_shippingmethod_express"
                      : "crp_order_details_shippingmethod"
                      }`}
                  >
                    {details?.shippingMethod}
                  </b>
                </Typography>
              }
            </>
          )}
          {details?.orderAmount && (
            <Typography
              variant="body1"
              spacingAfter={0}
              className="crp_order_details_shippingmethod"
            >
              Order total: <b>{formatPrice(+details?.orderAmount)}</b>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant="subtitle2"
            spacingAfter={0}
            className="crp_order_details_status"
          >
            {orderStatus}
          </Typography>
        </Grid>
      </Grid>
      {!!products?.length && <SubscriptionDetails products={products} />}
      {(warningsLoading || !!warnings.length || showLNWarning) && (
        <WarningBox warnings={warnings} loading={warningsLoading} showLNWarning={showLNWarning} idCheckStatus={idCheckStatus} />
      )}
      {!!orderedForOthersWarning && (
        <WarningBox
          hideIcon
          title={orderedForOthersWarning.title}
          warnings={[orderedForOthersWarning]} idCheckStatus={""} />
      )}
      <OrderNotes
        notes={[...(orderNotes ?? [])].reverse()}
        orderId={parseInt(details.orderId)}
      />
      {(idCheckApiStatus !== "" || idCheckStatus !== "") ? <IdCheckVerification orderDetails={orderDetails} onUpdateSuccess={onUpdateSuccess} isUpdateOrderModalOpen={isUpdateOrderModalOpen} /> : ""}
      {!!products?.length && <SubscriptionHistory products={products} />}

      {customerId && (
        <OrderHistoryDetails
          orderId={parseInt(details.orderId)}
          customerId={customerId}
        />
      )}
      {products && (
        <OrderItemsForReview
          products={products}
          orderDate={details.orderDate}
          idCheckStatus={orderDetails.idCheckStatus}
          idCheckApiStatus={orderDetails.idCheckApiStatus}
          restrictedItemsApproval={orderDetails?.restrictedItemsApproval}
          onUpdateImages={onUpdateImages}
          orderNotesForConfirmBMI={orderNotesForConfirmBMI}
        />
      )}
    </div>
  );
};

export default OrderDetailsWrapper(OrderDetails);