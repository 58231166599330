import React, { useRef, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  Theme,
  MenuItem,
  Menu,
  Avatar,
  Button,
  Grid
} from "@material-ui/core";

import Search from "./components/Search";
import { WEBSITE_NAME_CLINICAL } from "../../constants";
import { ReactComponent as IcArrowDown } from "../../assets/icons/header_arrow_down.svg";
import { ReactComponent as IcWarning } from "../../assets/icons/warning-outlined.svg";
import "./header.scss";
import { useAppSelector } from "redux/hooks";
import { selectAwaitCountIdChecks } from "pages/ClinicalReviewPage/reduxSlice/selector";
 
export const headerHeight: number = 80;

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    overflow: "visible",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      margin: "auto",
      marginTop: "-9px",
      display: "block",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: `10px solid ${theme.palette.background.paper}`,
    },
  },
  marginTop: {
    marginTop: 0,
  },
}));

export interface HeaderProps {
  email: string;
  signOut: () => Promise<void>;
}
const Header: React.FC<HeaderProps> = ({ signOut, email }) => {
  const classes = useStyles();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const handleUserMenuOpen = useCallback(() => setIsUserMenuOpen(true), []);
  const handleUserMenuClose = useCallback(() => setIsUserMenuOpen(false), []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorIdCheck, setAnchorIdCheck] = React.useState(null);
  
  const awaitCountIdChecks = useAppSelector(selectAwaitCountIdChecks);
  const userMenuAnchorRef = useRef(null);
  
  const handleClick = (event: any, text: string) => {
    switch (text) {
      case "poms":
        setAnchorEl(event.currentTarget);
        break;
      case "pmeds":
        setAnchorEl2(event.currentTarget);
        break;
      case "idCheck":
        setAnchorIdCheck(event.currentTarget);
        break;
      default:
        return;
    }
  };

  const handleClose = (text: string) => {
    switch (text) {
      case "poms":
        setAnchorEl(null);
        break;
      case "pmeds":
        setAnchorEl2(null);
        break;
      case "idCheck":
        setAnchorIdCheck(null);
        break;
      default:
        return;
    }
  };
  return (
    <AppBar position={"static"} elevation={0} className="navigation">
      <Toolbar>
        <Grid container className="header_container">
          <Grid>
          <Box mb={2}>
          <Button
            variant={"text"}
            color={"inherit"}
            component={Link}
            to={"/clinical-review"}
          >
            <span className="logo_text" data-testid={"header/title-typography"}>
              {WEBSITE_NAME_CLINICAL}
            </span>
          </Button>
        </Box>
          </Grid>
          <Grid> <Box><Search /></Box></Grid>
          <Grid><Box
          display={"flex"}
          flexDirection={"row"}
          className="navlinks"
          onClick={(e) => handleClick(e, "poms")}
        >
          <Box>
            <Typography className="link">POMs</Typography>
          </Box>
          <Box className="arrow_down">
            <IcArrowDown className="mr-10" />
          </Box>
        </Box>
        <Menu
          id="poms"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose("poms")}
          style={{ top: "40px" }}
        >
          <MenuItem onClick={() => handleClose("poms")}>
            <Link
              to="/clinical-review/POM/AWAITING_REVIEW"
              className="menuanchor"
            >
              Awaiting clinical review
            </Link>
          </MenuItem>
          <MenuItem onClick={() => handleClose("poms")}>
            {" "}
            <Link to="/clinical-review/POM/COMPLETED" className="menuanchor">
              Completed
            </Link>
          </MenuItem>
        </Menu></Grid>
          <Grid>
          <Box
          display={"flex"}
          flexDirection={"row"}
          className="navlinks"
          onClick={(e) => handleClick(e, "pmeds")}
        >
          <Box>
            <Typography className="link">PMeds</Typography>
          </Box>
          <Box className="arrow_down">
            <IcArrowDown className="mr-10" />
          </Box>
        </Box>
        <Menu
          id="pmeds"
          anchorEl={anchorEl2}
          keepMounted
          open={Boolean(anchorEl2)}
          onClose={() => handleClose("pmeds")}
          style={{ top: "40px" }}
        >
          <MenuItem onClick={() => handleClose("pmeds")}>
            <Link
              to="/clinical-review/PMed/AWAITING_REVIEW"
              className="menuanchor"
            >
              Awaiting clinical review
            </Link>
          </MenuItem>
          <MenuItem onClick={() => handleClose("pmeds")}>
            <Link to="/clinical-review/PMed/COMPLETED" className="menuanchor">
              Completed
            </Link>
          </MenuItem>
        </Menu>
        </Grid>
        <Grid>
          <Box
          display={"flex"}
          flexDirection={"row"}
          className="navlinks"
          onClick={(e) => handleClick(e, "idCheck")}
        >
          <Box>
            <Typography className="link">ID Verification</Typography>
          </Box>
          <Box className="id-check-warning">
            {awaitCountIdChecks>0 && <IcWarning className="mr-10"/>}
          </Box>
          <Box className="arrow_down">
            <IcArrowDown className="mr-10" />
          </Box>
        </Box>
        <Menu
          id="idCheck"
          anchorEl={anchorIdCheck}
          keepMounted
          open={Boolean(anchorIdCheck)}
          onClose={() => handleClose("idCheck")}
          style={{ top: "40px" }}
        >
          <MenuItem onClick={() => handleClose("idCheck")}>
            <Link
              to="/clinical-review/id-check/AWAITING_ID_CHECK"
              className="menuanchor"
            >
              Awaiting ID check
            </Link>
            {awaitCountIdChecks>0 && <IcWarning className="mr-10 id-check-awaiting"/>}
          </MenuItem>
          <MenuItem onClick={() => handleClose("idCheck")}>
            <Link to="/clinical-review/id-check/ID_CHECK_COMPLETED" className="menuanchor">
              Completed
            </Link>
          </MenuItem>
        </Menu>
          </Grid>
          <Grid>
          <Box display={"flex"}
          flexDirection={"row"}
          mt={2} mb={2} className={classes.marginTop}>
            <Button style={{minWidth:"90px"}}
              variant={"text"}
              color={"inherit"}
              component={Link}
              to={"/clinical-review/preapprovedproduct/PREAPPROVEDPRODUCT"}
            >
             Weight loss clinic
            </Button>
          </Box>
          </Grid>
          <Grid className="signin">
          <Button
          ref={userMenuAnchorRef}
          variant={"text"}
          aria-label={"account of current user"}
          aria-controls={"menu-user"}
          aria-haspopup={"true"}
          onClick={handleUserMenuOpen}
          color={"inherit"}
         // className="marginLeft"
          startIcon={
            <Avatar className="avatar">
              {email?.[0]?.toUpperCase() || ""}
            </Avatar>
          }
        >
          {email}
        </Button>
        <Menu
          classes={{ paper: classes.menu }}
          className="paddingTop"
          id={"menu-user"}
          anchorEl={userMenuAnchorRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={isUserMenuOpen}
          onClose={handleUserMenuClose}
        >
          <MenuItem onClick={signOut}>Sign out</MenuItem>
        </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
