import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getOrderListAsync,getIdVerificationAsync, getIdVerificationAwaitCountAsync, getPreApprovedProductCustomerListAsync } from "./reduxSlice/actionsAsync";
import {
  selectIdChecks,
  selectOrderList,
  selectOrderListLoader,
  selectTotalOrders,
  selectTotalIdChecks,
  selectPreApprovedProductCustomerList,
  selectTotalPreApprovedProductCustomer
} from "./reduxSlice/selector";
import "./index.scss";
import { ClinicalReviewPageProps } from "./ClinicalReviewPage";
import { fetchOrderListTypes } from "./ClinicalReviewApi";

const ORDER_LIMIT = 10;

export default (WrappedComponent: React.FC<ClinicalReviewPageProps>) => () => {
  const dispatch = useAppDispatch();
  const orderList = useAppSelector(selectOrderList);
  const totalOrders = useAppSelector(selectTotalOrders);
  const totalIdChecks = useAppSelector(selectTotalIdChecks);
  const orderListLoader = useAppSelector(selectOrderListLoader);
  const idChecks = useAppSelector(selectIdChecks);
  const preApprovedProductCustomerList = useAppSelector(selectPreApprovedProductCustomerList);
  const totalPreApprovedProductCustomer = useAppSelector(selectTotalPreApprovedProductCustomer);
  const [orderListParams, setOrderListParams] = useState<
    {
      currentPage: number;
      category?: string;
      status: "COMPLETED" | "AWAITING_REVIEW" | "AWAITING_ID_CHECK" | "ID_CHECK_COMPLETED" | "PREAPPROVEDPRODUCT";
    } & Pick<fetchOrderListTypes, "sortKey" | "isAscending">
  >({
    currentPage: 0,
    status: "AWAITING_REVIEW",
    sortKey: "date",
    isAscending: true,
  });

  const { category, status: orderStatus } = useParams<{
    category?: string;
    status: "COMPLETED" | "AWAITING_REVIEW" | "AWAITING_ID_CHECK" | "ID_CHECK_COMPLETED" | "PREAPPROVEDPRODUCT";
  }>();

  
  useEffect(() => {
    /**
     * Reset the page number to 1 if status or category change (filter selected)
     */
    setOrderListParams((params) => ({
      ...params,
      currentPage: 1,
      status: orderStatus ?? "AWAITING_REVIEW",
      category,
      sortKey: category === 'preapprovedproduct' ? 'nextReviewDate' : "date",
      isAscending: (orderStatus ?? "AWAITING_REVIEW") === "AWAITING_REVIEW",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, orderStatus]); //categoryFilter

  useEffect(() => {
    // trigger only if currentPage is not 0, to avoid call on initialize
    !!orderListParams.currentPage && (orderListParams.category!=="id-check" && orderListParams.category!=="preapprovedproduct") &&
      dispatch(
        getOrderListAsync({
          orderId: orderListParams.currentPage,
          limit: ORDER_LIMIT,
          category: orderListParams.category,
          orderStatus: orderListParams.status,
          sortKey: orderListParams.sortKey,
          isAscending: orderListParams.isAscending,
        }),
      );
      !!orderListParams.currentPage &&  orderListParams.category === "preapprovedproduct" &&
      dispatch(
        getPreApprovedProductCustomerListAsync({
          limit: ORDER_LIMIT,
          page:  orderListParams.currentPage.toString(),
          sortKey: orderListParams.sortKey,
          isAscending: orderListParams.isAscending,
        }),
      );
      !!orderListParams.currentPage && dispatch(getIdVerificationAsync({
        page: orderListParams.currentPage,
        limit: ORDER_LIMIT,
        status: orderListParams.status
      }));
      dispatch(getIdVerificationAwaitCountAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderListParams.currentPage,
    orderListParams.category,
    orderListParams.status,
    orderListParams.sortKey,
    orderListParams.isAscending,
  ]);

  const onPageChange = (currentPage: number) =>
    setOrderListParams((params) => ({ ...params, sortKey: params.sortKey,currentPage }));
    
    const onSortChange = (sortKeyName: string) =>
    setOrderListParams((params) => ({
      ...params,
      sortKey: sortKeyName,
      currentPage: 1,
      isAscending:
        params.sortKey === sortKeyName
          ? !params.isAscending
          : params.status === "AWAITING_REVIEW" ? params.status === "AWAITING_REVIEW" : params.status === "AWAITING_ID_CHECK",
    }));

  return (
    <WrappedComponent
      orderList={orderList}
      status={orderStatus}
      category={category}
      loading={orderListLoader}
      sortKey={orderListParams.sortKey}
      isAscending={orderListParams.isAscending}
      currentPage={orderListParams.currentPage}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
      totalItems={category === "id-check"? totalIdChecks: category === "preapprovedproduct" ? totalPreApprovedProductCustomer :  totalOrders}
      pageSize={ORDER_LIMIT}
      idChecks={idChecks}
      preApprovedProductCustomerList={preApprovedProductCustomerList}
    />
  );
};
