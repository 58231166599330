import React, {
  FC,
  useRef,
  useMemo,
  useState,
  ChangeEvent,
  useCallback,
} from "react";
import {
  makeStyles,
  Theme,
  fade,
  InputBase,
  Typography,
  Popper,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Cancel";
import Highlighter, { Chunk, FindChunks } from "react-highlight-words";

import {
  customerSearch_customerSearch,
  customerSearch_customerSearch_address,
} from "./__generated__/customerSearch";
import { workOutAge, formatDate, ukDateToIso } from "utils/time";
import { useHistory } from "react-router-dom";
import ListTileWrapper, { Main } from "../../../ListTile/ListTile";
// import { useAppSelector } from "redux/hooks";
// import { selectBookwellGroup } from "redux/reduxSlice/selector";

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      maxWidth: `calc(32ch + 2 * (1rem + ${theme.spacing(4)}px))`,
      width: `calc(32ch + 2 * (1rem + ${theme.spacing(4)}px))`,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    cursor: "pointer",
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  inputInput: {
    // Left & right padding is icon font-size + required padding between input
    padding: theme.spacing(1, `calc(1rem + ${theme.spacing(4)}px)`),
    height: "48px",
    width: "100%",
    color: "#fff",
  },
  popper: {
    zIndex: 4,
  },
  searchResults: {
    fontSize: "1rem",
    width: `calc(32ch + 2 * (2rem + ${theme.spacing(4)}px))`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    maxHeight: "calc(78px * 3.5)",
    overflow: "auto",
  },
}));

function addressToSingleLine(address: customerSearch_customerSearch_address) {
  const parts: string[] = [];

  for (const key of Object.keys(address)) {
    const value = address[key as keyof customerSearch_customerSearch_address];
    if (key !== "__typename" && value) {
      parts.push(value);
    }
  }

  return parts.join(", ");
}

export interface SearchProps {
  searchInput: string;
  searchQuery: string;
  isLoading: boolean;
  isSearching: boolean;
  results?: (customerSearch_customerSearch | null)[] | null;

  onQueryChange(event: ChangeEvent<HTMLInputElement>): void;

  clearSearchInput(): void;
}

const Search: FC<SearchProps> = ({
  searchInput,
  searchQuery,
  isLoading,
  isSearching,
  results,
  onQueryChange,
  clearSearchInput,
}) => {
  const searchWords = useMemo(() => searchQuery.split(" "), [searchQuery]);
  const classes = useStyles();
  const history = useHistory();
  const searchAnchorRef = useRef(null);
  const [isFoused, setIsFocused] = useState(false);
  
  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);
  
  const clearSearch = useCallback(() => {
    clearSearchInput();
  }, [clearSearchInput]);

  const gotoCustomer = useCallback(
    (id: string) => () => {
      clearSearchInput();
      setIsFocused(false);
      history.push(`/customer-details/${id}`);
    },
    [clearSearchInput, history],
  );

  const highlightDate = useCallback(
    ({ textToHighlight }: FindChunks) => {
      const chunks: Chunk[] = [];
      const dob = textToHighlight.split(" ")[0];
      const formattedDob = ukDateToIso(dob);

      for (const word of searchWords) {
        if (word === formattedDob) {
          chunks.push({
            start: 0,
            end: dob.length,
          });
          break;
        }
      }

      return chunks;
    },
    [searchWords],
  );
// const bookwellGroup = useAppSelector(selectBookwellGroup);
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      {searchInput && (
        <div
          data-testid={"clear-search"}
          className={classes.clearIcon}
          onClick={clearSearch}
        >
          <ClearIcon />
        </div>
      )}
      <InputBase
        ref={searchAnchorRef}
        placeholder={"Search customer name, DOB or postcode"}
        classes={{
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search", "data-testid": "search-input" }}
        value={searchInput}
        onChange={onQueryChange}
        onFocus={onFocus}
      />

      <Popper
        data-testid={"search-results"}
        anchorEl={searchAnchorRef.current}
        placement={"bottom-start"}
        open={!!searchInput && isFoused}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={clearSearch}>
          <Paper className={classes.searchResults} elevation={8}>
            {isLoading && searchInput &&
              <ListTileWrapper>
                <Main>
                  <Typography>Loading...</Typography>
                </Main>
              </ListTileWrapper>}
            {results && results.length > 0
              ? results.map((result) => (
                <ListTileWrapper
                  key={`customer-${result!.id}`}
                  isButton
                  onClick={gotoCustomer(result!.id)}
                >
                  <Main>
                    <Typography variant={"h6"} color={"textPrimary"}>
                      <Highlighter
                        autoEscape
                        searchWords={searchWords}
                        textToHighlight={`${result!.firstName} ${result!.lastName
                          }`}
                      />
                    </Typography>
                    <Typography variant={"caption"} component={"p"}>
                      <Highlighter
                        autoEscape
                        searchWords={searchWords}
                        textToHighlight={`${formatDate(
                          result!.dob,
                        )} (${workOutAge(result!.dob)}yrs)`}
                        findChunks={highlightDate}
                      />
                    </Typography>
                    <Typography variant={"caption"} component={"p"}>
                      <Highlighter
                        autoEscape
                        searchWords={searchWords}
                        textToHighlight={`${addressToSingleLine(
                          result!.address,
                        )}`}
                      />
                    </Typography>
                  </Main>
                </ListTileWrapper>
              ))
              : !isLoading &&
              !isSearching && (
                <ListTileWrapper>
                  <Main>
                    <Typography>No results</Typography>
                  </Main>
                </ListTileWrapper>
              )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default Search;