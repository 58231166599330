import { gql } from "@apollo/client";

export const ScreeningFragment = gql`
  fragment Screening on Screening {
    screeningId
    patientAdvice
    pharmacistInstructions
    questionText
    currentQuestionNumber
    nextQuestionNumber
    questionType
    message
    hasResult
    isAllowedService
  }
`;
