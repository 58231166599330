import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Appointment } from "graphql/types";
import { Button, Modal, ModalTitle, Typography } from "@welldigital/components";
import { MedicineInput } from "__generated__/globalTypes";

const useStyles = makeStyles((theme: Theme) => ({
  continueButton: {
    marginRight: theme.spacing(1),
    width: "240px",
  },
}));

export interface ChooseBookNextAppointmentModalProps {
  isModalOpen: boolean;
  appointment: Appointment;
  completeAppointment?: (medicine: MedicineInput) => Promise<any>;
  completeWithoutMedicine?: () => void;
}

const ChooseBookNextAppointmentModal = (
  props: ChooseBookNextAppointmentModalProps,
) => {
  const {
    appointment,
    isModalOpen,
    completeAppointment,
    completeWithoutMedicine,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const {
    id,
    course: { name, onSchedServiceId },
    medicine,
  } = appointment;
  const [isTheModalOpen, setIsModalOpen] = useState(false);
  const isSaxenda = process.env.REACT_APP_SAXENDA === onSchedServiceId;

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleComplete = useCallback(() => {
    if (completeWithoutMedicine) {
      completeWithoutMedicine();
      closeModal();
    } else if (completeAppointment) {
      completeAppointment({
        ...medicine!,
        batchNumber: medicine!.batchNumber ?? "",
      });
    }
  }, [closeModal, completeAppointment, medicine, completeWithoutMedicine]);

  useEffect(() => {
    setIsModalOpen(isModalOpen);
  }, [isModalOpen]);

  const bookNextAppointment = useCallback(() => {
    history.push(`/service-details/${id}/book-next`);
  }, [history, id]);

  return (
    <Modal
      data-testid={"chooseBookNextAppointmentModal/bookNextAppointmentModal"}
      open={isTheModalOpen}
      onClose={closeModal}
    >
      <ModalTitle label={"Book next appointment"} />
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        {`${name} requires a follow up appointment.`}
      </Typography>
      <Button
        key={"chooseBookNextAppointmentModal/bookNext-button"}
        data-testid={"chooseBookNextAppointmentModal/bookNext-button"}
        className={classes.continueButton}
        onClick={bookNextAppointment}
      >
        Continue
      </Button>
      {isSaxenda && (
        <Button
          key={"chooseBookNextAppointmentModal/complete-button"}
          data-testid={"chooseBookNextAppointmentModal/complete-button"}
          className={classes.continueButton}
          onClick={handleComplete}
          variant={"outlined"}
          color={"primary"}
        >
          Cancel all appointments
        </Button>
      )}
    </Modal>
  );
};

export default ChooseBookNextAppointmentModal;
