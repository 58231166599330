import axios from "axios";

export interface rescheduleAppointment {
    locationId: string | undefined;
    serviceId: string | undefined;
    resourceId: string;
    startDateTime: Date;
    endDateTime: Date;
    travelTimeMins: number,
    travelAppointmentId: string
  }

export async function rescheduleAppointmentAPI(
    rescheduleAppointmentPayload: rescheduleAppointment, appointmentId: string
  ): Promise<any> {
    const { data } = await axios.post(`${process.env.REACT_APP_POSTCODE_API}/services/appointments/${appointmentId}/reschedule`, rescheduleAppointmentPayload);
    return data;
  }