import { subYears } from "date-fns";

export function isDateValid(date: Date) {
  if (!date) return false;
  return !isNaN(new Date(date).getDate());
}

export function isUnder18(date: Date): boolean {
  const targetDate = subYears(new Date(), 18);
  return isDateValid(date) && new Date(date) > targetDate;
}

export function isAbove65(date: Date): boolean {
  const targetDate = subYears(new Date(), 65);
  return isDateValid(date) && new Date(date) < targetDate;
}

export function isAbove74(date: Date): boolean {
  const targetDate = subYears(new Date(), 74);
  return isDateValid(date) && new Date(date) < targetDate;
}
