import { formatDateAws, getDaysBetweenDate } from "utils/time";
import { DayInput } from "__generated__/globalTypes";

export function getCoverDays(startDate: Date, endDate: Date): DayInput[] {
  const days = getDaysBetweenDate(startDate, endDate);
  const coverDates: DayInput[] = days.map((d) => {
    return {
      date: formatDateAws(d),
    };
  });
  return coverDates;
}
