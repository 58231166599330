import {
  API_BASE_URL,
  IMAGES_API_BASE_URL,
  WARNINGS_API_BASE_URL,
  SUBSCRIPTION_API_BASE_URL,
  apiInstance,
} from "../../constants/api";
import { Customer, CustomerBC, ProductStatus } from "./Types";
/**
 * Fetch order details
 * @param orderId Number
 * @returns promise
 */
export function fetchOrderDetails(orderId: Number) {
  return apiInstance
    .get(`${API_BASE_URL}/orders/${orderId}`)
    .then((response) => response.data);
}

/**
 * Fetch Awaiting order list
 * @param orderId Number
 * @returns promise
 */
export function fetchOrderList({
  orderStatus = "AWAITING_REVIEW",
  orderId,
  limit = 10,
  category = "",
  sortKey,
  isAscending,
}: fetchOrderListTypes) {
  const params = {
    order_status: orderStatus,
    limit,
    page: orderId,
    category,
    sortBy: `${sortKey}.${isAscending ? "asc" : "desc"}`,
  };



  const paramsString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

  return apiInstance
    .get(`${API_BASE_URL}/orders?${paramsString}`)
    .then((resp) => resp.data);
}

export function fetchIdVerification({
  status,
  limit = 10,
  page,
}: fetchIdCheckListTypes) {
  status = status === "AWAITING_ID_CHECK" ? "awaiting" : status === "ID_CHECK_COMPLETED" ? "completed" : "awaiting"
  const params = {
    status,
    limit,
    page: page,
  };

  const paramsString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

  return apiInstance
    .get(`${API_BASE_URL}/idchecks?${paramsString}`)
    .then((resp) => resp.data);
}

export function fetchIdVerificationAwaitCount() {
  return apiInstance
    .get(`${API_BASE_URL}/count/idchecks`)
    .then((resp) => resp.data);
}

export function updateIdCheckStatus(customerId: number, updateIdCheck: updateIdCheckStatus) {
  return apiInstance
    .put(`${API_BASE_URL}/customers/${customerId}/idchecks`, updateIdCheck)
    .then((resp) => resp);
}

export function updateOrderStatus(orderId: number, updateOrder: updateOrderStatus) {
  return apiInstance
    .put(`${API_BASE_URL}/orders/${orderId}`, updateOrder)
    .then((resp) => resp);
}
export interface updateOrderStatus {
  status: string;
  updatedBy: string;
  rejectAll?: boolean;
}
export interface updateIdCheckStatus {
  status: string;
  lnSearchRef: string;
  updatedBy: string;
}
export interface fetchOrderListTypes {
  orderStatus: string;
  category?: String;
  orderId: Number;
  limit?: number;
  sortKey: string;
  isAscending: boolean;
}

export interface fetchIdCheckListTypes {
  status: string;
  limit?: number;
  page: number;
}

/**
 * Fetch Customer details
 * @param customerId Number
 * @param limit Number
 * @param page Number
 * @returns promise
 */
export function fetchOrderHistoryDetails({
  customerId,
  currentPage,
  orderId,
}: fetchOrderHistoryListTypes) {
  return apiInstance
    .get(
      `${API_BASE_URL}/customers/${customerId}/orders?limit=5&page=${currentPage}&skip=${orderId}`,
    )
    .then((response) => response.data);
}

export interface fetchOrderHistoryListTypes {
  customerId: Number;
  currentPage?: Number;
  orderId: number;
}

export function postOrderNote({ orderId, ...payload }: postOrderNoteTypes) {
  return apiInstance
    .post(`${API_BASE_URL}/orders/${orderId}/notes`, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error:", error);
    });
}

export interface postOrderNoteTypes {
  orderId: Number;
  noteText: String;
  createdBy: String;
}

/**
 * Get uploaded images
 * @param order_id
 * @param product_id
 * @returns
 */
export const getImages = (order_id: Number, secondary_tag: string, index: number) => {
  const currentTime = new Date().getTime();
  const uri = `${IMAGES_API_BASE_URL}/get_image/${order_id}/${secondary_tag.toLowerCase()}/${index}?ts=${currentTime}`;
  let requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return apiInstance.get(uri, requestOptions).then((res) => res.data);
};

/**
 * Fetch customer details
 * @param customerId Number
 * @returns promise
 */
export function fetchCustomerDetails(customerId: string | Number,treatment?: string) {
  const apiURL=treatment?`${API_BASE_URL}/customers/${customerId}?treatment=${treatment}` : `${API_BASE_URL}/customers/${customerId}`
  return apiInstance
    .get(apiURL)
    .then((resp) => resp.data);
}

/**
 * Update customer details
 * @param customerId number
 * @param customer Customer
 * @returns Promise
 */
export function updateCustomerDetails(customerId: number, customer: Customer, isBillingAddress: boolean) {
  if (isBillingAddress) {
    const { addressDetails: _, ...newCustomer } = customer;
    return apiInstance
      .put(`${API_BASE_URL}/customers/${customerId}`, newCustomer)
      .then((resp) => resp.data);
  } else {
    return apiInstance
      .put(`${API_BASE_URL}/customers/${customerId}`, customer)
      .then((resp) => resp.data);
  }
}

export function updateCustomerAddressDetails(customerId: number, customer: CustomerBC) {
  return apiInstance
    .put(`${API_BASE_URL}/customers/${customerId}/addresses`, customer)
    .then((resp) => resp.data);
}

export function fetchConsultationForm(traversalId : string) {
  return apiInstance
    .get(
      `${IMAGES_API_BASE_URL}/traversals/fb/${traversalId}`,
    )
    .then((resp) => resp.data);
}

export function updateApproveOrReject(
  orderId: number,
  producId: number,
  status: ProductStatus,
  comment: string,
  reviewedBy: string,
) {
  return apiInstance
    .put(`${API_BASE_URL}/orders/${orderId}/products/${producId}`, {
      status,
      comment,
      reviewedBy,
    })
    .then((resp) => resp.data);
}

export function getOrderWarnings(orderId: number, customerId: number) {
  //Fix for DWS-1153 - removing customerid from path params
  return apiInstance
    .get(`${WARNINGS_API_BASE_URL}/order/warnings/${orderId}`)
    .then((resp) => resp.data);
}

export function getSubscriptionDetails(subscriptionId: string) {
  return apiInstance
    .get(`${`${SUBSCRIPTION_API_BASE_URL}/subscriptions/${subscriptionId}`}`)
    .then((resp) => resp.data);
}

export function getSubscriptionHistory(
  subscriptionId: string,
  limit: number,
  page: number,
) {
  return apiInstance
    .get(
      `${API_BASE_URL}/subscriptions/${subscriptionId}/orders?limit=${limit}&page=${page}`,
    )
    .then((resp) => resp.data);
}

export interface fetchPreApprovedProductCustomerListTypes {
  page: string;
  limit?: number;
  sortKey: string;
  isAscending: boolean;
}

export function fetchPreApprovedProductCustomerList({ page,
  limit = 10,
  sortKey,
  isAscending,
}: fetchPreApprovedProductCustomerListTypes) {
  const params = {
    page,
    limit,
    sortBy: `${sortKey}.${isAscending ? "asc" : "desc"}`,
  };

  const paramsString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

  return apiInstance
    .get(`${API_BASE_URL}/preapprovedproducts/customers?${paramsString}`)
    .then((resp) => resp.data);
}

export function fetchPreApprovedProducts() {
  return apiInstance
    .get(`${process.env.REACT_APP_POSTCODE_API}/utils/preapprovedproducts/products`)
    .then((resp) => resp.data.data);
}
export interface CreatCartPayloadType {
  items: [
    {
      quantity: number;
      id?: number;
      doseNumber?: number,
      treatmentName?: string
    }
  ];
}

export function createCart(customerId: number, payload: CreatCartPayloadType) {
  return apiInstance
    .post(`${API_BASE_URL}/customers/${customerId}/cart`, payload)
    .then((resp) => resp);
}

export interface DeletePatientPayloadType {
  comment: string;
}

export function deletePreApprovedProductPatient(customerId: number,treatment:string, payload: DeletePatientPayloadType) {
  return apiInstance
    .delete(`${API_BASE_URL}/customers/${customerId}?treatment=${treatment}`, { data: payload })
    .then((resp) => resp);
}

export function fetchPreApprovedProductIdVerification(id: number) {

  return apiInstance
    .get(`${API_BASE_URL}/customers/${id}/idchecks`)
    .then((resp) => resp.data);
}

export interface UploadImages {
  title: string;
  src: string;
  order_item_id: number;
  file_name: string;
  order_id: number;
}

export function uploadImages(payload: UploadImages[]) {
  return apiInstance
    .post(`${IMAGES_API_BASE_URL}/image_upload`, payload)
    .then((resp) => resp);
}

export function confirmBMI({ orderId, productId, createdBy }: { orderId: number, productId: number, createdBy: string }) {
  return apiInstance
    .post(`${API_BASE_URL}/orders/${orderId}/products/${productId}/bmi`, { createdBy })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error:", error);
    });
}

export interface updateTreatment {
  treatmentName: string;
  updatedBy: string;
  currentTreatment: string;
}
export function updateTreatment(customerId: number, payload: updateTreatment) {
  return apiInstance
    .put(`${API_BASE_URL}/customers/${customerId}/treatments`, payload)
    .then((resp) => resp);
}