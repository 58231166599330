import React, { useCallback } from "react";
import {
  TimeRangeField,
  Form,
  FormConfirmationModalProps,
  Button,
  ModalActions,
  Typography,
  ModalTitle,
} from "@welldigital/components";
import { useHistory } from "react-router-dom";
import useLunchHours from "../hooks/useLunchHours";
import RescheduleConfirmation from "../../IndividualService/RescheduleConfirmation/RescheduleConfirmation";

export type LunchHoursProps = {
  onClose: () => void;
};

const LunchHours: React.FC<LunchHoursProps> = ({ onClose }) => {
  const history = useHistory();
  const { lunchHours, saveLunchHours, isLunchHoursLoading, isLunchHoursSaving } = useLunchHours();
  const onLunchHoursSubmit = useCallback(
    ({ lunchHours }) =>
      saveLunchHours(lunchHours).then(() => {
        onClose();
      }),
    [saveLunchHours, onClose],
  );

  const renderConfirmationModal = useCallback(
    (props: FormConfirmationModalProps) => (
      <RescheduleConfirmation {...props} />
    ),
    [],
  );
  if (isLunchHoursLoading) {
    return <p data-testid={"lunchHours/loading"}>Loading...</p>;
  }
  return (
    <>
      <ModalTitle
        label={"Lunch hours"}
        variant={"subtitle"}
        onBack={history.goBack}
      />
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        Enter the lunch hour for your pharmacy here. Customers will not be able
        to book appointments during this time.
      </Typography>
      <Form
        initialValues={{ lunchHours }}
        onSubmit={onLunchHoursSubmit}
        renderConfirmationModal={renderConfirmationModal}
      >
        <TimeRangeField
          name={"lunchHours"}
          required
          disabled={isLunchHoursLoading}
        />
        <ModalActions>
          <Button
            variant={"outlined"}
            color={"primary"}
            children={"Close"}
            size={"medium"}
            onClick={onClose}
          />
          <Button
            data-testid={"lunchHours/save"}
            type={"submit"}
            variant={"contained"}
            color={"secondary"}
            children={"Save"}
            size={"medium"}
            disabled = {isLunchHoursSaving}
          />
        </ModalActions>
      </Form>
    </>
  );
};

export default LunchHours;
