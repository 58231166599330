import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import clinicalReviewReducer from "../pages/ClinicalReviewPage/reduxSlice";
import bookwellReducer from "./reduxSlice";

export const store = configureStore({
  reducer: {
    clinicalReview: clinicalReviewReducer,
    bookwell:bookwellReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
