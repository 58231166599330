import React from "react";
import { Typography } from "@material-ui/core";

export interface CaptionedTextFieldProps {
  caption: string;
  value: string;
}

const CaptionedTextField: React.FC<CaptionedTextFieldProps> = (props) => {
  const { caption, value } = props;
  return (
    <div>
      <Typography variant={"caption"}>{caption}</Typography>
      <Typography variant={"body1"}>{value}</Typography>
    </div>
  );
};

export default CaptionedTextField;
