import React from "react";
import ReactDOM from "react-dom";
import "whatwg-fetch";
import "./public-path";
import { analytics } from "@welldigital/ui-common";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import "@welldigital/components/font/index.css";
import { prescriptionsUrlVar, homedeliveryUrlVar } from "./reactive-vars";

const mapFromLocationTo = new Map([
  ["servos.dev.wellbd.co.uk", "https://bookwell.dev.wellbd.co.uk"],
  ["servos.uat.wellbd.co.uk", "https://bookwell.uat.wellbd.co.uk"],
  ["servos.well.co.uk", "https://bookwell.well.co.uk"],
]);

if (mapFromLocationTo.get(window.location.host) !== undefined) {
  window.location.replace(mapFromLocationTo.get(window.location.host)!);
}

function render({
  container,
  prescriptionsUrl = "",
  homedeliveryUrl = "",
}: any) {
  analytics.init();
  prescriptionsUrlVar(prescriptionsUrl);
  homedeliveryUrlVar(homedeliveryUrl);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
      <App />
      </Provider>
    </React.StrictMode>,
    container
      ? container.querySelector("#root")
      : document.querySelector("#root"),
  );
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap(): Promise<void> {}

export async function mount(props: any): Promise<void> {
  render(props);
}

export async function unmount({ container }: any): Promise<void> {
  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector("#root")
      : document.querySelector("#root"),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
