import React, { useMemo } from "react";
import {
  Button,
  ConditionalFields,
  DaysMultiSelectField,
  PeriodTypes,
  SelectField,
  TimeSlotsField,
  Typography,
  InputField,
} from "@welldigital/components";
import { makeStyles } from "@material-ui/core";

export type WorkinghoursProps = {
  onDelete: () => void;
  baseName: string;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  primary: {
    display: "flex",
  },
  primaryType: {
    width: "230px",
  },
  primaryRange: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "& *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  time: {
    "&:nthchild": {
      display: "flex",
    },
  },
  secondary: {
    margin: theme.spacing(1, 0),
    display: "flex",
    alignItems: "center",
  },
  secondaryDays: {
    flex: 1,
  },
  secondaryAction: {
    flex: "0 0 auto",
  },
}));

const WorkingHoursContent: React.FC<WorkinghoursProps> = ({
  baseName,
  onDelete,
}) => {
  const classes = useStyles();
  const typeOptions = useMemo(
    () => [
      { label: "All week", value: PeriodTypes.allWeek },
      { label: "Weekdays", value: PeriodTypes.weekdays },
      { label: "Weekends", value: PeriodTypes.weekends },
    ],
    [],
  );

  return (
    <div className={classes.root}>
      <div className={classes.primary}>
        <div className={classes.primaryType}>
          <SelectField
            name={`${baseName}.periodType`}
            options={typeOptions}
            required
            spacingAfter={0}
          />
        </div>
        <div className={classes.input}>
          <InputField name={`${baseName}.hiddenID`} type={"hidden"} />
        </div>
        <div className={classes.primaryRange}>
          <div className={classes.time}>
            <TimeSlotsField
              name={`${baseName}.startTime`}
              label={"From"}
              spacingAfter={0}
              required
            />
          </div>
          <Typography>to</Typography>
          <div className={classes.time}>
            <TimeSlotsField
              name={`${baseName}.endTime`}
              label={"To"}
              spacingAfter={0}
              required
            />
          </div>
        </div>
      </div>
      <div className={classes.secondary}>
        <div className={classes.secondaryDays}>
          <ConditionalFields fieldName={`${baseName}.periodType`}>
            {(periodType: PeriodTypes) => (
              <DaysMultiSelectField
                periodType={periodType}
                name={`${baseName}.weekdays`}
                spacingAfter={0}
                required
              />
            )}
          </ConditionalFields>
        </div>
        <div
          className={classes.secondaryAction}
          data-testid={"workingHours/delete"}
        >
          <Button
            color={"primary"}
            variant={"text"}
            size={"small"}
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkingHoursContent;
