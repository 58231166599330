import React, { useCallback } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import { Divider, Spacing, Typography } from "@welldigital/components";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import ReactMarkdown from "react-markdown";
import { ApolloError } from "@apollo/client";
import { formatMarkdown } from "utils/markdown";
import ServiceSplitScreen from "components/ServiceSplitScreen/ServiceSplitScreen";
import { Question } from "graphql/types";
import {
  getAppointment,
  getAppointment_getAppointment_guardian,
} from "graphql/__generated__/getAppointment";
import { QuestionAudit } from "utils/question";
import EditQuestionsTable from "components/EditQuestionsTable/EditQuestionsTable";
import { PharmacistInput } from "../../__generated__/globalTypes";
import EditPharmacist from "../../components/EditPharmacist/EditPharmacist";
import AppointmentCountChip from "components/AppointmentCountChip/AppointmentCountChip";
import EditGuardian from "components/EditGuardian/EditGuardian";
import EditWegovyQuestionsTable from "components/EditWegovyQuestionsTable/EditWegovyQuestionsTable";
import { differenceInYears } from "date-fns";

const confirmStatementLabel =
  "I confirm this patient meets the service specification.";

export interface EditServiceDetailsPageProps {
  appointmentId: string;
  isLoading?: boolean;
  error?: ApolloError;
  data?: getAppointment;
  notes?: string | null;
  questionAudits: QuestionAudit[];
  onNotesChange: (n: string) => void;
  onSubmit: () => void;
  onQuestionUpdate: (q: Question) => void;
  updatedPharmacist: PharmacistInput;
  handleUpdatePharmacist: (pharmacist: PharmacistInput) => void;
  handleUpdateGuardian: (
    guardian: getAppointment_getAppointment_guardian | null,
  ) => void;
}

const EditServiceDetailsPage: React.FC<EditServiceDetailsPageProps> = (
  props,
) => {
  const {
    onNotesChange,
    onSubmit,
    questionAudits,
    onQuestionUpdate,
    handleUpdatePharmacist,
    handleUpdateGuardian,
    updatedPharmacist,
  } = props;
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const handleAppointmentNotesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onNotesChange(event.target.value);
    },
    [onNotesChange],
  );

  const handleConfirm = useCallback(
    (e: React.ChangeEvent<{}>, checked: boolean) => {
      setIsConfirmed(checked);
    },
    [setIsConfirmed],
  );

  if (props.isLoading) return <p>Loading...</p>;
  if (props.error) return <p>Error: {props.error.message}</p>;
  if (!props.data?.getAppointment) return <p>no data</p>;
  const { getAppointment: appointment } = props.data;

  const isUnder18 =
    differenceInYears(new Date(), new Date(appointment.customer?.dob)) <= 18;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const disableAction =
    !isConfirmed ||
    !updatedPharmacist.gphcNumber ||
    !updatedPharmacist.name ||
    !updatedPharmacist.title;

  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `/customer-details/${appointment.customer.id}`,
            name: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
          },
          {
            url: `/service-details/${appointment.id}`,
            name: `${appointment.course?.name}`,
          },
          {
            url: `/service-details/${appointment.id}/edit`,
            name: "Appointment in progress",
          },
        ]}
      />
      <ServiceSplitScreen
        actionButtonText={"Next"}
        appointment={appointment}
        isActionDisabled={disableAction}
        onActionSubmit={onSubmit}
      >
        <AppointmentCountChip appointmentId={appointment.id} />
        <Spacing spacing={1} />
        <Typography variant={"h3"} spacingAfter={1}>
          Review customer information
        </Typography>
        <Typography variant={"h4"} spacingAfter={1}>
          Pharmacist information
        </Typography>
        <EditPharmacist
          appointment={appointment}
          pharmacist={appointment?.pharmacist}
          handleUpdatePharmacist={handleUpdatePharmacist}
        />
        <Divider spacingBefore={2} spacingAfter={2} />

        {isUnder18 && (
          <>
            <EditGuardian
              appointment={appointment}
              guardian={appointment?.guardian}
              handleUpdateGuardian={handleUpdateGuardian}
            />
            <Divider spacingBefore={2} spacingAfter={2} />
          </>
        )}

    { appointment?.course.onSchedServiceId === process.env.REACT_APP_WEGOVY_SERVICES? 
     <Grid>
     <EditWegovyQuestionsTable
      appointmentId={appointment.id}
     />
   </Grid>
     :  <Grid>
     <EditQuestionsTable
       questionAudits={questionAudits}
       handleQuestionUpdate={onQuestionUpdate}
     />
   </Grid> 
      }

        {appointment.course?.pharmacistInstructions && (
          <>
            <Divider spacingBefore={2} spacingAfter={2} />
            <div>
              <Typography variant={"h5"} spacingAfter={0}>
                Pharmacist instructions
              </Typography>
              <ReactMarkdown
                source={formatMarkdown(
                  appointment.course?.pharmacistInstructions,
                )}
              />
            </div>
          </>
        )}

        <Divider spacingBefore={0} spacingAfter={2} />

        <Typography variant={"h5"} spacingAfter={1}>
          Pharmacist notes
        </Typography>
        <TextField
          inputProps={{
            "data-testid": "editServiceDetails/notes-input",
          }}
          multiline
          rows={6}
          fullWidth
          value={props.notes}
          onChange={handleAppointmentNotesChange}
        />

        <Divider spacingBefore={2} spacingAfter={2} />
        <Typography variant={"h5"} spacingAfter={1}>
          Confirmation statement
        </Typography>
        <FormControlLabel
          data-testid={"editServiceDetails/confirmation-checkbox"}
          control={<Checkbox color={"primary"} />}
          label={confirmStatementLabel}
          onChange={handleConfirm}
        />
      </ServiceSplitScreen>
    </>
  );
};

export default EditServiceDetailsPage;
