import { Days, PeriodTypes } from "@welldigital/components";

export function parsePeriodType(weekdays: Days[]) {
  let periodType: PeriodTypes;
  if (
    (weekdays.includes(Days.sat) || weekdays.includes(Days.sun)) &&
    (weekdays.includes(Days.mon) ||
      weekdays.includes(Days.tue) ||
      weekdays.includes(Days.wed) ||
      weekdays.includes(Days.thu) ||
      weekdays.includes(Days.fri))
  ) {
    periodType = PeriodTypes.allWeek;
  } else if (
    weekdays.length <= 2 &&
    (weekdays.includes(Days.sat) || weekdays.includes(Days.sun))
  ) {
    periodType = PeriodTypes.weekends;
  } else {
    periodType = PeriodTypes.weekdays;
  }
  return periodType;
}
