import { Typography } from "@welldigital/components";
import React from "react";
import { Link, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  paddingBottom: {
    paddingBottom: theme.spacing(3),
  },
  paddingBottomLg: {
    paddingBottom: theme.spacing(4),
  },
  bullets: {
    color: "#BDC3C7",
    paddingLeft: theme.spacing(2),
  },
  black: {
    color: "black",
  },
}));

function CovidPreInformation() {
  const classes = useStyles();

  return (
    <>
      <Typography variant={"h2"} className={classes.paddingBottom}>
        Covid-19 screening
      </Typography>
      <Typography variant={"body1"} className={classes.paddingBottom}>
        This test is not suitable if you think you might have COVID-19. You
        should not book an appointment if you or someone you live with has any
        symptoms of coronavirus, including:
      </Typography>
      <ul className={`${classes.bullets} ${classes.paddingBottom}`}>
        <li>
          <Typography variant={"body1"} className={classes.black}>
            a high temperature, or
          </Typography>
        </li>
        <li>
          <Typography variant={"body1"} className={classes.black}>
            a new, continuous cough,
          </Typography>
        </li>
        <li>
          <Typography variant={"body1"} className={classes.black}>
            or loss or change to sense of smell or taste.
          </Typography>
        </li>
      </ul>
      <Typography variant={"body1"} className={classes.paddingBottomLg}>
        Check the{" "}
        <Link
          target={"__blank"}
          href={"https://www.nhs.uk/conditions/coronavirus-covid-19/"}
        >
          NHS website for information
        </Link>{" "}
        and advice if you have symptoms of coronavirus.
      </Typography>
    </>
  );
}

export default CovidPreInformation;
