import { useQuery } from "@apollo/client";
//import { useAsync } from "../../../hooks/useAsync";
//import { getLocationId } from "../../../services/auth";
import {
  LocationQueryVars,
  GET_LOCATION,
} from "../../AvailabilityModal/IndividualServices/gql";
import { getLocation } from "../../AvailabilityModal/IndividualServices/__generated__/getLocation";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

export function useLocation(skip: boolean) {
  const locationId = useAppSelector(selectLocationId);
 // const { status, value: locationId } = useAsync<string>(getLocationId, true);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data, loading, error } = useQuery<getLocation, LocationQueryVars>(
    GET_LOCATION,
    {
      variables: { locationId: locationId as string, ignoreCovid: false },
      skip: !locationId || skip,
      //skip: !locationId || skip || status === "pending",
    },
  );

  return {
    locationId,
    //loading: status === "pending" || loading,
    loading: loading,
    location: data?.getLocation,
    //error: status === "error" ? "Couldn't retrieve locationId" : error?.message,
    error: error?.message,
  };
}
