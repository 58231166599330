import React, { useMemo, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { formatDate } from "utils/time";
import { getDay } from "date-fns";
import {
  Button,
  Form,
  FormConfirmationModalProps,
  InputField,
  ModalActions,
  TimeSlotsField,
  Checkbox,
  RadioSelectField,
  IRadioOption,
} from "@welldigital/components";
import { AddBlockRequestVariables } from "./types";
import { getRepeatInput } from "./utils";
import {
  WalkInFluClinicFormType,
  WALK_IN_FLU_CLINIC_TYPO,
  setNewEndDate,
  setStartDate,
} from "../WalkInFluClinic/WalkInFluClinic";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import RescheduleConfirmation from "../../IndividualService/RescheduleConfirmation/RescheduleConfirmation";

export interface ResourceBlockWalkInFluClinicFormProps {
  onSubmit: (values: AddBlockRequestVariables) => void;
  block?: WalkInFluClinicFormType | null;
  mockTime?: Date;
  formTitle?: string;
}

// e.g. 9am becomes 900
function getNumberHoursAndMins(d: Date): number {
  return d.getHours() * 100 + d.getMinutes();
}

export function convertInputBlockToVars(
  values: WalkInFluClinicFormType,
): AddBlockRequestVariables {
  const weekdayCode = getDay(new Date(values.startDate)).toString();
  const monthDay = new Date(values.startDate).getDate();

  const repeat = getRepeatInput(
    values.repeat?.frequency ?? "",
    weekdayCode,
    monthDay,
  );

  const startDateTime = new Date(values.startTime ?? setStartDate());
  const endDateTime = new Date(values.endTime ?? setNewEndDate());

  return {
    ...values,
    startDate: formatDate(values.startDate, "yyyy-MM-dd"),
    endDate: formatDate(values.endDate, "yyyy-MM-dd"),
    repeat,
    repeats: !!repeat,
    startTime: getNumberHoursAndMins(startDateTime),
    endTime: getNumberHoursAndMins(endDateTime),
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  ageBoxLabel: {
    font: "inherit",
    color: "currentColor",
    minWidth: "205px",
    height: "48px",
    display: "block",
    padding: "6px 0 7px",
    background: "none",
    borderRadius: "4px",
    margin: "0px 32px 0px 17px",
    border: "1px solid #d1d1d1",
  },
}));

function ResourceBlockWalkInFluClinicForm({
  block,
  onSubmit,
  ...props
}: ResourceBlockWalkInFluClinicFormProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
 
  const [isAllDay, setAllDay] = useState(false);

  const classes = useStyles();

  const handleAllDayCheckbox = useCallback(() => {
    setAllDay(!isAllDay);
  }, [setAllDay, isAllDay]);

  const doSubmit = useCallback(
    (values: WalkInFluClinicFormType) => {
      try {
        const formValues = convertInputBlockToVars({
          ...values,
          allDay: isAllDay,
        });
        onSubmit(formValues);
      } catch (e) {
        console.error("failed to submit form: ", e);
      }
    },
    [onSubmit, isAllDay],
  );

  const renderConfirmationModal = useCallback(
    (props: FormConfirmationModalProps) => (
      <RescheduleConfirmation {...props} />
    ),
    [],
  );

  const ageCheck: IRadioOption[] = useMemo(
    () => [
      { label: "Over 65", id: "Over 65" },
      { label: "Under 65", id: "Under 65" },
    ],
    [],
  );

  const history = useHistory();

  return (
    <Form
      initialValues={block}
      onSubmit={doSubmit}
      renderConfirmationModal={renderConfirmationModal}
    >     
      <Grid container spacing={2}>
        <Grid xs={12}>
          <RadioSelectField
            className={classes.ageBoxLabel}
            name={"fluWalkInCategory"}
            options={ageCheck}
            required
            spacingAfter={2}
            onChange={(event?:any) => {
              localStorage.setItem("ageCheck", event);
            }}
            label={"Select clinic type"}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            type={"date"}
            name={"startDate"}
            id={"ResourceBlockWalkInFluClinicForm/selectedStartDate-datePicker"}
            data-testid={"ResourceBlockWalkInFluClinicForm/selectedStartDate-datePicker"}
            label={"Start Date"}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            name={"endDate"}
            id={"ResourceBlockWalkInFluClinicForm/selectedEndDate-datePicker"}
            type={"date"}
            label={"End Date"}
          />
        </Grid>
        
          <>
            <Grid item xs={4}>
              {!isAllDay ? 
                <TimeSlotsField
                  name={"startTime"}
                  required
                  label={"Start Time"}
                  fullWidth
                /> 
                : 
                <TimeSlotsField
                  name={"startTime"}
                  disabled
                  label={"Start Time"}
                  fullWidth
                />
              }
            </Grid>
            <Grid item xs={4}>
              {!isAllDay ? 
                <TimeSlotsField
                  name={"endTime"}
                  required
                  label={"End Time"}
                  fullWidth
                />
                : 
                <TimeSlotsField
                  name={"endTime"}
                  disabled
                  label={"End Time"}
                  fullWidth
                />
              }
            </Grid>
            <Grid item xs={4}>
              <Checkbox onChange={handleAllDayCheckbox} label={"All day"} />
            </Grid>
          </>
          
        <Grid item xs={12}>
          <ModalActions>
            <Button
              data-testid={"back-button"}
              variant={"outlined"}
              color={"primary"}
              onClick={history.goBack}
              size={"medium"}
            >
              Back
            </Button> 
            <Button
              type={"submit"}
              variant={"contained"}
              color={"secondary"}
              children={WALK_IN_FLU_CLINIC_TYPO.buttons.save}
              size={"medium"}
            />
          </ModalActions>
        </Grid>
      </Grid>
    </Form>
  );
}

export default ResourceBlockWalkInFluClinicForm;
