import React from "react";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) => ({
  oval: {
    height: 140,
    width: 600,
    position: "absolute",
    right: 60,
    bottom: 0,
  },
  ovalCopy: {
    height: 150,
    width: 600,
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  logo: {
    height: 50,
    width: 100,
    position: "absolute",
    right: 80,
    bottom: 10,
  },
}));

const LogInCorner: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.oval}>
        <svg
          width={"600"}
          height={"140"}
          viewBox={"0 0 600 140"}
          fill={"none"}
          xmlns={"http://www.w3.org/2000/svg"}
        >
          <path
            d={
              "M677 1354C1050.9 1354 1354 1050.9 1354 677C1354 303.103 1050.9 0 677 0C303.103 0 0 303.103 0 677C0 1050.9 303.103 1354 677 1354Z"
            }
            fill={"#E6F5F4"}
          />
        </svg>
      </div>
      <div className={classes.ovalCopy}>
        <svg
          width={"600"}
          height={"150"}
          viewBox={"0 0 600 150"}
          fill={"none"}
          xmlns={"http://www.w3.org/2000/svg"}
        >
          <path
            d={
              "M677 1354C1050.9 1354 1354 1050.9 1354 677C1354 303.103 1050.9 0 677 0C303.103 0 0 303.103 0 677C0 1050.9 303.103 1354 677 1354Z"
            }
            fill={"#1A5E79"}
          />
        </svg>
      </div>
      <div className={classes.logo}>
        <svg
          width={"80"}
          height={"28"}
          viewBox={"0 0 80 28"}
          fill={"none"}
          xmlns={"http://www.w3.org/2000/svg"}
        >
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={
              "M9.40589 7.37489H7.46911V5.48462C7.46911 4.47005 6.65956 3.64029 5.66984 3.64029H5.58016C4.59072 3.64029 3.78062 4.47005 3.78062 5.48462V7.37489H1.84438C0.829779 7.37489 0 8.18469 0 9.17411V9.26406C0 10.2538 0.829779 11.0636 1.84438 11.0636H3.78062V13.046C3.78062 14.0605 4.59072 14.8903 5.58016 14.8903H5.66984C6.65956 14.8903 7.46911 14.0605 7.46911 13.046V11.0636H9.40589C10.4202 11.0636 11.25 10.2538 11.25 9.26406V9.17411C11.25 8.18469 10.4202 7.37489 9.40589 7.37489Z"
            }
            fill={"white"}
          />
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={
              "M39.6435 7.50815C38.4055 7.11384 37.045 7.7416 36.6193 8.9033L33.1808 18.2972L29.8397 9.1712L29.7414 8.9033C29.4052 7.98427 28.4838 7.39989 27.4999 7.39097C26.5167 7.39989 25.5944 7.98427 25.2581 8.9033L25.1598 9.1712L21.8193 18.2972L18.3805 8.9033C17.9552 7.7416 16.5944 7.11384 15.3567 7.50815C14.1186 7.90246 13.4535 9.17581 13.8783 10.3372L19.5546 25.8429L19.5671 25.878C19.9049 26.8001 20.8318 27.3851 21.8193 27.3903C22.8069 27.3851 23.7338 26.8001 24.0712 25.878L24.0838 25.8426L27.4999 16.5117L30.9158 25.8429L30.9283 25.878C31.2664 26.8001 32.1933 27.3851 33.1808 27.3903C34.1678 27.3851 35.095 26.8001 35.4325 25.878L35.4453 25.8426L41.1213 10.3372C41.547 9.17581 40.8816 7.90246 39.6435 7.50815Z"
            }
            fill={"white"}
          />
          <mask
            id={"mask0"}
            mask-type={"alpha"}
            maskUnits={"userSpaceOnUse"}
            x={"62"}
            y={"0"}
            width={"6"}
            height={"28"}
          >
            <path
              fillRule={"evenodd"}
              clipRule={"evenodd"}
              d={"M62.6064 0H67.3703V27.1726H62.6064V0Z"}
              fill={"white"}
            />
          </mask>
          <g mask={"url(#mask0)"}>
            <path
              fillRule={"evenodd"}
              clipRule={"evenodd"}
              d={
                "M65.0619 -9.15527e-05H64.9148C63.645 -9.15527e-05 62.6064 0.997317 62.6064 2.21614V24.9561C62.6064 26.1752 63.645 27.1726 64.9148 27.1726H65.0619C66.3314 27.1726 67.3703 26.1752 67.3703 24.9561V2.21614C67.3703 0.997317 66.3314 -9.15527e-05 65.0619 -9.15527e-05Z"
              }
              fill={"white"}
            />
          </g>
          <path
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            d={
              "M55.8766 15.1353C55.8704 15.1098 55.8651 15.0873 55.861 15.0711C55.8651 15.0873 55.8704 15.1098 55.8766 15.1353ZM46.9814 15.0337C47.0918 14.7254 47.2241 14.4329 47.3754 14.1586C47.6299 13.6608 47.9628 13.233 48.3662 12.8854C49.1401 12.2164 50.1755 11.8439 51.4199 11.8439C51.4452 11.8439 51.4698 11.8451 51.4951 11.8454C53.6938 11.8746 55.3019 13.1855 55.8525 15.0337H46.9814ZM61.2474 16.5957C60.8811 11.2044 56.8032 7.39029 51.4195 7.39029C45.7746 7.39029 41.25 11.8883 41.25 17.3903C41.25 22.8926 45.8199 27.3903 51.4641 27.3903C51.6587 27.3903 51.855 27.3857 52.0518 27.3775C54.9992 27.3535 57.3114 26.4467 59.0967 25.0051C59.2293 24.8907 59.3544 24.7585 59.4636 24.608C59.7038 24.278 59.8666 23.8623 59.8666 23.3597C59.8666 22.1191 58.9039 21.1697 57.5953 21.1697C56.9785 21.1697 56.593 21.3154 56.1693 21.607C55.0238 22.4128 53.7348 22.8826 52.2019 22.9188C52.1385 22.9227 51.451 22.9319 51.3346 22.9255C49.5187 22.8266 48.0806 21.967 47.3484 20.6509C47.2851 20.5321 47.2249 20.4104 47.1684 20.2848C47.0087 19.9269 46.8805 19.542 46.7847 19.1369H58.9117C61.1082 19.1369 61.2683 17.3313 61.2486 16.6328C61.248 16.6201 61.2474 16.6079 61.2474 16.5957Z"
            }
            fill={"white"}
          />
          <mask
            id={"mask1"}
            mask-type={"alpha"}
            maskUnits={"userSpaceOnUse"}
            x={"70"}
            y={"0"}
            width={"5"}
            height={"28"}
          >
            <path
              fillRule={"evenodd"}
              clipRule={"evenodd"}
              d={"M70.2144 0H74.9783V27.1726H70.2144V0Z"}
              fill={"white"}
            />
          </mask>
          <g mask={"url(#mask1)"}>
            <path
              fillRule={"evenodd"}
              clipRule={"evenodd"}
              d={
                "M72.6699 -9.15527e-05H72.5228C71.253 -9.15527e-05 70.2144 0.997317 70.2144 2.21614V24.9561C70.2144 26.1752 71.253 27.1726 72.5228 27.1726H72.6699C73.9394 27.1726 74.9783 26.1752 74.9783 24.9561V2.21614C74.9783 0.997317 73.9394 -9.15527e-05 72.6699 -9.15527e-05Z"
              }
              fill={"white"}
            />
          </g>
        </svg>
      </div>
    </>
  );
};

export default LogInCorner;
