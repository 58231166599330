import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { Link as RouterLink } from "react-router-dom";
import { WEBSITE_NAME } from "../../constants";

export interface LoginPageSSOProps {
  title?: string;
  hideSignInWithEmail?: boolean;
  onSubmit(): void;
}

const LoginPageSSO: React.FC<LoginPageSSOProps> = ({
    onSubmit,
    hideSignInWithEmail,
    title = WEBSITE_NAME
}) => {
  return (
    <>
      <Typography variant={"h1"} spacingAfter={3}>
        Welcome to {title}
      </Typography>
      <Typography spacingAfter={3}>
        Click the button below to automatically login with your Well account.
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            type={"submit"}
            data-testid={"login/signin-button"}
            onClick={onSubmit}
          >
            Sign in
          </Button>
        </Grid>
        {!hideSignInWithEmail && (
          <Grid item>
            <Button
              color={"primary"}
              variant={"outlined"}
              type={"submit"}
              data-testid={"login/signinWithEmail-button"}
              component={RouterLink}
              to={`login?email=true`}
            >
              Sign in with email
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LoginPageSSO;
