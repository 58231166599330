import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CLINICAL_REVIEW_USER_GROUP } from "../../../constants";
import {
  ClinicalReview,
  CognitoUserInfo,
  WarningsResponse,
  WarningsType,
} from "../Types";

import {
  getOrderDetailsAsync,
  getCustomerDetailsAsync,
  getOrderListAsync,
  getOrderHistoryDetailsAsync,
  setOrderNoteAsync,
  getOrderWarningsAsync,
  getSubscriptionDetailsAsync,
  getIdVerificationAsync,
  getIdVerificationAwaitCountAsync,
  getPreApprovedProductCustomerListAsync,
  getPreApprovedProductsAsync,
  getPreApprovedProductIdVerificationAsync
} from "./actionsAsync";

const initSubscription: ClinicalReview["subscription"] = {
  orders: [],
  id: "",
  cartId: "",
  productId: 0,
  customerId: 0,
  issueNumber: 0,
};

const initialState: ClinicalReview = {
  userGroup: undefined,
  orderDetails: null,
  orderList: [],
  orderHistory: null,
  customerDetails: null,
  totalOrders: 0,
  noteAdded: 0,
  userDetails: undefined,
  warnings: [],
  warningsLoader: false,
  customerDetailsLoader: false,
  orderDetailsLoader: false,
  orderListLoader: false,
  subscription: initSubscription,
  subscriptionOrders: [],
  orderForOthersWarning: undefined,
  idChecks : [],
  totalIdChecks: 0,
  awaitCountIdChecks: 0,
  preApprovedProductCustomerList: [],
  preApprovedProducts: [],
  idChecksPreApprovedProduct: {
                    idCheckPreviousStatus: '',
                    idCheckStatus: '',
                    lnSearchRef: '',
                    lnStatusUpdateAt: '',
                    lnStatusUpdatedBy: ''
                  },
  totalPreApprovedProductCustomer: 0,
};
export const clinicalReviewSlice = createSlice({
  name: "clinicalReview",
  initialState,
  reducers: {
    setUserGroup: (
      state,
      { payload }: PayloadAction<CLINICAL_REVIEW_USER_GROUP | undefined>,
    ) => {
      state.userGroup = payload;
    },
    setUserDetails: (
      state,
      { payload }: PayloadAction<CognitoUserInfo | undefined>,
    ) => {
      state.userDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDetailsAsync.fulfilled, (state, action) => {
        state.orderDetails = action.payload;
      })
      .addCase(getOrderListAsync.fulfilled, (state, action) => {
        state.orderList = action.payload.orders;
        state.totalOrders = action.payload.total;
        state.customerDetails = null;
        state.warnings = [];
        state.orderForOthersWarning = undefined;
        state.orderHistory = null;
        state.subscription = initSubscription;
        state.orderDetails = null;
        state.orderListLoader = false;
      })
      .addCase(getOrderListAsync.pending, (state) => {
        state.orderListLoader = true;
      })
      .addCase(getOrderListAsync.rejected, (state) => {
        state.orderListLoader = false;
      })
      .addCase(getOrderHistoryDetailsAsync.fulfilled, (state, action) => {
        state.orderHistory = action.payload.orders ? action.payload : null;
      })
      .addCase(getCustomerDetailsAsync.fulfilled, (state, action) => {
        state.customerDetails = action.payload;
        state.customerDetailsLoader = false;
      })
      .addCase(setOrderNoteAsync.fulfilled, (state, action) => {
        state.noteAdded = new Date().getTime();
      })
      .addCase(getOrderWarningsAsync.pending, (state) => {
        state.warningsLoader = true;
      })
      .addCase(getOrderWarningsAsync.rejected, (state) => {
        state.warningsLoader = false;
      })
      .addCase(getCustomerDetailsAsync.pending, (state) => {
        state.customerDetailsLoader = true;
      })
      .addCase(getCustomerDetailsAsync.rejected, (state) => {
        state.customerDetailsLoader = false;
      })
      .addCase(getOrderWarningsAsync.fulfilled, (state, action) => {
        const {
          validation = [],
          age = [],
          orderForOthers = [],
          products = [],
          customer_age,
          customer_gender,
        } = action.payload as WarningsResponse;

        const warnings: WarningsType[] = [];

        if (customer_age?.header) {
          warnings.push({
            header: customer_age.header,
            type: "customer_age",
            descriptions: [customer_age.descriptions as string],
            id: `age`,
          });
        }
        if (customer_gender?.header) {
          warnings.push({
            header: customer_gender.header,
            type: "customer_gender",
            descriptions: [customer_gender.descriptions as string],
            id: `gender`,
          });
        }

        warnings.push(
          ...(age.map(({ header, descriptions }, index) => ({
            header,
            type: "age",
            descriptions: [descriptions as string],
            id: `age_${index}`,
          })) as WarningsType[]),
          ...(validation.map(({ header, descriptions }, index) => ({
            header,
            type: "validation",
            descriptions: [descriptions as string],
            id: `validation_${index}`,
          })) as WarningsType[]),
          ...(products.map(({ header, descriptions }, index) => ({
            header,
            type: "products",
            descriptions: descriptions as string[],
            id: `products_${index}`,
          })) as WarningsType[]),
        );
        state.orderForOthersWarning = !!orderForOthers.length
          ? {
              id: "order_for_others",
              header: orderForOthers[0].header,
              title: orderForOthers[0].descriptions as string,
            }
          : undefined;
        state.warnings = warnings;
        state.warningsLoader = false;
      })
      .addCase(getSubscriptionDetailsAsync.fulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      .addCase(getIdVerificationAsync.fulfilled, (state, action) => {
        state.idChecks = action.payload.idChecks;
        state.totalIdChecks = action.payload.total;
      }).addCase(getIdVerificationAwaitCountAsync.fulfilled, (state, action) => {
        state.awaitCountIdChecks = action.payload.total;
      }).addCase(getPreApprovedProductCustomerListAsync.fulfilled, (state, action) => {
        state.preApprovedProductCustomerList = action.payload.customers;
        state.totalPreApprovedProductCustomer = action.payload.total;
      }).addCase(getPreApprovedProductsAsync.fulfilled, (state, action) => {
        state.preApprovedProducts = action.payload;
      }).addCase(getPreApprovedProductIdVerificationAsync.fulfilled, (state, action) => {
        state.idChecksPreApprovedProduct = action.payload;
      }).addCase(getPreApprovedProductIdVerificationAsync.rejected, (state, action) => {
        state.idChecksPreApprovedProduct = initialState.idChecksPreApprovedProduct;
      });
  },
});

export default clinicalReviewSlice.reducer;
export const clinicalReviewActions = clinicalReviewSlice.actions;
