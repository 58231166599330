import React from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { Typography, Spacing } from "@welldigital/components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { WEBSITE_NAME } from "../../constants";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid Well email address")
    .trim()
    .lowercase()
    .required("Enter a valid Well email address"),
});

export interface LoginForm {
  email: string;
}

export type LoginPageProps = {
  onSubmit(data: LoginForm): void;
  errorMessage?: string;
  isSsoShown: boolean;
};

const LoginPage: React.FC<LoginPageProps> = ({
  onSubmit,
  errorMessage,
  isSsoShown,
}) => {
  const { register, handleSubmit, errors } = useForm<LoginForm>({
    validationSchema: LoginSchema,
  });

  return (
    <>
      <Typography variant={"h1"} spacingAfter={3}>
        Welcome to {WEBSITE_NAME}
      </Typography>
      <Typography>
        Enter your branch email address to get a sign-in code. Your branch email
        address should contain your store ID and end @well.co.uk
      </Typography>
      <form
        id={"login-form"}
        data-testid={"login-form"}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container>
          <Grid item xs={12}>
            <Spacing spacing={3} />
            <TextField
              fullWidth
              label={"Branch email address"}
              name={"email"}
              error={!!errors.email || !!errorMessage}
              helperText={errors.email && errors.email.message}
              inputRef={register}
              inputProps={{
                "data-testid": "login/email-input",
              }}
              FormHelperTextProps={
                {
                  "data-testid": "helper-text",
                } as any
              } // date-testid is omitted from the available props
            />
          </Grid>
        </Grid>
        {errorMessage && <ErrorAlert message={errorMessage} />}
        <Grid container spacing={2}>
          <Grid item>
            <Spacing spacing={4} />
            <Button type={"submit"} data-testid={"login/signin-button"}>
              Sign in
            </Button>
          </Grid>
          {isSsoShown && (
            <Grid item>
              <Spacing spacing={4} />
              <Button
                color={"primary"}
                variant={"outlined"}
                type={"submit"}
                data-testid={"login/signinWithWellAccount-button"}
                component={RouterLink}
                to={"login"}
              >
                Sign in with Well account
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default LoginPage;
