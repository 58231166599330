import {
  CONSULTATION_FORM_TITLE_MAP,
  CONSULTATION_FORM_TITLE_MAP_TO_SECONDARY_TAG,
} from "../../../../../constants";
import {
  ConsultationForm,
  TraversalSummary,
  TraversalSummaryResponse,
} from "pages/ClinicalReviewPage/Types";

type types = string | boolean | number;
type record = Record<
  string,
  types | Record<string, types> | Record<string, types>[]
>;

export function getConsultationFormTitle(
  formType: string,
  identifier: string,
  secondaryTag: string,
) {
  switch (formType) {
    case "ed":
    case "pmed":
      return CONSULTATION_FORM_TITLE_MAP[formType.toUpperCase()];
    case "lg":
      return CONSULTATION_FORM_TITLE_MAP_TO_SECONDARY_TAG[
        secondaryTag?.toLowerCase()
      ];
    case "healthhero":
      return CONSULTATION_FORM_TITLE_MAP[identifier];
  }
  return "";
}

export function parseConsultationForm(
  consultationForm: record,
  secondaryTag: string,
): ConsultationForm {
  const formType = consultationForm["form-type"] as string;
  const identifier = consultationForm.identifier as string;
  return {
    exCount: Number(consultationForm.exCount),
    title: getConsultationFormTitle(formType, identifier, secondaryTag),
    formType,
    productName: consultationForm.product_name as string,
    identifier,
    createdAt: consultationForm.created_at as string,
    wCount: Number(consultationForm.wCount),
    summary: parseSummary(
      formType,
      identifier,
      consultationForm.traversal_summary as record[],
    ),
  };
}

function parseSummary(
  formType: string,
  identifier: string,
  summary: record[],
): TraversalSummary[] {
  switch (formType) {
    case "lg":
    case "ed":
      return summary.map((record) => {
        const responses = (
          (record.traversal_response as record).options as record[]
        )
          .filter((option) => option.checked)
          .map((option) => ({
            value: option.value as string,
            exclusion: (option.alert as string) === "red",
            warning: (option.alert as string) === "yellow",
          })) as TraversalSummaryResponse[];
        return {
          id: record.identifier as string,
          question: record.label as string,
          height: record?.height as string,
          weight: record?.weight as string,
          bmiValue: record?.bmiValue as number,
          responses,
          exclusion: !!responses.filter((resp) => resp.exclusion).length,
          warning: !!responses.filter((resp) => resp.warning).length,
        };
      });
    case "healthhero":
      return summary.map((record) => {
        const responses = (record.answers as record[])
          .filter((answer) => answer.isAnswered)
          .map((answer) => ({
            value: answer.value as string,
            // @ts-ignore
            exclusion: (answer.data?.Properties?.Alert as string) === "RedFlag",
            warning:
              // @ts-ignore
              (answer.data?.Properties?.Alert as string) === "YellowFlag",
          })) as TraversalSummaryResponse[];
        return {
          id: `${record.assetId}`,
          question: record.displayText as string,
          responses,
          exclusion: !!responses.filter((resp) => resp.exclusion).length,
          warning: !!responses.filter((resp) => resp.warning).length,
        };
      });
    case "pmed":
      return summary.map((record) => {
        const responses = (record.options as record[])
          .filter((option) => option.checked)
          .map((option) => ({
            value: option.value as string,
            exclusion: (option.alert as string) === "red",
            warning: (option.alert as string) === "yellow",
          })) as TraversalSummaryResponse[];
        return {
          id: `${record.id}`,
          question: record.label as string,
          responses,
          exclusion: !!responses.filter((resp) => resp.exclusion).length,
          warning: !!responses.filter((resp) => resp.warning).length,
        };
      });
    default:
      break;
  }
  return [];
}
