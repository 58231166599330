import React, { useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import SecretCodePage, { SecretCodeForm } from "./SecretCodePage";
import { answerCustomChallenge } from "services/auth";
// import { useAppSelector } from "redux/hooks";
// import { selectBookwellGroup } from "redux/reduxSlice/selector";

const SecretCodeContainer: React.FC = () => {
  let [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
 // const bookwellGroup = useAppSelector(selectBookwellGroup);
  const onSubmit = useCallback(
    async (data: SecretCodeForm) => {
      await answerCustomChallenge(data.secret);
      setIsLoggedIn(true);
    },
    [setIsLoggedIn],
  );

  if (isLoggedIn) {
   // return bookwellGroup ?  <Redirect to={"/central-visibility"} /> : <Redirect to={"/"} />;
    return <Redirect to={"/"} />;
  }

  return <SecretCodePage onSubmit={onSubmit}></SecretCodePage>;
};

export default SecretCodeContainer;
