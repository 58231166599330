import { gql } from "@apollo/client";

export const CANCEL_APPOINTMENT = gql`
  mutation cancelAppointment(
    $appointmentId: String!
    $shouldRefund: Boolean!
    $reason: String!
    $locationId: String!
    $cancelledBy: String!
  ) {
    cancelAppointment(
      appointmentId: $appointmentId
      shouldRefund: $shouldRefund
      reason: $reason
      locationId: $locationId
      cancelledBy: $cancelledBy
    ) {
      id
      status
    }
  }
`;
