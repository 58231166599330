import { useQuery, QueryHookOptions, QueryResult } from "@apollo/client/react";
import { OperationVariables } from "@apollo/client";
import { DocumentNode } from "graphql";
import { useCallback } from "react";

/**
 * Small wrapper around `useQuery` so that we can use it imperatively.
 *
 * @see Credit: https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 *
 * @example
 * const callQuery = useImperativeQuery(query, options)
 * const handleClick = async () => {
 *   const{ data, error } = await callQuery()
 * }
 */
export default function useImperativeQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {},
): QueryResult<TData, TVariables>["refetch"] {
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    skip: true,
  });

  const imperativelyCallQuery = useCallback(
    (queryVariables: TVariables) => {
      return refetch(queryVariables);
    },
    [refetch],
  );

  return imperativelyCallQuery;
}
