import { gql } from "@apollo/client";

export const GET_PREVIOUS_APPOINTMENTS = gql`
  query getPreviousAppointments($appointmentId: ID!, $locationId: String!) {
    getPreviousAppointments(appointmentId: $appointmentId, locationId: $locationId) {
      id
      number
      startTime
      creationDate
      notes
      course {
        id
      }
      medicine {
        expiryDate
        manufacturer
        method
      }
      guardian {
        title
        firstName
        lastName
      }
    }
  }
`;
