import React, { useCallback, useState, useEffect } from "react";
import {
  Theme,
  makeStyles,
  TextField,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { Appointment } from "graphql/types";
import { PharmacistInput } from "../../__generated__/globalTypes";
import { colors } from "@welldigital/components";

const useStyles = makeStyles((theme: Theme) => ({
  titleInput: {
    marginBottom: theme.spacing(1),
    minWidth: "100px",
  },
  confirmTextField: {
    marginBottom: theme.spacing(1),
    backgroundColor: colors.updatedInputBackground,
  },
  confirmTitleInput: {
    backgroundColor: colors.updatedInputBackground,
  },
}));

export interface EditPharmacistProps {
  isEdited?: boolean;
  pharmacist: PharmacistInput | null;
  appointment: Appointment;
  handleUpdatePharmacist: (pharmacist: PharmacistInput) => void;
}

const EditPharmacist: React.FC<EditPharmacistProps> = (props) => {
  const { appointment, handleUpdatePharmacist, isEdited, pharmacist } = props;
  const classes = useStyles();
  const [pharmacistTitle, setPharmacistTitle] = useState(
    pharmacist ? pharmacist?.title : appointment?.pharmacist?.title || "",
  );
  const [pharmacistName, setPharmacistName] = useState(
    pharmacist ? pharmacist?.name : appointment?.pharmacist?.name || "",
  );
  const [gphcNumber, setGphcNumber] = useState(
    pharmacist
      ? pharmacist?.gphcNumber
      : appointment?.pharmacist?.gphcNumber || "",
  );

  const handleTitleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
    ) => {
      setPharmacistTitle(event.target.value as string);
    },
    [setPharmacistTitle],
  );

  const handlePharmacistNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPharmacistName(event.target.value);
    },
    [setPharmacistName],
  );
  const handleGphcNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGphcNumber(event.target.value);
    },
    [setGphcNumber],
  );

  useEffect(() => {
    handleUpdatePharmacist({
      title: pharmacistTitle,
      name: pharmacistName,
      gphcNumber: gphcNumber,
    });
  }, [pharmacistTitle, pharmacistName, gphcNumber, handleUpdatePharmacist]);

  return (
    <>
      <Typography variant={"h3"} spacingAfter={1}>
        {appointment.course?.name}
      </Typography>
      <Typography variant={"h4"} spacingAfter={2}>
        Pharmacist information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant={"outlined"} fullWidth>
            <InputLabel htmlFor={"outlined-title-native-simple"}>
              Title
            </InputLabel>
            <Select
              data-testid={"pharmacist-title"}
              value={pharmacistTitle}
              onChange={handleTitleChange}
              className={
                isEdited ? classes.confirmTitleInput : classes.titleInput
              }
              error={!pharmacistTitle}
            >
              {["Mr", "Mrs", "Miss", "Ms", "Mx", "Sir", "Dr"].map(
                (title, index) => {
                  return (
                    <MenuItem key={`${title}-${index}`} value={title}>
                      {title}
                    </MenuItem>
                  );
                },
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            data-testid={"pharmacist-gphc"}
            label={"GPhC number"}
            value={gphcNumber}
            className={isEdited ? classes.confirmTextField : undefined}
            onChange={handleGphcNumberChange}
            size={"medium"}
            fullWidth
            error={!gphcNumber}
            helperText={!gphcNumber && "Enter your GPhC number"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-testid={"pharmacist-name"}
            label={"Pharmacist name"}
            value={pharmacistName}
            className={isEdited ? classes.confirmTextField : undefined}
            onChange={handlePharmacistNameChange}
            size={"medium"}
            fullWidth
            error={!pharmacistName}
            helperText={!pharmacistName && "Enter your name"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditPharmacist;
