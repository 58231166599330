import React from "react";
import { InfoListItem } from "@welldigital/components";
import useServicePause from "../hooks/useServicePause";

const ManageServices: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { areAllPaused, areAllExceptFluPaused } = useServicePause();

  return (
    <>
      <InfoListItem
        label={"Manage individual services"}
        href={"/services"}
        disabled={!!areAllExceptFluPaused || !!areAllPaused}
      />
      <InfoListItem
        label={"Emergency service change"}
        href={"/emergencypause"}
      />
    </>
  );
};

export default ManageServices;
