import React, { FC } from "react";

import { Container, makeStyles, Theme } from "@material-ui/core";
import BreadCrumbs from "components/BreadCrumbs/Breadcrumbs";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const WalkInLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <BreadCrumbs
        links={[
          {
            url: "/",
            name: "Dashboard",
          },
          {
            url: `${window.location.pathname}`,
            name: `Book an appointment`,
          },
        ]}
      />
      <Container className={classes.container} maxWidth={"sm"} disableGutters>
        {children as Element}
      </Container>
    </>
  );
};

export default WalkInLayout;
