import axios from "axios";
export const API_HOST = process.env.REACT_APP_CV_API_BASE_URL;

export interface FetchServiceBlock {
  serviceId: string;
  locationId: string;
  startDate?: any | null;
  endDate?: any | null;
}
  export const fetchServiceBlock = async (params: FetchServiceBlock) => {
    const response = await axios.get(`${API_HOST}/services/locations/${params.locationId}/service/${params.serviceId}/blocks/${params.startDate}/${params.endDate}`, 
    );
    return response.data;
  };