import React, { FC, useEffect, useState } from "react";
import { ImagesInterface } from "pages/ClinicalReviewPage/Types";
import "./viewImages.scss"
import { formatDate } from "utils/time";


interface ImageCarosuleInterface {
  imageList: ImagesInterface[];
  current: number;
  imageUploaded: (src: string) => void;
  imageCount: number;
}
export const UPLOAD_IMAGE_LIMIT = 5;

const ImageCarosule: FC<ImageCarosuleInterface> = (props) => {

  const [images, setImages] = React.useState<ImagesInterface[]>([]);
  const [imgSrc, setImgSrc] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [uploadedDetails, setUploadedDetails] = useState("")

  useEffect(() => {
    setImages(props.imageList);

  }, [props.imageList]);

  React.useEffect(() => {
    console.log("data:image/png;base64," + images[0]?.src);
    setTitle(images[0]?.title);
    setImgSrc("data:image/png;base64," + images[0]?.src);
    if (images.length > 0 && images[0].created_at && images[0].created_at !== undefined && images[0].created_by) {
      const createdAt = formatDate(images[0].created_at || "", "hh:mm dd/MM/yy");
      const createdBy = images[0].created_by;
      setUploadedDetails(createdAt + ' ' + createdBy)
    }
  }, [images]);

  return (
    <div className={"image_carsolue"}>
      <p className="imageTitle">
        {title}
      </p>
      <p>Uploaded {uploadedDetails}</p>
      {images?.length > 0 && <div className="image_container"><div><img src={imgSrc} alt={`image_${title}`} /></div></div>}
      {images?.length > 0 && <p className="image_numbers">
        {props.current + " of " + props.imageCount}
      </p>}
    </div>
  );
};

export default ImageCarosule;
