import React, { useCallback, useEffect, useState } from "react";
import LoginPageSSO from "pages/LoginPage/LoginPageSSO";
import { azureLogin, isAuthenticated } from "services/auth";
import { ORIGIN_URL, WEBSITE_NAME_CLINICAL_REVIEW } from "../../../constants";
import { useLocation } from "react-router-dom";
import { Spacing } from "@welldigital/components";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import { useAppSelector } from "redux/hooks";
import { selectUserGroup } from "../reduxSlice/selector";
import { Helmet } from "react-helmet";
import "./index.scss";
import { apiInstance } from "constants/api";

const LoginPageContainer: React.FC = () => {
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const userGroup = useAppSelector(selectUserGroup);

  useEffect(() => {
    sessionStorage.removeItem(ORIGIN_URL);
  }, []);

  useEffect(() => {
    isAuthenticated().then((isAuth) => {
      if (
        isAuth &&
        !userGroup &&
        apiInstance.defaults.headers.common.Authorization
      ) {
        setIsError(true);
      }
    });
  }, [userGroup, setIsError]);

  const onSubmit = useCallback(async () => {
    sessionStorage.setItem(ORIGIN_URL, location.pathname.replace("/login", ""));
    azureLogin();
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{WEBSITE_NAME_CLINICAL_REVIEW}</title>
      </Helmet>
      <LoginPageSSO
        hideSignInWithEmail
        title={WEBSITE_NAME_CLINICAL_REVIEW}
        onSubmit={onSubmit}
      />
      {isError && (
        <>
          <Spacing spacing={3} />
          <ErrorAlert
            className="error-container"
            message={"Access Denied!!! Please contact administrator"}
          />
        </>
      )}
    </>
  );
};

export default LoginPageContainer;
