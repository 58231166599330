import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query Customer($customerId: ID!, $locationId: String!) {
    getCustomer(id: $customerId, locationId: $locationId) {
      id
      firstName
      lastName
      email
      sex
      dob
      phone
      nhsNumber
      surgeryName
      surgeryAddress
      address {
        line1
        line2
        city
        postcode
      }
      appointments {
        id
        startTime
        endTime
        source
        onschedApptId
        modifiedBy
        modifiedAt
        course {
          id
          name
          paymentType
          onSchedServiceId
        }
        status
      }
    }
  }
`;
