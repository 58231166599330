import React, { FC, useCallback, useMemo,useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BookAnAppointment from "./BookAnAppointment";
import { ROUTES } from "app/routers/LoggedInRoutes";
import { useAppointmentPersister } from "persister/useAppointmentPersister";
import { ErrorAlertProps } from "components/ErrorAlert/ErrorAlert";
//import { getLocationId } from "services/auth";
import { BookInitAppointment, initBookingAPI } from "pages/WalkInCustomerDetails/components/AddressFields/api.fetchers";
import { initErrorMessage, KEY_INIT_API } from "components/Screening/hooks/useBookAppointment";
import { setToLocalStorage } from "utils/localStorageUtils";
import { useAppSelector } from "redux/hooks";
import { selectLocationId } from "redux/reduxSlice/selector";

const BookAnAppointmentContainer: FC = () => {
  const history = useHistory();
  const locationId = useAppSelector(selectLocationId);

  const [initError, setInitError] = useState<ErrorAlertProps | null>();

  const { appointment } = useAppointmentPersister();

  const onCancel = useCallback(() => {
    history.push("/");
  }, [history]);

  const onNext = useCallback(() => {
    if (!appointment || !appointment.serviceId || !appointment.bookedSlot) {
      console.error("Missing serviceId or bookedSlot: ", appointment);
      return;
    }
    // history.push(ROUTES.walkIn.customerDetails);  
    if (appointment?.bookedSlot && locationId){
       // getLocationId().then((locationId) => {
         
          let requestInitBooking: BookInitAppointment[] = [];
          requestInitBooking.push({
            locationId: locationId,
            serviceId: parseInt(appointment?.serviceId!!),
            resourceId: parseInt(appointment?.bookedSlot?.resourceId!!),
            startDateTime: appointment?.bookedSlot?.startTime!!,
            endDateTime: appointment?.bookedSlot?.endTime!!,
          });
          initBookingAPI(requestInitBooking).then((response) => {
            console.log('response is', response)
            if (!response || !response[0]) {
              setInitError({
                message: "Response is blank after initBooking API",
              });
            }
           let initialappointment = response[0];
           setToLocalStorage(KEY_INIT_API,initialappointment)  
           history.push(ROUTES.walkIn.customerDetails);
          })
         
         .catch((error)=>{
          if(error && error.message.indexOf('409') && appointment.bookedSlot){
            setInitError({message:initErrorMessage})
          }
          else{            
            setInitError({
              message: "Response is blank after initBooking API",
            });
          }
         });
        
       // });
      }
     //  Store it to localStorage       
  }, [history, appointment,locationId]);

useEffect(()=>{
 if (appointment?.bookedSlot){
   setInitError(null)
 }
},[appointment, history])

  const isNextDisabled = useMemo(
    () => !appointment || !appointment.serviceId || !appointment.bookedSlot,
    [appointment],
  );

  return (
    <BookAnAppointment
      onCancel={onCancel}
      onNext={onNext}
      nextDisabled={isNextDisabled}
      initError={initError!!}
    />
  );
};

export default BookAnAppointmentContainer;