import React, { useCallback } from "react";
import {
  ItemManagerField,
  ItemManagerFieldProps,
  PeriodTypes,
  Typography,
  ModalTitle,
  Form,
  ModalActions,
  Button,
  FormConfirmationModalProps,
  Days,
} from "@welldigital/components";
import { useHistory } from "react-router-dom";
import RescheduleConfirmation from "../../IndividualService/RescheduleConfirmation/RescheduleConfirmation";
import useWorkingHours, { useWorkHours } from "../hooks/useWorkingHours";
import WorkingHoursContent from "./WorkingHoursForm";
import { getDateFromTime } from "../../IndividualService/IndividualService.utils";
import { parsePeriodType } from "./workingHours.utils";

export type WorkingHoursProps = {
  onClose: () => void;
};
const WorkingHours: React.FC<WorkingHoursProps> = ({ onClose }) => {
  const history = useHistory();
  const { calendarID, locationId } = useWorkHours();
  const renderItem: ItemManagerFieldProps["renderItem"] = useCallback(
    (item) => <WorkingHoursContent {...item} />,
    [],
  );

  const renderConfirmationModal = useCallback(
    (props: FormConfirmationModalProps) => (
      <RescheduleConfirmation {...props} />
    ),
    [],
  );
  const { saveWorkingHours } = useWorkingHours();

  const onWorkingHoursSubmit = useCallback(
    ({ workingHoursInput }) =>
      saveWorkingHours(workingHoursInput).then(() => {
        onClose();
      }),
    [saveWorkingHours, onClose],
  );
  if (!locationId || !calendarID) {
    return <p data-testid={"workingHours/loading"}>Loading...</p>;
  } else {
    let workingHoursDaysFormatting: any[] = [];

    if (calendarID && calendarID[0]?.availability) {
      for (const [key, value] of Object.entries(calendarID[0].availability)) {
        if (key !== "__typename") {
          workingHoursDaysFormatting.push({
            startTime: value?.startTime,
            endTime: value?.endTime,
            weekdays: [key],
          });
        }
      }

      workingHoursDaysFormatting.forEach((unique, i, a) => {
        let index;
        for (index = 0; index < a.length; index++) {
          const isStartTimeSame = unique.startTime === a[index].startTime;
          const isEndTimeSame = unique.endTime === a[index].endTime;
          const isNotSameDay = unique.weekdays !== a[index].weekdays;
          if (isStartTimeSame && isEndTimeSame && isNotSameDay) {
            const concatWeekends = unique.weekdays.concat(a[index].weekdays);
            unique.weekdays = concatWeekends.sort();
            if (a.length > 1) {
              a.splice(index, 1);
              index = -1;
            }
          }
        }
      });
    }
    const workingHoursInput = workingHoursDaysFormatting?.map(
      (workingHoursInputField: {
        weekdays: Days[];
        startTime: number;
        endTime: number;
      }) => {
        return {
          periodType: parsePeriodType(workingHoursInputField.weekdays),
          startTime: getDateFromTime(workingHoursInputField.startTime),
          endTime: getDateFromTime(workingHoursInputField.endTime),
          weekdays: workingHoursInputField.weekdays,
        };
      },
    );

    return (
      <div data-testid={"workingHours/container"}>
        <ModalTitle
          label={"Appointment hours"}
          variant={"subtitle"}
          onBack={history.goBack}
        />
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          These are the hours that customers can book appointments.
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          00:00 - 00:00 indicates that these days are currently set to
          unavailable.
        </Typography>
        <Form
          initialValues={{ workingHoursInput }}
          onSubmit={onWorkingHoursSubmit}
          renderConfirmationModal={renderConfirmationModal}
        >
          <div data-testid={"workingHours/content"}>
            <ItemManagerField
              name={"workingHoursInput"}
              renderItem={renderItem}
              newItemValue={{ periodType: PeriodTypes.weekdays }}
              noItems={false}
            />
            <ModalActions>
              <Button
                data-testid={"button/close"}
                variant={"outlined"}
                color={"primary"}
                children={"Close"}
                size={"medium"}
                onClick={onClose}
              />
              <Button
                data-testid={"workingHours/save"}
                type={"submit"}
                variant={"contained"}
                color={"secondary"}
                children={"Save"}
                size={"medium"}
              />
            </ModalActions>
          </div>
        </Form>
      </div>
    );
  }
};

export default WorkingHours;
