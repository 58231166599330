import axios from "axios";
import TabTitle from "components/TabTitle/TabTitle";
import React, { useEffect, useState } from "react";
import { AlphabetNav } from "./components/AlphabetNav";
import { PharmacyLocation, PharmacyLocationProps } from "./components/PharmacyLocations";
import BreadCrumbs from "../../components/BreadCrumbs/Breadcrumbs";
import { Box } from "@material-ui/core";
import { Spacing } from "@welldigital/components";
export const API_HOST = process.env.REACT_APP_CV_API_BASE_URL;

const CentralVisibilityPage = () => {
  const [locationsData, setLocationsData] = useState<PharmacyLocationProps[]>([]);
  useEffect(() => {
    async function getLocations() {
      const response = await axios.get(`${API_HOST}/services/masterlocations`);
      if (response.status === 200) {
        setLocationsData(response.data)

      }
    }
    getLocations();
  }, [])

  return (
   <>
    <TabTitle title={"Dashboard"} />
    <BreadCrumbs
      links={[
        {
          url: "/",
          name: "Dashboard",
        },
      ]}
    />
    {locationsData.length > 0 && <Box mt={3}>
      <AlphabetNav locationData={locationsData} />
      <Spacing spacing={2} />
      {locationsData.filter(item => item?.title !== "%").map((location) => (
        <PharmacyLocation {...location} />
      ))}
    </Box>}
  </>);
};

export default CentralVisibilityPage;